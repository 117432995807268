import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimaryNavComponent } from './primary-nav/primary-nav.component';
import { FooterNavComponent } from './footer-nav/footer-nav.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationService } from './navigation.service';
import { CookieService } from 'ngx-cookie-service';
import { PlatformServicesModule } from '@investec-online/platform-services';
import { AppModuleStorybook } from '@investec/io-bootstrap';
import { LiveChatComponent } from './live-chat/live-chat.component';
import { CloseChatConformationComponent } from './live-chat/close-chat-conformation/close-chat-conformation.component';
import { BrowserDetectComponent } from './browser-detect/browser-detect.component';
import { BrowserDetectModalComponent } from './browser-detect/browser-detect-modal/browser-detect-modal.component';
import { RouterModule } from '@angular/router';
import { ShopfrontNavComponent } from './shopfront-nav/shopfront-nav.component';
import { ShopfrontFooterNavComponent } from './shopfront-footer-nav/shopfront-footer-nav.component';
import { MegaMenuComponent } from './mega-menu/mega-menu.component';
//import { PerfectScrollbarModule } from '@eklipse/perfect-scrollbar';

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PlatformServicesModule,
    AppModuleStorybook,
    RouterModule,
  ],
  providers: [NavigationService, CookieService],
  exports: [
    PrimaryNavComponent,
    ShopfrontNavComponent,
    ShopfrontFooterNavComponent,
    FooterNavComponent,
    ShopfrontFooterNavComponent,
    BrowserDetectComponent,
    MegaMenuComponent
  ],
  declarations: [
    PrimaryNavComponent,
    FooterNavComponent,
    LiveChatComponent,
    CloseChatConformationComponent,
    BrowserDetectComponent,
    BrowserDetectModalComponent,
    ShopfrontNavComponent,
    ShopfrontFooterNavComponent,
    MegaMenuComponent,
  ],
})
export class GlobalNavigationModule {}
