import {Component} from '@angular/core';
import { Router } from '@angular/router';
import { PreviousUrlService } from '../../../services/previous-url.service';
import {ProgrammableCardService} from "../../../services/programmable-card.service";
import {RootCard, RootCardAccount} from "../models/root-card.interface";

@Component({
  selector: 'investec-online-prog-cards-overview',
  templateUrl: './root-cards-overview.component.html',
  styleUrls: ['./root-cards-overview.component.scss']
})
export class ProgCardsOverviewComponent {
  cardList: Array<RootCard>;
  cardDisplay: Array<RootCard>;

  selectedAccount: RootCardAccount;

  profileList = [];
  accountList = [];
  cardAccountList: Array<RootCardAccount>;

  showEmptyCardListAlert = false;
  hasError = false;
  isLoading = true;
  isMobileResolution;
  showGridView = true;
  isLoadingIDE = false;
  loadingMessage = 'Loading...';

  constructor(
    private _rc: ProgrammableCardService,
    private router: Router,
    public previousUrlService: PreviousUrlService
  ) {
    this.isMobileResolution = window.innerWidth < 768;

    this._rc.rootCardsList.subscribe(res => {
      this.cardList = res;
      this.cardDisplay = res;

      if (this.cardList.length > 0) {
        this.cardAccountList = [];
        let acc = {AccountNumber: 'All'};
        this.cardAccountList.push(acc);
        this.cardList.forEach(c => {
          if (this.cardAccountList.find(x => x.AccountNumber == c.AccountNumber) == null) {
            let acc = {AccountNumber: c.AccountNumber};
            this.cardAccountList.push(acc);
          }
        });
      }

      this.cardList.length === 0 ? this.showEmptyCardListAlert = true : this.showEmptyCardListAlert = false;
    });
  }

  ngOnInit(): void {
    this.getCards();
  }

  getCards() {
    this._rc.getRootCards().subscribe(res => {
      this.hasError = false;
      this._rc.rootCardsList.next(res);

      this.isLoading = false;
    }, error => { 
      this.hasError = true;
    })
  }

  filterCardsByAccount(account) {
    if ((<RootCardAccount>account).AccountNumber !== 'All') {
      this.cardDisplay = this.cardList.filter(c => c.AccountNumber == (<RootCardAccount>account).AccountNumber);
    }
    else {
      this.cardDisplay = this.cardList;
    }
  }

  cardLayout(gridview) {
      this.showGridView = gridview;
  }

  viewTandCs() {
    this.router.navigate(['programmable-banking/ide/terms-and-conditions']);
  }
}
