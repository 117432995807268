<a *ngIf="!isMobile" (click)="selectCard()">
  <div class="card-number-container clickable" (click)="selectCard()">
    <div class="row is-programmable" *ngIf="rootCard.IsProgrammable">
      <div class="col-auto p-0">&lt;</div>
      <div class="col-auto p-0"><p class="wording">programmable</p></div>
      <div class="col-auto p-0">/&gt;</div>
    </div>
    <img src="assets/root/images/{{cardImage}}.png" class="img-fluid" alt="Investec card">
    <div class="row card-number">
      <div class="col-auto pr-0">
        <h6 class="mb-0">{{cardNumberArray[0]}} {{cardNumberArray[1]}} {{cardNumberArray[2]}}
          {{cardNumberArray[3]}}</h6>
      </div>
      <div class="col-auto pr-0">
        <h6 class="mb-0">{{cardNumberArray[4]}} {{cardNumberArray[5]}} {{cardNumberArray[6]}}
          {{cardNumberArray[7]}}</h6>
      </div>
      <div class="col-auto pr-0">
        <h6 class="mb-0">{{cardNumberArray[8]}} {{cardNumberArray[9]}} {{cardNumberArray[10]}}
          {{cardNumberArray[11]}}</h6>
      </div>
      <div class="col-auto pr-0">
        <h6 class="mb-0">{{cardNumberArray[12]}} {{cardNumberArray[13]}} {{cardNumberArray[14]}}
          {{cardNumberArray[15]}}</h6>
      </div>
    </div>
  </div>
</a>

<div *ngIf="!isMobile" class="ml-1 form-group row">
  <label class="col-3 col-form-label">Enabled:</label>
  <div class="col-4">
    <ui-switches [id]="rootCard.CardKeyHash" (returnEvt)="toggleCardForRoot($event, rootCard)"
                 [model]="rootCard.IsProgrammable"></ui-switches>
  </div>
</div>

<div *ngIf="isMobile" class="ml-1 form-group row">
  <div class="row card-number">
    <div class="col-auto pr-0">
      <h6 class="mb-0">{{cardNumberArray[0]}} {{cardNumberArray[1]}} {{cardNumberArray[2]}}
        {{cardNumberArray[3]}}</h6>
    </div>
    <div class="col-auto pr-0">
      <h6 class="mb-0">{{cardNumberArray[4]}} {{cardNumberArray[5]}} {{cardNumberArray[6]}}
        {{cardNumberArray[7]}}</h6>
    </div>
    <div class="col-auto pr-0">
      <h6 class="mb-0">{{cardNumberArray[8]}} {{cardNumberArray[9]}} {{cardNumberArray[10]}}
        {{cardNumberArray[11]}}</h6>
    </div>
    <div class="col-auto pr-0">
      <h6 class="mb-0">{{cardNumberArray[12]}} {{cardNumberArray[13]}} {{cardNumberArray[14]}}
        {{cardNumberArray[15]}}</h6>
    </div>
  </div>
  <div class="col-md-12">
    <div class="row">
      <label class="col-6 col-form-label">Enabled:</label>
      <div class="col-6">
        <ui-switches [id]="rootCard.CardKeyHash" (returnEvt)="toggleCardForRoot($event, rootCard)"
                     [model]="rootCard.IsProgrammable"></ui-switches>
      </div>
    </div>
  </div>
</div>
