<ui-full-page-loader [loading]="isLoadingIDE" [message]="loadingMessage"></ui-full-page-loader>

<div class="container mt-4">
  <h3 class="display-3">Programmable Banking</h3>
  <p>You can enable a card for Programmable Banking by <strong>toggling it on</strong> - this will enable
    executing your
    code against real world card transactions. Disabling a card will stop your code executing against real
    world
    card transactions HOWEVER your code will
    still be saved, you can simulate and you can enable a card again at any time.</p>
  <p class="lead">
    <a class="btn btn-primary" href="http://developer.investec.com/programmable-banking/" target="_blank" role="button">Learn more</a>
  </p>
</div>

<div class="container mt-4">
  <div *ngIf="!isMobileResolution">
    <p class="mt-2">Click on a card to start coding logic against the respective card. Please see the <a
      href="https://developer.investec.com/programmable-banking/" target="_blank">Programmable Banking documentation</a> and <a href="" (click)="viewTandCs(); false">Terms &amp; Conditions</a> for more information.</p>
  </div>

  <div *ngIf="!isLoading && !isMobileResolution" class="mb-2">
    <hr/>
    <div class="float-right">
      <label class="col-form-label">Layout:</label>
      <ui-icon (click)="cardLayout(true)" class="item ml-2 clickable" [name]="'grid-view-new'" [size]="'24'"
               placement="right" ngbTooltip="Show cards in a grid" container="body"></ui-icon>
      <ui-icon (click)="cardLayout(false)" class="item ml-2 clickable" [name]="'listview'" [size]="'24'"
               placement="right" ngbTooltip="Show cards in a list" container="body"></ui-icon>
    </div>
    <ui-dropdown *ngIf="cardAccountList.length > 0" [id]="'cardAccountSelect'"
      [fullWidth]="false"
      [optionList]="cardAccountList"
      [label]="'Account'"
      [disabled]="false"
      [scrollBar]="false"
      [isLabelBold]="false"
      [selectedOption]="cardAccountList[0].AccountNumber"
      [displayProperty]="'AccountNumber'"
      [class]=""
      (returnEvt)="filterCardsByAccount($event)"></ui-dropdown>
      <hr/>
  </div>

  <ui-table-content-loader *ngIf="isLoading"></ui-table-content-loader>

  <div *ngIf="hasError">
    <ui-alerts [alertName]="'danger'" [size]="true" [heading]="'Error in retrieving account information'"
                         [message]="'Sorry, we are unable to get your account information at this time.'"></ui-alerts>
  </div>
  <ui-alerts *ngIf="showEmptyCardListAlert" [alertName]="'info'"
                       [message]="'This profile does not have any programmable cards. Switch your profile and select the correct account to access your programmable card.'"></ui-alerts>


  <ng-container *ngIf="!isLoading && !showGridView">
    <div class="row">
      <div class="col-12" *ngFor="let card of cardDisplay">
        <investec-online-prog-card-row [rootCard]="card"
          [selectedAccountNumber]="card.AccountNumber"
          (isLoadingEmitter)="isLoadingIDE = $event"
          [isMobile]="isMobileResolution"></investec-online-prog-card-row>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isLoading && showGridView">
    <div class="row">
      <div class="pb-3" [ngClass]="{'col-12': isMobileResolution, 'col-4': !isMobileResolution}" *ngFor="let card of cardDisplay">
        <investec-online-prog-card-container [rootCard]="card"
          [selectedAccountNumber]="card.AccountNumber"
          (isLoadingEmitter)="isLoadingIDE = $event"
          [isMobile]="isMobileResolution"></investec-online-prog-card-container>
      </div>
    </div>
  </ng-container>

</div>
