import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from "@angular/core";

export interface UserType {
  defaultUserType: string,
  currentUserType: string
}

export class SetUserType {
  static readonly type = '[UserType] Add';

  constructor(public payload: UserType) {
  }
}

export class UserTypeStateModel {
  type: UserType;
  segment: string;
}

@State<UserTypeStateModel>({
  name: 'UserType'
})

@Injectable()

export class UserTypeState {

  @Selector()
  static getUserType(state: UserTypeStateModel) {
    return state.type;
  }

  @Selector()
  static getUserSegment(state: UserTypeStateModel) {
    return state.segment;
  }

  @Action(SetUserType)
  add({getState, patchState}: StateContext<UserTypeStateModel>, {payload}: SetUserType) {
    patchState({
      type: payload
    });
  }
}
