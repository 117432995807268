export interface MerchantInterface {
  code: string,
  name: string
}

export class MerchantClass {
  constructor() {
  }

  merchantList: Array<MerchantInterface> = [
    {
      code: "7623",
      name: "A/C, Refrigeration Repair"
    },
    {
      code: "8931",
      name: "Accounting/Bookkeeping Services"
    },
    {
      code: "7311",
      name: "Advertising Services"
    },
    {
      code: "763",
      name: "Agricultural Cooperative"
    },
    {
      code: "4511",
      name: "Airlines, Air Carriers"
    },
    {
      code: "4582",
      name: "Airports, Flying Fields"
    },
    {
      code: "4119",
      name: "Ambulance Services"
    },
    {
      code: "7996",
      name: "Amusement Parks/Carnivals"
    },
    {
      code: "5937",
      name: "Antique Reproductions"
    },
    {
      code: "5932",
      name: "Antique Shops"
    },
    {
      code: "7998",
      name: "Aquariums"
    },
    {
      code: "8911",
      name: "Architectural/Surveying Services"
    },
    {
      code: "5971",
      name: "Art Dealers and Galleries"
    },
    {
      code: "5970",
      name: "Artists Supply and Craft Shops"
    },
    {
      code: "7531",
      name: "Auto Body Repair Shops"
    },
    {
      code: "7535",
      name: "Auto Paint Shops"
    },
    {
      code: "7538",
      name: "Auto Service Shops"
    },
    {
      code: "5531",
      name: "Auto and Home Supply Stores"
    },
    {
      code: "6011",
      name: "Automated Cash Disburse"
    },
    {
      code: "5542",
      name: "Automated Fuel Dispensers"
    },
    {
      code: "8675",
      name: "Automobile Associations"
    },
    {
      code: "5533",
      name: "Automotive Parts and Accessories Stores"
    },
    {
      code: "5532",
      name: "Automotive Tire Stores"
    },
    {
      code: "9223",
      name: "Bail and Bond Payments (payment to the surety for the bond, not the actual bond paid to the government agency)"
    },
    {
      code: "5462",
      name: "Bakeries"
    },
    {
      code: "7929",
      name: "Bands, Orchestras"
    },
    {
      code: "7230",
      name: "Barber and Beauty Shops"
    },
    {
      code: "7995",
      name: "Betting/Casino Gambling"
    },
    {
      code: "5940",
      name: "Bicycle Shops"
    },
    {
      code: "7932",
      name: "Billiard/Pool Establishments"
    },
    {
      code: "5551",
      name: "Boat Dealers"
    },
    {
      code: "4457",
      name: "Boat Rentals and Leases"
    },
    {
      code: "5942",
      name: "Book Stores"
    },
    {
      code: "5192",
      name: "Books, Periodicals, and Newspapers"
    },
    {
      code: "7933",
      name: "Bowling Alleys"
    },
    {
      code: "4131",
      name: "Bus Lines"
    },
    {
      code: "8244",
      name: "Business/Secretarial Schools"
    },
    {
      code: "7278",
      name: "Buying/Shopping Services"
    },
    {
      code: "4899",
      name: "Cable, Satellite, and Other Pay Television and Radio"
    },
    {
      code: "5946",
      name: "Camera and Photographic Supply Stores"
    },
    {
      code: "5441",
      name: "Candy, Nut, and Confectionery Stores"
    },
    {
      code: "7512",
      name: "Car Rental Agencies"
    },
    {
      code: "7542",
      name: "Car Washes"
    },
    {
      code: "5511",
      name: "Car and Truck Dealers (New & Used) Sales, Service, Repairs Parts and Leasing"
    },
    {
      code: "5521",
      name: "Car and Truck Dealers (Used Only) Sales, Service, Repairs Parts and Leasing"
    },
    {
      code: "1750",
      name: "Carpentry Services"
    },
    {
      code: "7217",
      name: "Carpet/Upholstery Cleaning"
    },
    {
      code: "5811",
      name: "Caterers"
    },
    {
      code: "8398",
      name: "Charitable and Social Service Organizations - Fundraising"
    },
    {
      code: "5169",
      name: "Chemicals and Allied Products (Not Elsewhere Classified)"
    },
    {
      code: "5641",
      name: "Chidrens and Infants Wear Stores"
    },
    {
      code: "8351",
      name: "Child Care Services"
    },
    {
      code: "8049",
      name: "Chiropodists, Podiatrists"
    },
    {
      code: "8041",
      name: "Chiropractors"
    },
    {
      code: "5993",
      name: "Cigar Stores and Stands"
    },
    {
      code: "8641",
      name: "Civic, Social, Fraternal Associations"
    },
    {
      code: "7349",
      name: "Cleaning and Maintenance"
    },
    {
      code: "7296",
      name: "Clothing Rental"
    },
    {
      code: "8220",
      name: "Colleges, Universities"
    },
    {
      code: "5046",
      name: "Commercial Equipment (Not Elsewhere Classified)"
    },
    {
      code: "5139",
      name: "Commercial Footwear"
    },
    {
      code: "7333",
      name: "Commercial Photography, Art and Graphics"
    },
    {
      code: "4111",
      name: "Commuter Transport, Ferries"
    },
    {
      code: "4816",
      name: "Computer Network Services"
    },
    {
      code: "7372",
      name: "Computer Programming"
    },
    {
      code: "7379",
      name: "Computer Repair"
    },
    {
      code: "5734",
      name: "Computer Software Stores"
    },
    {
      code: "5045",
      name: "Computers, Peripherals, and Software"
    },
    {
      code: "1771",
      name: "Concrete Work Services"
    },
    {
      code: "5039",
      name: "Construction Materials (Not Elsewhere Classified)"
    },
    {
      code: "7392",
      name: "Consulting, Public Relations"
    },
    {
      code: "8241",
      name: "Correspondence Schools"
    },
    {
      code: "5977",
      name: "Cosmetic Stores"
    },
    {
      code: "7277",
      name: "Counseling Services"
    },
    {
      code: "7997",
      name: "Country Clubs"
    },
    {
      code: "4215",
      name: "Courier Services"
    },
    {
      code: "9211",
      name: "Court Costs, Including Alimony and Child Support - Courts of Law"
    },
    {
      code: "7321",
      name: "Credit Reporting Agencies"
    },
    {
      code: "4411",
      name: "Cruise Lines"
    },
    {
      code: "5451",
      name: "Dairy Products Stores"
    },
    {
      code: "7911",
      name: "Dance Hall, Studios, Schools"
    },
    {
      code: "7273",
      name: "Dating/Escort Services"
    },
    {
      code: "8021",
      name: "Dentists, Orthodontists"
    },
    {
      code: "5311",
      name: "Department Stores"
    },
    {
      code: "7393",
      name: "Detective Agencies"
    },
    {
      code: "5964",
      name: "Direct Marketing - Catalog Merchant"
    },
    {
      code: "5965",
      name: "Direct Marketing - Combination Catalog and Retail Merchant"
    },
    {
      code: "5967",
      name: "Direct Marketing - Inbound Telemarketing"
    },
    {
      code: "5960",
      name: "Direct Marketing - Insurance Services"
    },
    {
      code: "5969",
      name: "Direct Marketing - Other"
    },
    {
      code: "5966",
      name: "Direct Marketing - Outbound Telemarketing"
    },
    {
      code: "5968",
      name: "Direct Marketing - Subscription"
    },
    {
      code: "5962",
      name: "Direct Marketing - Travel"
    },
    {
      code: "5310",
      name: "Discount Stores"
    },
    {
      code: "8011",
      name: "Doctors"
    },
    {
      code: "5963",
      name: "Door-To-Door Sales"
    },
    {
      code: "5714",
      name: "Drapery, Window Covering, and Upholstery Stores"
    },
    {
      code: "5813",
      name: "Drinking Places"
    },
    {
      code: "5912",
      name: "Drug Stores and Pharmacies"
    },
    {
      code: "5122",
      name: "Drugs, Drug Proprietaries, and Druggist Sundries"
    },
    {
      code: "7216",
      name: "Dry Cleaners"
    },
    {
      code: "5099",
      name: "Durable Goods (Not Elsewhere Classified)"
    },
    {
      code: "5309",
      name: "Duty Free Stores"
    },
    {
      code: "5812",
      name: "Eating Places, Restaurants"
    },
    {
      code: "8299",
      name: "Educational Services"
    },
    {
      code: "5997",
      name: "Electric Razor Stores"
    },
    {
      code: "5065",
      name: "Electrical Parts and Equipment"
    },
    {
      code: "1731",
      name: "Electrical Services"
    },
    {
      code: "7622",
      name: "Electronics Repair Shops"
    },
    {
      code: "5732",
      name: "Electronics Stores"
    },
    {
      code: "8211",
      name: "Elementary, Secondary Schools"
    },
    {
      code: "7361",
      name: "Employment/Temp Agencies"
    },
    {
      code: "7394",
      name: "Equipment Rental"
    },
    {
      code: "7342",
      name: "Exterminating Services"
    },
    {
      code: "5651",
      name: "Family Clothing Stores"
    },
    {
      code: "5814",
      name: "Fast Food Restaurants"
    },
    {
      code: "6012",
      name: "Financial Institutions"
    },
    {
      code: "9222",
      name: "Fines - Government Administrative Entities"
    },
    {
      code: "5718",
      name: "Fireplace, Fireplace Screens, and Accessories Stores"
    },
    {
      code: "5713",
      name: "Floor Covering Stores"
    },
    {
      code: "5992",
      name: "Florists"
    },
    {
      code: "5193",
      name: "Florists Supplies, Nursery Stock, and Flowers"
    },
    {
      code: "5422",
      name: "Freezer and Locker Meat Provisioners"
    },
    {
      code: "5983",
      name: "Fuel Dealers (Non Automotive)"
    },
    {
      code: "7261",
      name: "Funeral Services, Crematories"
    },
    {
      code: "7641",
      name: "Furniture Repair, Refinishing"
    },
    {
      code: "5712",
      name: "Furniture, Home Furnishings, and Equipment Stores, Except Appliances"
    },
    {
      code: "5681",
      name: "Furriers and Fur Shops"
    },
    {
      code: "1520",
      name: "General Services"
    },
    {
      code: "5947",
      name: "Gift, Card, Novelty, and Souvenir Shops"
    },
    {
      code: "5231",
      name: "Glass, Paint, and Wallpaper Stores"
    },
    {
      code: "5950",
      name: "Glassware, Crystal Stores"
    },
    {
      code: "7992",
      name: "Golf Courses - Public"
    },
    {
      code: "9399",
      name: "Government Services (Not Elsewhere Classified)"
    },
    {
      code: "5411",
      name: "Grocery Stores, Supermarkets"
    },
    {
      code: "5251",
      name: "Hardware Stores"
    },
    {
      code: "5072",
      name: "Hardware, Equipment, and Supplies"
    },
    {
      code: "7298",
      name: "Health and Beauty Spas"
    },
    {
      code: "5975",
      name: "Hearing Aids Sales and Supplies"
    },
    {
      code: "1711",
      name: "Heating, Plumbing, A/C"
    },
    {
      code: "5945",
      name: "Hobby, Toy, and Game Shops"
    },
    {
      code: "5200",
      name: "Home Supply Warehouse Stores"
    },
    {
      code: "8062",
      name: "Hospitals"
    },
    {
      code: "7011",
      name: "Hotels, Motels, and Resorts"
    },
    {
      code: "5722",
      name: "Household Appliance Stores"
    },
    {
      code: "5085",
      name: "Industrial Supplies (Not Elsewhere Classified)"
    },
    {
      code: "7375",
      name: "Information Retrieval Services"
    },
    {
      code: "6399",
      name: "Insurance - Default"
    },
    {
      code: "6300",
      name: "Insurance Underwriting, Premiums"
    },
    {
      code: "9950",
      name: "Intra-Company Purchases"
    },
    {
      code: "5944",
      name: "Jewelry Stores, Watches, Clocks, and Silverware Stores"
    },
    {
      code: "780",
      name: "Landscaping Services"
    },
    {
      code: "7211",
      name: "Laundries"
    },
    {
      code: "7210",
      name: "Laundry, Cleaning Services"
    },
    {
      code: "8111",
      name: "Legal Services, Attorneys"
    },
    {
      code: "5948",
      name: "Luggage and Leather Goods Stores"
    },
    {
      code: "5211",
      name: "Lumber, Building Materials Stores"
    },
    {
      code: "6010",
      name: "Manual Cash Disburse"
    },
    {
      code: "4468",
      name: "Marinas, Service and Supplies"
    },
    {
      code: "1740",
      name: "Masonry, Stonework, and Plaster"
    },
    {
      code: "7297",
      name: "Massage Parlors"
    },
    {
      code: "8099",
      name: "Medical Services"
    },
    {
      code: "8071",
      name: "Medical and Dental Labs"
    },
    {
      code: "5047",
      name: "Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies"
    },
    {
      code: "8699",
      name: "Membership Organizations"
    },
    {
      code: "5611",
      name: "Mens and Boys Clothing and Accessories Stores"
    },
    {
      code: "5691",
      name: "Mens, Womens Clothing Stores"
    },
    {
      code: "5051",
      name: "Metal Service Centers"
    },
    {
      code: "5699",
      name: "Miscellaneous Apparel and Accessory Shops"
    },
    {
      code: "5599",
      name: "Miscellaneous Auto Dealers"
    },
    {
      code: "7399",
      name: "Miscellaneous Business Services"
    },
    {
      code: "5499",
      name: "Miscellaneous Food Stores - Convenience Stores and Specialty Markets"
    },
    {
      code: "5399",
      name: "Miscellaneous General Merchandise"
    },
    {
      code: "7299",
      name: "Miscellaneous General Services"
    },
    {
      code: "5719",
      name: "Miscellaneous Home Furnishing Specialty Stores"
    },
    {
      code: "2741",
      name: "Miscellaneous Publishing and Printing"
    },
    {
      code: "7999",
      name: "Miscellaneous Recreation Services"
    },
    {
      code: "7699",
      name: "Miscellaneous Repair Shops"
    },
    {
      code: "5999",
      name: "Miscellaneous Specialty Retail"
    },
    {
      code: "5271",
      name: "Mobile Home Dealers"
    },
    {
      code: "7832",
      name: "Motion Picture Theaters"
    },
    {
      code: "4214",
      name: "Motor Freight Carriers and Trucking - Local and Long Distance, Moving and Storage Companies, and Local Delivery Services"
    },
    {
      code: "5592",
      name: "Motor Homes Dealers"
    },
    {
      code: "5013",
      name: "Motor Vehicle Supplies and New Parts"
    },
    {
      code: "5571",
      name: "Motorcycle Shops and Dealers"
    },
    {
      code: "5561",
      name: "Motorcycle Shops, Dealers"
    },
    {
      code: "5733",
      name: "Music Stores-Musical Instruments, Pianos, and Sheet Music"
    },
    {
      code: "5994",
      name: "News Dealers and Newsstands"
    },
    {
      code: "6051",
      name: "Non-FI, Money Orders"
    },
    {
      code: "6540",
      name: "Non-FI, Stored Value Card Purchase/Load"
    },
    {
      code: "5199",
      name: "Nondurable Goods (Not Elsewhere Classified)"
    },
    {
      code: "5261",
      name: "Nurseries, Lawn and Garden Supply Stores"
    },
    {
      code: "8050",
      name: "Nursing/Personal Care"
    },
    {
      code: "5021",
      name: "Office and Commercial Furniture"
    },
    {
      code: "8043",
      name: "Opticians, Eyeglasses"
    },
    {
      code: "8042",
      name: "Optometrists, Ophthalmologist"
    },
    {
      code: "5976",
      name: "Orthopedic Goods - Prosthetic Devices"
    },
    {
      code: "8031",
      name: "Osteopaths"
    },
    {
      code: "5921",
      name: "Package Stores-Beer, Wine, and Liquor"
    },
    {
      code: "5198",
      name: "Paints, Varnishes, and Supplies"
    },
    {
      code: "7523",
      name: "Parking Lots, Garages"
    },
    {
      code: "4112",
      name: "Passenger Railways"
    },
    {
      code: "5933",
      name: "Pawn Shops"
    },
    {
      code: "5995",
      name: "Pet Shops, Pet Food, and Supplies"
    },
    {
      code: "5172",
      name: "Petroleum and Petroleum Products"
    },
    {
      code: "7395",
      name: "Photo Developing"
    },
    {
      code: "7221",
      name: "Photographic Studios"
    },
    {
      code: "5044",
      name: "Photographic, Photocopy, Microfilm Equipment, and Supplies"
    },
    {
      code: "7829",
      name: "Picture/Video Production"
    },
    {
      code: "5131",
      name: "Piece Goods, Notions, and Other Dry Goods"
    },
    {
      code: "5074",
      name: "Plumbing, Heating Equipment, and Supplies"
    },
    {
      code: "8651",
      name: "Political Organizations"
    },
    {
      code: "9402",
      name: "Postal Services - Government Only"
    },
    {
      code: "5094",
      name: "Precious Stones and Metals, Watches and Jewelry"
    },
    {
      code: "8999",
      name: "Professional Services"
    },
    {
      code: "4225",
      name: "Public Warehousing and Storage - Farm Products, Refrigerated Goods, Household Goods, and Storage"
    },
    {
      code: "7338",
      name: "Quick Copy, Repro, and Blueprint"
    },
    {
      code: "4011",
      name: "Railroads"
    },
    {
      code: "6513",
      name: "Real Estate Agents and Managers - Rentals"
    },
    {
      code: "5735",
      name: "Record Stores"
    },
    {
      code: "7519",
      name: "Recreational Vehicle Rentals"
    },
    {
      code: "5973",
      name: "Religious Goods Stores"
    },
    {
      code: "8661",
      name: "Religious Organizations"
    },
    {
      code: "1761",
      name: "Roofing/Siding, Sheet Metal"
    },
    {
      code: "7339",
      name: "Secretarial Support Services"
    },
    {
      code: "6211",
      name: "Security Brokers/Dealers"
    },
    {
      code: "5541",
      name: "Service Stations"
    },
    {
      code: "5949",
      name: "Sewing, Needlework, Fabric, and Piece Goods Stores"
    },
    {
      code: "7251",
      name: "Shoe Repair/Hat Cleaning"
    },
    {
      code: "5661",
      name: "Shoe Stores"
    },
    {
      code: "7629",
      name: "Small Appliance Repair"
    },
    {
      code: "5598",
      name: "Snowmobile Dealers"
    },
    {
      code: "1799",
      name: "Special Trade Services"
    },
    {
      code: "2842",
      name: "Specialty Cleaning"
    },
    {
      code: "5941",
      name: "Sporting Goods Stores"
    },
    {
      code: "7032",
      name: "Sporting/Recreation Camps"
    },
    {
      code: "7941",
      name: "Sports Clubs/Fields"
    },
    {
      code: "5655",
      name: "Sports and Riding Apparel Stores"
    },
    {
      code: "5972",
      name: "Stamp and Coin Stores"
    },
    {
      code: "5111",
      name: "Stationary, Office Supplies, Printing and Writing Paper"
    },
    {
      code: "5943",
      name: "Stationery Stores, Office, and School Supply Stores"
    },
    {
      code: "5996",
      name: "Swimming Pools Sales"
    },
    {
      code: "4723",
      name: "TUI Travel - Germany"
    },
    {
      code: "5697",
      name: "Tailors, Alterations"
    },
    {
      code: "9311",
      name: "Tax Payments - Government Agencies"
    },
    {
      code: "7276",
      name: "Tax Preparation Services"
    },
    {
      code: "4121",
      name: "Taxicabs/Limousines"
    },
    {
      code: "4812",
      name: "Telecommunication Equipment and Telephone Sales"
    },
    {
      code: "4814",
      name: "Telecommunication Services"
    },
    {
      code: "4821",
      name: "Telegraph Services"
    },
    {
      code: "5998",
      name: "Tent and Awning Shops"
    },
    {
      code: "8734",
      name: "Testing Laboratories"
    },
    {
      code: "7922",
      name: "Theatrical Ticket Agencies"
    },
    {
      code: "7012",
      name: "Timeshares"
    },
    {
      code: "7534",
      name: "Tire Retreading and Repair"
    },
    {
      code: "4784",
      name: "Tolls/Bridge Fees"
    },
    {
      code: "7991",
      name: "Tourist Attractions and Exhibits"
    },
    {
      code: "7549",
      name: "Towing Services"
    },
    {
      code: "7033",
      name: "Trailer Parks, Campgrounds"
    },
    {
      code: "4789",
      name: "Transportation Services (Not Elsewhere Classified)"
    },
    {
      code: "4722",
      name: "Travel Agencies, Tour Operators"
    },
    {
      code: "7511",
      name: "Truck StopIteration"
    },
    {
      code: "7513",
      name: "Truck/Utility Trailer Rentals"
    },
    {
      code: "2791",
      name: "Typesetting, Plate Making, and Related Services"
    },
    {
      code: "5978",
      name: "Typewriter Stores"
    },
    {
      code: "9405",
      name: "U.S. Federal Government Agencies or Departments"
    },
    {
      code: "5137",
      name: "Uniforms, Commercial Clothing"
    },
    {
      code: "5931",
      name: "Used Merchandise and Secondhand Stores"
    },
    {
      code: "4900",
      name: "Utilities"
    },
    {
      code: "5331",
      name: "Variety Stores"
    },
    {
      code: "742",
      name: "Veterinary Services"
    },
    {
      code: "7993",
      name: "Video Amusement Game Supplies"
    },
    {
      code: "7994",
      name: "Video Game Arcades"
    },
    {
      code: "7841",
      name: "Video Tape Rental Stores"
    },
    {
      code: "8249",
      name: "Vocational/Trade Schools"
    },
    {
      code: "7631",
      name: "Watch/Jewelry Repair"
    },
    {
      code: "7692",
      name: "Welding Repair"
    },
    {
      code: "5300",
      name: "Wholesale Clubs"
    },
    {
      code: "5698",
      name: "Wig and Toupee Stores"
    },
    {
      code: "4829",
      name: "Wires, Money Orders"
    },
    {
      code: "5631",
      name: "Womens Accessory and Specialty Shops"
    },
    {
      code: "5621",
      name: "Womens Ready-To-Wear Stores"
    },
    {
      code: "5935",
      name: "Wrecking and Salvage Yards"
    }
  ];

  populateMerchantList() {
    return this.merchantList
  }

}
