export const environment = {
  activeEnv: 'staging',
  production: true,
  development: false,
  ServiceWorker: false,
  apiBaseUrl: 'https://10.0.149.146:3000/account-list/1.0.0',
  dashboard: {
    WiZaPortfolioV2: true,
    MuCorporateProfiles: true,
    PaymentHoliday: true,
    showManageLimitsLink: true,
    muShowDashboardMessage: true,
  },
  platform: {
    extendable:true,
    callsign: true,
    callsignEtgCall: true,
    callsignConfig: {
      essx: 'https://app.s06.callsign.com',
      esto: 'org-k49w1p01-xjegnbes-ba8bgm19d-kqsv0cka',
      apiKey:
        'G6keWqz5vkbSz6aQBpFRPGKEiZZHhfwSyVsKVGqfGLvW9aneoihFo3Bxoszqin4Y',
      tenantId: 'tnt-5yy6ej3p-p34t8dgx-gwwrb82x9-rmky3xkv',
    },
    showMarketplaceMenu: true,
    enableBrowserDetect: true,
    ixContactNumbers: true,
    liveChatToggleCategory: 'API.LiveChat',
    platformAuthorCategory: 'PlatformAuthor',
    platformAuthenticationCategroy: 'PlatformAuthentication',
    platformAuthorBaseUrl: '/proxy/user/v2',
    home: true,
    authentication: true,
    secondFactor: true,
    logout: true,
    userApi: true,
    userFeatures: true,
    errorToast: true,
    liveChatEnabled: true,
    liveChatUrl: 'https://apistg.secure.investec.com/livechat',
    liveChatSkillset: 'WC_Default_Skillset',
    urls: {
      ixDestination: 'https://ix-uat.secure.investec.com/',
      ifaUkDestination: '/ifaroot/uk-ifa-dashboards/investec-for-advisers',
      sociusDestination:
        'https://ifastg.secure.investec.com/investec/en-gb/site/index',
      envBaseUrl: 'https://loginstg.secure.investec.com',
      ioPcoBaseUrl: 'https://applyonlinestg.investec.com',
    },
    stickyFooterEnvironmentToggle: true,
    disableUpdateMyDetailsSa: false,
    internationalNumberUpdate: true,
    wealthTempPassBaseUrl: '/az-proxy/iwi-uk-apistg.secure.investec.com',
    wealthTempPassToggle: 'UXP.Login',
    wealthToggleApiUrl: '/proxy/',
  },
  wealthSA: {
    toggles: 'UXP.ZA.WI',
  },
  myInvestmentSA: {
    toggles: 'UXP.ZA.Investments',
  },
  hybrid: {
    toggles: 'Hybrid',
  },
  bb: {
    fbccUrl: 'https://fbccstg.secure.investec.com',
    apiBaseUrl: '/proxy/za/tbb/api',
    mockBaseUrl: 'https://10.0.149.39:3000',
    toggles: 'API.ZA.TBB',
    FBCC_DayOld_Support_Enabled: true,
  },
  life: {
    toggles: 'API.Life',
  },
  ifa: {
    // API base url
    ccmBaseURL: 'https://cxtifi-nonprod.secure.investec.com/api/v1',
    // API v2 base url
    ccmV2BaseURL: 'https://cxtifi-nonprod.secure.investec.com/api/v2',
    // toolkit base url
    toolkitBaseUrl: 'https://stg.investec.com/en_za/toolkit/',
    // UI url
    ccmUIBaseUrl: 'https://loginstg.secure.investec.com',
    ccmUIProxyUrl: '/proxy',
    toggles: 'API.ZA.IB',
    enableCCMClientManagement:
      '02bb526eb37d9455f76db2e279ae15d3b6cb692e2a0142af88652176cc94158e',
    enableCCMIntermediaryAdmin:
      '88aa3361096e20f30cc40b2f3afb703f968447e9d6dd6100c94fd14632304594',
    enableCCMDocuments:
      '39f0e10c89771ed1ac205112cc960d94a6b3035a31696c805a3b252f05df2fd4',
    enableCCMGuarantee:
      'ed9c92f7afbfba13b0668b3bc515d9685884e56eac70d97ebaf17b11bf77f38d',
    enableCCMClientAdmin:
      '705a6b4d289cab5cf6d2d1948040c8f5576f0f927f2e7729b409a01f895df609',
    enableCCMBulkClosure:
      '4993fa117f211beb83919565bb2ef6c6c3221287f5cad514aeef82073f82bd23',
    enableMAUOffshoreBanking:
      'd7c498a81e56abf7185dbe1aea40f2d63e0edde30c34b553c20f477f574176b8',
    enableCCMClientOnboarding:
      '8773f87b49f6874ca1b4b8c7e1c85c7fe58823ca9946ddd431a81943269bb845',
    enableCCMFasterPaymentCheck:
      'c02fcf0de1a8a8d604627f9c16fc3a69a80f5190034b9eaa6c83db11f03991b0',
    enableToolkitRedirect:
      '8958de713db78b74b46d8d4cb673885c1120da3db7b306048008e7e7644ba912',
    enableCCMAccessAllUsers:
      '6c492090912e8ed3e3c7a3dcd1a651b05cba063d446bb5fa7ad3524c754d1383',
    enableNewDocsEndpoint:
      '0016b3d5db987fdb20d807329e92f41e899fe2050795a9942eb49bf15126d5cc',
    enableNewBenEndpoint:
      'cfbc50034031e28aa769eb746d57c0dc4f980ef54d989d7d7d33c658b52163fe',
    enableCCMInternationalPayment:
      '92a21483061811ba2e3b1cf8e785ccfae13505d2f654b6ca1d539223ee9ab8ee',
    enableCCMAuthNewEndpoint:
      'bed880665856c8b18445232bc2161761e1a65b023638a7f369d8e5d10781e5e7',
    enableCCMAllocateNewEndpoint:
      '499ff1cd242db7adcf115ac8649b654f3f649ad3b34d84149020ab3a6d1c5d57',
    enableCCMAwaitingAuthNewEndpoint:
      'f760b06f9059e948d7e4b01f0c3814da5d3b9fc60aa50525869cf3319c10127c',
    enableNewProductEndpoints:
      'c3310c73fa78e0ad1f6849876ccd87fe7f82562b265e4bb8351216954112baea',
    enableCCMDownloadFormNewEndpoint:
      '6c19682daccf0242d394185ea67c6480730a7db37e96b2865a0163027691a135',
    enableNewCCMAccessCall:
      'fa38f760da93e6498d4aaffa6b90082b2e5a3904ffe53b15543e660c18f6a585',
    enableAuthorisationRename:
      '929a09656690cd36534b9026feafbf1092d55cee6fe384addd6ecc27e35305f1',
    enableCCMExpiredPayments:
      'ea0d497f0e171608058728924bef4bd5418d07788b5c20162b2b71f6cff85357',
    enableCCMColumMerge:
      '9d200829810306bd1ea22fa51e29833f1286a743779a16ce752c6c175f830493',
    enableCCMPaymentAuthorization:
      '0960e6c9250e73a692a0d3eca41d6641f071c657ea4f9f632c087fa783d9217c',
    enableCCMRatesAndFees:
      '7473984db0d522a525ab09f40a3644af0c0db51dd27f04d205b7fd43684b13c8',
    enableCCMResolverToggle:
      '6512d4272b78bcba753ad858777e6cb43ba8ce507b8c2cf0fd2ea22af206ccd0',
    enableCCMFormBuilder:
      'ee0eac72753dec3c06321029e5d8892086399a18ca825499de8ca007d185780b',
    enableCCMEnv:
      '115c714282799e49ad601592eed75821dd5f9cb4fadc9c1def2eac27d1cd7a2c',
    enableV2Intermediary:
      'fed2a0e50bcf3eb09a6921e8cc49fade3ecf3eb44ca39e6062527ef5ec1ac3c9	',
    enableClientOnboarding:
      '60161199088349f4747de36e91cc71bacb5f2356e0056f34999f7aaa34f5a6ac',
    enableCCMCloseAccEnhance:
      '70858c889f9bdabf2fb9b74f33cba2aa3ba171e1dcdbeb407984a0ad594b5cbd',
  },
  bbuk: {
    ecpService: 'https://ctr-lcg-ecp-nonprod.secure.investec.com',
    dotComContentBaseUrl: 'https://www.investec.com',
    elkService: 'https://elk.investec.com',
  },
  paymentsSA: {
    preselectedAccountAndProfile: true,
    csvInjection: true,
    userLevel0Changes: true,
    manageBenGeoLinkToUK: true,
    authorisationFasterPaymentAsCurrentDay: true,
    querySelectorRemoval: true,
    spotScamSnatching: true,
    dashboardExtract: true,
    rewardsV2: true,
    multiplePaymentConfIssueFixes: true,
    shouldShowLinkUKAccounts: false,
    removeFaxOption: true,
    elecTariffsDoc: true,
    auditTrail: true,
    auditTrailAuthorisedBy: true,
    ccmUIProxyUrl: '/proxy',
    toggles: 'UXP.ZA.Multicurrency',
    EnableMultiCurrencyPocket:
      'ddbbafc72f6df7b1a140c84993c71012e87bf54fe799e22f553b8e61b5fa8908',
    bffService: 'https://api.local.secure.investec.com',
    ecpService: 'https://ctr-lcg-ecp-nonprod.secure.investec.com',
  },
  pcuk: {
    cdxPcUkService: '/az-proxy/cdx-pc-apistg.secure.investec.com',
  },
  pbuk: {
    ukExecClient:
      '/az-proxy/ctr-lcg-ecp-nonprod.secure.investec.com/contactdetails',
    apiMapping: '/proxy',
    toggles: 'UXP.UK.BANKING',
    noticePlus: {
      AllowCloseAccountOnly: true,
    },
  },
  wiukci: {
    cdxPcWiukciBaseUrl: 'cdx-pc-apistg.secure.investec.com/',
  },
  pbmu: {
    makeAPayment: true,
    toggles: {
      redirectToOldOnlineBanking: {
        makeAPayment: {
          beneficiary: {
            enabled: false,
            path: '/pbmu/pbmu.html/beneficiaryPayment',
          },
          onceOff: {
            enabled: false,
            path: '/pbmu/pbmu.html/onceoffpayment',
          },
          transfer: {
            enabled: false,
            path: '/pbmu/pbmu.html/transfers',
          },
          scheduledPayment: {
            enabled: false,
            path: '/pbmu/pbmu.html/addStandingOrder',
          },
          trade: {
            enabled: false,
            path: '/pbmu/pbmu.html/forex',
          },
        },
        manageBeneficiaries: {
          enabled: false,
          path: '/pbmu/pbmu.html/manageBeneficiaries',
        },
      },
      crossCurrencyEnhancements: true,
      accountTransferEnhancements: true,
      aggregatedAccountList: true,
      showCategories: true,
      bankingAccountNewRequestInput: true,
      zeroBalanceSolution: true,
      IsPurposeOfPaymentRequired: true,
      scheduledPaymentFixToggle: true,
      mauritiusValidateIntAccToggle: false,
      validateFedwireSortCodeToggle: true,
      isCCMLiveToggle: true,
      fixedDepositEnhancementsToggle: true,
      investecNextToggle: true,
      implementPurposeOfPaymentRulesToggle: true,
      NoticeProductToggle: true,
    },
    investecNextProfile: 'MAU_PVT',
  },
  pcoExistingClients: {
    baseUrl: 'https://loginstg.secure.investec.com',
    pcoToggleCategory: 'Online.PCO',
    reId: true,
  },
  myInvestments: {
    isWebView: false,
    endpoints: {
      fundingAccountsUrl: 'investments/investment/fundingaccounts',
      wiInitiateFundingUrl: 'wi/portfolio/InitiateFunding',
      pbInitiateFundingUrl: 'pb/portfolio/InitiateFunding',
      industrySectionsUrl: 'investments/compliance/IndustrySections',
      industryGroupsUrl: 'investments/compliance/IndustryGroups',
      industryDetailsUrl: 'investments/compliance/IndustryDetails',
      occupationGroupsUrl: 'investments/compliance/OccupationGroups',
      occupationUnitsUrl: 'investments/compliance/OccupationUnits',
      productsUrl: 'wi/products',
      lookupUrl: 'opm/reference/lookup',
      sourceOfWealth: '/investments/compliance/SourcesOfWealth',
      creatInstructionUrl: 'investments/investment/createinstruction',
      categoriesUrl: 'investments/Product/ProductShelf',
      fundsproductsUrl: 'wi/productcards',
    },
  },
  spf: {
    lightstone: {
      hasComparableSales: false,
      hasPointsOfInterest: false,
      hasPropertyDetails: false,
      hasUIAlerts: false,
      hasMarkerDrawingTool: false,
    },
    hasPaymentPlan: true,
    hasPropertySummary: true,
    hasExposuresSummary: true,
    hasDashboard: true,
    hasMapSummary: true,
  },
  rewards: {
    calculatorEnabled: true,
    documentsEnabled: true,
    expiryPointsV2: true,
    perPageDataEnabled: true,
    anchorTransformHelperEnabled: true,
    referralsEnabled: true,
  },
  pbza: {
    hasTemporaryLimitManipulation: false,
    routeToIoZA: true,
  },
  cards: {
    useV2CardApi: true,
    showPinLayoutAndLandingPage: true, // to show or hide the new consolidated PIN screen
    changeCardPinEnabled: true,
    showLinkedPaymentDevices: true,
    showVirtualCards: true,
    showCardSettings: true,
    showCardLimits: true,
    useV2CardModule: true,
    showCancelAndReplace: true,
    pageSize: 10,
  },
  beneficiary: {
    auditTrailDrop2: false,
  },
  privateClientsDashboard: {
    multipleManagers: true,
    preferenceAPI: 'cxt-dashboardstg.secure.investec.com',
    toggles: 'Dashboard',
  },
  ifb: {
    UseAPIV2: true, // to handle fbcc calls when fbcc is done
    googleKey: 'AIzaSyBIhXTZU0PoWAqY6MCVTNIvhJXiaydgCPE',
    apiBaseUrl: '/proxy/za/tbb/api',
    enableProfileSwitcher: true,
  },
  wici: {
    valuationSelectionMinimumDate: { year: 2018, month: 6, day: 28 }, // Used for restricting documents before a certain date being selected in documents
    taxpackSelectionMinimumDate: { year: 2018, month: 1, day: 1 }, // Used for restricting documents before a certain date being selected in documents
    lossNotificationSelectionMinimumDate: { year: 2020, month: 6, day: 11 }, // Used for restricting documents before a certain date being selected in documents
    feeInvoiceSelectionMinimumDate: { year: 1960, month: 1, day: 1 }, // Used for restricting documents before a certain date being selected in documents
  },
  wiuk: {
    valuationSelectionMinimumDate: { year: 2018, month: 6, day: 28 }, // Used for restricting documents before a certain date being selected in documents
    taxpackSelectionMinimumDate: { year: 2018, month: 1, day: 1 }, // Used for restricting documents before a certain date being selected in documents
    lossNotificationSelectionMinimumDate: { year: 2020, month: 6, day: 11 }, // Used for restricting documents before a certain date being selected in documents
    feeInvoiceSelectionMinimumDate: { year: 1960, month: 1, day: 1 }, // Used for restricting documents before a certain date being selected in documents
  },
  shopfront: {
    baseUrl: 'https://stg.investec.com/en_za/next',
    omniChannel: {
      data_app_id: '9e442c23-3585-4b52-a3ab-fbb4bef4c376',
      data_lcw_version: 'prod',
      data_org_id: '1cc5046c-d3dd-4d05-8efd-5815dd872517',
      data_org_url: 'https://org65633939-crm4.omnichannelengagementhub.com',
      enableChat: true,
    },
    toggles: 'UXP.Shopfront',
    apiUrl: 'https://stg.investec.com/proxy/',
    requestcallBackUrl:
      'https://apistg.secure.investec.com/dotcom/lead/v2/submit',
    insightsEngineBaseUrl: 'https://cxt-dsfstg.secure.investec.com/',
    mainNavBaseUrl: 'https://stg.investec.com/', // Refer to prod ts file for main nav url
    oneViewIdBaseUrl: 'https://cxt-dsfstg.secure.investec.com',
    dataInsertionUrl:
      'https://smetrics.investec.com/b/ss/invbnkplaygroundstaging/1/JS-2.22.4-LDQM',
  },
  connect: {
    apiBaseUrl: 'https://cxt-iconnectstg.secure.investec.com/',
    messagingBaseUrl: 'https://chirpeee.com/',
    eventsEnabled: true,
    messagingEnabled: true,
  },
  forYou: {
    insightsCardAPI: '/az-proxy/cxt-personalisationstg.secure.investec.com',
    aemBaseUrl: '/ct-proxy/',
    toggles: 'UXP.ForYou',
    apiUrl: '/proxy/',
    homeLoan: 'https://apistg.secure.investec.com/dotcom/lead/v2/submit',
    dataInsertionBaseUrl:
      'https://smetrics.investec.com/b/ss/invbnkplaygroundstaging/1/JS-2.22.4-LDQM',
    personalizationCode: {
      env: 'NonProd',
      garmin: {
        activityName: 'garminCodeDisplay',
        toggle:
          '11278b7fa8d968cbbe1330aba6cf3b4cd301277eb6704c1a4904ffa163fe44d3',
      },
      homeLoan: {
        activityName: 'HomeLoanLPMV2',
        toggle:
          'd1faeacf822bb6ebddd21a0f33e0f3d877ea35b7ad20ff3834cf3af5113fd768',
      },
      privateLane: {
        activityName: 'privateLane',
        toggle:
          '3e76ee84931328da5db28248895ff5b35409d6b9f976f5669764bcdad3e90629',
      },
      connect: {
        activityName: '',
        toggle:
          '1a2303ede07493acc7caaa7c737f3c52bcc9cf04372be19ed1b0af6b9f2c791e',
      },
      privateVault: {
        activityName: '',
        toggle:
          'a7987e4efb5fa6d7d96c9e3051302e4aff33481d95aeb435ab211de667f3e8ce',
      },
      beyondBanking: {
        activityName: '',
        toggle:
          '357033683861d2333034d186581ba291c0d1125c7890f1c0d375fdaea9a0232d',
      },
      travel: {
        activityName: 'InvestecTravel',
        toggle:
          'd2b98fb53714f23cc0aa7b0ad6dbf910dd537237d7debe818a142590a322b992',
      },
      babylonstoren: {
        activityName: 'babylonstorenCode',
        toggle:
          '158f75f3b0a8bf8761ff791335a8de1505a17c7111ff740eb3abccc52faeb9ae',
      },
      youthAccount: {
        activityName: 'YouthAccount',
        toggle:
          '8f84c7bcc637087006609a005b7121466e2c368ffc827b87713fdeca8c949272',
      },
      ukPrivateBankAccount: {
        activityName: 'UKPrivateBankAccount',
        toggle:
          '796b224fe3ae9a3ed2e9a487a0eac7854feb138a9372793fd022d474074fc714',
      },
      foreignCurrencyCallAccounts: {
        activityName: 'ForeignCurrencyCallAccounts',
        toggle:
          '537d7c0145c18a2fa35163569183ff816425e47e655e7767385f400bfb3b12ad',
      },
      sustainabilityFinance: {
        activityName: 'SustainabilityFinance',
        toggle:
          'c720d0f8fd1a998c04e9e7141a7341db5f81eb025d1ea4e37e899d0e6771d404',
      },
      staticHomeloan: {
        activityName: 'StaticHomeloan',
        toggle:
          '7437fca9ae06fd385da815c7dc0f326c87d28c5d328230f6b09c6b3f997944ba',
      },
      vehicleFinance: {
        activityName: 'VehicleFinance',
        toggle:
          '31be53429c951e798db38ed7a25ee394318185c5d2ea9aa35054d269c0e3c8eb',
      },
      lifeInsurance: {
        activityName: 'LifeInsurance',
        toggle:
          '130bfe52797f48be6d5cb42613c872c306ce7bc4872d8b50a89fc810f423c598',
      },
      severeIllness: {
        activityName: 'SevereIllness',
        toggle:
          '6e805af08c5770f246db192a4c3b6e2ac74a8c3b896486d0e510e7eb26f3b6bf',
      },
      aonInsurance: {
        activityName: 'AonInsurance',
        toggle:
          '0a2cb5530105411667addc6db86a8bfe532963beb71852efc90764f202380b20',
      },
      myInvestmentGeneric: {
        activityName: 'myInvestmentGeneric',
        toggle:
          'f986fcc3eb8474ada97c50699c2a0064e03ff111d91e0a2608cc8bd01a01898e',
      },
      unitTrustInvestments: {
        activityName: 'UnitTrustInvestments',
        toggle:
          '10c1e60c4ad5197b35528635ba45f6dd285bd4933471e9b4d41b0965ad7640ad',
      },
      retirementInvestment: {
        activityName: 'RetirementInvestment',
        toggle:
          '0944fcdfba046d2f82b4395623b60cebed743f1b9575d4033b65ffbb496b19cf',
      },
      taxfreeSavingsAccounts: {
        activityName: 'Tax-freeSavingsAccounts',
        toggle:
          '80dde6e19b5a9e77570d4444f14bf0f3fb8c0c9e39c858ef123f17b4ddf01085',
      },
      lifeStarterForYou: {
        activityName: 'LifeStarter',
        toggle:
          '7f98c975712bab0a9e1adc7b7da33a4105e378df8ca31b2225399234f215c43a',
      },
      lifeStarterDashboard: {
        activityName: 'LifeStarterDashboard',
        toggle:
          '7f98c975712bab0a9e1adc7b7da33a4105e378df8ca31b2225399234f215c43a',
      },
      rewardsReferral: {
        activityName: 'RewardsReferral',
        toggle:
          '195c78d70e95234865be49895af78bc49a837731ae15bf2df8fa3245805ea5c9',
      },
      dermastore: {
        activityName: 'Dermatore',
        toggle:
          'af8c9bd75d90ff0a3728294c4451e2eeb24db6683fd74ea469533f1d49ff5e7d',
      },
      faithfulToNature: {
        activityName: 'FaithfulToNature',
        toggle:
          'fe0b048f59ca98a25d8147f6618104a5a04872abf42f49438640bc94357dab8b',
      },
      uCOOK: {
        activityName: 'UCOOK',
        toggle:
          'afbe223ee43bb97e95d377cdf45279600474f02c46bb746f76bb04ecb75617b9',
      },
      pulsator: {
        activityName: 'Pulsator_Drop_1',
      },
      featureBanner: {
        activityName: 'Featured_Drop_1',
      },
      privateSelection1: {
        activityName: 'PrivateSelection_Drop_1',
      },
      privateSelection2: {
        activityName: 'PrivateSelection_Drop_2',
      },
      privateSelection3: {
        activityName: 'PrivateSelection_Drop_3',
      },
      privateSelection4: {
        activityName: 'PrivateSelection_Drop_4',
      },
      privateSelection5: {
        activityName: 'PrivateSelection_Drop_5',
      },
      privateSelection6: {
        activityName: 'PrivateSelection_Drop_6',
      },
      sustainableSolutions: {
        activityName: 'SustainableSolutions',
      },
      sevenDayNoticeDeposit: {
        activityName: '7DayNoticeDeposit',
      },
      fifteenDayNoticeDeposit: {
        activityName: '15DayNoticeDeposit',
      },
      inTransit: {
        activityName: 'InTransit',
      },
      newUserEnv: 'Nonprod',
    },
  },
  musu: {
    onlineBankingProfile: 'TMOB_STG',
    toggles: {
      ccmIdDocumentUploadField: true,
    },
  },
  privateVault: {
    apiUrl: '/az-proxy/cxt-data-vault-nonprod.secure.investec.com',
    alertTimeout: 3000,
    toggleUrl: '/proxy/',
    toggleCategory: 'Tools',
    toggle: 'a7987e4efb5fa6d7d96c9e3051302e4aff33481d95aeb435ab211de667f3e8ce',
  },
  pfm: {
    apiUrl: '/ct-proxy/',
    toggleUrl: '/proxy/',
    toggleCategory: 'Tools',
    registrationAEMReplacement:
      '94d348d53bda7c45b1be76a855c4245dcd2d694f06e5be824ab863211b9f839a',
  },
  swissBankIbsag: {
    apiUrl: '/az-proxy/cxt-wealth-ibsag-nonprod.secure.investec.com',
    alertTimeout: 3000,
    toggleUrl: '/proxy/',
    toggleCategory: 'Tools',
    toggle: '',
  },
  mcpba: {
    enabled: true,
  },
  onboardingReskin: {
    showOnboardingReskin: true,
    showLoginReskin: false,
    showChecklist: true,
  },
  solar: {
    apiUrl: 'https://cxt-calculator-nonprod.secure.investec.com/',
    solarEstimator: 'https://apistg.secure.investec.com/dotcom/lead/v2/submit',
    dataInsertionBaseUrl: 'https://smetrics.investec.com/b/ss/invbnkplaygroundstaging/1/JS-2.22.4-LDQM',
    enabled: true,
  },
};
