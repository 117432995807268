import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from "@angular/core";

export class SetUserSegment {
  static readonly type = '[UserSegment] Add';

  constructor(public payload: string) {
  }
}

export class UserSegmentStateModel {
  segment: string;
}

@State<UserSegmentStateModel>({
  name: 'UserSegment'
})

@Injectable()
export class UserSegmentState {

  @Selector()
  static getUserSegment(state: UserSegmentStateModel) {
    return state.segment;
  }

  @Action(SetUserSegment)
  addSegment({getState, patchState}: StateContext<UserSegmentStateModel>, {payload}: any) {
    patchState({
      segment: payload
    });
  }
}
