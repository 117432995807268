import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {DataLayerService} from "@investec-online/platform-services";
import {Store} from "@ngxs/store";
import {SetPlatformInteraction} from "@investec-online/platform-state-management";

@Component({
  selector: 'investec-online-prog-card-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss']
})
export class ProgCardCodeEditorComponent implements OnInit, OnDestroy {
  adjustableWidths = {
    sidebar: 12,
    ide: 44,
    simulator: 44
  };

  passTheTab;

  constructor(private _analytics: DataLayerService,
              private _store: Store) {
    this._store.dispatch(new SetPlatformInteraction({fullHeaderWidth: true}))
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._store.dispatch(new SetPlatformInteraction({fullHeaderWidth: false}))
  }

  updateWidths($event: any) {
    this.adjustableWidths = $event
  }

  passTabDataToIde($event: any) {
    this.passTheTab = $event;
  }

  beforeChange($event: NgbNavChangeEvent) {

    let analyticsEventName;
    if ($event.nextId === 'logs') {
      analyticsEventName = 'event logs'
    } else {
      analyticsEventName = 'simulation transaction'
    }

    this._analytics.trackData(
      analyticsEventName,
      'CTA|clicked',
      'programmable banking',
      'click',
      'loyalty',
      'flow',
      'engagement',
      'programmable banking',
      '',
      'cta'
    )

  }
}
