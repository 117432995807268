import {Injectable} from '@angular/core';
import {ServicesModule} from "./services.module";
import {BehaviorSubject, ReplaySubject} from "rxjs";
import {RootCard} from "../components/programmable-card/models/root-card.interface";
import {HttpClient} from "@angular/common/http";
import {FunctionCode} from "../components/programmable-card/models/function-code.interface";
import {RootEnvironmentVariablesInterface} from "../components/programmable-card/models/root-environment-variables.interface";
import {IdeConfigInterface} from "../components/programmable-card/models/ide-config.interface";
import {LogsInterface} from "../components/programmable-card/models/logs.interface";
import {Store} from "@ngxs/store";
import {SourceControl} from "./root-sourcecontrol.class";

@Injectable({
  providedIn: ServicesModule
})
export class ProgrammableCardService {

  public rootCardsList = new ReplaySubject<Array<RootCard>>(1);
  public rootCodeId = new ReplaySubject<any>(1);
  public rootCode = new ReplaySubject<any>(1);
  public ideCode = new ReplaySubject<any>(1);
  public activeLog = new ReplaySubject<any>(1);
  public simulation = new ReplaySubject<any>(1);
  public selectedRootCard: RootCard;
  public selectedAccountNumber: string;
  public ideConfig = new BehaviorSubject<IdeConfigInterface>({theme: 'vs-dark', language: 'javascript'})
  public codeModified: boolean;
  public sourceControl = new SourceControl;

  constructor(private _http: HttpClient, private _store: Store) {
  }

  initializeRootCode() {
    return this._http.post('/proxy/open-banking/v1/code/create', {
      accountNumber: this.selectedAccountNumber,
      cardKeyHash: this.selectedRootCard.CardKeyHash
    })
  }

  updateRootCode(code) {
    return this._http.post<FunctionCode>('/proxy/open-banking/v1/' + this.selectedRootCard.CardKeyHash + '/code', {
      accountNumber: this.selectedAccountNumber,
      code: code
    });
  }

  publishRootCode(codeid, code) {
    return this._http.post<FunctionCode>('/proxy/open-banking/v1/' + this.selectedRootCard.CardKeyHash + '/publish', {
      accountNumber: this.selectedAccountNumber,
      codeid: codeid,
      code: code
    })
  }

  getFunctionsCode() {
    return this._http.get<FunctionCode>('/proxy/open-banking/v1/' + this.selectedAccountNumber + '/' + this.selectedRootCard.CardKeyHash + '/code')
  }

  getPublishedCode() {
    return this._http.get<FunctionCode>('/proxy/open-banking/v1/' + this.selectedAccountNumber + '/' + this.selectedRootCard.CardKeyHash + '/publishedcode')
  }

  setStateStoreCode(code) {
    localStorage.setItem('io.root.savedCodeState', JSON.stringify(code));
    this.codeModified = true;
  }

  clearStateStoreCode() {
    localStorage.removeItem('io.root.savedCodeState');
    this.codeModified = false;
  }

  getStateStoreCode() {
    const savedCode = JSON.parse(localStorage.getItem('io.root.savedCodeState'));
    return savedCode;
  }

  // getMerhcantOptions() {
  //   return this._http.get('/unicorn/api/helpers/merchant-categories',
  //     {headers: this.httpOptions})
  // }

  addOrUpdateEnvironmentVariables(EnvData) {
    const formatVariables = JSON.parse(EnvData);
    const payload = {
      accountNumber: this.selectedAccountNumber,
      variables: formatVariables
    };

    return this._http.post<RootEnvironmentVariablesInterface>(
      '/proxy/open-banking/v1/' + this.selectedRootCard.CardKeyHash + '/environmentvariables',
      payload
    )
  }

  getRootEnvironmentVariables() {
    return this._http.get<RootEnvironmentVariablesInterface>('/proxy/open-banking/v1/' + this.selectedAccountNumber + '/' + this.selectedRootCard.CardKeyHash + '/environmentvariables')
  }

  executeCodeSnippet(code) {
    return this._http.post('/proxy/open-banking/v1/' + this.selectedRootCard.CardKeyHash + '/code/execute', code)
  }

  getRootCards() {
    /*return this._http.post<Array<RootCard>>('/proxy/za/pb/account/cards',
      data, {headers: this.httpOptions})*/
      return this._http.get<Array<RootCard>>('/proxy/open-banking/v1/cards')
  }

  getLogs() {
    return this._http.get<LogsInterface>('/proxy/open-banking/v1/' + this.selectedAccountNumber + '/' + this.selectedRootCard.CardKeyHash + '/code/executions')
  }

  toggleCard(selectedAccountNumber, cardKeyHash) {
    return this._http.post('/proxy/open-banking/v1/toggle-programmable-feature', {
      AccountNumber: selectedAccountNumber,
      CardKey: cardKeyHash
    });
  }

  getTermsAndConditions() {
    return this._http.get('/proxy/za/pb/programmableBankingTerms/TermsAcceptanceStatus');
  }

  getTermsAndConditionsContent() {
    return this._http.get('/proxy/content/termsandconditions/45');
  }

  setTermsAndConditions(data) {
    return this._http.post('/proxy/za/pb/programmableBankingTerms/AcceptTerms', data);
  }

}
