import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map, tap} from "rxjs/operators";

export interface IFastLink4 {
  accessToken: string;
  fastLinkUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class PfmShellService {

  constructor(private _http: HttpClient) { }

  getAccessToken() {
    return this._http.get('/proxy/pfm/portfolio/fastlink').pipe(
      map((response: any) => {
        const fastLink:IFastLink4 = {
          accessToken: response.AccessToken,
          fastLinkUrl: response.FastLinkUrl
        };
        return fastLink;
      })
    );
  }

  getRefCurrency(currency: string) {
    return this._http.post('/proxy/pfm/portfolio/currency-preference', { referenceCurrency: currency});
  }

  refreshPrepopulatedAccounts(providerId: string) {
    return this._http.get(`/proxy/pfm/portfolio/accounts/refresh/${providerId}`);
  }
}
