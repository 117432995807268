import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from "@angular/core";

export interface ClientDetails {
  BeneficiaryPaymentNotifyMeBy: string
  CellPhone: string
  DateOfBirth: string
  DayPhone: string
  EmailAddress: string
  ErrorDescription: string
  ErrorMnemonic: string
  ErrorNumber: string
  EveningPhone: string
  FaxNumber: string
  FirstName: string
  IdNumber: string
  OnceOffPaymentNotifyMeBy: string
  SetupAmendSchedulePaymentNotifyMeBy: string
  Surname: string
  Title: string
  WebAddress: string
}

export class SetClientDetails {
  static readonly type = '[ClientDetails] Add';

  constructor(public payload: ClientDetails) {
  }
}

export class ClientDetailsStateModel {
  details: ClientDetails;
}

@State<ClientDetailsStateModel>({
  name: 'ClientDetails'
})

@Injectable()

export class ClientDetailsState {

  @Selector()
  static getClientDetails(state: ClientDetailsStateModel) {
    return state.details;
  }

  @Action(SetClientDetails)
  add({getState, patchState}: StateContext<ClientDetailsStateModel>, {payload}: SetClientDetails) {
    patchState({
      details: payload
    });
  }
}
