<a *ngIf="!isMobile" (click)="selectCard()">
  <div class="col-12 ml-1 table row clickable" (click)="selectCard()">
    <div class="row card-number col-6 mt-2">
      <div class="col-auto pr-0">
        <h6 class="mb-0">{{cardNumberArray[0]}} {{cardNumberArray[1]}} {{cardNumberArray[2]}}
          {{cardNumberArray[3]}}</h6>
      </div>
      <div class="col-auto pr-0">
        <h6 class="mb-0">{{cardNumberArray[4]}} {{cardNumberArray[5]}} {{cardNumberArray[6]}}
          {{cardNumberArray[7]}}</h6>
      </div>
      <div class="col-auto pr-0">
        <h6 class="mb-0">{{cardNumberArray[8]}} {{cardNumberArray[9]}} {{cardNumberArray[10]}}
          {{cardNumberArray[11]}}</h6>
      </div>
      <div class="col-auto pr-0">
        <h6 class="mb-0">{{cardNumberArray[12]}} {{cardNumberArray[13]}} {{cardNumberArray[14]}}
          {{cardNumberArray[15]}}</h6>
      </div>
    </div>
    <div class="col-3 row mt-2" *ngIf="rootCard.IsProgrammable">
      <div class="col-auto p-0">&lt;</div>
      <div class="col-auto p-0"><p class="wording">programmable</p></div>
      <div class="col-auto p-0">/&gt;</div>
    </div>
    <div class="col-3 row" *ngIf="!rootCard.IsProgrammable">
    </div>
    <div *ngIf="!isMobile" class="col-3 ml-1 mt-2 form-group row">
      <label class="col-form-label col-6">Enabled:</label>
      <div class="col-6">
        <ui-switches [id]="rootCard.CardKeyHash" (returnEvt)="toggleCardForRoot($event, rootCard)"
                     [model]="rootCard.IsProgrammable"></ui-switches>
      </div>
    </div>
  </div>
</a>