import {Component, OnInit} from '@angular/core';
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient} from "@angular/common/http";
import {NavigationService} from "../../../../../../../global-navigation/src/lib/navigation.service";
import {ProgrammableCardService} from "../../../../services/programmable-card.service";
import {DataLayerService} from "@investec-online/platform-services";
import {FunctionCode} from "../../models/function-code.interface";
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'investec-online-prog-card-sidebar',
  templateUrl: './code-editor-sidebar.component.html',
  styleUrls: ['./code-editor-sidebar.component.scss']
})
export class ProgCardSidebarComponent implements OnInit {

  activeEnv = environment.activeEnv;

  closeResult: string;
  showFeedbackConfirmation = false;
  feedbackLoading = false;
  feedbackInfo = {Rating: '', MessageBody: '', Type: '', Page: '', Area: '', PageUrl: ''};
  pageUrl = window.location.href;
  showFeedbackSuccess = false;
  ratings = [{iconName: 'help-and-support-rating-1', color: 'rating1', selected: false},
    {iconName: 'help-and-support-rating-2', color: 'rating2', selected: false},
    {iconName: 'help-and-support-rating-3', color: 'rating3', selected: false},
    {iconName: 'help-and-support-rating-4', color: 'rating4', selected: false},
    {iconName: 'help-and-support-rating-5', color: 'rating5', selected: false}];
  pageName = 'Programmable Banking IDE';
  user: string;
  savedCode: FunctionCode;
  publishedCode: FunctionCode;
  showSourceCodeVersions = false;

  feedbackSubjectOptions = [{Type: 'Comment'}, {Type: 'Compliment'}, {Type: 'Improvement'}, {Type: 'Issue'}];

  constructor(
    private modalService: NgbModal,
    private _http: HttpClient,
    private _nav: NavigationService,
    private _rootCard: ProgrammableCardService,
    private _analytics: DataLayerService,
    private router: Router
  ) {
    this._nav.sharedAuthRes.subscribe(authRes => {
      this.user = authRes.Client.FirstName;
    });
  }

  ngOnInit() {
  }

  open(content, item) {
    this._analytics.trackData(
      item,
      'CTA|clicked',
      'programmable banking',
      'click',
      'loyalty',
      'flow',
      'engagement',
      'programmable banking',
      '',
      'cta'
    )
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openSourceControl(content, item) {
    this.open(content, item);
    this._rootCard.getFunctionsCode().subscribe(res => {
      this._rootCard.sourceControl.savedCode = res;
      this.savedCode = this._rootCard.sourceControl.savedCode;
      this.showSourceCodeVersions = true;
    });
    this._rootCard.getPublishedCode().subscribe(res => {
      this._rootCard.sourceControl.publishedCode = res;
      this.publishedCode = this._rootCard.sourceControl.publishedCode;
      this.showSourceCodeVersions = true;
    });
  }

  selectSourceCode(selectedCode: FunctionCode) {
    this._rootCard.rootCodeId.next(selectedCode.codeId);
    this._rootCard.rootCode.next(selectedCode.code);
    this.modalService.dismissAll('Source code selected click');
  }

  private getDismissReason(reason: any): string {
    this.showFeedbackConfirmation = false;
    this.showFeedbackSuccess = false;
    this.feedbackInfo = {Rating: '', MessageBody: '', Type: '', Page: '', Area: '', PageUrl: ''};
    this.ratings.forEach(value => {
      value.selected = false;
    });
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  showCardsOverview() {
    this.router.navigate(['programmable-banking/ide/']);
  }

  sendFeedback(form) {
    this._analytics.trackData(
      'submit feedback',
      'CTA|clicked',
      'programmable banking',
      'click',
      'loyalty',
      'flow',
      'engagement',
      'programmable banking',
      '',
      'cta'
    )
    form.resetForm();
    this.showFeedbackConfirmation = true;
    this.feedbackLoading = true;
    this.feedbackInfo.PageUrl = this.pageUrl;
    this.feedbackInfo.Page = this.pageName;
    this.feedbackInfo.MessageBody = form._directives[1].model;
    this.feedbackInfo.Type = form._directives[0].model;

    this._http.post<{ Success: boolean }>('/proxy/feedback', this.feedbackInfo).subscribe(res => {
      this.showFeedbackSuccess = res.Success;
    });

  }

  selectRating(i) {
    this.ratings.forEach(value => {
      value.selected = false;
    });
    this.ratings[i].selected = true;
    this.feedbackInfo.Rating = i + 1;
  }


  resetCode() {
    this._analytics.trackData(
      'revert code confirm',
      'CTA|clicked',
      'programmable banking',
      'click',
      'loyalty',
      'flow',
      'engagement',
      'programmable banking',
      '',
      'cta'
    )
    this._rootCard.rootCode.next(
      `// This function runs during the card transaction authorization flow.
// It has a limited execution time, so keep any code short-running.
const beforeTransaction = async (authorization) => {
    console.log(authorization);
    return true;
};

// This function runs after a transaction.
const afterTransaction = async (transaction) => {
    console.log(transaction)
};`
    );
    this.modalService.dismissAll();
  }

  trackClickForAnalytics(item) {
    this._analytics.trackData(
      item,
      'CTA|clicked',
      'programmable banking',
      'click',
      'loyalty',
      'flow',
      'engagement',
      'programmable banking',
      '',
      'cta'
    )
  }
}
