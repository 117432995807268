import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from "@angular/core";

export interface BusinessBankingProfile {
  userId: number,
  loginId: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  defaultCompany: {
    companyId: number,
    name: string
  },
  userActiveFlag: string,
  userCompanyAbbreviatedName: string,
  userCompanyType: number,
  UserCompanyTypeDescription: number
}

export interface BusinessBankingProfiles extends Array<BusinessBankingProfile> {
}

export class AddBusinessBankingProfiles {
  static readonly type = '[BusinessBankingProfiles] Add';

  constructor(public payload: BusinessBankingProfiles) {
  }
}

export class BusinessBankingProfilesStateModel {
  userProfiles: BusinessBankingProfiles;
}

@State<BusinessBankingProfilesStateModel>({
  name: 'businessBankingProfiles'
})

@Injectable()

export class BusinessBankingProfilesState {

  @Selector()
  static getBusinessBankingProfiles(state: BusinessBankingProfilesStateModel) {
    return state.userProfiles;
  }

  @Action(AddBusinessBankingProfiles)
  add({getState, patchState}: StateContext<BusinessBankingProfilesStateModel>, {payload}: AddBusinessBankingProfiles) {
    patchState({
      userProfiles: payload
    });
  }
}
