
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Live chat</h4>
    <button type="button" class="close" aria-label="Close"  (click)="activeModal.dismiss('')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to leave the chat ?</p>
  </div>
  <div class="modal-footer">
    <ui-button [btnClass]="'primary'" [behaviorType]="'button'" (click)="activeModal.close('Disconnect');">Yes</ui-button>

    <ui-button [btnClass]="'secondary'" [behaviorType]="'button'" (click)="activeModal.dismiss('')">No</ui-button>
  </div>

