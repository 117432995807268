<div class="modal-body">
  <div class="row justify-content-center">
    <div class="col text-center">
      <img src="assets/images/icon-inapp.png" alt="password">
      <p>A message has been sent to your mobile to confirm your log in</p>

      <div class="destination mb-1">
        <h5 class="m-0">{{secondFactorData.Destination}}</h5>
      </div>

      <p class="small">If the mobile number reflected above is incorrect please contact the Investec Client Support Center</p>

      <div class="stopwatch text-center mt-2">
        <img src="assets/images/stopwatch.png" alt="timeout stopwatch">
        <p>{{popUpTimer}}s</p>
      </div>
      <p class="font-weight-bold">Awaiting mobile confirmation</p>

      <ui-button class="pr-2" [btnClass]="'secondary'" (click)="cancelUssd()">Cancel</ui-button>
      <ui-button *ngIf="canRetry" [btnClass]="'primary'" (click)="retryUssd()" [loading]="isLoading">Retry</ui-button>
    </div>
  </div>
</div>
