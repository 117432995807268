import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing/app-routing.module';
import {GlobalInterceptorModule} from '@investec-online/global-interceptor';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {GlobalNavigationModule} from '@investec-online/global-navigation';
import {AppModuleStorybook} from '@investec/io-bootstrap';
import {HttpClientModule} from '@angular/common/http';
import {PlatformStateManagementModule} from '@investec-online/platform-state-management';
import {SecondFactorPlatformModule} from '@investec-online/second-factor-platform';
import {PlatformComponentsModule} from "@investec-online/platform-components";
import { DataLayerAnalyticsRouteResolver } from './data-layer-analytics-route.resolver';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    GlobalInterceptorModule,
    NgbModule,
    GlobalNavigationModule,
    AppModuleStorybook,
    HttpClientModule,
    PlatformStateManagementModule,
    PlatformComponentsModule,
    SecondFactorPlatformModule
  ],
  providers: [DataLayerAnalyticsRouteResolver],
  bootstrap: [AppComponent]
})
export class AppModule {}
