import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from "@angular/core";
import {produce} from "immer"

export interface MenuConfig{
  mainMenu: MenuItems,
  mainMenuWithoutChildren: MenuItems,
  menuPreference?: string,
  callResultMap?: Array<CallResultMapModel>,
  hasError?:boolean,
  currentMenuRegion?: string
}

export interface CallResultMapModel {
  url: string,
  result: boolean,
  region: string
}

export interface MenuItemMapConfig{
  menuId: string,
  redirectUrl:RedirectUrlModel,
  analyticsQueryString: string
}

export interface RedirectUrlModel{
  menuUrlDefault?: string,
  menuUrlZA?: string,
  menuUrlUK?: string,
  menuUrlCI?: string,
  menuUrlMU?: string
}

export interface MenuItem {
  Id: number,
  PositionRank: number,
  Name: string,
  ExclusiveHide: boolean,
  AbsoluteUrl: string,
  New: boolean,
  SearchKeys: string,
  LongDescription?: string;
  Children: Array<MenuItem>
}
export interface MenuItems extends Array<MenuItem> {
}

export class AddMenuConfig {
  static readonly type = '[MenuConfig] Add';

  constructor(public payload: MenuConfig) {
  }
}

export class UpdateCallResultMap {
  static readonly type = '[MenuConfig] Update ResultMap';

  constructor(public payload: {callResultMap: CallResultMapModel,hasError:boolean}) {
  }
}

export class UpdateMenuPreference {
  static readonly type = '[MenuConfig] Update MenuPreference';

  constructor(public payload: string) {
  }
}

export class UpdateMenuRegion {
  static readonly type = '[MenuConfig] Update Menu Region';

  constructor(public payload: string) {
  }
}

export class ResetMenu {
  static readonly type = '[MenuConfig] Reset Menu';
}

export class MenuConfigStateModel {
  menuConfig: MenuConfig;
}

@State<MenuConfigStateModel>({
  name: 'Menu'
})

@Injectable()

export class MenuConfigState {

  @Selector()
  static getMenuConfig(state: MenuConfigStateModel) {
    return state.menuConfig;
  }

  @Action(AddMenuConfig)
  add({getState, patchState}: StateContext<MenuConfigStateModel>, {payload}: AddMenuConfig) {
    patchState({
      menuConfig: payload
    });
  }

  @Action(ResetMenu)
  ResetMenu({patchState}: StateContext<MenuConfigStateModel>) {
    patchState({
      menuConfig: {mainMenu:[],mainMenuWithoutChildren:[]}
    });
  }

  @Action(UpdateCallResultMap)
  UpdateCallResultMap(ctx: StateContext<MenuConfigStateModel>, {payload}: UpdateCallResultMap) {
    const newState = produce(ctx.getState(),draft => {
      let itemExists = false;
      for (let i = 0; i < draft.menuConfig.callResultMap.length; i++) {
        const callResultMapElement = draft.menuConfig.callResultMap[i];
        if(callResultMapElement.url === payload.callResultMap.url){
          callResultMapElement.result = payload.callResultMap.result;
          itemExists = true;
          break;
        }
      }

      if(!itemExists){
        draft.menuConfig.callResultMap.push(payload.callResultMap)
      }

      draft.menuConfig.hasError = payload.hasError;
    })

    ctx.setState(newState)
  }

  @Action(UpdateMenuPreference)
  UpdateMenuPreference(ctx: StateContext<MenuConfigStateModel>, {payload}: UpdateMenuPreference) {
    const newState = produce(ctx.getState(),draft => {
      draft.menuConfig.menuPreference = payload;
    })

    ctx.setState(newState)
  }

  @Action(UpdateMenuRegion)
  UpdateMenuRegion(ctx: StateContext<MenuConfigStateModel>, {payload}: UpdateMenuRegion) {
    const newState = produce(ctx.getState(),draft => {
      draft.menuConfig.currentMenuRegion = payload;
    })

    ctx.setState(newState)
  }

}
