import {State, Action, StateContext, Selector, Store, NgxsOnInit} from '@ngxs/store';
import {Injectable} from "@angular/core";
import {produce} from "immer";
import {UserTypeState} from "./user-type";

export interface PlatformInteraction {
  showFooter?: boolean,
  showHeader?: boolean,
  showShopFrontFooter?: boolean,
  showShopFrontHeader?: boolean,
  showMenu?: boolean,
  showLogOut?: boolean,
  showLogin?: boolean,
  showLeftNav?: boolean,
  showRightNav?: boolean,
  showLiveChat?: boolean,
  showNotifications?: boolean,
  showMarketplaceMenu?: boolean,
  enableStickyFooter?: boolean,
  fullFooter?: boolean,
  fullHeaderWidth?: boolean,
  hideFooterOnMobileView?: boolean
  theme?: string,
  shopfrontMenu?: boolean
}

export const platformInteractionStateTemplates = {
  loggedIn:{
    showFooter: true,
    showHeader: true,
    showShopFrontFooter: false,
    showShopFrontHeader: false,
    showMenu: true,
    showMarketplaceMenu: true,
    showLogOut: true,
    showLogin: false,
    showLeftNav: false,
    showRightNav: false,
    showLiveChat: true,
    showNotifications: true,
    enableStickyFooter: true,
    fullFooter: false,
    fullHeaderWidth: false,
    hideFooterOnMobileView: false,
    theme: 'dark'
  },
  notLoggedIn:{
    showFooter: true,
    showHeader: true,
    showShopFrontFooter: false,
    showShopFrontHeader: false,
    showLogin: false,
    showNotifications: false,
    showMenu: false,
    showMarketplaceMenu: false,
    showLogOut: false,
    showLeftNav: false,
    showRightNav: false,
    showLiveChat: false,
    enableStickyFooter: true,
    fullHeaderWidth: false,
    hideFooterOnMobileView: false,
    fullFooter: false
  },
  logoutPage:{
    showFooter: true,
    showHeader: true,
    showShopFrontFooter: false,
    showShopFrontHeader: false,
    showLogin: true,
    showNotifications: false,
    showMenu: false,
    showMarketplaceMenu: false,
    showLogOut: false,
    showLeftNav: false,
    showRightNav: false,
    showLiveChat: false,
    enableStickyFooter: true,
    fullHeaderWidth: false,
    hideFooterOnMobileView: false,
    fullFooter: false
  },
  contentPage:{
    showHeader: false,
    showFooter: false,
    showLogin: false,
    showShopFrontFooter: true,
    showShopFrontHeader: true,
    showNotifications: false,
    showMenu: false,
    showMarketplaceMenu: false,
    showLogOut: false,
    showLeftNav: false,
    showRightNav: false,
    showLiveChat:false,
    fullHeaderWidth: false,
    hideFooterOnMobileView: false,
    fullFooter: false
  },

}

export class SetPlatformInteraction {
  static readonly type = '[PlatformInteraction] Set';

  constructor(public payload: PlatformInteraction) {
  }
}

export class UpdatePlatformInteraction {
  static readonly type = '[PlatformInteraction] Update';

  constructor(public payload: PlatformInteraction) {
  }
}

export class PlatformInteractionStateModel {
  interactions: PlatformInteraction;
}

@State<PlatformInteractionStateModel>({
  name: 'PlatformInteraction',
  defaults: {
    interactions: platformInteractionStateTemplates.loggedIn
  }
})

@Injectable()

export class PlatformInteractionState implements NgxsOnInit{

  isLoggedIn = false;

  constructor(private _store: Store) {}

  ngxsOnInit(ctx: StateContext<PlatformInteractionStateModel>) {
    //enableES5()

    this._store.select(UserTypeState.getUserType).subscribe(res => {
      this.isLoggedIn = res !== undefined
    })

    // console.log(this.isLoggedIn);

    ctx.dispatch(new SetPlatformInteraction(this.isLoggedIn? platformInteractionStateTemplates.loggedIn :platformInteractionStateTemplates.notLoggedIn));
  }

  @Selector()
  static getPlatformInteraction(state: PlatformInteractionStateModel) {
    return state.interactions;
  }

  @Action(SetPlatformInteraction)
  set(ctx: StateContext<PlatformInteractionStateModel>, {payload}: SetPlatformInteraction) {
    const newState = produce(ctx.getState(),draft => {
      Object.keys(platformInteractionStateTemplates.loggedIn).forEach((value => {
        if(draft.interactions[value] !== platformInteractionStateTemplates.loggedIn[value]){
          draft.interactions[value] = platformInteractionStateTemplates.loggedIn[value];
        }
      }))

      Object.keys(payload).forEach((value => {
        if(draft.interactions[value] !== payload[value]){
          draft.interactions[value] = payload[value];
        }
      }))
    })

    ctx.setState(newState)
  }

  @Action(UpdatePlatformInteraction)
  update(ctx: StateContext<PlatformInteractionStateModel>, {payload}: UpdatePlatformInteraction) {
    const newState = produce(ctx.getState(),draft => {
      Object.keys(payload).forEach((value => {
        if(draft.interactions[value] !== payload[value]){
          draft.interactions[value] = payload[value];
        }
      }))
    })

    ctx.setState(newState)
  }
}
