import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from "@angular/core";
import {produce} from "immer";
import {object} from "@amcharts/amcharts4/core";

export interface PortfolioInterface {
  portfolios?: {},
  contactData?: {
    privateBanker?: PrivateBankerInterface,
    wealthManager?: WealthManagerInterface,
    lifeAdviser?: LifeAdvisorInterface
  }
  callResultMap?: {flag:string,result:boolean}[],
  excludes?: {flag:string,result:boolean}[],
  currency?: string,
  totals?: {PortfolioAssets, PortfolioLiabilities, PortfolioAvailableBalance}
}

export interface PrivateBankerInterface {
  BusinessCardIndicator: string
  Consultant: string
  CscName: string
  CscNumber: string
  DisplayName: string
  Email: string
  FaxNumber: string
  GroupRef: string
  MobileNumber: string
  ParentRef: string
  PhoneNumber: string
}

export interface WealthManagerInterface {
  BusinessCardIndicator: boolean
  CSCName: string
  CSCNumber: string
  Consultant: string
  DisplayName: string
  Email: string
  FaxNumber: string
  MobileNumber: string
  PhoneNumber: string
}

export interface LifeAdvisorInterface {
  BusinessCardIndicator: string,
  Consultant: string,
  DisplayName: string,
  Email: string,
  FaxNumber: string,
  GroupRef: number,
  PhoneNumber: string,
  CSCName: string,
  CSCNumber: string
}

export class SetEmptyPortfolio {
  static readonly type = '[Portfolio] Add';
}

export class UpdatePortfolio {
  static readonly type = '[Portfolio] Update';

  constructor(public payload: PortfolioInterface) {
  }
}

export class UpdatePortfolioExcludes {
  static readonly type = '[Excludes] Update';

  constructor(public payload: {flag:string,result:boolean}[]) {
  }
}

export class UpdateContactData {
  static readonly type = '[Contact data] Update';

  constructor(public payload: {contactData,type}) {
  }
}

export class PortfolioStateModel {
  data: PortfolioInterface
}

@State<PortfolioStateModel>({
  name: 'Portfolio'
})

@Injectable()

export class PortfolioState {

  @Selector()
  static getPortfolio(state: PortfolioStateModel) {
    return state.data;
  }

  @Action(UpdatePortfolio)
  updatePortfolio(ctx: StateContext<PortfolioStateModel>, {payload}: UpdatePortfolio) {
    const newState = produce(ctx.getState(), draft => {

      if(draft.data === undefined){
        draft.data = {totals:{PortfolioAssets:0,PortfolioAvailableBalance:0,PortfolioLiabilities:0},portfolios:{},callResultMap:[],currency:'',excludes:[]}
      }

      Object.keys(payload).forEach(value => {
        switch (value) {
          case 'currency': draft.data.currency = payload.currency
            break
          case 'portfolios':
            Object.keys(payload.portfolios).forEach(key => {
              this.calculateNetWorth(payload.portfolios[key],key,draft.data)
              draft.data.portfolios[key] = payload.portfolios[key];
            })
            break
          case 'callResultMap':
          case 'excludes':
            payload[value].forEach(result => {
              const i = draft.data[value].findIndex(x => x.flag === result.flag)
              i !== -1 ? draft.data[value][i] = result : draft.data[value].push(result)
            })
            break
        }
      })
    })

    ctx.setState(newState)
  }

  @Action(UpdatePortfolioExcludes)
  UpdatePortfolioExcludes(ctx: StateContext<PortfolioStateModel>, {payload}: UpdatePortfolioExcludes) {
    const newState = produce(ctx.getState(), draft => {
      payload.forEach(result => {
        const i = draft.data.excludes.findIndex(x => x.flag === result.flag)
        i !== -1 ? draft.data.excludes[i] = result : draft.data.excludes.push(result)
        this.calculateNetWorth(draft.data.portfolios[result.flag],result.flag,draft.data);
      })
    })

    ctx.setState(newState)
  }

  @Action(UpdateContactData)
  UpdateContactData(ctx: StateContext<PortfolioStateModel>, {payload}: UpdateContactData) {
    const newState = produce(ctx.getState(), draft => {
      if(draft.data.contactData ===  undefined){
        draft.data.contactData = {}
      }
     draft.data.contactData[payload.type] = payload.contactData
    })

    ctx.setState(newState)
  }

  @Action(SetEmptyPortfolio)
  setEmptyPortfolio({patchState}: StateContext<PortfolioStateModel>) {
    patchState({
      data: {totals:{PortfolioAssets:0,PortfolioAvailableBalance:0,PortfolioLiabilities:0},portfolios:{},callResultMap:[],currency:'',excludes:[]}
    });
  }

  calculateNetWorth(res,flag,tempPortfolio: PortfolioInterface){

    let excludeFromTotal = false;
    tempPortfolio.excludes.forEach(value => {
      if(value.flag === flag){
        excludeFromTotal = value.result;
      }
    })

    if(tempPortfolio.portfolios[flag] !== undefined){
      tempPortfolio.totals.PortfolioAssets -= tempPortfolio.portfolios[flag].PortfolioAssets
      tempPortfolio.totals.PortfolioLiabilities -= tempPortfolio.portfolios[flag].PortfolioLiabilities
      tempPortfolio.totals.PortfolioAvailableBalance -= tempPortfolio.portfolios[flag].PortfolioAvailableBalance
    }

    if(!excludeFromTotal){
      object.keys(tempPortfolio.totals).forEach(key => {
        if(res[key] !== undefined){
          if(typeof res[key] === 'string'){
            tempPortfolio.totals[key] += parseFloat(res[key]);
          } else {
            tempPortfolio.totals[key] += res[key];
          }
        }
      })
    }
  }
}
