import { State, Action, StateContext, Selector } from '@ngxs/store';
import {Injectable} from "@angular/core";

export interface SelectedCampaign {
  dashboardBanner: string,
  selectedCampaign: string
}

export class SetSelectedCampaign {
  static readonly type = '[SelectedCampaign] Add';

  constructor(public payload: SelectedCampaign) {}
}

export class SelectedCampaignStateModel {
  campaign: SelectedCampaign;
}

@State<SelectedCampaignStateModel>({
  name: 'SelectedCampaign'
})

@Injectable()

export class SelectedCampaignState {

  @Selector()
  static getSelectedCampaign(state: SelectedCampaignStateModel) {
    return state.campaign;
  }

  @Action(SetSelectedCampaign)
  add({getState, patchState }: StateContext<SelectedCampaignStateModel>, { payload }: SetSelectedCampaign) {
    patchState({
      campaign: payload
    });
  }
}
