<div class="container">
  <div class="row">
    <div class="col d-none d-md-block"></div>
    <div class="col-md-auto">
      <div class="row">
        <div id="live-chat-container">
          <div class="live-chat-popup w-100" [ngClass]="{'show': !minimized}">
            <div class="align-items-center justify-content-between title">
              <div class="overline-2">CHAT WITH A BANKER</div>
              <div>
                <ui-icon [name]="'interface-minus-large'" [size]="'24'" color="white"
                         (click)="minimizeLiveChat()"></ui-icon>
                <ui-icon [name]="'interface-close-medium'" [size]="'24'" color="white"
                         (click)="open()" class="ml-1 icon"></ui-icon>
              </div>
            </div>
            <div class="chat-body ps" [ngClass]="{'align-items-center': !hasConnectedToAvaya}" >
                <div class="content-box live-chat-body w-100">
                  <ng-container *ngFor="let message of messagesList">
                    <div class="d-flex flex-column mb-3" *ngIf="message.message !== ''" [ngClass]="message.participant === 'Agent' ? 'Agent mr-n1' : 'Client ml-n1'">
                      <p class="chat-time mb-1" [ngClass]="message.participant === 'Agent' ? 'ml-auto' : 'mr-auto'">{{message.participant}}<span *ngIf="message.chatTime" class="mr-1 ml-1">|</span>{{message.chatTime}}</p>
                      <div [class]="message.className">
                        <p class="speech-bubble-arrow">{{message.message}}</p>
                      </div>
                    </div>
                  </ng-container>

                  <div *ngIf="isTyping" class="dot-container p-1"><span class="dot"></span><span
                    class="dot"></span><span
                    class="dot"></span></div>

                  <div class='d-flex flex-row justify-content-center'
                       *ngIf="!hasConnectedToAvaya"><span class='dot'></span><span
                    class='dot'></span><span class='dot'></span>
                  </div>
                  <div>
                    <ui-alerts *ngIf="agentHasLeft" [alertName]="'info'"
                               message="You've been disconnected from the chat."></ui-alerts>
                    <ui-alerts *ngIf="!agentHasConnected && hasConnectedToAvaya && !agentHasLeft && !agentNotAvailable" [alertName]="'info'"
                               [message]=requestChatMsg></ui-alerts>
                    <ui-alerts *ngIf="agentNotAvailable" [alertName]="'info'"
                               [message]="apiErrorMessage ? apiErrorMessage : 'There are no bankers available online to assist you now, please call the global Client Support Centre on +27 11 286 9663.'"></ui-alerts>
                  </div>
                </div>
            </div>
            <div class="chat-footer">
              <div class="input-group input-group-append-icon d-flex align-items-center">
                <textarea id="chat-text-area" maxlength="2000" (keydown)="isUserTyping()"
                          [disabled]="!agentHasConnected"
                          #chatInput [(ngModel)]="chatMessage" (keyup.enter)="sendMessage()" class="form-control"
                          placeholder="Chat message">
                </textarea>
                <ui-button [disabled]="!agentHasConnected" class="ml-1" (click)="sendMessage()" id="send-message" roundBtn="true" btnClass="round-primary" iconName="interface-message-send"></ui-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
