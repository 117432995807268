import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from "@angular/core";

export interface BankingAvailableAccount {
  Region: string,
  ProductSegment: string,
  AccountId: string,
  AccountNumber: string,
  AccountNumberHash: string,
  AccountName: string,
  AccountType: string,
  Country: string,
  Currency: string,
  ProfileId?: string,
  IsFCAccount?: boolean,
  CanMakePayment?: boolean,
  CanMakeFxPayment?: boolean,
  CanMakeTransfers?: boolean,
  CanMakeStandingOrders?: boolean,
  BalanceAssets?: number
  BalanceLiabilities?: number
  BaseBalanceAssets?: number
  BaseBalanceLiabilities?: number
  AvailableBalance?: number
  BaseAvailableBalance?: number
  Urls: {
    MakeSinglePaymentUrl?: string,
    ViewTransactionUrl?: string,
    AccountOverviewUrl?: string,
    ManageAccountUrl?: string,
    MaturityInstruction?: string,
    InternationalPaymentUrl?: string,
    MakeNoticeWithdrawalUrl?: string,
    BenManageUrl?: string,
    AccountSummaryUrl?: string,
    FixedDepositUrl?: string,
    IntTransferUrl?: string,
    AccountPayUrl?: string,
    TranactionHistoryUrl?: string,
  }
}

export interface WealthAvailableAccount {
  AccountHoldingUrl?: string
  AccountProvider?: string
  AccountTypeDescription?: string
  BaseProductAccountAvailValue?: number
  BaseProductAccountValue?: number
  CanPay?: boolean
  CanTrade?: boolean
  Currency?: string
  HideAvailValue?: boolean
  IsFicaCompliant?: boolean
  IsPlatformAccount?: boolean
  MyInvestmentsAdditionalContributionUrl?: string
  MyLocalUnitTrustAdditionalContributionUrl?: string
  MyPortfolioUrl?: string
  PlatformAccountDisclaimer?: string
  ProductAccountAvailValue?: number
  ProductAccountNumber?: string
  ProductAccountValue?: number
  ProductName?: string
  ProductOnly?: boolean
  ProductSegment?: string
  ProviderName?: string
  SortIndex?: number
  Urls: {
    AccountHoldingUrl?: string,
    MyInvestmentsAdditionalContributionUrl?: string,
    MyPortfolioUrl?: string,
    TransactionHistoryUrl?: string,
    TradeUrl?: string,
    PaymentUrl?: string
  }
}

export interface MortgageAvailableAccount {
  Region: string,
  ProductSegment: string,
  AccountId: string,
  AccountNumber: string,
  AccountNumberHashed: string,
  AccountName: string,
  AccountType: string,
  Country: string,
  Currency: string,
  BalanceLiabilities?: number
  BaseBalanceLiabilities?: number
  AvailableBalance?: number
  BaseAvailableBalance?: number
  Urls: {
    ManageAccountUrl?: string
  }
}

export interface BankingAvailableAccounts extends Array<BankingAvailableAccount> {
}

export interface WealthAvailableAccounts extends Array<WealthAvailableAccount> {
}

export interface MortgageAvailableAccounts extends Array<MortgageAvailableAccount> {
}

export interface PcAvailableAccounts {
  bankingAccounts: BankingAvailableAccounts,
  wealthAccounts: WealthAvailableAccounts,
  mortgageAccounts: MortgageAvailableAccounts
}

export class AddPcAvailableAccounts {
  static readonly type = '[PcAvailableAccounts] Add';

  constructor(public payload: PcAvailableAccounts) {
  }
}

export class PcAvailableAccountsStateModel {
  availableAccounts: PcAvailableAccounts;
}

@State<PcAvailableAccountsStateModel>({
  name: 'PrivateClientAccounts'
})

@Injectable()

export class PcAvailableAccountsState {

  @Selector()
  static getPcAvailableAccounts(state: PcAvailableAccountsStateModel) {
    return state.availableAccounts;
  }

  @Action(AddPcAvailableAccounts)
  add({getState, patchState}: StateContext<PcAvailableAccountsStateModel>, {payload}: AddPcAvailableAccounts) {
    patchState({
      availableAccounts: payload
    });
  }
}
