import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SecondFactorModalComponent} from "./second-factor-modal/second-factor-modal.component";
import {SecondFactorService} from "../service/second-factor.service";

@Component({
  selector: 'investec-online-second-factor',
  templateUrl: './second-factor.component.html',
  styleUrls: ['./second-factor.component.scss']
})
export class SecondFactorComponent implements OnInit {

  modal: any;

  constructor(private modalService: NgbModal, private _secondFactorService: SecondFactorService) {
    this._secondFactorService.invokeSecondFactorModal.subscribe(res => {
      this.invokeSecondFactor();
    });
  }

  ngOnInit() {
  }

  invokeSecondFactor() {
    this.modal = this.modalService.open(SecondFactorModalComponent, {
      centered: true,
      keyboard: false,
      backdrop: "static"
    });

    this._secondFactorService.secondFactorInProgress = true;
  }

}
