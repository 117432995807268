import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class SharedUtilsService {

  constructor() { }

  removeDuplicatesFromArray(array, property?) {

    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if(property === undefined){
          if (a[i] === a[j])
            a.splice(j--, 1);
        } else {
          if (a[i][property] === a[j][property])
            a.splice(j--, 1);
        }

      }
    }
    return a;
  }

  deepCopy = inObject => {
    let outObject, value, key

    if (typeof inObject !== "object" || inObject === null) {
      return inObject // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {}

    // tslint:disable-next-line:forin
    for (key in inObject) {
      value = inObject[key]

      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = this.deepCopy(value)
    }

    return outObject
  }

  downloadBlob(blob, name = 'file.txt') {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }

}
