import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppModuleStorybook } from "@investec/io-bootstrap";
import { AmchartsModule } from '@investec/io-charts';
import { JsonDatePipe } from "./pipes/json-date.pipe";
import { InputFocusAndSelectDirective } from "./directives/input-focus-and-select";
import { ChecklistDirective } from "./directives/checklist";
import { AddressLookupComponent } from './components/address-lookup/address-lookup.component';
import { ServicesModule } from "./services/services.module";
import { HttpClientModule } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ErrorToastComponent } from "./components/error-toast/error-toast.component";
import { ShortNumberPipe } from "./pipes/short-number.pipe";
import { OauthConnectedAppsOverviewComponent } from './components/oauth-connected-apps-overview/oauth-connected-apps-overview.component';
import { OauthIndividualComponent } from './components/oauth-connected-apps-overview/oauth-individual/oauth-individual.component';
import { Oauth3rdPartyComponent } from './components/oauth-connected-apps-overview/oauth-3rd-party/oauth-3rd-party.component';
import { OauthBusinessComponent } from './components/oauth-connected-apps-overview/oauth-business/oauth-business.component';
import { OauthIntermediaryComponent } from './components/oauth-connected-apps-overview/oauth-intermediary/oauth-intermediary.component';
import { ProgCardContainerComponent } from './components/programmable-card/cards-overview/root-card-container/root-card-container.component';
import { ProgCardsOverviewComponent } from './components/programmable-card/cards-overview/root-cards-overview.component';
import { ProgCardFileExplorerComponent } from './components/programmable-card/code-editor/code-editor-file-explorer/code-editor-file-explorer.component';
import { ProgCardLogTableComponent } from './components/programmable-card/code-editor/code-editor-logs/log-table/log-table.component';
import { ProgCardLogsComponent } from './components/programmable-card/code-editor/code-editor-logs/root-logs.component';
import { ProgCardSidebarComponent } from './components/programmable-card/code-editor/code-editor-sidebar/code-editor-sidebar.component';
import { ProgCardSimulatorComponent } from './components/programmable-card/code-editor/code-editor-simulator/simulator.component';
import { ProgCardCodeEditorComponent } from './components/programmable-card/code-editor/code-editor.component';
import { ProgCardVariablesComponent } from './components/programmable-card/code-editor/code-editor-variables/root-variables.component';
import { MonacoEditorModule, NgxMonacoEditorConfig } from "ngx-monaco-editor-v2";
import { ProgCardRowComponent } from './components/programmable-card/cards-overview/root-card-row/root-card-row.component';
import { PlatformToastComponent } from './components/platform-toast/platform-toast.component';
import { ProgCardTermsAndConditionsComponent } from './components/programmable-card/terms-and-conditions/terms-and-conditions.component';
import { MinValueDirective } from './directives/min-value.directive';
import { MaxValueDirective } from './directives/max-value.directive';
import { SufficientBalanceDirective } from './directives/sufficient-balance.directive';
import { SortableHeaderDirective } from './directives/sortable-header.directive';
import { MultiSelectFocusComponent } from './components/multi-select-focus/multi-select-focus.component';
import { TimeAgoPipe } from "./pipes/time-ago.pipe";
import { Ng2SearchPipe } from './pipes/ng2-filter.pipe';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { PickerModule } from './components/picker/picker.module';
import { EmojiModule } from './components/picker/ngx-emoji';

const monacoConfig: NgxMonacoEditorConfig = {
  baseUrl: 'assets/' // configure base path for monaco editor default: './assets'
};


@NgModule({
  declarations: [
    JsonDatePipe,
    InputFocusAndSelectDirective,
    ChecklistDirective,
    AddressLookupComponent,
    ErrorToastComponent,
    ShortNumberPipe,
    OauthConnectedAppsOverviewComponent,
    OauthIndividualComponent,
    Oauth3rdPartyComponent,
    OauthBusinessComponent,
    OauthIntermediaryComponent,
    ProgCardContainerComponent,
    ProgCardRowComponent,
    ProgCardsOverviewComponent,
    ProgCardFileExplorerComponent,
    ProgCardLogTableComponent,
    ProgCardLogsComponent,
    ProgCardSidebarComponent,
    ProgCardSimulatorComponent,
    ProgCardCodeEditorComponent,
    ProgCardVariablesComponent,
    PlatformToastComponent,
    ProgCardTermsAndConditionsComponent,
    MinValueDirective,
    MaxValueDirective,
    SufficientBalanceDirective,
    SortableHeaderDirective,
    MultiSelectFocusComponent,
    TimeAgoPipe,
    Ng2SearchPipe,
    HighlightSearchPipe
  ],
  imports: [
    CommonModule,
    AppModuleStorybook,
    AmchartsModule,
    ServicesModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    EmojiModule,
    PickerModule,
    MonacoEditorModule.forRoot(monacoConfig)
  ],
  exports: [
    JsonDatePipe,
    ShortNumberPipe,
    InputFocusAndSelectDirective,
    ChecklistDirective,
    AddressLookupComponent,
    ErrorToastComponent,
    OauthConnectedAppsOverviewComponent,
    OauthIndividualComponent,
    Oauth3rdPartyComponent,
    OauthBusinessComponent,
    OauthIntermediaryComponent,
    ProgCardsOverviewComponent,
    ProgCardCodeEditorComponent,
    ProgCardTermsAndConditionsComponent,
    MinValueDirective,
    MaxValueDirective,
    SufficientBalanceDirective,
    SortableHeaderDirective,
    MultiSelectFocusComponent,
    TimeAgoPipe,
    Ng2SearchPipe,
    HighlightSearchPipe,
    PickerModule,
    EmojiModule,
    PlatformToastComponent,
    ProgCardRowComponent
  ]
})
export class PlatformComponentsModule {
}
