import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse, HttpParams } from "@angular/common/http";
import {
  AddCurrencies,
  AuthResponse,
  BusinessBankingProfile, Currencies,
  Features,
  InvestecServices, LifeAdvisorInterface,
  UserSettings
} from "../../../platform-state-management/src";
import { CurrentProfileAccess } from "../../../second-factor-platform/src";
import { LoginModel, LoginResponse, ServiceNotification } from "./models";
import {environment} from "@env-vars";

@Injectable({
  providedIn: 'root'
})
export class SharedHttpService {

  constructor(private _http: HttpClient) {
  }

  //sso login calls
  login(ping: string, model: LoginModel, userApi?: boolean) {

    const payload = {
      password: model.password,
      ping: ping,
      userName: model.username
    };

    if (userApi) {
      return this._http.post<LoginResponse>('/proxy/user/v2/login', payload)
    } else {
      return this._http.post<LoginResponse>('/proxy/auth', payload);
    }
  }

  getGenericPing() {
    return this._http.get<string>('/proxy/generic/Ping');
  }

  getServiceNotification() {
    return this._http.get<ServiceNotification>("/content/investec/shared/service-notifications/jcr:content/configs/investec-online.jsonData")
  }

  // global http calls

  getUserCapabilities() {
    return this._http.get<{ RedirectExternalUrl, RedirectInternalUrl, SecurityDetailsRequired, Applications: Array<string>, Capabilities: Array<any>, Failed: Array<any> }>('/apiv2sim/capabilities')
  }

  getUserSettings() {
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('', new Date().getTime().toString());

    return this._http.get<UserSettings>('/proxy/user/settings', {params: params});
  }

  getFullLoginContext() {
    return this._http.get<AuthResponse>('/proxy/user/LoginState')
  }

  getAvailableUserSettings() {
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('', new Date().getTime().toString());

    return this._http.get<UserSettings>('/proxy/user/availableSettings', {params: params});
  }

  addUserSettings(payload) {
    return this._http.post('/proxy/user/SettingAdd', payload)
  }

  getProfileList() {
    return this._http.get<any[]>('/proxy/za/pb/profiles?' + new Date().getTime())
  }

  getPortfolio(region, endPoint, currency, profileId?, refreshCache?) {
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('', new Date().getTime().toString());
    if (refreshCache) {
      params = params.append('refreshCache', 'true');
    }

    if (profileId === undefined || profileId === '') {
      return this._http.get<any>('/proxy/' + region + endPoint + '/' + currency, {params: params})
    } else {
      return this._http.get<any>('/proxy/' + region + endPoint + '/' + profileId + '/' + currency, {params: params})
    }

  }

  getCurrencyList(){
    return this._http.get<Currencies>('/proxy/reference/currencies')
  }

  getUserFeatures() {
    return new Promise<Features>((resolve, reject) => {
      let params = new HttpParams();

      // Begin assigning parameters
      params = params.append('', new Date().getTime().toString());

      const getUserFeatures$ = this._http.get<Features>('/bin/userFeatures', {params: params});

      function GetFeaturesLoop() {
        getUserFeatures$.subscribe(userFeatures => {
          if (userFeatures['NOT_LOGGED_IN']) {
            GetFeaturesLoop()
          } else {
            userFeatures.alwaysTrue = true;
            resolve(userFeatures)
          }
        }, err => {
          reject(err);
        })
      }

      GetFeaturesLoop();
    })
  }

  getDigitalFeatures() {
    return this._http.get<Array<{ Code, ExclusiveHide, Id }>>('/proxy/feature')
  }

  getPrivateBankZAFeatures() {
    return this._http.get<Array<{ Code, ExclusiveHide, Id }>>('/proxy/za/pb/feature')
  }

  getPrivateBankUKFeatures() {
    return this._http.get<Array<{ Code, ExclusiveHide, Id }>>('/proxy/uk/pb/feature')
  }

  setAcceptHeader() {
    let headers = new HttpHeaders();
    return headers = headers.set('Accept', 'application/vnd.investec.uxp.v2.0.1.1');
  }

  wealthUpSellActions(clientName: string , accountsArray : Array<string>, wealthUpSellCode) {
    const payload: any = {
      "Data": {
        "Name": clientName,
        "AccountNumbers": accountsArray
      }
    }

    const baseUrl =   environment.pcuk.cdxPcUkService + '/uk/accounts';
    const resource = `${baseUrl}/v1.0/accounts/wealth-up-sell`;

    this._http.post(resource, payload, { withCredentials: true, headers: this.setAcceptHeader(), observe: 'response'}).subscribe((res: HttpEvent<any>) => {
      if (res instanceof HttpResponse && res.status === 202) {
        this.deactivatePrivateBankUKFeature(wealthUpSellCode);
      }
    }, err => {
    });
  }

  getPrivateBankUkDynamicFeatures() {
    return this._http.get<Array<{ Code, ExclusiveHide, Id }>>('/proxy/uk/pb/feature/dynamic')
  }

  getWealthZAFeatures() {
    return this._http.get<Array<{ Code, ExclusiveHide, Id }>>('/proxy/za/wi/feature')
  }

  getInvestecServices() {
    return this._http.get<InvestecServices>('/proxy/user/profiles')
  }

  getCurrentProfileAccess() {
    return this._http.get<CurrentProfileAccess>('/proxy/sso/CurrentProfileAccess')
  }

  getCurrentAuthenticationLevel() {
    return this._http.get<string>('/proxy/sso/CurrentAuthenticationLevel')
  }

  //business banking platform http calls

  getBusinessBankingUserProfiles() {
    return this._http.get<{ data: Array<BusinessBankingProfile> }>('/proxy/za/tbb/api/v1/users/profiles')
  }

  deactivatePrivateBankUKFeature(featureCode: string){
    const payload = {
      Code: featureCode
    }
    return this._http.post<any>('/proxy/uk/pb/feature/deactivate', payload).subscribe(response => {
    }, error => {
    });
  }

  getLifeAdvisorContactDetails(){
    return this._http.get<{LifeAdviser: Array<LifeAdvisorInterface>}>('/proxy/life/lifeAdviserContactDetails')
  }
}
