import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import * as moment from 'moment-mini';

export function ValidateIdNumber(dateOfBirth: { year: number, month: number, day: number }, gender: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const formatDOB = moment(dateOfBirth).format('YYMMDD');

    const ValidDateOfBirth = control.value.toString().substr(0, 6) === formatDOB;
    const ValidGender = parseInt(control.value.toString().substr(6, 4), 0) >= 5000 ? 'Male' : 'Female';
    const ValidCheckSumDigit = control.value.toString().substr(12, 1);

    function GetControlDigit(idNumber: String) {
      let d = -1;
      try {
        let a = 0;
        for (let i = 0; i <= 10; i+=2) {
          a += parseInt(idNumber[i], 0);
        }
        let b = '';
        for (let i = 1; i <= 11; i+=2) {
          b = b+idNumber[i];
        }
        const bt = parseInt(b,0) * 2;

        let c = 0;
        for (let i = 0; i <= bt.toString().length-1; i++) {
          c += parseInt(bt.toString()[i],0);
        }
        const t = a + c;
        d = 10 - parseInt(t.toString().substr(t.toString().length - 1,1), 0);
      }
      catch {/*ignore*/
      }
      return d.toString().substr(d.toString().length - 1,1);
    }

    if (ValidDateOfBirth && (ValidGender === gender) && (ValidCheckSumDigit === GetControlDigit(control.value.toString()))) {
      return null
    } else {
      return {'invalidIdNumber': true}
    }
  }
}
