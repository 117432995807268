import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NGXS_PLUGINS, NgxsModule} from "@ngxs/store";
import {persistPlugin} from "./persist-state/persist.plugin";
import {SelectedProfileState} from "./states/selected-profile";
import {SelectedAccountState} from "./states/selected-account";
import {UserSettingsState} from "./states/user-settings";
import {CurrenciesState} from "./states/currencies";
import {SelectedCampaignState} from "./states/campaigns";
import {AuthResponseState} from "./states/auth-response";
import {NgxsReduxDevtoolsPluginModule} from "@ngxs/devtools-plugin";
import {NgxsLoggerPluginModule} from "@ngxs/logger-plugin";
import {environment} from "@env-vars";
import {UserTypeState} from "./states/user-type";
import {FeaturesState} from "./states/user-features";
import {ClientDetailsState} from "./states/client-details";
import {SelectedAccountPBUKState} from './states/selected-account-pbuk';
import {BusinessBankingProfilesState} from "./states/business-banking-profiles";
import {InvestecServicesState} from "./states/investec-services";
import {PcAvailableAccountsState} from "./states/available-accounts";
import {AvailableUserSettingsState} from "./states/available-user-settings";
import {WealthAndInvestmentState} from "./states/wealth-and-investment";
import {WIUKSelectedAccountState} from "./states/selected-account-wiuk";
import {PlatformInteractionState} from "./states/platform-interactions";
import {NgxsResetPluginModule} from "ngxs-reset-plugin";
import {WICISelectedAccountState} from "./states/selected-account-wici";
import {MenuConfigState} from "./states/menu-config";
import {CallsignConfigState} from "./states/callsign-config";
import {ShopfrontState} from "./states/shopfront";
import {MarketPlaceApiCacheState} from "./states/market-place-api";
import {SelectedAccountIBCIState} from './states/selected-account-ibci';
import {SelectedAccountSpsState} from './states/selected-account-sps';
import {PortfolioState} from "./states/portfolio";
import { UserSegmentState } from './states/user-segment';

@NgModule({
  imports: [CommonModule,
    NgxsModule.forRoot([
        SelectedProfileState,
        SelectedAccountState,
        SelectedAccountPBUKState,
        SelectedAccountIBCIState,
        SelectedAccountSpsState,
        WIUKSelectedAccountState,
        WICISelectedAccountState,
        UserSettingsState,
        AvailableUserSettingsState,
        CurrenciesState,
        SelectedCampaignState,
        AuthResponseState,
        UserTypeState,
        ClientDetailsState,
        FeaturesState,
        InvestecServicesState,
        BusinessBankingProfilesState,
        PcAvailableAccountsState,
        WealthAndInvestmentState,
        PlatformInteractionState,
        CallsignConfigState,
        ShopfrontState,
        MenuConfigState,
        MarketPlaceApiCacheState,
        PortfolioState,
        UserSegmentState
      ],
      {developmentMode: !environment.production}),
    NgxsResetPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({disabled: environment.production}),
    NgxsLoggerPluginModule.forRoot({disabled: environment.production})
  ],
  providers: [
    {
      provide: NGXS_PLUGINS,
      useValue: persistPlugin,
      multi: true
    },
  ]
})
export class PlatformStateManagementModule {
}
