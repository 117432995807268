import {Injectable} from '@angular/core';
import {
  HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse, HttpXsrfTokenExtractor
}
  from '@angular/common/http';

import { Observable, catchError, map} from 'rxjs';
import "rxjs-compat/add/operator/do";
import {throwError} from "rxjs/internal/observable/throwError";
import {GlobalErrorService, LogoutService} from "@investec-online/platform-services";
import {SecondFactorService} from "@investec-online/second-factor-platform";
import {environment} from "@env-vars";

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  secondFactorEnabled = environment.platform.secondFactor;
  errorToast = environment.platform.errorToast;

  constructor(private _secondFactor: SecondFactorService,
              private tokenExtractor: HttpXsrfTokenExtractor,
              public _logout: LogoutService,
              private _glbError: GlobalErrorService) {
  }

  logOut(){
    this._logout.mustLogOut.next(true);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.method === "POST" && req.url !== "/bin/restproxy/auth") {
      if(this.tokenExtractor.getToken() as string === null){
        //this.logOut();
      }
    }

    let cloneReq: any;
    if(req.url && req.url.includes("/ofds")) {
       cloneReq = req.clone({
        headers: req.headers
      });
    }
    else if(req.url && req.url.includes("/rest/v1/delivery")) {
      cloneReq = req.clone({
        headers: req.headers
      });
    }
    else {
      cloneReq = req.clone({
        headers: req.headers.set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
          .set('If-Modified-Since', '0')
          .set('Accept', 'application/vnd.investec.uxp.v2.0.1.1+json')
          .set('Accept', 'application/vnd.investec.uxp.v2.0.0.1+json')
      });
    }
    return next.handle(cloneReq).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse && (event.body === 'Error' || event.body === 'error' || event.body === null)) {
        if(event.status !== 201 && event.status !== 202 && event.status !== 204){
          throw(new HttpErrorResponse({status:500, statusText:event.body}))
        }
      } else if (event instanceof HttpResponse && (event.body.message === 'Invalid session! You are not logged in')){
        this.logOut()
      } else if(event instanceof HttpResponse && (event.body['Method'] && this.secondFactorEnabled)){
        if(!this._secondFactor.secondFactorInProgress){
          this._secondFactor.currentSecondFactorData.next(event.body);
          this._secondFactor.invokeSecondFactorModal.next(true);
        }
      }

      return event;

    }),
    catchError(err => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err.status === 403) {
          console.log('The authentication session has expired or the user is not authorised. Redirecting to login page.');
          this.logOut();
        }
        if(this.errorToast){
          this._glbError.show('API Error', req.url)
        }
        return throwError(err)
      }
      if(this.errorToast){
        this._glbError.show('API Error', req.url)
      }
      return throwError(err)
    })
    )
  }
}
