import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CurrencyPipe, getCurrencySymbol } from '@angular/common';

export interface LinkedAccountIBCI {
  CanTransferFrom: boolean; // true
  CanTransferTo: boolean; // true
  ClientName: string; // "FullName:- 013154"
  Currency: string; // "USD"
  CurrencySymbol: string; // "$"
  DataType: string; // "Bank"
  HasPaymentsFrom: boolean; // true
  IsNoticeAccount: boolean; // false
  ProductAccountNumber: string; // "1315401"
  ProductName: string; // "Account Number:-01315401"
  Type: string; // "Product:- 141"
  ClientType?: string;
  ClientCharityType?: string;
}

/**
 * NOTE: BaseBalanceAssets and BaseBalanceLiabilities (returned by the API) are both derived from the same underlying
 * service property "LedgerBalance".
 * If LedgerBalance > 0 then BaseBalanceAssets = LedgerBalance and BaseBalanceLiabilities = 0
 * If LedgerBalance < 0 then BaseBalanceAssets = 0 and BaseBalanceLiabilities = -1*LedgerBalance
 */
export interface SelectedAccountIBCI {
  AccountReference: string;
  AccountType: string;
  AccountName: string;
  AccountNumberHash: string;
  AvailableBalance: number;
  BaseAvailableBalance: number;
  InterestRate: number; // Not part of API - generated in code (from AccountBalance)
  InterestRateType: string; // Not part of API - generated in code (from AccountBalance)
  Balance: string; // BaseBalanceAssets or -1*BaseBalanceLiabilities depending on which value is set (see *NOTE) TODO (228249): should be populated from Balances call
  AccountNumber: string;
  SortCode: string;
  Currency: string;
  CurrencySymbol: string; // Not part of API - generated in code (from LinkedAccountIBCI)
  CurrencySymbolAngular: string; // Not part of API - generated in code (Angular currency symbol generated from currency code)
  CurrencyInfo?: string; // Not part of API - generated in code
  Branch: string; // BranchName
  Country: string;
  Properties: SelectedAccountIBCIProperties, // Not part of API - generated in code
  SortCodeFormatted?: string; // Not part of API - generated in code
  ClientName?: string; // Not part of API - generated in code (from LinkedAccountIBCI)
  ClientType?: string; // Not part of API - generated in code (from LinkedAccountIBCI)
  ClientCharityType?: string; // Not part of API - generated in code (from LinkedAccountIBCI)
}

export interface SelectedAccountIBCIProperties {
  CanMakeInternationalPayment: boolean;
  CanMakePayment: boolean;
  CanManageAccount: boolean;
  CanTransferFrom: boolean;
  IsInvestmentAccount: boolean;
  IsBankingAccount: boolean;
}

export interface AccountBalance {
  Available: number; // 47708060
  Balance: number; // 47708060 TODO (228249): not used - this value is returned incorrectly for fixed term accounts - needs to be reviewed in the API
  InterestRate: number; // 0.3
  InterestRateType: BalanceRateType; // "CR"
}

export enum BalanceRateType {
  Credit = 'CR',
  Debit = 'DR'
}


// parsing API response into flat structure
function parseSelectedAccount(account: any): SelectedAccountIBCI {
  const balance = account.BaseBalanceAssets >= account.BaseBalanceLiabilities ? account.BaseBalanceAssets : (-1 * account.BaseBalanceLiabilities); // TODO (228249): should be populated from Balances call
  const currencySymbolAngular = getCurrencySymbol(account.Currency, 'wide', 'en-UK');
  const currencyPipe = new CurrencyPipe('en-UK');
  return {
    AccountReference: account.AccountReference,
    AccountType: account.Type,
    AccountName: account.ProductName,
    AccountNumber: account.ProductAccountNumber,
    AccountNumberHash: account.AccountNumberHash,
    AvailableBalance: account.BaseAvailableBalance,
    BaseAvailableBalance: account.BaseAvailableBalance,
    InterestRate: undefined,
    InterestRateType: undefined,
    Balance: currencyPipe.transform(balance, account.Currency, 'symbol'),
    SortCode: account.SortCode,
    Currency: account.Currency,
    CurrencySymbol: account.CurrencySymbol,
    CurrencySymbolAngular: currencySymbolAngular,
    CurrencyInfo: `${account.Currency} (${currencySymbolAngular})`,
    ClientName: account.ClientName,
    ClientType: account.ClientType,
    ClientCharityType: account.ClientCharityType,
    Branch: account.BranchName,
    Country: account.Country,
    Properties: {
      CanMakeInternationalPayment: !!account.CanMakeInternationalPayment,
      CanMakePayment: !!account.CanMakePayment,
      CanManageAccount: !!account.CanManageAccount, // todo doesn't seem to be relevant, review if we need this
      CanTransferFrom: !!account.CanTransferFrom,
      IsBankingAccount: account.DataType === 'Bank',
      IsInvestmentAccount: account.DataType === 'Invest'
    }
  };
}

function parseNonUKAccount(account: any) {
  return {
    ...account,
    Properties: null
  };
}

// loop to parse full array of accounts
function parseAccounts(accounts: Array<any>): Array<any> {
  const temp: SelectedAccountIBCI[] = [];
  accounts.forEach((account) => {
    temp.push(parseSelectedAccount(account));
  });
  return temp;
}

function updateSelectedAccountFromAccountsList(accounts: Array<any>, selectedAccount?: SelectedAccountIBCI) {
  accounts.filter((x: SelectedAccountIBCI) => {
    if (x.AccountNumber === selectedAccount?.AccountNumber) {
      selectedAccount = x;
    }
    return null;
  });
  return selectedAccount;
}

// action
export class SetSelectedAccountIBCI {
  static readonly type = '[SelectedAccountIBCI] Set selected account';

  constructor(public payload?: SelectedAccountIBCI) {
  }
}

//action
export class IbciAccountsLoadedFromAPI {
  static readonly type = '[SelectedAccountIBCI] Accounts Loaded From API';

  constructor(public payload: Array<any>) {
  }
}

//action
export class IbciUpdateAccountLoadedFlag {
  static readonly type = '[SelectedAccountIBCI] Update Account Loaded Flag';

  constructor(public payload: boolean) {
  }
}

//action
export class IbciSetRefreshAccountsFlag {
  static readonly type = '[SelectedAccountIBCI] Set Refresh Accounts Flag';

  constructor(public payload: boolean) {
  }
}

export class IbciReloadAccountBalance {
  static readonly type = '[SelectedAccountIBCI] Reload Account Balance';

  constructor(public payload: boolean) {
  }
}

export class SelectedAccountIBCIStateModel {
  account: SelectedAccountIBCI | undefined;
  accounts: Array<SelectedAccountIBCI> | undefined;
  accountLoaded: boolean | undefined;
  refreshAccounts: boolean | undefined;
  reloadAccountBalance: boolean | undefined;
}

export function getSelectedAccountIBCIInit(): SelectedAccountIBCIStateModel {
  return {
    account: undefined,
    accounts: undefined,
    accountLoaded: false,
    refreshAccounts: false,
    reloadAccountBalance: false
  };
}

@State<SelectedAccountIBCIStateModel>({
  name: 'selectedAccountIBCI',
  defaults: getSelectedAccountIBCIInit()
})

@Injectable()
export class SelectedAccountIBCIState {

  @Selector()
  static getSelectedAccount(state: SelectedAccountIBCIStateModel) {
    return state.account;
  }

  @Action(IbciAccountsLoadedFromAPI)
  IbciAccountsLoadedFromAPI(ctx: StateContext<SelectedAccountIBCIStateModel>, {payload}: any) {
    const parsedAccounts = parseAccounts(payload);
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      accounts: parsedAccounts,
      accountLoaded: true
    });
    if (state.account !== null) {
      // get latest balance from returned account call
      // match selected accountId with Account Id from accounts call
      // fixes incorrect balance after a payment is made
      const newSelectedAccount = updateSelectedAccountFromAccountsList(parsedAccounts, state.account);
      ctx.dispatch([new SetSelectedAccountIBCI(newSelectedAccount), new IbciUpdateAccountLoadedFlag(true), new IbciReloadAccountBalance(true)]);
    }
  }

  @Action(SetSelectedAccountIBCI)
  SetAccountInStore(ctx: StateContext<SelectedAccountIBCIStateModel>, {payload}: SetSelectedAccountIBCI) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      account: payload
    });
  }

  @Action(IbciUpdateAccountLoadedFlag)
  UpdateAccountLoadedFlag(ctx: StateContext<SelectedAccountIBCIStateModel>, {payload}: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      accountLoaded: payload
    });
  }

  @Action(IbciSetRefreshAccountsFlag)
  SetRefreshAccountsFlag(ctx: StateContext<SelectedAccountIBCIStateModel>, {payload}: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      refreshAccounts: payload
    });
  }

  @Action(IbciReloadAccountBalance)
  ReloadAccountBalance(ctx: StateContext<SelectedAccountIBCIStateModel>, {payload}: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      reloadAccountBalance: payload
    });
  }
}
