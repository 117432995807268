import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { environment } from '@env-vars';

export interface SelectedAccountPBUK {
  Nickname: string;
  Iban: string;
  BicSwift: string;
  CreditInterestRate: number | null;
  DebitInterestRate: number | null;
  AccountId: string;
  AccountName: string;
  AccountType: string;
  AccountNumber: string;
  AccountNumberHashed?: string;
  AccountNumberOriginal?: string;
  AvailableBalance: number;
  AvailableImmediateAccess?: number;
  BaseBalanceAssets: number;
  BalanceAssets: number; // create new property to match require fields to display balance on account selector - set to BaseBalanceAssets value
  BalanceLiabilities: number; // create new property to match require fields to display balance on account selector - set to 0
  Currency: string;
  CurrencySymbol: string;
  IsEasyAccessAccount: boolean;
  IsVoyage: boolean;
  IsPBA: boolean;
  IsVoyageReserve: boolean;
  Region: string;
  SortCode: string;
  SortCodeFormatted?: string;
  OverdraftLimit: PBUKCurrencyValue | null | undefined;
  RemainingOverdraft: PBUKCurrencyValue | null | undefined;
  LastDataRefreshDate: string | null;
  DealDetail?: SelectedAccountPBUKDealDetail | null;
  NoticeDetail?: {
    ImmediateAccessPercentage: number;
    NoticePeriod: number;
    LastImmediateAccessPaymentDate: string;
    NextImmediateAccessPaymentDate: string;
    IsInLoyaltyRatePeriod: boolean;
  };
  Properties: SelectedAccountPBUKProperties;
}

export interface SelectedAccountPBUKDealDetail {
  AreInstructionsAllowed: boolean;
  IsMaturityEditable: boolean;
  MaturesIn: string;
}

export interface SelectedAccountPBUKProperties {
  HasStandingOrders: boolean;
  HasPaymentsFrom: boolean;
  HasGroupPaymentsFrom: boolean;
  CanTransferFrom: boolean;
  CanTransferTo: boolean;
  IsCardEnabled: boolean;
  AreDeferredDebitsEnabled: boolean;
  IsNoticeAccount: boolean;
  IsDealAccount: boolean;
  AreStatementsEnabled: boolean;
  CanPayFxFrom: boolean;
  CanPayFxTo: boolean;
  HideExternalPayaway: boolean;
  IsNoticePlus: boolean;
  IsImmediateAccessEligible: boolean;
  HasOverdraft: boolean;
  IsHighFiveAccount: boolean;
  IsPBAAccount: boolean;
  IsVoyageAccount: boolean;
  IsMortgage: boolean;
  AllowCloseAccountOnly: boolean;
}

export interface PBUKCurrencyValue {
  Value: number;
  CurrencyCode: string;
  CurrencySymbol: string;
}

// parsing API response into flat structure
function parseSelectedAccount(account: any): SelectedAccountPBUK {
  return {
    Nickname: account.Nickname,
    Iban: account.Iban,
    BicSwift: account.BicSwiftStatic,
    CreditInterestRate: account.Rate?.CreditInterest?.Value,
    DebitInterestRate: account.Rate?.DebitInterest?.Value,
    AccountId: account.AccountId,
    AccountName: account.Name,
    AccountType: account.Product.Description,
    AccountNumber: account.Product?.Properties?.IsDealAccount ? account.AccountNumber : account.AccountId,
    AccountNumberHashed: account.AccountNumberHashed,
    AccountNumberOriginal: account.AccountNumber,
    AvailableBalance: account.Balance.Available.Amount,
    AvailableImmediateAccess: account.Balance.AvailableForImmediateAccess.Amount,
    BaseBalanceAssets: account.Balance.Current.Amount,
    BalanceAssets: account.Balance.Current.Amount,
    BalanceLiabilities: 0,
    Currency: account.Currency.Code,
    CurrencySymbol: account.Currency.Symbol,
    IsEasyAccessAccount: account.Product.Id === 149,
    IsVoyage: account.Product.Id === 160,
    IsPBA: account.Product.Id === 164,
    IsVoyageReserve: account.Product.Id === 194,
    Region: 'UK',
    SortCode: account.SortCodeStatic,
    OverdraftLimit: {
      Value: account.Overdraft?.Limit?.Amount,
      CurrencyCode: account.Overdraft?.Limit?.Currency?.Code,
      CurrencySymbol: account.Overdraft?.Limit?.Currency?.Symbol
    },
    RemainingOverdraft: {
      Value: account.Balance?.RemainingOverdraft?.Amount,
      CurrencyCode: account.Balance?.RemainingOverdraft?.Currency?.Code,
      CurrencySymbol: account.Balance?.RemainingOverdraft?.Currency?.Symbol
    },
    LastDataRefreshDate: null,
    DealDetail: account.DealDetail,
    NoticeDetail: account.NoticeDetail,
    Properties: {
      HasStandingOrders: account.Product.Properties.HasStandingOrders,
      HasPaymentsFrom: account.Product.Properties.HasPaymentsFrom,
      HasGroupPaymentsFrom: account.Product.Properties.HasGroupPaymentsFrom,
      CanTransferFrom: account.Product.Properties.CanTransferFrom,
      CanTransferTo: account.Product.Properties.CanTransferTo,
      IsCardEnabled: account.Product.Properties.IsCardEnabled,
      AreDeferredDebitsEnabled: account.Product.Properties.AreDeferredDebitsEnabled,
      IsNoticeAccount: account.Product.Properties.IsNoticeAccount,
      IsDealAccount: account.Product.Properties.IsDealAccount,
      AreStatementsEnabled: account.Product.Properties.AreStatementsEnabled,
      CanPayFxFrom: account.Product.Properties.CanPayFxFrom,
      CanPayFxTo: account.Product.Properties.CanPayFxTo,
      HideExternalPayaway: account.Product.Properties.HideExternalPayaway,
      IsNoticePlus: account.Product.Properties.IsNoticePlus,
      IsImmediateAccessEligible: account.Product.Properties.IsImmediateAccessEligible,
      HasOverdraft: account.Product.Properties.HasOverdraft,
      IsHighFiveAccount: account.Product.Properties.IsHighFiveAccount,
      IsPBAAccount: account.Product.Id === 164,
      IsVoyageAccount: account.Product.Id === 160,
      IsMortgage: false,
      AllowCloseAccountOnly: account.Product.Properties.IsNoticePlus && environment.pbuk.noticePlus.AllowCloseAccountOnly
    }
  };
}

function parseMortgageAccount(account: SelectedAccountPBUK) {
  return {
    AccountId: account.AccountId,
    AccountName: account.AccountName,
    AccountType: account.AccountType,
    AccountNumber: account.AccountNumber,
    AccountNumberHashed: account.AccountNumberHashed,
    AccountNumberOriginal: account.AccountNumber,
    AvailableBalance: account.AvailableBalance,
    BalanceLiabilities: account.BalanceLiabilities,
    Currency: account.Currency,
    IsEasyAccessAccount: false,
    IsVoyage: false,
    IsPBA: false,
    IsVoyageReserve: false,
    Region: 'UK',
    Properties: {
      HasStandingOrders: false,
      HasPaymentsFrom: false,
      HasGroupPaymentsFrom: false,
      CanTransferFrom: false,
      CanTransferTo: false,
      IsCardEnabled: false,
      AreDeferredDebitsEnabled: false,
      IsNoticeAccount: false,
      IsDealAccount: false,
      AreStatementsEnabled: false,
      CanPayFxFrom: false,
      CanPayFxTo: false,
      HideExternalPayaway: false,
      IsNoticePlus: false,
      IsImmediateAccessEligible: false,
      HasOverdraft: false,
      IsHighFiveAccount: false,
      IsPBAAccount: false,
      IsVoyageAccount: false,
      IsMortgage: true,
      AllowCloseAccountOnly: false
    }
  };
}

function parseNonUKAccount(account: any) {
  return {
    ...account,
    AccountId: '',
    IsEasyAccessAccount: false,
    Properties: null
  };
}

// loop to parse full array of accounts
function parseAccounts(accounts: Array<any>): Array<any> {
  const temp: Array<any> = [];
  accounts.forEach((account) => {
    if (account.Product instanceof Object) {
      // making an assumption that if Product is an Object, it's a UK/CI account
      temp.push(parseSelectedAccount(account));
    } else if (account.ProductSegment === 'Borrow') {
      // making an assumption that if ProductSegment is Borrow, it's a UK mortgage account
      temp.push(parseMortgageAccount(account));
    } else {
      // otherwise it's a non-UK account
      temp.push(parseNonUKAccount(account));
    }
  });
  return temp;
}

function updateSelectedAccountFromAccountsList(accounts: Array<any>, selectedAccount: SelectedAccountPBUK | undefined) {
  accounts.filter((x: SelectedAccountPBUK) => {
    if (x.AccountId === selectedAccount?.AccountId) {
      selectedAccount = x;
    }
    return null;
  });
  return selectedAccount;
}

//action
export class SetSelectedAccountPBUK {
  static readonly type = '[SelectedAccountPBUK] Set selected account';

  constructor(public payload: SelectedAccountPBUK | undefined) {
  }
}

//action
export class AccountsLoadedFromAPI {
  static readonly type = '[SelectedAccountPBUK] Accounts Loaded From API';

  constructor(public payload: Array<any>) {
  }
}

//action
export class UpdateAccountLoadedFlag {
  static readonly type = '[SelectedAccountPBUK] Update Account Loaded Flag';

  constructor(public payload: boolean) {
  }
}

//action
export class SetRefreshAccountsFlag {
  static readonly type = '[SelectedAccountPBUK] Set Refresh Accounts Flag';

  constructor(public payload: boolean) {
  }
}

//action
export class ReloadAccountBalance {
  static readonly type = '[SelectedAccountPBUK] Reload Account Balance';

  constructor(public payload: boolean) {
  }
}

export class SelectedAccountPBUKStateModel {
  account: SelectedAccountPBUK | null | undefined;
  accounts: Array<SelectedAccountPBUK> | null | undefined;
  accountLoaded: boolean | undefined;
  refreshAccounts: boolean | undefined;
  reloadAccountBalance: boolean | undefined;
}

export function getSelectedAccountPBUKInit(): SelectedAccountPBUKStateModel {
  return {
    account: null,
    accounts: null,
    accountLoaded: false,
    refreshAccounts: false,
    reloadAccountBalance: false
  };
}

@State<SelectedAccountPBUKStateModel>({
  name: 'selectedAccountPBUK',
  defaults: getSelectedAccountPBUKInit()
})

@Injectable()
export class SelectedAccountPBUKState {

  @Selector()
  static getSelectedAccount(state: SelectedAccountPBUKStateModel) {
    return state.account;
  }

  @Action(AccountsLoadedFromAPI)
  AccountsLoadedFromAPI(ctx: StateContext<SelectedAccountPBUKStateModel>, { payload }: any) {
    const parsedAccounts = parseAccounts(payload);
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      accounts: parsedAccounts,
      accountLoaded: true
    });
    if (state.account !== null) {
      // get latest balance from returned account call
      // match selected accountId with Account Id from accounts call
      // fixes incorrect balance after a payment is made
      const newSelectedAccount = updateSelectedAccountFromAccountsList(parsedAccounts, state.account);
      ctx.dispatch([new SetSelectedAccountPBUK(newSelectedAccount), new UpdateAccountLoadedFlag(true), new ReloadAccountBalance(true)]);
    }
  }

  @Action(SetSelectedAccountPBUK)
  SetAccountInStore(ctx: StateContext<SelectedAccountPBUKStateModel>, { payload }: SetSelectedAccountPBUK) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      account: payload
    });
  }

  @Action(UpdateAccountLoadedFlag)
  UpdateAccountLoadedFlag(ctx: StateContext<SelectedAccountPBUKStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      accountLoaded: payload
    });
  }

  @Action(SetRefreshAccountsFlag)
  SetRefreshAccountsFlag(ctx: StateContext<SelectedAccountPBUKStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      refreshAccounts: payload
    });
  }

  @Action(ReloadAccountBalance)
  ReloadAccountBalance(ctx: StateContext<SelectedAccountPBUKStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      reloadAccountBalance: payload
    });
  }
}
