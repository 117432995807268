import { AbstractControl } from '@angular/forms';

export function ValidateEmail(control: AbstractControl) {

  const validEmail = (/[^@]+@[^\.]+\..+/).test(control.value);

  if(validEmail){
    return null
  } else {
    return {validEmail: true}
  }
}
