import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {invsy} from "@investec/invsy-sdk";

@Injectable({
  providedIn: 'root'
})
export class PreviousUrlService {
  private history: string[] = []

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    });
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.router.navigateByUrl(this.history[this.history.length - 1]);
    } else {
      invsy.platform.determineJourneyAfterLogin();
    }
  }

  addUrl(urlToAdd: string): void {
    this.history.push(urlToAdd);
  }
}
