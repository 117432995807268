import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {RootCard} from "../../models/root-card.interface";
import {Router} from "@angular/router";
import {ProgrammableCardService} from "../../../../services/programmable-card.service";
import {HttpClient} from "@angular/common/http";
import {DataLayerService} from "@investec-online/platform-services";

@Component({
  selector: 'investec-online-prog-card-row',
  templateUrl: './root-card-row.component.html',
  styleUrls: ['./root-card-row.component.scss']
})
export class ProgCardRowComponent implements OnChanges {

  @Input() rootCard: RootCard;
  @Input() isMobile: false;
  @Output() isLoadingEmitter: EventEmitter<any> = new EventEmitter();
  @Input() selectedAccountNumber;

  cardNumberArray: Array<string> = [];

  constructor(
    private _rootCard: ProgrammableCardService,
    private router: Router,
    private _http: HttpClient,
    private _analytics: DataLayerService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rootCard) {
      if (changes.rootCard.currentValue.CardNumber) {
        this.cardNumberArray = changes.rootCard.currentValue.CardNumber.split('');
      }
    }
  }

  selectCard() {

    this._analytics.trackData(
      'navigate to IDE',
      'CTA|clicked',
      'programmable banking',
      'click',
      'loyalty',
      'flow',
      'engagement',
      'programmable banking',
      '',
      'cta'
    )

    this._rootCard.selectedRootCard = this.rootCard;
    this._rootCard.selectedAccountNumber = this.rootCard.AccountNumber;
    this._rootCard.getFunctionsCode().subscribe(codeRes => {

      if (codeRes['error']) {
        this.isLoadingEmitter.emit(true);
        this._rootCard.initializeRootCode().subscribe(initRes => {
          this.isLoadingEmitter.emit(false);
          if (initRes['error']) {
            // TODO: do something about the error
          }
        }, err => {
          console.log(err);
        });
      } else {
        this.router.navigate(['programmable-banking/ide/editor', this.rootCard.CardKeyHash])
      }
    });
  }

  toggleCardForRoot($event, card) {

    this._analytics.trackData(
      'toggle card: ' + $event,
      'CTA|clicked',
      'programmable banking',
      'click',
      'loyalty',
      'flow',
      'engagement',
      'programmable banking',
      '',
      'cta'
    )

    this.rootCard.IsProgrammable = $event; // duplicate  api response is correct
    this._rootCard.toggleCard(this.selectedAccountNumber, card.CardKeyHash).subscribe(res => {
      // this.rootCard.IsProgrammable = $event;
      if (res['error']) {
        // TODO: do something about the error
      }
    }, err => {
      // TODO: dont throw the error until api returns a decent response
      // console.log(err);
    });

  }
}
