<div [ngSwitch]="secondFactorData.Method">
  <div *ngSwitchCase="'OTP'">
    <investec-online-type-otp [secondFactorData]="secondFactorData" (stopSecondFactor)="closeModal($event)"></investec-online-type-otp>
  </div>
  <div *ngSwitchCase="'APP'">
    <investec-online-type-inapp [secondFactorData]="secondFactorData" (stopSecondFactor)="closeModal($event)"></investec-online-type-inapp>
  </div>
  <div *ngSwitchCase="'USSD'">
    <investec-online-type-ussd [secondFactorData]="secondFactorData" (stopSecondFactor)="closeModal($event)"></investec-online-type-ussd>
  </div>
</div>
