import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from "@angular/core";

export interface Features {
  alwaysTrue: boolean,
  PBSA_PROG_CARD?: boolean,
  OAUTH_EXT_CONN?: boolean
}


export class AddFeatures {
  static readonly type = '[Features] Add';

  constructor(public payload: Features) {
  }
}

export class FeaturesStateModel {
  features: Features;
}

@State<FeaturesStateModel>({
  name: 'userFeatures'
})

@Injectable()

export class FeaturesState {

  @Selector()
  static getFeatures(state: FeaturesStateModel) {
    return state.features;
  }

  @Selector()
  static getAppStoreFeatures(state: FeaturesStateModel) {
    const returnArray = [];

    Object.keys(state.features).forEach(value => {
      switch (value) {
        case 'PBSA_PROG_CARD':
          returnArray.push(value)
          break
      }
    })
    return returnArray;
  }

  @Action(AddFeatures)
  add({getState, patchState}: StateContext<FeaturesStateModel>, {payload}: AddFeatures) {
    patchState({
      features: payload
    });
  }
}
