import { State, Action, StateContext, Selector } from '@ngxs/store';
import {Injectable} from "@angular/core";
import {UserSettings} from "@investec-online/platform-state-management";

export class AddAvailableUserSettings {
  static readonly type = '[AvailableUserSettings] Add';

  constructor(public payload: UserSettings) {}
}

export class AvailableUserSettingsStateModel {
  availableSettings: UserSettings;
}

@State<AvailableUserSettingsStateModel>({
  name: 'availableUserSettings'
})

@Injectable()

export class AvailableUserSettingsState {

  @Selector()
  static getAvailableUserSettings(state: AvailableUserSettingsStateModel) {
    return state.availableSettings;
  }

  @Action(AddAvailableUserSettings)
  add({getState, patchState }: StateContext<AvailableUserSettingsStateModel>, { payload }: AddAvailableUserSettings) {
    patchState({
      availableSettings: payload
    });
  }
}
