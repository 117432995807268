<investec-online-platform-toast></investec-online-platform-toast>

<investec-online-prog-card-sidebar></investec-online-prog-card-sidebar>

<main role="main">

  <div class="editor-wrapper">

    <investec-online-prog-card-file-explorer [adjustableWidths]="adjustableWidths" [openTabFromSimulator]="passTheTab" (valueChange)='updateWidths($event)'></investec-online-prog-card-file-explorer>

    <div class="editor-simulator h-100" [style.width.%]="adjustableWidths.simulator"
         *ngIf="adjustableWidths.simulator > 0">

      <ngb-tabset [justify]="'justified'" (tabChange)="beforeChange($event)">
        <ngb-tab id="simulator">
          <ng-template ngbTabTitle>Simulate transaction</ng-template>
          <ng-template ngbTabContent>
            <investec-online-prog-card-simulator (valueChange)="passTabDataToIde($event)"></investec-online-prog-card-simulator>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="logs">
          <ng-template ngbTabTitle>Event Logs</ng-template>
          <ng-template ngbTabContent>
            <investec-online-prog-card-logs></investec-online-prog-card-logs>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>

    </div>

  </div>

</main>

