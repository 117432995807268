import { Component, OnInit } from '@angular/core';
import {GlobalErrorService} from "@investec-online/platform-services";

@Component({
  selector: 'investec-online-error-toast',
  templateUrl: './error-toast.component.html',
  styleUrls: ['./error-toast.component.scss']
})
export class ErrorToastComponent implements OnInit {

  constructor(public _glbError: GlobalErrorService) { }

  ngOnInit(): void {
  }

}
