import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SelectedAccountWIUK, WIUKSelectedAccountStateModel } from "./selected-account-wiuk";

interface GenerateOtpResponseModel {
  token: string | undefined,
  transactionToken: string | undefined,
}

interface WiukciIfaAgency {
  Code: string;
  Description: string;
  ClientCount: number;
  AccountCount: number;
  CountDescription: string | null;
  Balance: number;
  StructureType: string;
  AccountType: string | null;
  LinkedMain: string | null;
  IfaStructure: Array<WiukciIfaAgency> | null;
  ValuationCurrency?: string | null;
  ValuationValue?: number;
  PreferredCurrency?: string;
  PreferredValue?: number;
  BalanceBreakDown?: Array<IfaBalanceBreakDown> | null;
}

interface IfaBalanceBreakDown {
  Currency: string;
  TotalValue: number;
}

interface CountryDiallingCode {
  code: string | undefined,
  name: string | undefined,
  displayName: string | undefined,
  popular: boolean | undefined
}
//action
export class WICISetSelectedAccount {
  static readonly type = '[SelectedAccountWICI] Set selected account';

  constructor(public payload: SelectedAccountWIUK | undefined) {
  }
}

//action
export class WICIAccountsLoadedFromAPI {
  static readonly type = '[SelectedAccountWICI] Accounts Loaded From API';

  constructor(public payload: Array<any>) {
  }
}

//action
export class WICICountryDiallingCodesLoadedFromAPI {
  static readonly type = '[SelectedAccountWICI] Country Dialling Codes Loaded From API';

  constructor(public payload: Array<any>) {
  }
}

//action
export class WICIUpdateAccountLoadedFlag {
  static readonly type = '[SelectedAccountWICI] Update Account Loaded Flag';

  constructor(public payload: boolean) {
  }
}

//action
export class WICISetIfaAgencyInStore {
  static readonly type = '[SelectedAccountWICI] Set selected IFA Agency';

  constructor(public payload: WiukciIfaAgency | undefined) {
  }
}

//action
export class WICIIfaAgenciesLoadedFromAPI {
  static readonly type = '[SelectedAccountWICI] IFA Agencies Loaded From API';

  constructor(public payload: Array<any>) {
  }
}

//action
export class WICIUpdateIfaAgenciesLoadedFlag {
  static readonly type = '[SelectedAccountWICI] Update IFA Agency Loaded Flag';

  constructor(public payload: boolean) {
  }
}

//action
export class WICIGenerateOtpRequestedFromAPI {
  static readonly type = '[SelectedAccountWICI] Generate Otp Requested From API';

  constructor(public payload: GenerateOtpResponseModel) {
  }
}

//action
export class WICIOtpValidatedFromAPI {
  static readonly type = '[SelectedAccountWICI] Otp Validated From API ';

  constructor(public payload: boolean) {
  }
}

//action
export class WICIIsUserEnabledMfaFlagFromAPI {
  static readonly type = '[SelectedAccountWICI] WICI Is User Enabled Mfa Flag From API';

  constructor(public payload: boolean) {
  }
}

export class WICIClientConsentResponseFromApi {
  static readonly type = '[SelectedAccountWICI] WICI Client Consent Response From API';

  constructor(public payload: boolean) {
  }
}

//action
export class WICIWiukciAzureFeatureFlagsFromAPI {
  static readonly type = '[SelectedAccountWICI] WICI Wiukci Azure Feature Flags From API';

  constructor(public payload: boolean) {
  }
}

export class WICISelectedAccountStateModel {
  account: SelectedAccountWIUK | undefined;
  accounts: Array<SelectedAccountWIUK> | undefined;
  accountLoaded: boolean = false;
  countryDiallingCodes: Array<CountryDiallingCode> | undefined;
  countryDiallingCodesLoaded: boolean = false;
  otpTransactionToken: string | undefined | null;
  otpToken: string | undefined | null;
  otpValidated: boolean = false;
  otpIsLocked: boolean = false;
  otpIsInvalid: boolean = false;
  otpValidatedResponseLoadedFromApi: boolean = false;
  submitClientConsentResponse: boolean | undefined;
  showClientConsentTabApiFlag: boolean = false;
  show2faFlag: boolean = false;
  wiukciFeatureFlags: any;

  ifaAgency: WiukciIfaAgency | undefined;
  ifaAgencies: Array<WiukciIfaAgency> | undefined;
  ifaAgencyLoaded: boolean = false;
  ifaTotals: any; // todo not any
  vulnerableClient: boolean = false;
}

export function getWICISelectedAccountInit(): WICISelectedAccountStateModel {
  return {
    account: undefined,
    accounts: undefined,
    accountLoaded: false,
    ifaAgency: undefined,
    ifaAgencies: undefined,
    ifaAgencyLoaded: false,
    ifaTotals: null,
    vulnerableClient: false,
    countryDiallingCodes: undefined,
    countryDiallingCodesLoaded: false,
    otpToken: undefined,
    otpTransactionToken: undefined,
    otpValidated: false,
    show2faFlag: false,
    wiukciFeatureFlags: undefined,
    otpValidatedResponseLoadedFromApi: false,
    otpIsInvalid: false,
    otpIsLocked: false,
    submitClientConsentResponse: undefined,
    showClientConsentTabApiFlag: false
  }
}

@State<WICISelectedAccountStateModel>({
  name: 'selectedAccountWICI',
  defaults: getWICISelectedAccountInit()
})

@Injectable()
export class WICISelectedAccountState {

  @Selector()
  static getSelectedAccount(state: WICISelectedAccountStateModel) {
    return state.account;
  }

  @Action(WICIAccountsLoadedFromAPI)
  AccountsLoadedFromAPI(ctx: StateContext<WICISelectedAccountStateModel>, {payload}: any) {
    // using the static method in UK model which returns the same structure
    const parsedAccounts: Array<any> | undefined = WIUKSelectedAccountStateModel.wiukciParseAccounts(payload);
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      accounts: parsedAccounts,
      vulnerableClient: payload?.Vulnerable,
      accountLoaded: true
    });
    if (state.account !== undefined) {
      // get latest balance from returned account call
      // match selected accountId with Account Id from accounts call
      // fixes incorrect balance after a payment is made
      // using the static method in UK model which returns the same structure
      const newSelectedAccount = WIUKSelectedAccountStateModel.wiukciUpdateSelectedAccountFromAccountsList(parsedAccounts, state.account);
      ctx.dispatch([new WICISetSelectedAccount(newSelectedAccount), new WICIUpdateAccountLoadedFlag(true)]);
    }
  }

  @Action(WICICountryDiallingCodesLoadedFromAPI)
  CountryDiallingCodesLoadedFromAPI(ctx: StateContext<WICISelectedAccountStateModel>, {payload}: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      countryDiallingCodes: payload,
      countryDiallingCodesLoaded: true
    })
  }

  @Action(WICISetSelectedAccount)
  SetAccountInStore(ctx: StateContext<WICISelectedAccountStateModel>, {payload}: WICISetSelectedAccount) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      account: payload
    })
  }

  @Action(WICIUpdateAccountLoadedFlag)
  UpdateAccountLoadedFlag(ctx: StateContext<WICISelectedAccountStateModel>, {payload}: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      accountLoaded: payload
    })
  }

  @Action(WICIIfaAgenciesLoadedFromAPI)
  IfaAgenciesLoadedFromAPI(ctx: StateContext<WICISelectedAccountStateModel>, {payload}: any) {
    // using the static method in UK model which returns the same structure
    const parsedAgencies = WIUKSelectedAccountStateModel.getFilteredAgencies(payload.Data);
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      ifaAgencies: parsedAgencies,
      ifaTotals: payload.Totals,
      ifaAgencyLoaded: true
    });
    if (state.ifaAgency !== null) {
      const newSelectedAgency = WIUKSelectedAccountStateModel.wiukciUpdateSelectedAgencyFromAgenciesList(parsedAgencies, state.ifaAgency);
      ctx.dispatch([new WICISetIfaAgencyInStore(newSelectedAgency), new WICIUpdateIfaAgenciesLoadedFlag(true)]);
    }
  }

  @Action(WICISetIfaAgencyInStore)
  SetIfaAgencyInStore(ctx: StateContext<WICISelectedAccountStateModel>, {payload}: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      ifaAgency: payload
    })
  }

  @Action(WICIUpdateIfaAgenciesLoadedFlag)
  UpdateAIfaAgenciesLoadedFlag(ctx: StateContext<WICISelectedAccountStateModel>, {payload}: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      ifaAgencyLoaded: payload
    })
  }

  @Action(WICIGenerateOtpRequestedFromAPI)
  GenerateOtpRequestedFromAPI(ctx: StateContext<WICISelectedAccountStateModel>, {payload}: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      otpTransactionToken: payload.transactionToken,
      otpToken: payload.token
    })
  }

  @Action(WICIOtpValidatedFromAPI)
  OtpValidatedFromAPI(ctx: StateContext<WICISelectedAccountStateModel>, {payload}: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      otpValidated: payload.success,
      otpIsInvalid: payload.Data?.isInvalid,
      otpIsLocked: payload.Data?.isLocked,
      otpValidatedResponseLoadedFromApi: true
    })
  }

  @Action(WICIIsUserEnabledMfaFlagFromAPI)
  IsUserEnabledMfaFlagFromAPI(ctx: StateContext<WICISelectedAccountStateModel>, {payload}: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      show2faFlag : payload
    })
  }

  @Action(WICIClientConsentResponseFromApi)
  ClientConsentResponseFromApi(ctx: StateContext<WICISelectedAccountStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      submitClientConsentResponse: payload,
    });
  }

  @Action(WICIWiukciAzureFeatureFlagsFromAPI)
  WiukciAzureFeatureFlagsFromAPI(ctx: StateContext<WICISelectedAccountStateModel>, {payload}: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      wiukciFeatureFlags : payload
    })
  }
}
