import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Store } from '@ngxs/store';
import {
  AddAvailableUserSettings,
  AddBusinessBankingProfiles, AddCurrencies,
  AddFeatures,
  AddInvestecServices,
  AddPcAvailableAccounts,
  AddUserSettings,
  AuthResponse,
  AuthResponseState,
  AvailableUserSettingsState,
  BankingAvailableAccount,
  BankingAvailableAccounts,
  BusinessBankingProfilesState, Currencies,
  Features,
  FeaturesState,
  InvestecServices,
  InvestecServicesState,
  MortgageAvailableAccounts,
  PcAvailableAccountsState,
  PlatformInteractionState,
  SelectedAccount,
  SelectedAccountPBUK,
  SelectedAccountPBUKState,
  SelectedAccountState,
  SelectedProfileState,
  SetAuthResponse,
  SetSelectedAccount,
  SetSelectedAccountPBUK,
  SetUserType,
  UpdateMenuRegion,
  UpdateUserSetting,
  UserSetting,
  UserSettings,
  UserSettingsState,
  UserTypeState,
  WealthAvailableAccounts
} from '@investec-online/platform-state-management';
import { CookieService } from 'ngx-cookie-service';

import { SharedHttpService } from './shared-http.service';
import { ActivatedRoute, NavigationEnd, RouteConfigLoadEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LogoutService } from './logout.service';
import { environment } from 'environments/environment';
import { first, takeWhile } from 'rxjs/operators';
import { StateClear } from "ngxs-reset-plugin";
import { HttpClient } from '@angular/common/http';
import { invsy } from "@investec/invsy-sdk";

export class DecisionFlags {
  constructor(
    public PersonalBanking = '/io-wpaas/accounts-overview',
    public WealthManagement = '/investmentmanagement/home',
    public BusinessBankingZa = 'types',
    public IntermediaryBanking = '/ifaroot-wpaas'
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserContextService {

  windowServiceHref: string;
  isTest = false;
  authentication = environment.platform.authentication;
  userApiFlag = environment.platform.userApi;
  userFeatures = environment.platform.userFeatures;
  destinationURLS = environment.platform.urls;
  FBCC_DayOld_Support_Enabled = environment.bb.FBCC_DayOld_Support_Enabled

  userApi = false;
  isSimulator = new BehaviorSubject<boolean>(false);
  decisionFlagsDestinations = new DecisionFlags;
  loginJourneyPath = new ReplaySubject<string>(1);
  currentMenuPreference = new ReplaySubject<string>(1);
  passwordHasBeenReset = new ReplaySubject<boolean>(1);
  userTypeFlags = new BehaviorSubject<any>(false);
  hasForgottenPassword = new BehaviorSubject<boolean>(false);
  isPremiumCustomerZA = false;
  isPrivateBankZA = false;
  isPrivateBankZaTargetMarket = false;
  isStaffClient = false;
  hasRewards = false;

  hasLiveParam = false;
  hasNoticePlusParam = false;
  hasYouthApplicationParam = false;
  wealthUkCiVulnerableClient = false;

  //local variables
  onboarding = { isNewUser: false };
  userContextFetched = false;

  constructor(private _cookie: CookieService,
    private _store: Store,
    private _logout: LogoutService,
    private _sharedHttp: SharedHttpService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _titleService: Title,
    private _http: HttpClient) {
    if (this.userApiFlag) {
      this.isSimulator.subscribe(res => {
        this.userApi = res;
      });
    }
  }

  //Global user context

  windowHref(url: string) {
    url = environment.development ? '#/' + url : url;
    !this.isTest ? invsy.platform.navigateUrl(url) : this.windowServiceHref = url;
  }

  setMenuPreference(menuPreference: string) {
    this.currentMenuPreference.next(menuPreference);
  }

  setDynatraceUserId() {
    let activeSubscription = true
    this._store.select(AuthResponseState.getAuthResponse).pipe(takeWhile(() => activeSubscription)).subscribe(res => {

      if (res !== undefined) {
        activeSubscription = false;
        if (typeof window['dtrum'] !== "undefined") {
          window['dtrum'].identifyUser(res.Misc.Zeta);
        }
      }
    });
  }

  checkCurrentAuthenticationLevel(userPlatform?: boolean) {
    let checkSession = true
    this._store.select(AuthResponseState.getAuthResponse).pipe(takeWhile(() => checkSession)).subscribe(res => {
      if (res !== undefined) {
        if ((res.MaxSession === res.ProfileMaxSession) && (res.ProfileMaxSession === 'OTP')) {
          this._sharedHttp.getCurrentAuthenticationLevel().subscribe(allowAccess => {
            if (allowAccess === 'PIN') {
              if (this.authentication) {
                userPlatform ? this._router.navigate(['/authentication']) : this.windowHref('/usrroot/authentication');
              } else {
                this.windowHref('/sso/authentication.html');
              }
            }
          });
        } else {
          checkSession = false;
        }
      }
    });
  }

  setUserCapabilities(authRes) {
    return new Promise<AuthResponse>(resolve => {
      if (this.userApi) {
        if (authRes.HasCapabilities === undefined) {
          if (!authRes.HasCapabilities) {
            this._sharedHttp.getUserCapabilities().subscribe(res => {
              authRes.RedirectExternalUrl = res.RedirectExternalUrl;
              authRes.RedirectInternalUrl = res.RedirectInternalUrl;
              authRes.SecurityDetailsRequired = res.SecurityDetailsRequired;
              res.Applications.forEach(value => {
                authRes[value] = true;
              });
              res.Capabilities.forEach(value => {
                authRes[value] = true;
              });
              //ToDo: should come from API
              authRes.Client = {
                'Title': 'Mr',
                'FirstName': 'EfhuxhuRu',
                'Surname': 'AtelxelWl',
                'IdNumber': '8204135150084',
                'CkNumber': null,
                'Types': {
                  'PrivateClient': true,
                  'Intermediary': false,
                  'Mua': true,
                  'Corporate': false,
                  'DefaultType': 'PrivateClient',
                  'Youth': false
                },
                'EmailAddress': 'TGQAH@someweb.co.za',
                'MobileNumber': '0111234212'
              };
              authRes.HasCapabilities = true;
              this.setCompleteAuthResponse(authRes);
              // this.setAuthResInCookie(authRes);
              resolve(authRes);
            });
          } else {
            resolve(authRes);
          }
        } else {
          resolve(authRes);
        }
      } else {
        resolve(authRes);
      }
    });
  }

  getLoginJourneyPath(authRes: AuthResponse) {
    let path = '';
    if (authRes.ShowWelcomeScreen) {
      path = 'welcome';
    } else if (environment.onboardingReskin.showOnboardingReskin && authRes.displayChecklist) {
      path = 'checklist';
    } else if (environment.onboardingReskin.showOnboardingReskin && !authRes.AcceptedWebTCs) {
      path = 'terms-and-conditions';
    } else if (authRes.ResetPinRequired) {
      path = 'change-password';
    } else if (authRes.RedirectInternalUrl !== '') {
      this.windowHref(authRes.RedirectInternalUrl);
    } else if (authRes.RedirectExternalUrl !== '') {
      this.windowHref(authRes.RedirectExternalUrl);
    } else if (authRes.SecurityDetailsRequired) {
      path = 'contact-details';
    } else if (!authRes.HasQa) {
      path = 'security-questions';
    } else if (environment.onboardingReskin.showOnboardingReskin && !authRes.DownloadedApp) {
      path = 'download-app';
    } else if (!environment.onboardingReskin.showOnboardingReskin && authRes.IsNewUser) {
      path = 'download-app';
    } else if (authRes.IsNewUser) {
      path = 'thank-you';
    }

    return path;
  }

  getLoginStateAndUpdateAuthContext() {
    return new Promise(resolve => {
      this._sharedHttp.getFullLoginContext().subscribe(res => {
        this.setCompleteAuthResponse(res);
        // this.setAuthResInCookie(res);
        this.setMenuPreference(this.mapClientDefaultTypeFlagToUserType(res.Client.Types.DefaultType));
        resolve(res);
      })
    })
  }

  getDestinationBasedOnCurrentContext(context: AuthResponse) {
    return new Promise<string>(resolve => {
      let flags = this.setDecisionFlags(context);
      const vm = this;

      function setFlagsAndResolve() {
        vm.userTypeFlags.next([...flags]);
        flags.length <= 1 ? resolve(vm.decisionFlagsDestinations[flags[0].key]) : resolve('types');
      }

      if (flags.length === 0) {
        this.getLoginStateAndUpdateAuthContext().then(res => {
          flags = this.setDecisionFlags(res);
          setFlagsAndResolve();
        })
      } else {
        setFlagsAndResolve();
      }
    });
  }

  filterSecondaryDestinations(context: AuthResponse, currentDestination) {
    if (context.IsMasqueradedAsClient && context.CanMasquerade) {
      return this.decisionFlagsDestinations.PersonalBanking;
    } else if (context.CorporateAndInvestmentBankingUK) {
      return this.destinationURLS.ixDestination;
    } else if (this.hasYouthApplicationParam) {
      return '/sso/products/apply/youth-account.html';
    } else if (context.Client.Types.DefaultType === 'PrivateClient' && context.PrivateBankZA && this.hasLiveParam) {
      return '/life/life.html#/gettingStarted';
    } else if (context.Client.Types.DefaultType === 'PrivateClient' && this.hasNoticePlusParam) {
      return '/sso/products/apply/notice-plus.html';
    } else if (context.Client.Types.DefaultType === 'PrivateClient' && context.CibUkIntermediaryBanking) {
      return this.destinationURLS.sociusDestination;
    } else if (context.Client.Types.DefaultType === 'Intermediary' && context.WealthAndInvestmentUK) {
      return this.destinationURLS.ifaUkDestination;
    } else if (context.Client.Types.DefaultType === 'Intermediary' && context.WealthAndInvestmentGU) {
      return '/wealthci/ifa';
    } else {
      return currentDestination;
    }
  }

  determineDestinationAfterLogin(authRes: AuthResponse) {
    return new Promise(resolve => {
      this.getDestinationBasedOnCurrentContext(authRes).then(destination => {
        this.setUserCapabilities(authRes).then((newAuthRes) => {
          const userTypeObj = { currentUserType: '', defaultUserType: '' };

          switch (newAuthRes.Client.Types.DefaultType) {
            case 'PrivateClient':
              userTypeObj.defaultUserType = 'personalBanking';
              break;
            case 'Intermediary':
              userTypeObj.defaultUserType = 'IntermediaryBanking';
              break;
            case 'Mua':
              userTypeObj.defaultUserType = 'personalBanking';
              break;
            case 'Corporate':
              userTypeObj.defaultUserType = 'businessBanking';
              break;
            case 'Youth':
              userTypeObj.defaultUserType = 'personalBanking';
              break;
          }

          if (!newAuthRes.IsMasqueradedAsClient && newAuthRes.CanMasquerade) {
            destination = 'types'
          }

          if (newAuthRes.PrivateBankMU) {
            for (let i = 0; i < newAuthRes.ProfileList.length; i++) {
              if (newAuthRes.ProfileList[i].KeyName.includes('TMOB') && newAuthRes.ProfileList[i].KeyName.includes('ADMIN')) {
                destination = 'types'
                break
              }
            }
          }

          this._route.queryParams.pipe(first()).subscribe(params => {
            this.hasLiveParam = params['64j3iynb886590jb895h7895gre9g04'] === '0004214329585438578594038854309';
            this.hasNoticePlusParam = params['BkWgoKV6er4qtetMbZjzBN1bvNImUt'] === 'aulz1oRxvJ7G3HOzqWGsJrZ1JiMrgL';
            this.hasYouthApplicationParam = params['WW91dGhBY2NvdW50QXBwbGljYXRpb24'] === '15347129967cec34801b3e7081069dc4';
            destination = (this.hasLiveParam || this.hasNoticePlusParam || this.hasYouthApplicationParam) ? '/io/accounts-overview' : destination;

            if (params['ZGVzdGluYXRpb24']) {
              destination = atob(params['ZGVzdGluYXRpb24']);
            }
          });

          if (destination !== 'types') {
            const userType = this.mapDestinationFlagToUserType(destination);
            userTypeObj.currentUserType = userType;
            if (userTypeObj.defaultUserType === '') {
              userTypeObj.defaultUserType = userType;
            }
            this._store.dispatch(new SetUserType(userTypeObj));
            this.windowHref(this.filterSecondaryDestinations(newAuthRes, destination));
          } else {
            if (userTypeObj.defaultUserType) {
              this._store.dispatch(new SetUserType({
                currentUserType: userTypeObj.defaultUserType,
                defaultUserType: userTypeObj.defaultUserType
              }));
              const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
              this.windowHref(isIE && window.location.origin !== 'https://ng.secure.investec.com:8080' ? '/usrroot/' + destination : destination);
            } else {
              resolve('/types');
            }
          }
        });
      });
    });
  }

  navigateToDefaultHome() {
    const userType = this._store.selectSnapshot(UserTypeState.getUserType);
    const authRes = this._store.selectSnapshot(AuthResponseState.getAuthResponse);

    if (userType.currentUserType === undefined) {
      if (userType.defaultUserType === undefined) {
        this.determineDestinationAfterLogin(authRes)
      } else {
        this.windowHref(this.filterSecondaryDestinations(authRes, this.mapUserTypeToDestinationFlag(userType.defaultUserType)))
      }
    } else {
      this.windowHref(this.filterSecondaryDestinations(authRes, this.mapUserTypeToDestinationFlag(userType.currentUserType)))
    }
  }

  setDecisionFlags(context) {
    const returnArray = [];

    Object.keys(this.decisionFlagsDestinations).forEach((value) => {
      if (context[value]) {
        returnArray.push({ key: value, value: this.decisionFlagsDestinations[value] });
      }
    });

    return returnArray;
  }

  setUserFeatures() {
    this._store.selectOnce(FeaturesState.getFeatures).subscribe(features => {
      if (features === undefined) {
        if (this.userFeatures) {
          let waitForAuthRes = true;

          this._store.select(AuthResponseState.getAuthResponse).pipe(takeWhile(() => waitForAuthRes)).subscribe(authRes => {
            if (authRes !== undefined) {
              const featureObj: Features = { alwaysTrue: true }
              waitForAuthRes = false;

              const digitalFeatures = new Promise<Array<{ Code, ExclusiveHide, Id }>>(resolve => {
                this._sharedHttp.getDigitalFeatures().subscribe(res => {
                  resolve(res);
                }, error => {
                  resolve([])
                });
              })

              const PrivateBankZaFeatures = new Promise<Array<{ Code, ExclusiveHide, Id }>>(resolve => {
                if (authRes.PrivateBankZA) {
                  this._sharedHttp.getPrivateBankZAFeatures().subscribe(res => {
                    resolve(res);
                  }, error => {
                    resolve([])
                  });
                } else {
                  resolve([])
                }
              })

              const PrivateBankUKFeatures = new Promise<Array<{ Code, ExclusiveHide, Id }>>(resolve => {
                if (authRes.PrivateBankUK) {
                  this._sharedHttp.getPrivateBankUKFeatures().subscribe(res => {
                    resolve(res);
                  }, error => {
                    resolve([])
                  });
                } else {
                  resolve([])
                }
              })

              const PrivateBankUkDynamicFeatures = new Promise<Array<{ Code, ExclusiveHide, Id }>>(resolve => {
                if (authRes.PrivateBankUK) {
                  this._sharedHttp.getPrivateBankUkDynamicFeatures().subscribe(res => {
                    resolve(res);
                  }, error => {
                    resolve([])
                  });
                } else {
                  resolve([])
                }
              })

              const WealthZAFeatures = new Promise<Array<{ Code, ExclusiveHide, Id }>>(resolve => {
                if (authRes.PrivateBankZA) {
                  this._sharedHttp.getWealthZAFeatures().subscribe(res => {
                    resolve(res);
                  }, error => {
                    resolve([])
                  });
                } else {
                  resolve([])
                }
              })

              let exclusiveHideArray: Array<{ Code, ExclusiveHide, Id }> = [];
              Promise.all([digitalFeatures, PrivateBankZaFeatures, PrivateBankUKFeatures, PrivateBankUkDynamicFeatures, WealthZAFeatures]).then(values => {
                values.forEach(value => {
                  exclusiveHideArray = [...exclusiveHideArray, ...value.filter(x => x.ExclusiveHide)]
                  value.forEach(key => {
                    if (featureObj[key.Code] === undefined) {
                      featureObj[key.Code] = true
                    }
                  })
                })

                exclusiveHideArray.forEach(value => {
                  if (featureObj[value.Code] !== undefined) {
                    delete featureObj[value.Code]
                  }
                })

                this._store.dispatch(new AddFeatures(featureObj));
              })
            }
          });
        } else {
          if (this._store.selectOnce(AuthResponseState.getAuthResponse) !== undefined) {
            this._sharedHttp.getUserFeatures().then(res => {
              this._store.dispatch(new AddFeatures(res));
            });
          }
        }

      }
    });
  }

  setCurrentUserTypeContext(CurrentUserType) {
    this._store.selectOnce(UserTypeState.getUserType).subscribe(res => {
      const Payload = {
        currentUserType: CurrentUserType,
        defaultUserType: res !== undefined ? (res.defaultUserType === undefined ? CurrentUserType : res.defaultUserType) : CurrentUserType
      };
      this._store.dispatch(new SetUserType(Payload));
    });
  }

  getAuthResState() {
    this._sharedHttp.getFullLoginContext().subscribe(res => {
      this._store.dispatch(new SetAuthResponse(res));
    }, error => {
      this._logout.mustLogOut.next(true);
    })
  }

  // setAuthResInCookie(authRes: AuthResponse) {
  //   const safeAuthRes = new SafeAuthResponse(authRes);
  //   const cookieObj = {};
  //
  //   Object.keys(safeAuthRes).forEach(prop => {
  //     if (typeof (safeAuthRes[prop]) === 'boolean') {
  //       if (safeAuthRes[prop]) {
  //         cookieObj[prop] = safeAuthRes[prop];
  //       }
  //     } else {
  //       cookieObj[prop] = safeAuthRes[prop];
  //     }
  //   });
  //
  //   this._cookie.set('authResponse', JSON.stringify(cookieObj), Date.now(), '/', '');
  // }

  setAuthResponseContext() {
    //Load AuthRes in the store
    this._store.selectOnce(state => state.AuthResponse.authRes).subscribe(value => {
      if (value === undefined) {
        this.getAuthResState();
      }
    });
  }

  setCompleteAuthResponse(authResponse) {
    this._store.dispatch(new SetAuthResponse(authResponse));
  }

  setUserSettingsContext() {
    return new Promise<UserSettings>((resolve, reject) => {
      this._store.select(state => state.userSettings.settings).subscribe(value => {
        if (value !== undefined) {
          const cookieRefCur = this._cookie.get('RefCur');

          if (cookieRefCur === this._store.selectSnapshot(UserSettingsState.getDefaultCurrency) || cookieRefCur === '') {
            resolve(value);
          } else {
            let activeSubscription = true;
            let tempUserSetting: UserSetting;
            this._store.select(AvailableUserSettingsState.getAvailableUserSettings).pipe(takeWhile(() => activeSubscription)).subscribe(res => {
              if (res !== undefined) {
                activeSubscription = false;
                tempUserSetting = res.filter(x => x.KeyName === 'Currency' && x.Value === cookieRefCur)[0];
                this._cookie.set('RefCur', cookieRefCur, Date.now(), '/', '', true);
                this._store.dispatch(new UpdateUserSetting(tempUserSetting));
              }
            });
          }
        } else {
          this._sharedHttp.getUserSettings().subscribe(res => {
            this._cookie.set('RefCur', res.filter(x => x.KeyName === 'Currency')[0].Value, Date.now(), '/', '', true);
            this._store.dispatch(new AddUserSettings(res));
            resolve(res);
          });
        }
      });
    });
  }

  setAvailableUserSettingsContext() {
    this._store.selectOnce(AvailableUserSettingsState.getAvailableUserSettings).subscribe(value => {
      if (value === undefined) {
        this._sharedHttp.getAvailableUserSettings().subscribe(res => {
          this._store.dispatch(new AddAvailableUserSettings(res));
        });
      }
    });
  }

  updateUserSettings(payload) {
    return new Promise((resolve, reject) => {
      this._sharedHttp.addUserSettings(payload).subscribe(res => {
        if (payload.KeyName === 'Currency') {
          this._cookie.set('RefCur', payload.Value, Date.now(), '/', '', true);
        }

        this._store.dispatch(new UpdateUserSetting(payload));
        resolve([]);
      }, err => {
        reject();
      });
    });

  }

  setUserProfilesAndReturnIt() {
    return new Promise<InvestecServices>(resolve => {
      const authRes = this._store.selectSnapshot(AuthResponseState.getAuthResponse);

      if (authRes?.ProfileList?.length > 0) {
        this._store.dispatch(new AddInvestecServices(authRes.ProfileList));
        resolve(authRes.ProfileList);
      } else {
        this._sharedHttp.getInvestecServices().subscribe(res => {
          this._store.dispatch(new AddInvestecServices(res));
          resolve(res);
        })
      }

    })
  }

  setWealthUkCiVulnerableFlag(vulnerableFlag: boolean) {
    this.wealthUkCiVulnerableClient = vulnerableFlag;
  }

  getUserProfiles() {
    return new Promise<InvestecServices>(resolve => {
      this._store.selectOnce(InvestecServicesState.getInvestecServices).subscribe(investecServices => {
        if (investecServices === undefined) {
          this.setUserProfilesAndReturnIt().then(res => {
            resolve(res);
          })
        } else {
          resolve(investecServices);
        }
      });
    })
  }

  getInvestecServicesAndReturnIt() {
    return new Promise((resolve, reject) => {
      const authRes = this._store.selectSnapshot(AuthResponseState.getAuthResponse);
      const vm = this;

      function splitOutInvestecServices(res: InvestecServices) {
        const activeProfiles = [];
        res.forEach(value => {
          if (value.ProfileName === 'Asset Management' ||
            value.KeyName === 'UK Investment Banking Portal' ||
            value.KeyName === 'AFG' ||
            value.KeyName === 'IXCORP' ||
            value.KeyName.includes('ICM_WALLSTREET') ||
            (value.KeyName.includes('TMOB') && value.KeyName.includes('ADMIN')) ||
            value.KeyName.includes('Reichmans') ||
            value.KeyName.includes('ICIB_TESTInvestecBusinessSA') ||
            value.KeyName.includes('Borrowing Base Facility Calculation') ||
            (value.ProfileName === 'Dayold FBCC Support' && vm.FBCC_DayOld_Support_Enabled) ||
            value.ProfileName === 'FlexTrade for business banking' ||
            value.ProfileName === 'Investec Forex Online' ||
            value.ProfileName === 'e-dealing' ||
            value.ProfileName === 'Investec Business' ||
            value.ProfileName === 'Investec Business Online' ||
            value.ProfileName === 'W&I Online SA (Securities)' ||
            value.ProfileName === 'Import Solutions' ||
            value.KeyName.includes('corp_') ||
            value.KeyName.includes('CorpHub')) {
            activeProfiles.push(value);
          }

          if (!authRes.IsMasqueradedAsClient && authRes.CanMasquerade) {
            if (value.ProfileName === 'Online Banking' || value.ProfileName === 'OnlineBankning ZD1') {
              activeProfiles.push(value)
            }
          }
        });

        const returnArray = [];

        for (let i = 0; i < activeProfiles.length; i++) {
          let valueExists = false;
          returnArray.forEach(value => {
            if (activeProfiles[i].ProfileName === value.ProfileName) {
              valueExists = true;
            }
          });

          if (valueExists === false) {
            returnArray.push(activeProfiles[i]);
          }
        }

        return returnArray
      }

      this.getUserProfiles().then(investecServices => {
        resolve(splitOutInvestecServices(investecServices));
      })
    });
  }

  mapDestinationFlagToUserType(path: string) {
    switch (path) {
      case '/io/accounts-overview':
      case '/investmentmanagement/home':
        return 'personalBanking';
      case '/sso/dashboard/business-dashboard.html':
        return 'businessBanking';
      case '/intermediary/intermediary.html':
        return 'intermediaryBanking';
    }
  }

  mapClientDefaultTypeFlagToUserType(defaultType: string) {
    switch (defaultType) {
      case 'PrivateClient':
      case 'Youth':
        return 'personalBanking';
      case 'Corporate':
        return 'businessBanking';
      case 'Intermediary':
        return 'intermediaryBanking';
      default:
        return 'personalBanking';
    }
  }

  mapUserTypeToDestinationFlag(type: string) {
    switch (type.toLowerCase()) {
      case 'personalbanking':
        return '/io/accounts-overview';
      case 'businessbanking':
        return '/sso/dashboard/business-dashboard.html';
      case 'intermediarybanking':
        return '/intermediary/intermediary.html';
    }
  }

  setTitleToCurrentPageContext() {
    return new Promise<string>((resolve, reject) => {
      this._router.events.subscribe(res => {
        if (res instanceof RouteConfigLoadEnd) {
          this._titleService.setTitle(res.route.path);
          resolve(res.route.path);
        }
      });
    });
  }

  setUrlOnRouteChange() {
    this._router.events.subscribe(res => {
      if (res instanceof NavigationEnd) {
        const baseHref = document.querySelector('base').attributes.item(0).value;
        baseHref === '/' ? invsy.platform.updateUrl(res.url) : invsy.platform.updateUrl('/' + baseHref.replace(/\//gm, '') + res.url);
      }
    });
  }

  refreshPlatform() {
    invsy.platform.reset()
    this._store.dispatch(new StateClear(PlatformInteractionState))
    this.getLoginStateAndUpdateAuthContext().then(() => {
      this.setRequiredContext().then(() => {
        setTimeout(() => {
          invsy.platform.determineJourneyAfterLogin();
        }, 1500)
      });
    })
  }

  setCustomPageTitle(title) {
    this._titleService.setTitle(title);
  }

  //User App context

  setRequiredContext() {
    return new Promise(resolve => {
      this.setAuthResponseContext();
      this.setUserSettingsContext().then(() => {
        resolve([]);
      });
      this.setMenuPreference(this.mapClientDefaultTypeFlagToUserType(this._store.selectSnapshot(AuthResponseState.getAuthResponse).Client.Types.DefaultType));
      this.setCurrentUserTypeContext(this.mapClientDefaultTypeFlagToUserType(this._store.selectSnapshot(AuthResponseState.getAuthResponse).Client.Types.DefaultType));
      this.setUserFeatures();
      this.setAvailableUserSettingsContext();
      this.setDynatraceUserId()
    });
  }

  getCurrencies() {
    this._store.select(state => state.currencies.currencies).subscribe(currList => {
      if (currList === undefined) {
        this._sharedHttp.getCurrencyList().subscribe(ApiCurrList => {
          this._store.dispatch(new AddCurrencies(ApiCurrList))
        });
      }
    });
  }

  //Private Client Context

  setPrivateClientAccountsList(accountList: Array<any>, region) {
    const currentAccountList = { ...this._store.selectSnapshot(PcAvailableAccountsState.getPcAvailableAccounts) };
    let wealthAccounts: WealthAvailableAccounts = [], bankingAccounts: BankingAvailableAccounts = [], mortgageAccounts: MortgageAvailableAccounts = []
    if (currentAccountList !== undefined) {
      currentAccountList.wealthAccounts === undefined ? wealthAccounts = [] : wealthAccounts = [...currentAccountList.wealthAccounts];
      currentAccountList.bankingAccounts === undefined ? bankingAccounts = [] : bankingAccounts = [...currentAccountList.bankingAccounts];
      currentAccountList.mortgageAccounts === undefined ? mortgageAccounts = [] : mortgageAccounts = [...currentAccountList.mortgageAccounts];
    }

    const validProductSegments = ['bank', 'borrow', 'save'];

    accountList.forEach(value => {
      if (value.ProductSegment === 'wealth' || value.ProductSegment === undefined) {
        value.ProductSegment = 'wealth'
        wealthAccounts.forEach((account, key) => {
          if (value.ProductAccountNumber === account.ProductAccountNumber) {
            wealthAccounts.splice(key, 1);
          }
        });

        wealthAccounts.push({
          AccountTypeDescription: value.AccountTypeDescription,
          BaseProductAccountAvailValue: value.BaseProductAccountAvailValue,
          BaseProductAccountValue: value.BaseProductAccountValue,
          CanPay: value.CanPay,
          CanTrade: value.CanTrade,
          Currency: value.Currency,
          HideAvailValue: value.HideAvailValue,
          IsFicaCompliant: value.IsFicaCompliant,
          IsPlatformAccount: value.IsPlatformAccount,
          PlatformAccountDisclaimer: value.PlatformAccountDisclaimer,
          ProductAccountAvailValue: value.ProductAccountAvailValue,
          ProductAccountNumber: value.ProductAccountNumber,
          ProductAccountValue: value.ProductAccountValue,
          ProductName: value.ProductName,
          ProductSegment: value.ProductSegment,
          ProviderName: value.ProviderName,
          SortIndex: value.SortIndex,
          Urls: {
            AccountHoldingUrl: value.AccountHoldingUrl,
            MyInvestmentsAdditionalContributionUrl: value.MyInvestmentsAdditionalContributionUrl,
            MyPortfolioUrl: value.MyPortfolioUrl,
            TransactionHistoryUrl: value.TransactionHistoryUrl,
            TradeUrl: value.TradeUrl,
            PaymentUrl: value.PaymentUrl
          }
        });
      } else if (validProductSegments.includes(value.ProductSegment?.toLowerCase())) {
        bankingAccounts.forEach((account, key) => {
          if ((value.AccountNumber === account.AccountNumber || value.AccountId === account.AccountId) && value.AccountType === account.AccountType) {
            bankingAccounts.splice(key, 1);
          }
        });

        bankingAccounts.push({
          Region: region,
          ProductSegment: value.ProductSegment,
          AccountId: value.AccountId ? value.AccountId : '',
          AccountNumber: value.AccountNumber,
          AccountNumberHash: value.AccountNumberHash,
          AccountName: value.AccountName,
          AccountType: value.AccountType,
          Country: value.Country,
          Currency: value.Currency,
          CanMakePayment: value.CanMakePayment,
          CanMakeFxPayment: value.CanMakeFxPayment,
          CanMakeTransfers: value.CanMakeTransfers,
          CanMakeStandingOrders: value.CanMakeStandingOrders,
          IsFCAccount: value.IsFCAccount,
          ProfileId: value.ProfileId ? value.ProfileId : '',
          BalanceAssets: value.BalanceAssets,
          BalanceLiabilities: value.BalanceLiabilities,
          BaseBalanceAssets: value.BaseBalanceAssets,
          BaseBalanceLiabilities: value.BaseBalanceLiabilities,
          AvailableBalance: value.AvailableBalance,
          BaseAvailableBalance: value.BaseAvailableBalance,
          Urls: {
            MakeSinglePaymentUrl: value.MakeSinglePaymentUrl,
            AccountOverviewUrl: value.AccountOverviewUrl,
            ManageAccountUrl: value.ManageAccountUrl,
            MaturityInstruction: value.MaturityInstruction,
            InternationalPaymentUrl: value.InternationalPaymentUrl,
            MakeNoticeWithdrawalUrl: value.MakeNoticeWithdrawalUrl,
            BenManageUrl: value.BenManageUrl,
            ViewTransactionUrl: value.ViewTransactionUrl,
            AccountSummaryUrl: value.AccountSummaryUrl,
            FixedDepositUrl: value.FixedDepositUrl,
            IntTransferUrl: value.IntTransferUrl,
            AccountPayUrl: value.AccountPayUrl,
            TranactionHistoryUrl: value.TranactionHistoryUrl
          }
        });
      } else if (value.SortCode === undefined) {
        mortgageAccounts.forEach((account, key) => {
          if (value.AccountNumber === account.AccountNumber) {
            mortgageAccounts.splice(key, 1);
          }
        });

        mortgageAccounts.push({
          Region: region,
          ProductSegment: value.ProductSegment,
          AccountId: value.AccountId ? value.AccountId : '',
          AccountNumber: value.AccountNumber,
          AccountNumberHashed: value.AccountNumberHashed,
          AccountName: value.AccountName,
          AccountType: value.AccountType,
          Country: value.Country,
          Currency: value.Currency,
          BalanceLiabilities: value.BalanceLiabilities,
          BaseBalanceLiabilities: value.BaseBalanceLiabilities,
          AvailableBalance: value.AvailableBalance,
          BaseAvailableBalance: value.BaseAvailableBalance,
          Urls: {
            ManageAccountUrl: value.ManageAccountUrl,
          }
        });
      }
    });

    this._store.dispatch(new AddPcAvailableAccounts({
      bankingAccounts: bankingAccounts,
      wealthAccounts: wealthAccounts,
      mortgageAccounts: mortgageAccounts
    }));
  }

  setPrivateClientBankingAccountsListFromPortfolios(excludeFlags: Array<any>, accountList?: Array<any>, accountRegion?: string) {
    return new Promise(resolve => {
      const vm = this;
      const authRes = this._store.selectSnapshot(AuthResponseState.getAuthResponse);
      const selectedProfile = this._store.selectSnapshot(SelectedProfileState.getSelectedProfile);
      const currency = this._store.selectSnapshot(UserSettingsState.getDefaultCurrency);
      let numOfCallsMade = 0, numOfCallsComplete = 0;

      function setPcAccountsList(region, endPoint, profileId) {
        numOfCallsMade++;
        vm._sharedHttp.getPortfolio(region, endPoint, currency, profileId).subscribe(res => {
          vm.setPrivateClientAccountsList(res.PrivateBankAccounts, region);
          numOfCallsComplete++;
          if (numOfCallsMade === numOfCallsComplete) {
            resolve([]);
          }
        }, error1 => {
          numOfCallsComplete++;
          if (numOfCallsMade === numOfCallsComplete) {
            resolve([]);
          }
        });
      }

      function isExcluded(flag) {
        return excludeFlags.filter(x => x === flag).length >= 1;
      }

      if (accountList !== undefined && accountList.length > 0) {
        this.setPrivateClientAccountsList(accountList, accountRegion);
      }

      Object.keys(authRes).forEach(value => {
        switch (value) {
          case 'PrivateBankZA':
            if (authRes[value] && !isExcluded(value)) setPcAccountsList('za', '/pbv2/portfolio', selectedProfile.ProfileId);
            break;
          case 'PrivateBankUK':
            if (authRes[value] && !isExcluded(value)) setPcAccountsList('uk', '/pb/portfolio', '');
            break;
          case 'PrivateBankCI':
            if (authRes[value] && !isExcluded(value)) setPcAccountsList('ci', '/pb/portfolio', '');
            break;
          case 'PrivateBankMU':
            if (authRes[value] && !isExcluded(value)) setPcAccountsList('mu', '/pb/portfolio', '');
            break;
        }
      });

      if (numOfCallsMade === numOfCallsComplete) {
        resolve([]);
      }

    });
  }

  refreshPrivateClientPortfolioAndReturnIt(region, endPoint, profileId, currency, allAccountsOptionDisplayed = false, refreshCache = false, retryWithRefresh = true) {
    return new Promise((resolve, reject) => {
      this._sharedHttp.getPortfolio(region, endPoint, currency, profileId, refreshCache).subscribe(res => {
        let currentAccount: SelectedAccount | SelectedAccountPBUK;
        switch (region) {
          case 'za':
            currentAccount = this._store.selectSnapshot(SelectedAccountState.getSelectedAccount);
            if (!allAccountsOptionDisplayed) {
              if (currentAccount === undefined) {
                this._store.dispatch(new SetSelectedAccount(res.PrivateBankAccounts[0]));
              } else {
                const selectedAccountInList = res.PrivateBankAccounts.filter(x => x.AccountNumber === currentAccount.AccountNumber);
                if (selectedAccountInList.length === 0) {
                  this._store.dispatch(new SetSelectedAccount(res.PrivateBankAccounts[0]));
                }
              }
            }
            break;
          case 'uk':
            currentAccount = this._store.selectSnapshot(SelectedAccountPBUKState.getSelectedAccount);
            if (currentAccount === undefined) {
              this._store.dispatch(new SetSelectedAccountPBUK(res.PrivateBankAccounts[0]));
            } else {
              const selectedAccountInList = res.PrivateBankAccounts.filter(x => x.AccountNumber === currentAccount.AccountNumber);
              if (selectedAccountInList === undefined) {
                this._store.dispatch(new SetSelectedAccountPBUK(res.PrivateBankAccounts[0]));
              }
            }
            break;
        }

        resolve(res);
      }, (err) => {
        if (refreshCache && retryWithRefresh) {
          setTimeout(() => {
            this.refreshPrivateClientPortfolioAndReturnIt(region, 
              endPoint, 
              profileId, 
              currency, 
              allAccountsOptionDisplayed, 
              refreshCache, 
              false).then(res => resolve(res), err => reject(err));  
          }, 3000);
        } else {
          reject(err);
        }
      });
    });
  }

  mapGeoLinkedPrivateClientAccountURL(account: BankingAvailableAccount, pageType) {
    const geoLinkedURLpropertyNames = {
      za: {
        beneficiary: 'AccountPayUrl',
        manageBeneficiary: 'BenManageUrl',
        transactions: 'ViewTransactionUrl',
        default: 'AccountPayUrl'
      },
      uk: {
        beneficiary: 'MakeSinglePaymentUrl',
        manageBeneficiary: 'ManageAccountUrl',
        transactions: 'ViewTransactionUrl',
        default: 'ManageAccountUrl'
      },
      ci: {
        beneficiary: 'MakeSinglePaymentUrl',
        manageBeneficiary: 'AccountOverviewUrl',
        transactions: 'ViewTransactionUrl',
        default: 'AccountOverviewUrl'
      },
      mu: {
        beneficiary: 'AccountPayUrl',
        manageBeneficiary: 'BenManageUrl',
        transactions: 'ViewTransactionUrl',
        default: 'AccountOverviewUrl'
      }
    };

    const urlFromPortfolioCall = account.Urls[geoLinkedURLpropertyNames[account.Region][pageType]];

    if (urlFromPortfolioCall === undefined) {
      const manuallyResolvedUrl = this.tryToResolveUrlManually(account.Region, pageType);
      return manuallyResolvedUrl ? manuallyResolvedUrl : account.Urls[geoLinkedURLpropertyNames[account.Region]['default']];
    } else {
      this._store.dispatch(new UpdateMenuRegion(account.Region));
      return urlFromPortfolioCall;
    }
  }

  tryToResolveUrlManually(region, pageType) {
    const hardcodedUrlDB = {
      za: {
        transactions: '/pc-za-wpaas/payments-sa/individual-accounts',
        beneficiary: '/pc-za-wpaas/payments-sa/payment-method/make-payment/beneficiary',
        transfer: '/pc-za-wpaas/payments-sa/payment-method/make-payment/transfer',
        scheduled: '/pc-za-wpaas/payments-sa/payment-method/make-payment/scheduled',
        onceoff: '/pc-za-wpaas/payments-sa/payment-method/make-payment/onceoff',
        manageBeneficiary: '/pc-za-wpaas/payments-sa/beneficiary/manage/local',
        managePayments: '/pc-za-wpaas/payments-sa/payment-method/make-payment/beneficiary',
        statements: '/pc-za-wpaas/pbza/documents',
        authorizations: '/pc-za-wpaas/payments-sa/authorisations/authorise'
      },
      uk: {
        transactions: '/io-uk-wpaas/pbuk/dashboard/transactions',
        beneficiary: '/io-uk-wpaas/pbuk/dashboard/pay-and-transfer/make-a-payment/single-payment',
        transfer: '/io-uk-wpaas/pbuk/dashboard/pay-and-transfer/make-a-payment/transfer',
        scheduled: '/io-uk-wpaas/pbuk/dashboard/pay-and-transfer/make-a-payment/standing-order',
        onceoff: '/io-uk-wpaas/pbuk/dashboard/pay-and-transfer/make-a-payment/single-payment',
        manageBeneficiary: '/io-uk-wpaas/pbuk/dashboard/pay-and-transfer/manage-payees/uk',
        managePayments: '/io-uk-wpaas/pbuk/dashboard/pay-and-transfer/manage-payments/manage-online-payments',
        statements: undefined,
        authorizations: undefined
      },
      mu: {
        transactions: '/pc-mu-wpaas/pbmu/transactions',
        beneficiary: '/pc-mu-wpaas/pbmu/make-a-payment/beneficiary-payment',
        transfer: '/pc-mu-wpaas/pbmu/make-a-payment/transfer',
        scheduled: '/pc-mu-wpaas/pbmu/existing-scheduled-payments-transfers',
        onceoff: '/pc-mu-wpaas/pbmu/make-a-payment/onceoff-payment',
        manageBeneficiary: '/pc-mu-wpaas/pbmu/manage-beneficiary',
        managePayments: '/pc-mu-wpaas/pbmu/existing-scheduled-payments-transfers',
        statements: '/pc-mu-wpaas/pbmu/statements-documents',
        authorizations: '/pc-mu-wpaas/pbmu/authorise-transactions/pending-all'
      },
      ci: {
        manageBeneficiary: '/io-uk-wpaas/ibci/pay-and-transfer/manage-beneficiary/uk',
      }
    };

    this._store.dispatch(new UpdateMenuRegion(region));
    return hardcodedUrlDB[region][pageType];
  }


  //Business Banking Context
  setBusinessBankingUserProfiles() {
    this._store.selectOnce(BusinessBankingProfilesState.getBusinessBankingProfiles).subscribe(value => {
      if (value === undefined) {
        this._sharedHttp.getBusinessBankingUserProfiles().subscribe(res => {
          this._store.dispatch(new AddBusinessBankingProfiles(res.data));
        });
      }
    });
  }

  getToggles() {
    const payload = { CategoryName: environment.pbuk.toggles };
    return this._http.post(`${environment.pbuk.apiMapping}` + '/toggles', payload);
  }

  private async fetchUserContext() : Promise<void> {
    if (this.userContextFetched) return;
    try {
      const res = await invsy.data.getAllUserContext();
      if (res.result) {
        this.isPrivateBankZA = res?.result.AuthResponse?.authRes?.PrivateBankZA === true;
        this.isPrivateBankZaTargetMarket = res?.result.AuthResponse?.authRes?.PrivateBankZATargetMarket === true;
        this.isStaffClient = res?.result.ZaClientData?.data?.Occupation?.Value === 'Investec - Staff' || res?.result.ZaClientData?.data?.Employer === 'Investec';
        this.isPremiumCustomerZA = res?.result?.Portfolio?.data?.portfolios?.PrivateBankZA?.IsPremiumcustomer === true;
        this.hasRewards = res?.result?.selectedProfile?.profile?.RewardsEnabled === true;
        this.userContextFetched = true;
      }
      else {
        console.log('Failed to get user context');
        this.userContextFetched = false;
      }
    } catch (error) {
      this.userContextFetched = false;
      throw new Error(error.message);
    }
  }

  async filterBy(type: string): Promise<boolean> {
    try {
      await this.fetchUserContext();
      switch (type) {
        case 'rewards': {
          return this.isPrivateBankZA && this.isPrivateBankZaTargetMarket && !this.isPremiumCustomerZA;
        }
        case 'rewardsReferrals': {
          return this.isPrivateBankZA && this.isPrivateBankZaTargetMarket && !this.isStaffClient && this.hasRewards;
        }
        case 'sustainableSolutions':
        case 'travel': {
          return this.isPrivateBankZA && this.isPrivateBankZaTargetMarket;
        }
        default: {
          return false;
        }
      }
    }
    catch (error) {
      console.error(error);
      return false;
    }
  }

  getInvestmentCapabilities() {
    return this._http.get<{HasAccess:boolean}>('/proxy/za/investments/capabilities').pipe();
  }
}
