<div *ngIf="platformInteractions.showFooter" [ngClass]="(platformInteractions.hideFooterOnMobileView)? 'd-none d-lg-block': ''" id="footer">

<!--  <investec-online-feedback-popup [pageName]="pageName" (returnEvt)="toggleActions('showFeedback')"-->
<!--                                  [showFeedback]="actions.showFeedback"-->
<!--                                  (analyticsEvent)="trackAnalyticsEvent($event)"></investec-online-feedback-popup>-->
  <investec-online-live-chat *ngIf="!liveChatKilled && enableLiveChat && !liveChatClosed && isLoggedIn"
                             [chatConnection]="chatConnection"
                             [minimized]="minimized" (minimizeClick)="toggleMinimize()"
                             (closedChat)="toggleClose()"
                             (unreadMessage)="incrementUnread()"
                             (chatStatus)="setChatStatus($event)"></investec-online-live-chat>

  <div class="live-chat-button-float align-items-center" [ngClass]="showBtn ? 'open' : 'closed'" *ngIf="newLifeChat && (!liveChatKilled && enableLiveChat && platformInteractions.showLiveChat && isLoggedIn)"
       (click)="toggleActions('showLiveChat')">
    <div class="text-nowrap text">Chat to us</div>
    <ui-icon name="help-and-support-live-chat" color="white"></ui-icon>
  </div>

  <div id="bottom-footer" class="footer-container mt-5" [ngClass]="pageName === 'payments-sa' ? 'd-print-none': ''">
    <!--<div class="container-fluid px-0 sticky-footer-background">
      <div *ngIf="platformInteractions.fullFooter" class="container">
        <div class="row">
          <div class="col col-md-6 col-lg-4 pt-2 d-none d-md-block">
            <div *ngIf="displayQuickLinks.length > 0">
              <ui-dropdown (returnEvt)="actionTo($event)" [id]="'wantToDropDown'" [placement]="'top'"
                           [label]="'I want to'"
                           [displayProperty]="'Title'" [optionList]="displayQuickLinks" [isLabelBold]="false"
                           [selectedOption]="selectedAction"></ui-dropdown>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-8 align-items-center justify-content-md-end">
            <div class="option py-3 px-lg-3 d-flex"
                 (click)="toggleActions('showFeedback');">
                <ui-icon class="mr-1" [name]="'help-and-support-feedback'" [size]="'24'" [color]="'grey'"></ui-icon>
                <span id="give-feedback">Give Feedback</span>
            </div>
            <div *ngIf="!liveChatKilled && enableLiveChat"
                 class="option py-3 px-lg-3 d-flex"
                 (click)="toggleActions('showLiveChat')">
                <span *ngIf="unreadMessages > 0" class="notification-counter">{{unreadMessages}}</span>
                <ui-icon class="mr-1  connected-chat-state-icon" [name]="'help-and-support-chat-bubbles'" [size]="'24'"
                         [color]="isInChat ? 'success' : 'grey'"></ui-icon>
                <span [ngClass]="isInChat ? 'text-success': ''">Live chat</span>
            </div>
            <div class="option p-3 d-none d-md-flex" (click)="scrollToTop()">
                Back to top
            </div>
          </div>
        </div>
      </div>
    </div>-->

    <hr>

    <div class="footer d-lg-flex align-items-lg-center px-2 pb-3 pt-0 show">
      <ng-container *ngFor="let item of footerLinks; let i = index">
          <a class="links" target="{{item.target}}" href="{{item.url}}" rel="noreferrer">{{item.label}}</a>
      </ng-container>

      <div class="d-flex align-items-center d-md-inline-flex mb-4 mb-lg-0" *ngIf="isLoggedIn">
        <div *ngIf="!newLifeChat && (!liveChatKilled && enableLiveChat && platformInteractions.showLiveChat &&!platformInteractions.fullFooter && isLoggedIn)" class="align-items-center live-chat-button mr-3"
             (click)="toggleActions('showLiveChat' )">
          <span *ngIf="unreadMessages > 0" class="notification-counter">{{unreadMessages}}</span>
          <ui-icon [name]="'help-and-support-chat-bubbles'" [size]="'24'"></ui-icon>
          <span class="ml-1" [ngClass]="isInChat ? 'text-success': ''">Live chat</span>
        </div>
        <div><a (click)="giveFeedback()" class="links" rel="noreferrer">Give feedback</a></div>
      </div>

      <div *ngIf="isLoggedIn" class="d-md-flex d-lg-inline-flex ml-lg-auto align-items-md-center justify-content-md-between">
        <div class="secure" [ngClass]="showFscsLogo ? 'mb-4 mb-md-0' : ''">
          <ui-icon [name]="'security-and-protection-lock-locked'" [size]="'24'"></ui-icon>
          <span class="ml-1">Secure</span>
        </div>

        <div *ngIf="showFscsLogo" class="fscs-logo ml-lg-3" (click)="goToFscs()">
          <img src="assets/images/FSCS.svg" alt="fscs badge">
        </div>
      </div>
    </div>
  </div>
</div>
