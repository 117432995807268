import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from "@angular/core";

export interface SelectedAccountSPS {
  ProductSegment?: string;
  POID?: number;
  CN?: string;
  CC?: string;
  CCo?: string;
  CSC?: string;
  EC?: string;
  S?: string;
  A?: string;
  AD?: string;
  AP?: number;
  AQ?: number;
  AM?: number;
  AB?: number;
  EV?: number;
  CP?: number;
  V?:{ Q?: number, P?: number, D?: string, E?: string, EV?: number } []
}

export class SetSelectedAccountSPS {
  static readonly type = '[SelectedAccountSPS] Add';

  constructor(public payload: SelectedAccountSPS) {
  }
}

export class SelectedAccountSpsStateModel {
  account: SelectedAccountSPS;
}

function storeSafeValue(data: SelectedAccountSPS) {
  return {
    ProductSegment: data.ProductSegment,
    POID: data.POID,
    CN: data.CN,
    CC: data.CC,
    CCo: data.CCo,
    CSC: data.CSC,
    EC: data.EC,
    S: data.S,
    A: data.A,
    AD: data.AD,
    AP: data.AP,
    AQ: data.AQ,
    AM: data.AM,
    AB: data.AB,
    EV: data.EV,
    CP: data.CP,
    V: data.V,
  }
}

@State<SelectedAccountSpsStateModel>({
  name: 'selectedAccountSPS'
})

@Injectable()

export class SelectedAccountSpsState {

  @Selector()
  static getSelectedAccountSPS(state: SelectedAccountSpsStateModel) {
    return state.account;
  }

  @Action(SetSelectedAccountSPS)
  add({ getState, patchState }: StateContext<SelectedAccountSpsStateModel>, { payload }: SetSelectedAccountSPS) {
    patchState({
      account: storeSafeValue(payload)
    });
  }
}
