import { Injectable } from '@angular/core';
import { ReplaySubject } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { environment } from "@env-vars";
import {
  PlatformInteractionState,
  SelectedCampaignState,
  UserTypeState
} from "@investec-online/platform-state-management";
import { Store } from "@ngxs/store";
import { HttpClient } from "@angular/common/http";
import { StateClear, StateReset } from "ngxs-reset-plugin";
import { invsy } from '@investec/invsy-sdk';



@Injectable({
  providedIn: 'root'
})
export class LogoutService {
  public mustLogOut = new ReplaySubject<boolean>(1);

  isOnLoginPage = false;
  useNewLogoutPage = environment.platform.logout;
  defaultLogoutUrl = '/content/investec/shared/logoutcontentpages/default_generic'

  constructor(private cookieService: CookieService, private _store: Store, private _http: HttpClient) {
    this.mustLogOut.subscribe(res => {
      if (res) {
        if (this.useNewLogoutPage) {
          if (!this.isOnLoginPage) {
            this._store.dispatch(new StateReset(UserTypeState));
            invsy.platform.logout()
          }
        } else {
          invsy.platform.initSession()
        }
      }
    })
  }

  clearSessionContext() {
    this.cookieService.deleteAll();
    sessionStorage.clear();

    localStorage.removeItem('LOCALSTORAGE_APP_STATE');
    localStorage.removeItem('browserDetectClosed');
    localStorage.removeItem('roadBlockShown');

    this.logoutSsoCookieSession().subscribe(res => {
      //console.log(res)
    });

    this._store.dispatch(new StateClear(PlatformInteractionState))
  }

  getLogoutCampaign() {
    return new Promise<Array<any>>(resolve => {
      const selectedCam = this._store.selectSnapshot(SelectedCampaignState.getSelectedCampaign);

      if (selectedCam === undefined) {
        this.getCampaignContent(this.defaultLogoutUrl).then(returnArray => {
          resolve(returnArray);
        })
      } else {
        this._http.get<{ snippetcontainer: { logoutPageContentWrapper: { logoutContentPath: string } } }>(selectedCam.selectedCampaign + '/_jcr_content/par.logoutPageContentWrapper.9.json').subscribe(res => {
          this.getCampaignContent(res.snippetcontainer.logoutPageContentWrapper.logoutContentPath).then(returnArray => {
            resolve(returnArray);
          })
        }, error => {
          this.getCampaignContent(this.defaultLogoutUrl).then(returnArray => {
            resolve(returnArray);
          })
        })
      }
    })
  }

  getCampaignContent(url) {
    return new Promise<Array<any>>(resolve => {
      this._http.get(url + '.9.json').subscribe(logoutContent => {

        const returnArray: Array<any> = [];

        Object.keys(logoutContent['jcr:content']).forEach(value => {
          if (value.indexOf('logoutcontentpar') !== -1) {
            returnArray.push(logoutContent['jcr:content'][value]);
          }
        });

        resolve(returnArray);
      });
    })
  }

  logoutSsoCookieSession() {
    return this._http.post('/proxy/auth/logout', {loggedOut: true}, {headers: {SourceApplication: 'UXP_IO'}})
  }
}
