import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';

export interface SelectedAccount {
  AccountName?: string;
  AccountNameOriginal?: string;
  AccountNumber?: string;
  AccountNumberHash?: string;
  AccountType?: string;
  HostId?: number;
  BalanceAssets?: number;
  BalanceLiabilities?: number;
  BaseBalanceAssets?: number;
  BaseBalanceLiabilities?: number;
  AvailableBalance?: number;
  BaseAvailableBalance?: number;
  BalanceDate?: string;
  MovementIn?: number;
  MovementOut?: number;
  DividentBalance?: number;
  DividentAvailableBalance?: number;
  ProductCategory?: number;
  PrivateBankTransactions?: number;
  ProfileId?: string;
  AccountNumberForRequests?: string;
  HasLinkedCards?: boolean;
  Country?: string;
  Currency?: string;
  AccountSummaryUrl?: string;
  OnlineDocUrl?: string;
  TranactionHistoryUrl?: string;
  AccountPayUrl?: string;
  BenManageUrl?: string;
  BenBulkUploadUrl?: string;
  ExternalAccountUrl?: string;
  IntPayUrl?: string;
  IntTransferUrl?: string;
  ClientSearchUrl?: string;
  NoticeAccountUrl?: string;
  IsNoticeAccount?: boolean;
  CanMakeInternationalPayment?: boolean;
  CanMakePayment?: boolean;
  VasEnabled?: boolean;
  VasKillSwitch?: boolean;
  FinanceAccount?: boolean;
  PbaAccount?: boolean;
  SortOrder?: number;
  IsBusinessAccount?: boolean;
  IsYouthAccount?: boolean;
  IsSavingsAccount?: boolean;
  KycCompliant?: boolean;
  KycCompliantMessage?: string;
  KycCompianceNumbers?: number;
  IsDefault?: boolean;
  IsFCAccount?: boolean;
  ProductSegment?: string;
  ApAvailableBalance?: string;
  CanSendCash?: boolean;
  HasRewards?: boolean;
  RewardsBehavioural?: boolean;
  RewardsCanRedeem?: boolean;
  RewardsCanTransfer?: boolean;
  RewardsPointsRandEquivalent?: number;
  RewardsAccountProductCode?: string;
  IsPayShapPaymentEnabled?: boolean;
  PocketAccounts?: SelectedAccount[];
  AccountOpen?: boolean;
  IntelligentRoutingEnabled?: boolean;
  TotalAvailableBalance?: number;
  BaseTotalAvailableBalance?: number;
  AuthorizedBalance?: number;
  TotalPendingAmount?: number;
}

export class SetSelectedAccount {
  static readonly type = '[SelectedAccount] Add';

  constructor(public payload: SelectedAccount) {}
}

export class SelectedAccountStateModel {
  account: SelectedAccount;
}

function storeSafeValue(data: SelectedAccount) {
  return {
    AccountName: data.AccountName,
    AccountNameOriginal: data.AccountNameOriginal,
    AccountNumber: data.AccountNumber,
    AccountNumberHash: data.AccountNumberHash,
    AccountType: data.AccountType,
    AccountNumberForRequests: data.AccountNumberForRequests,
    AvailableBalance: data.AvailableBalance,
    ApAvailableBalance: (data.AvailableBalance - data.BalanceLiabilities).toFixed(2),
    CanMakePayment: data.CanMakePayment,
    ProductSegment: data.ProductSegment,
    Currency: data.Currency,
    BalanceAssets: data.BalanceAssets,
    BalanceLiabilities: data.BalanceLiabilities,
    BaseBalanceAssets: data.BaseBalanceAssets,
    BaseBalanceLiabilities: data.BaseBalanceLiabilities,
    BaseAvailableBalance: data.BaseAvailableBalance,
    CanSendCash: data.CanSendCash,
    FinanceAccount: data.FinanceAccount,
    KycCompliant: data.KycCompliant,
    KycCompliantMessage: data.KycCompliantMessage,
    BenBulkUploadUrl: data.BenBulkUploadUrl,
  };
}

@State<SelectedAccountStateModel>({
  name: 'selectedAccount',
})
@Injectable()
export class SelectedAccountState {
  @Selector()
  static getSelectedAccount(state: SelectedAccountStateModel) {
    return state.account;
  }

  @Action(SetSelectedAccount)
  add({ getState, patchState }: StateContext<SelectedAccountStateModel>, { payload }: SetSelectedAccount) {
    patchState({
      account: storeSafeValue(payload),
    });
  }
}
