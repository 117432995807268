import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ProgrammableCardService} from "../../../../services/programmable-card.service";
import {ToastService} from "../../../../services/toast.service";
import {MerchantClass} from "../../models/merchant.class";
import {CountryCodes} from "../../models/country-codes";
import {DataLayerService} from "@investec-online/platform-services";
import { environment } from 'environments/environment';

@Component({
  selector: 'investec-online-prog-card-file-explorer',
  templateUrl: './code-editor-file-explorer.component.html',
  styleUrls: ['./code-editor-file-explorer.component.scss']
})
export class ProgCardFileExplorerComponent implements OnInit, OnChanges {

  activeEnv = environment.activeEnv;

  @Input() adjustableWidths;
  @Input() openTabFromSimulator;
  @Output() valueChange = new EventEmitter();

  editorOptions = {
    theme: 'vs-dark',
    language: 'javascript'
  };
  editorOptionsVariables = {
    theme: 'vs-dark',
    language: 'json'
  };
  editorOptionsLogs = {
    theme: 'vs-dark',
    language: 'json',
    readOnly: true
  };
  editor;
  codeID: string;
  code: string;
  backupCode: string;
  backupVariables: string;
  simulation = null;
  activeLog = null;
  activeEditor = null;
  viewDependencies = false;
  viewFiles = true;
  tempFiles = false;
  viewReferences = false;
  variables: string;
  showAutoSave = false;
  stateStoreCode;

  rootEnvironmentVariables = {
    environmentVariablesId: '',
    rootCodeFunctionId: '',
    variables: {},
    createdAt: '',
    updatedAt: '',
    error: {}
  };
  savingInProgress: boolean;
  card: any;

  merchantOptions: string;
  countryCodes: string;
  ideTabs: Array<any> = [];
  tabs = {
    main: {
      id: 'main',
      type: 'js',
      canSave: true,
      shouldSave: false
    },
    env: {
      id: 'env',
      type: 'json',
      canSave: true,
      shouldSave: false
    },
    merchants: {
      id: 'merchants',
      type: 'json'
    },
    countryCodes: {
      id: 'country-codes',
      type: 'json'
    },
    log: {
      id: 'log',
      type: 'json'
    },
    simulation: {
      id: 'simulation',
      type: 'json'
    },
    countries: {
      id: 'countries',
      type: 'json'
    }
  };
  defaultCode = `// This function runs during the card transaction authorization flow.
// It has a limited execution time, so keep any code short-running.
const beforeTransaction = async (authorization) => {
    console.log(authorization);
    return true;
};

// This function runs after a transaction.
const afterTransaction = async (transaction) => {
    console.log(transaction)
};`;

  constructor(
    private _rootCard: ProgrammableCardService,
    public toastService: ToastService,
    private _analytics: DataLayerService
  ) {
    this._rootCard.getRootEnvironmentVariables().subscribe(res => {
      this.rootEnvironmentVariables = res;
      this.variables = JSON.stringify(this.rootEnvironmentVariables.variables, null, 4);
      this.backupVariables = JSON.stringify(this.rootEnvironmentVariables.variables, null, 4);

      this.card = this._rootCard.selectedRootCard.CardNumber.match(/.{1,4}/g);

      this.merchantOptions = JSON.stringify(new MerchantClass().populateMerchantList(), null, 4);
      this.countryCodes = JSON.stringify(new CountryCodes().populateCountryCodeList(), null, 4);
    });

    const vm = this;
    document.addEventListener("keydown", function (e) {
      if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.key.charCodeAt(83)) {
        e.preventDefault();
        // Process the event here (such as click on submit button)
        if (!vm.savingInProgress) {
          vm.updateCode();
          // stop holding down s from continuous saving
          // vm.savingInProgress = true;
          // setTimeout(function () {
          //   vm.savingInProgress = false;
          // }, 1000);
        }
      }
      setTimeout(function () {
        vm._rootCard.setStateStoreCode(vm.code);
      }, 300000);

    }, false);

  }

  ngOnChanges(changes) {
    if (changes.openTabFromSimulator) {
      if (!changes.openTabFromSimulator.firstChange) {
        this.pushIdeTab(this.tabs[changes.openTabFromSimulator.currentValue])
      }
    }
  }

  pushIdeTab(tab) {
    const idWithoutDash = tab.id.replace(/-/g, '');

    this._analytics.trackData(
      idWithoutDash + '.' + tab.type,
      'CTA|clicked',
      'programmable banking',
      'click',
      'loyalty',
      'flow',
      'engagement',
      'programmable banking',
      '',
      'cta'
    )

    // if tab exists, don't push new tab
    if (this.ideTabs.includes(tab) === false) this.ideTabs.push(tab);
    // still set active editor to the tab
    this.activeEditor = tab.id;
  }

  removeTab(tab) {
    const index: number = this.ideTabs.indexOf(tab);
    if (index !== -1) {
      this.ideTabs.splice(index, 1);
    }
    try {
      this.activeEditor = this.ideTabs[index].id;
    } catch (e) {
      try {
        this.activeEditor = this.ideTabs[index - 1].id;
      } catch (e) {
        this.activeEditor = null;
      }
    }
  }

  mainEditorOnInit(editor) {
    this.editor = editor;

// need toc create a npm module to be shared between the lamda and the ide
//     const merchantLibraryObj = new MerchantClass().createMerchantLibrary();
//     monaco.languages.typescript.javascriptDefaults.addExtraLib([
//       `const investec = {
//     helpers: {
//         format: {
//             currency: '',
//             decimal: '',
//         },
//     }
// };
// `,
//     ].join('\n'), 'filename/facts.d.ts');
  }

  ngOnInit() {
    this._rootCard.rootCode.subscribe(res => {
      this.code = res;
      this.backupCode = res;
      if (res === this.defaultCode) {
        this.tabs.main.shouldSave = true;
      }
    });

    this._rootCard.rootCodeId.subscribe(res => {
      this.codeID = res;
    });

    this._rootCard.simulation.subscribe(res => {
      this.simulation = res;
      this.pushIdeTab(this.tabs.simulation);
      this.tempFiles = true;
    });

    this._rootCard.activeLog.subscribe(res => {
      this.activeLog = res;
      this.pushIdeTab(this.tabs.log);
      this.tempFiles = true;
    });

    this.stateStoreCode = this._rootCard.getStateStoreCode();
    if (this.stateStoreCode != null) {
      this.showAutoSave = true;
    }
  }

  hideSimulator() {
    const backup = this.activeEditor;
    this.activeEditor = null;
    if (this.adjustableWidths.simulator > 0) {
      this.adjustableWidths = {
        sidebar: 15,
        ide: 85,
        simulator: 0
      };
    } else {
      this.adjustableWidths = {
        sidebar: 12,
        ide: 44,
        simulator: 44
      };
    }
    this.valueChange.emit(this.adjustableWidths);

    const vm = this;
    setTimeout(function () {
      vm.activeEditor = backup;
    }, 100);
  }

  updateRootVariables() {
    this._rootCard.addOrUpdateEnvironmentVariables(this.variables).subscribe(res => {
      if (!res['error']) {
        this.toastService.show(
          'variables.js updated!',
          {classname: 'bg-success text-white'}
        );
        this.tabs.env.shouldSave = false;
      } else {
        this.toastService.show(
          'Something went wrong, please try again.',
          {classname: 'bg-danger text-white'}
        );
      }
    }, error1 => {
      this.toastService.show(
        'Something went wrong, please try again.',
        {classname: 'bg-danger text-white'}
      );
    });

  }

  updateCode() {
    this.savingInProgress = true;
    const errors = (<any>window).monaco.editor.getModelMarkers({});
    const isBeforeAndAfterPresent = this.validateBeforeAndAfterIsPresent(this.code);
    if (!isBeforeAndAfterPresent) {
      this.savingInProgress = false;
      // do nothing
    } else if (errors.length) {
      this.savingInProgress = false;
      errors.forEach(value => {
        if (value.severity === 8) {
          this.toastService.show(
            'Error on line: ' + value.startLineNumber + ', ' + value.message,
            {classname: 'bg-danger text-white'}
          );
        }
      })
    } else {
      this.updateRootVariables();
      this._rootCard.updateRootCode(this.code).subscribe(res => {
        if (!res['error']) {
          //this._rootCard.rootCode.next(this.code);
          this._rootCard.rootCode.next(res.code);
          this._rootCard.rootCodeId.next(res.codeId);

          this.toastService.show(
            'main.js updated!',
            {classname: 'bg-success text-white'}
          );

          this.savingInProgress = false;

          this.tabs.main.shouldSave = false;

        } else {
          this.savingInProgress = false;
          this.toastService.show(
            'An error occurred, please try again.',
            {classname: 'bg-danger text-white'}
          );
        }
      }, err => {
        console.log(err);
      });
      this._rootCard.clearStateStoreCode();
    }
  }

  publishCode() {
    if (this._rootCard.codeModified) {
      this.updateCode();
    }
    //Publish code...
    this._rootCard.publishRootCode(this.codeID, this.code).subscribe(res => {
      if (!res['error']) {
        this._rootCard.rootCode.next(res.code);
        this._rootCard.rootCodeId.next(res.codeId);

        this.toastService.show(
          'Code published!',
          {classname: 'bg-success text-white'}
        );

        this.savingInProgress = false;

        this.tabs.main.shouldSave = false;

      } else {
        this.savingInProgress = false;
        this.toastService.show(
          'An error occurred, please try again.',
          {classname: 'bg-danger text-white'}
        );
      }
    }, err => {
      console.log(err);
    });
  }

  validateBeforeAndAfterIsPresent(code) {
    const isValidRootCode = code.indexOf('beforeTransaction') !== -1 && code.indexOf('afterTransaction') !== -1;

    if (isValidRootCode) {
      return true;
    } else {
      this.toastService.show(
        'beforeTransaction and afterTransaction methods are required.',
        {classname: 'bg-danger text-white'}
      );
      return false;
    }
  }

  cancelAutoSave() {
    this.showAutoSave = false;
    this._rootCard.clearStateStoreCode();
  }

  loadAutoSave() {
    this.showAutoSave = false;
    this._rootCard.rootCode.next(this.stateStoreCode);
  }

  changesNeedToBeSaved(tab: string, code) {
    if (tab === 'main' && code !== this.backupCode) {
      this.tabs.main.shouldSave = true;
    } else if (tab === 'env' && code !== this.backupVariables) {
      this.tabs.env.shouldSave = true;
    }
  }

  insertIntoIde($event: string) {
    this.editor.trigger('keyboard', 'paste', {text: $event});
  }

}
