import { Injectable } from '@angular/core';
import {ReplaySubject, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Store} from "@ngxs/store";
import {environment} from '@env-vars'

import {LogoutService, SharedUtilsService} from "@investec-online/platform-services";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  quickLinks = new Subject<Array<{Title,Url}>>();
  sharedAuthRes = new ReplaySubject<any>(1);
  mustLogOut = new Subject<boolean>();
  menuPreference: string;

  constructor(private _http: HttpClient,
              private _store: Store,
              private _logout: LogoutService,
              private _utils: SharedUtilsService) {

    //get the auth response
    this._store.select(state => state.AuthResponse.authRes).subscribe(value => {
      if(value !== undefined){

        //Global authResponse variable
        this.sharedAuthRes.next(value);
      }
    });

    //LogOut subscription for global navigation
    this.mustLogOut.subscribe(res => {
      this._logout.mustLogOut.next(res);
    });
  }

  liveChatKillswitch() {
    return this._http.post('/proxy/toggles', {
      CategoryName: environment.platform.liveChatToggleCategory,
      Version: ''
    });
  }
}
