import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from "@ngxs/store";
import { AuthResponse, AuthResponseState, UserSegmentState, UserTypeState } from "@investec-online/platform-state-management";
import { environment } from "@env-vars";

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  startTrackingData = new Subject<any>();
  companyid = 'investec';
  timeDifferenceClientServerMs = 0; // to be reset on initialization,
  data: any;
  environment = environment.activeEnv
  authRes: AuthResponse;
  userSegment: string;

  constructor(private _store: Store) {
    const vm = this;

    this._store.select(AuthResponseState.getAuthResponse).subscribe(res => this.authRes = res);
    this._store.select(UserTypeState.getUserSegment).subscribe(res => {
      this.userSegment = res;
      if (this.data?.page?.user) {
        // path: user.0.profile.segment
        this.data.user = [
          { profile: { segment: this.userSegment ? this.userSegment : '' } },
        ];
      }
    });
    this._store.select(UserSegmentState.getUserSegment).subscribe(res => {
      this.userSegment = res;
      if (this.data?.page?.user) {
        // path: user.0.profile.segment
        this.data.user = [
          { profile: { segment: this.userSegment ? this.userSegment : '' } },
        ];
      }
    });

    window.addEventListener('DOMContentLoaded', function () {
      vm.data = (<any>window).digitalData = { event: [] };
      vm.digitalData(window, window.document, {}, new Date());

    });
    this.startTrackingData.subscribe(res => {
      vm.digitalData(window, window.document, res, new Date())
    });

  }

  // event.eventName, event.eventAction, event.itemName, event.eventType, primaryCategory, subCategory1, subCategory2, event.subcategory3, event.attributes, event.itemID

  trackData(eventName, eventAction, itemName, eventType, primaryCategory, subCategory1, subCategory2, subcategory3, attributes, itemID, subcategory5 = '', additional = undefined, sectionName = undefined) {
    const currentTime = new Date().toLocaleString()
    const event = {
      'eventInfo': {
        'eventName': eventName,
        'eventAction': eventAction,
        'type': eventType,
        'timeStamp': currentTime,
        'additional': additional,
        'sectionName': sectionName
      },
      'category': {
        'primaryCategory': primaryCategory,
        'subCategory1': subCategory1,
        'subCategory2': subCategory2,
        'subCategory3': subcategory3,
        'subCategory5': subcategory5,
      },
      'attributes': {
        'itemName': itemName,
        'type': eventType,
        'itemURL': window.location.href,
        'itemID': itemID
      }
    };
    this.startTrackingData.next(event);
  };

  trackDataHybrid(data: any) {
    this.startTrackingData.next(data);
  }

  isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  getClientIdentifier(authResponse: AuthResponse) {
    const zeta = authResponse?.Misc?.Zeta;
    if (zeta) {
      return zeta
    }
    return '';
  }

  getSSOProfiles(authResponse: AuthResponse) {
    const profileList = authResponse?.ProfileList;
    const profilesArray = [];
    if (profileList) {
      for (let index = 0; index < profileList.length; index++) {
        if (profileList[index]?.KeyName) {
          profilesArray.push(profileList[index].KeyName);
        }
      }
      return profilesArray.join(',')
    }

    return '';
  }

  getJurisdiction(authResponse: AuthResponse) {
    const jurisdiction = authResponse?.HIPI;
    if (jurisdiction) {
      return jurisdiction;
    }

    return '';
  }

  digitalData(window, document, event, date) {

    if (!this.data) {
      // if we don't have digitalData, we can't continue
      return;
    }

    const clientProfiles = this.getSSOProfiles(this.authRes);
    const clientidentifier = this.getClientIdentifier(this.authRes);
    const clientJurisdiction = this.getJurisdiction(this.authRes);

    if (this.isEmpty(event)) {
      const vm = this;
      const subCategoryOne = window.location.pathname.split('/')[1];
      const subCategoryTwo = window.location.pathname.split('/')[2] === null || window.location.pathname.split('/')[2] === false ? '' : window.location.pathname.split('/')[2];
      const dateTimeUTC = new Date(date.getTime() - vm.timeDifferenceClientServerMs).toISOString();
      const unixTime = Math.round(Date.now() / 1000)
      const page = {
        pageInfo: {
          destinationURL: document.location.href.split("?")[0],
          environment: this.environment,
          language: navigator.language,
          pageName: "IO_" + subCategoryOne + '/' + subCategoryTwo,
          pageTitle: document.title,
          referringURL: document.referrer,
          sysEnv: "desktop|landscape"
        }, category: {
          pageType: '/apps/sadashboard',
          primaryCategory: "IO",
          subCategory1: subCategoryOne,
          subCategory2: subCategoryTwo
        },
        attributes: {
          hierarchy: 'IO|' + subCategoryOne + '|' + subCategoryTwo,
          pageLoadTime: (window.performance.timing.domComplete - window.performance.timing.navigationStart) / 1000,
          sessionID: Date.now() + Math.random() + ''
        },
        user: {
          id: clientidentifier,
          location: clientJurisdiction,
          businessArea: '',
          products: [],
          productAccess: clientProfiles
        }
      };

      this.data.page = page;
      this.data.user = [{
        visitorID: "",
        profile: {
          id: clientidentifier,
          productAccess: clientProfiles,
          jurisdiction: clientJurisdiction,
          segment: this.userSegment ? this.userSegment : ''
        }
      }];

      this.data.version = '1.0';
      this.data.dateTimeUTC = dateTimeUTC;
      this.data.custom = { companyid: vm.companyid, investec: { serverUnixTime: unixTime.toString() } };
    } else {
      const vm = this;
      const subCategoryOne = window.location.pathname.split('/')[1];
      const subCategoryTwo = window.location.pathname.split('/')[2];
      const dateTimeUTC = new Date(date.getTime() - vm.timeDifferenceClientServerMs).toISOString();
      const unixTime = Math.round(Date.now() / 1000)
      const page = {
        pageInfo: {
          destinationURL: location.toString(),
          environment: "dev",
          language: navigator.language,
          pageName: "IO_" + window.location.pathname.split('/')[1] + '/' + window.location.pathname.split('/')[2],
          pageTitle: document.title,
          referringURL: document.referrer,
          sysEnv: "desktop|landscape"
        }, category: {
          pageType: '/apps/sadashboard',
          primaryCategory: "IO",
          subCategory1: subCategoryOne,
          subCategory2: subCategoryTwo
        },
        attributes: {
          hierarchy: 'IO|' + window.location.pathname.split('/')[1] + '|' + window.location.pathname.split('/')[2],
          pageLoadTime: (window.performance.timing.domComplete - window.performance.timing.navigationStart) / 1000,
          sessionID: Date.now() + Math.random() + '',
          lastEvent: event
        },
        user: {
          id: clientidentifier,
          location: clientJurisdiction,
          businessArea: '',
          products: [],
          productAccess: clientProfiles
        }
      };

      this.data.page = page;
      this.data.user = [{
        visitorID: "",
        profile: {
          id: clientidentifier,
          productAccess: clientProfiles,
          jurisdiction: clientJurisdiction,
          segment: this.userSegment ? this.userSegment : ''
        }
      }];
      this.data.event.push(event);
      this.data.version = '1.0';
      this.data.dateTimeUTC = dateTimeUTC;
      this.data.custom = { companyid: vm.companyid, investec: { serverUnixTime: unixTime.toString() } };
    }
  };

}
