import {Component, OnInit} from '@angular/core';
import {ProgrammableCardService} from "../../../../services/programmable-card.service";
import {NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {DataLayerService} from "@investec-online/platform-services";

@Component({
  selector: 'investec-online-prog-card-logs',
  templateUrl: './root-logs.component.html',
  styleUrls: ['./root-logs.component.scss']
})
export class ProgCardLogsComponent implements OnInit {
  rootLogs = {simulator: [], production: []};

  constructor(
    private _rootCard: ProgrammableCardService,
    private _analytics: DataLayerService
  ) {
    this._rootCard.getLogs().subscribe(res => {

      res.executionItems.forEach(value => {
        value.sandbox ? this.rootLogs.simulator.push(value) : this.rootLogs.production.push(value)
      });

      this._rootCard.ideCode.next(JSON.stringify(res.executionItems[0], null, 4));

    }, err => {
      console.log(err)
    });
  }

  ngOnInit() {
  }

  beforeChange($event: NgbNavChangeEvent) {

    let analyticsEventName;
    if ($event.nextId === 'simulation') {
      analyticsEventName = 'simulation logs'
    } else {
      analyticsEventName = 'production logs'
    }

    this._analytics.trackData(
      analyticsEventName,
      'CTA|clicked',
      'programmable banking',
      'click',
      'loyalty',
      'flow',
      'engagement',
      'programmable banking',
      '',
      'cta'
    )

  }
}
