import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from "@angular/core";

export interface WealthAndInvestmentStateModel {
  selectedAccounts: Array<any>;
  tradingAccount: object;
  paymentAccount: object;
  instrumentCode: string;
  worldAxisCode: string;
  mandateCode: string;
  fundInstrumentCode: string;
  CaMasterID: string;
  CaAccountNumber: string;
  CaViewId: number;
  toggles: Array<any>;
  corporateActionClosed: boolean;
}

export class AddAccounts {
  static readonly type = '[Weatlh Accounts] Add';

  constructor(public payload: object) {
  }
}

export class SetCaViewId {
  static readonly type = '[Corporate Action view id] Add';

  constructor(public payload: any) {
  }
}

export class SetCaMasterId {
  static readonly type = '[Corporate Action master id] Add';

  constructor(public payload: any) {
  }
}

export class SetCaAccountNumber {
  static readonly type = '[Corporate Action account number] Add';

  constructor(public payload: any) {
  }
}

export class SetInstrumentCode {
  static readonly type = '[Instrument code] Add';

  constructor(public payload: any) {
  }
}

export class SetFundInstrumentCode {
  static readonly type = '[Instrument fund code] Add';

  constructor(public payload: any) {
  }
}

export class SetWorldAxisCode {
  static readonly type = '[Instrument world axis code] Add';

  constructor(public payload: any) {
  }
}

export class SetMandateCode {
  static readonly type = '[Instrument mandate code] Add';

  constructor(public payload: any) {
  }
}

export class SetPaymentAccount {
  static readonly type = '[Payment account] Add';

  constructor(public payload: object) {
  }
}

export class SetTradingAccount {
  static readonly type = '[Trading account] Add';

  constructor(public payload: object) {
  }
}

export class Toggles {
  static readonly type = '[Toggles] Loaded';

  constructor(public payload: object) {
  }
}

export class SetOutstandingModalClose {
  static readonly type = '[SetOutstandingModalClose] Add';

  constructor(public payload: any) {
  }
}

@State<WealthAndInvestmentStateModel>({
  name: 'WealthAndInvestment',
  defaults: {
    selectedAccounts: [],
// @ts-ignore
    tradingAccount: null,
// @ts-ignore
    paymentAccount: null,
    instrumentCode: '',
    worldAxisCode: '',
    mandateCode: '',
    fundInstrumentCode: '',
    CaMasterID: '',
    CaAccountNumber: '',
// @ts-ignore
    CaViewId: null,
    toggles: [],
    corporateActionClosed: false
  }
})

@Injectable()

export class WealthAndInvestmentState {

  @Selector()
  static getWealthAndInvestment(state: WealthAndInvestmentStateModel) {
    return state;
  }

  @Action(AddAccounts)
  addWealthAccounts(ctx: StateContext<WealthAndInvestmentStateModel>, {payload} : any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      selectedAccounts: payload.selectedAccounts,
    });
  }

  @Action(SetCaViewId)
  setCorporateActionId(ctx: StateContext<WealthAndInvestmentStateModel>, {payload} : any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      CaViewId: payload.CaViewId
    });
  }

  @Action(SetCaMasterId)
  setCorporateActionMasterId(ctx: StateContext<WealthAndInvestmentStateModel>, {payload} : any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      CaMasterID: payload.CaMasterID
    });
  }

  @Action(SetCaAccountNumber)
  setCorporateActionAccountNumber(ctx: StateContext<WealthAndInvestmentStateModel>, {payload} : any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      CaAccountNumber: payload.CaAccountNumber
    });
  }

  @Action(SetInstrumentCode)
  instrumentCode(ctx: StateContext<WealthAndInvestmentStateModel>, {payload} : any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      instrumentCode: payload.instrumentCode
    });
  }

  @Action(SetFundInstrumentCode)
  fundInstrumentCode(ctx: StateContext<WealthAndInvestmentStateModel>, {payload} : any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      fundInstrumentCode: payload.fundInstrumentCode
    });
  }

  @Action(SetWorldAxisCode)
  worldAxisCode(ctx: StateContext<WealthAndInvestmentStateModel>, {payload} : any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      worldAxisCode: payload.worldAxisCode
    });
  }

  @Action(SetMandateCode)
  mandateCode(ctx: StateContext<WealthAndInvestmentStateModel>, {payload} : any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      mandateCode: payload.mandateCode
    });
  }

  @Action(SetPaymentAccount)
  paymentAccount(ctx: StateContext<WealthAndInvestmentStateModel>, {payload} : any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      paymentAccount: payload.paymentAccount
    });
  }

  @Action(SetTradingAccount)
  tradeAccounts(ctx: StateContext<WealthAndInvestmentStateModel>, {payload} : any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      tradingAccount: payload.tradingAccount
    });
  }

  @Action(Toggles)
  togglesLoaded(ctx: StateContext<WealthAndInvestmentStateModel>, {payload} : any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      toggles: payload.toggles
    });
  }

  @Action(SetOutstandingModalClose)
  outstandingCorporateActionsModal(ctx: StateContext<WealthAndInvestmentStateModel>, {payload} : any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      corporateActionClosed: payload.corporateActionClosed
    });
  }
}
