import {
  Component,
  EventEmitter, HostListener,
  Input, OnChanges,
  OnInit,
  Output,
  Renderer2, SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { NavigationService } from '../navigation.service';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngxs/store';
import { AuthResponseState, FeaturesState } from '@investec-online/platform-state-management';
import { takeWhile } from 'rxjs/operators';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import {
  PlatformInteraction,
  PlatformInteractionState
} from "@investec-online/platform-state-management";
import {invsy} from "@investec/invsy-sdk";

@Component({
  selector: 'investec-online-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterNavComponent implements OnInit, OnChanges {
  @Input() pageName = '';
  @Input() showFscsLogo = false;
  @Output() analyticsEvent: EventEmitter<any> = new EventEmitter();

  platformInteractions : PlatformInteraction

  isSticky = true;

  windowHeight = window.innerHeight;
  selectedAction = 'Select an option';
  actions = { showFeedback: false, showLiveChat: false };
  authRes: any;
  displayQuickLinks = [];
  enableLiveChat = false;
  liveChatKilled = true;
  newLifeChat = false;
  liveChatClosed = true;
  minimized = false;
  unreadMessages = 0;
  env = environment;
  chatConnection: any;
  showBtn = false;

  currentYear = new Date().getFullYear()

  footerLinks = [{
    url: 'https://www.investec.com/en_int/welcome-to-investec/about-us.html',
    label: 'About Investec',
    target: '_blank'
  },
    { url: 'https://www.investec.com/en_int/legal.html', label: 'Legal', target: '_blank' },
    { url: 'https://www.investec.com/en_int/legal/know-fraud.html', label: 'Security advice', target: '_blank' },
    { url: 'https://www.investec.com/en_int/legal/help-and-support/sso-contact-us.html', label: 'Help and support', target: '_blank' }
  ];
  isLoggedIn = false;
  isInChat = false;

  constructor(private _nav: NavigationService, private renderer: Renderer2, private _store: Store) {
    this._store.select(AuthResponseState.getAuthResponse).subscribe(authRes => {
      this.isLoggedIn = authRes !== undefined;
    });

    this._nav.sharedAuthRes.subscribe(authRes => {
      this.authRes = authRes;

      Object.keys(this.authRes).map((key) => {
        if (key === 'PrivateBankUK') {
          this.showFscsLogo = this.authRes[key];
        }
      });
    });

    this._nav.quickLinks.subscribe(res => {
      this.resetQuickLinks();
      res.forEach(value => {
        this.displayQuickLinks.unshift(value);
      });
    });

    let activeSubscription = true;
    this._store.select(FeaturesState.getFeatures).pipe(takeWhile(() => activeSubscription)).subscribe(res => {
      if (res !== undefined) {
        if (res['LIVE_CHAT']) {
          activeSubscription = false;
          this.enableLiveChat = true;
          this.initChat();
        }
      }
    })

    this._nav.liveChatKillswitch().subscribe((res: any) => {
        this.liveChatKilled = res.indexOf("d67fe64f5ad6ebf59979523d3ad2d0fc53f87af3824eb8f043f5ef3ad46d03a8") === -1;
        this.newLifeChat = res.includes('5ce128fd9681424b0a4e2d69cca06f9104988f658e5ce26eb0f4129c62fc83b9');
    })

    this._store.select(PlatformInteractionState.getPlatformInteraction).subscribe(res => {
      if(res !== undefined){
        this.platformInteractions = res
      }
    })
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPos = window.innerHeight + window.scrollY

    const footerEl = document.getElementById('bottom-footer')
    if (footerEl !== null) {
      this.isSticky = scrollPos < footerEl.offsetTop + 55;
    }
  }

  ngOnInit() {
    this.resetQuickLinks();
    this.renderer.addClass(document.body, 'pb-0');
  }

  toggleMinimize() {
    this.unreadMessages = 0;
    this.minimized = !this.minimized;
    this.showBtn = !this.showBtn;
  }

  toggleClose() {
    this.liveChatClosed = !this.liveChatClosed;
    this.showBtn = !this.showBtn;
  }

  incrementUnread() {
    this.unreadMessages++;
  }

  ngOnChanges(changes: SimpleChanges) {}

  actionTo(evt) {
    invsy.platform.navigateUrl(evt.Url);
    this.selectedAction = evt.Title;
    this.trackAnalyticsEvent({
      eventName: 'i-want-to-' + this.selectedAction,
      eventAction: 'FormField|selected',
      itemName: 'IO-dashboard',
      eventType: '',
      subcategory3: 'dashboard',
      attributes: '',
      itemID: ''
    });
  }

  toggleActions(option: string) {
    //This is my weird way of making sure only 1 tab is active at a time. There may be a much better way of doing this, but my brain isnt working
    const currentValue = this.actions[option];
    this.actions = _.mapValues(this.actions, () => false);
    this.actions[option] = !currentValue;

    if (option === 'showLiveChat') {
      if (this.liveChatClosed) {
        this.toggleClose();
      } else if (this.minimized) {
        this.toggleMinimize();
      }
    } else {
      this.trackAnalyticsEvent({
        eventName: 'feedback-started',
        eventAction: 'start',
        itemName: 'General Feedback',
        eventType: 'feedback',
        subcategory3: 'dashboard',
        attributes: '',
        itemID: ''
      });
    }
  }

  giveFeedback(){
    invsy.platform.navigateUrl('/usrroot-wpaas/contact-us/feedback');
    this.trackAnalyticsEvent({
      eventName: 'feedback-started',
      eventAction: 'start',
      itemName: 'General Feedback',
      eventType: 'feedback',
      subcategory3: 'dashboard',
      attributes: '',
      itemID: ''
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  goToFscs() {
    invsy.platform.navigateUrl('https://www.fscs.org.uk/', '_blank');
  }

  resetQuickLinks() {
    this.displayQuickLinks = [{ Url: '/settings/update-login-details.html', Title: 'Update login details' },
      { Url: '/settings/change-security-questions.html', Title: 'Update security questions' },
      { Url: '/settings/website-settings.html', Title: 'Update website settings' }];
  }

  trackAnalyticsEvent(event: { eventName, eventAction, itemName, eventType, subcategory3, attributes, itemID }) {
    this.analyticsEvent.next(event);
  }

  initChat() {
    this.chatConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.platform.liveChatUrl}/chatHub?client=desktop`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();

    if (window.sessionStorage.getItem('chatOpen') === 'true') {
      this.toggleActions('showLiveChat');
    }
  }

  setChatStatus(event) {
    this.isInChat = event;
  }
}
