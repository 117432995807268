<div *ngIf="showLoginReskin">
  <div class="container d-flex align-items-center justify-content-center">
    <div class="card text-center">
      <div class="row justify-content-center">
        <div class="col text-center">
          <div class="my-3">
            <ui-icon [name]="'technology-devices-mobile'" [color]="'blue-black'" [size]="'48'"></ui-icon>
          </div>
          <div *ngIf="secureInappEnabled !== null">
            <div *ngIf="inappCodeVersion===2 else version1Template">
              <h6 class="font-weight-bold mb-2">Authenticate your login with a security code</h6>
              <p>A message has been sent to your {{appDetails.deviceType}} to complete your login.</p>
              <p>On the <span class="font-weight-bold">{{appDetails.displayName}}</span> App, please tap the <span class="font-weight-bold">four-character code</span> that corresponds with the code below.</p>
            </div>
            <ng-template #version1Template>
              <h6 class="font-weight-bold mb-2">Authenticate your login</h6>
              <p>A message has been sent to your {{appDetails.deviceType}} to complete your login.</p>
              <p>Please confirm login via the <span class="font-weight-bold">{{appDetails.displayName}} App</span> with the reference:</p>
            </ng-template>
            <h1 class="font-weight-bold mb-2">{{inappCode}}</h1>
          </div>
          <div class="mt-2">
            <ui-icon [name]="'finance-time'" [color]="'blue-black'" [size]="'24'"></ui-icon>
            <h6 class="font-weight-bold mb-0">Time remaining</h6>
            <p>{{popUpTimer}}s</p>
          </div>
          <ui-button [btnClass]="'secondary'" (click)="cancelInApp()">Cancel</ui-button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!showLoginReskin">
  <div class="modal-body">
    <div class="row justify-content-center">
      <div class="col text-center">
        <div class="my-3">
          <ui-icon [name]="'technology-devices-mobile'" [color]="'blue-black'" [size]="'48'"></ui-icon>
        </div>
        <div *ngIf="secureInappEnabled !== null">
          <div *ngIf="inappCodeVersion===2 else version1Template">
            <h6 class="font-weight-bold mb-2">Authenticate your login with a security code</h6>
            <p>A message has been sent to your {{appDetails.deviceType}} to complete your login.</p>
            <p>On the <span class="font-weight-bold">{{appDetails.displayName}}</span> App, please tap the <span class="font-weight-bold">four-character code</span> that corresponds with the code below.</p>
          </div>
          <ng-template #version1Template>
            <h6 class="font-weight-bold mb-2">Authenticate your login</h6>
            <p>A message has been sent to your {{appDetails.deviceType}} to complete your login.</p>
            <p>Please confirm login via the <span class="font-weight-bold">{{appDetails.displayName}} App</span> with the reference:</p>
          </ng-template>
          <h1 class="font-weight-bold mb-2">{{inappCode}}</h1>
        </div>
        <div class="mt-2">
          <ui-icon [name]="'finance-time'" [color]="'blue-black'" [size]="'24'"></ui-icon>
          <h6 class="font-weight-bold mb-0">Time remaining</h6>
          <p>{{popUpTimer}}s</p>
        </div>
        <ui-button [btnClass]="'secondary'" (click)="cancelInApp()">Cancel</ui-button>
      </div>
    </div>
  </div>
</div>
