import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SecondFactorComponent} from './second-factor/second-factor.component';
import {SecondFactorModalComponent} from './second-factor/second-factor-modal/second-factor-modal.component';
import {TypeOtpComponent} from './second-factor/second-factor-modal/type-otp/type-otp.component';
import {AppModuleStorybook} from "@investec/io-bootstrap";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import { TypeInappComponent } from './second-factor/second-factor-modal/type-inapp/type-inapp.component';
import { TypeUssdComponent } from './second-factor/second-factor-modal/type-ussd/type-ussd.component';
import {PlatformComponentsModule} from "../../../platform-components/src";

@NgModule({
    imports: [CommonModule, AppModuleStorybook, FormsModule, HttpClientModule, PlatformComponentsModule],
    declarations: [SecondFactorComponent, SecondFactorModalComponent, TypeOtpComponent, TypeInappComponent, TypeUssdComponent],
    exports: [SecondFactorComponent, TypeOtpComponent, TypeInappComponent, TypeUssdComponent]
})
export class SecondFactorPlatformModule {
}
