import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {UserContextService} from "@investec-online/platform-services";
import {platformInteractionStateTemplates, SetPlatformInteraction} from "@investec-online/platform-state-management";
import {Store} from "@ngxs/store";

@Injectable()
export class UserContextResolver implements Resolve<boolean> {
  constructor(private _userContext: UserContextService, private _store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    //check if logged in
    if(!route.data.isAuthenticated){
      state.url.includes('logout') ?  this._store.dispatch(new SetPlatformInteraction(platformInteractionStateTemplates.logoutPage)) :
        this._store.dispatch(new SetPlatformInteraction(platformInteractionStateTemplates.notLoggedIn))
    }

   return true
  }
}
