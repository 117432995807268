import { Injectable } from '@angular/core';
import {ServicesModule} from "./services.module";

@Injectable({
  providedIn: ServicesModule
})
export class SimulatorService {
  mock = {
    Amount: '10000',
    Currency: 'South African Rand',
    MerchantName: 'The Coders Bakery',
    MerchantCat: {
      code: 5462
    },
    countryCode: {
      name: 'South Africa',
      code: 'ZA'
    },
    MerchantCity: 'Cape Town'
  };
  activeCurrency = {
    Code: "ZAR",
    Country: "South Africa"
  };

  constructor() { }

  update(newMock) {
    this.mock = newMock;
  }

  fetch() {
    return this.mock;
  }

}
