import { AbstractControl } from '@angular/forms';

///password validators
export function ValidateSequentialChar(control: AbstractControl) {

  // Check for sequential numerical characters
  for (let i = 0; i < control.value.length; i++){
    const n = +control.value[i];
    if (n+1 === +control.value[i+1] && n+2 === +control.value[i+2]) {
      return {validSequentialChar : true};
    }
  }

  // Check for sequential alphabetical characters
  for (let i = 0; i < control.value.length; i++){
    const s = control.value[i];
    if (String.fromCharCode(control.value.charCodeAt(+s)+1) === control.value[i+1] && String.fromCharCode(control.value.charCodeAt(+s)+2) === control.value[i+2]){
      return {validSequentialChar : true};
    }
  }

  // must be longer than 0
  if(control.value.length > 0) {
    return null
  } else {
    return {validSequentialChar : true}
  }
}

export function ValidateConsecutiveChar(control: AbstractControl) {

  for (let i = 0; i < control.value.length; i++) {
    if (control.value[i] === control.value[i + 1] && control.value[i] === control.value[i + 2]) {
      return {validConsecutiveChar: true};
    }
  }

  // must be longer than 0
  if(control.value.length > 0) {
    return null
  } else {
    return {validConsecutiveChar : true}
  }
}

export function ValidateUpperLowerCase(control: AbstractControl) {

  const upperLower = (/^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d!@#$&()\\-`.+,\-*=%^?\/\"]{0,}$/).test(control.value);

  if(upperLower){
    return null
  } else {
    return {validUpperLower: true}
  }
}

export function ValidateHasNumber(control: AbstractControl) {

  const hasNumber = (/^(?=.*\d)[A-Za-z\d!@#$&()\\-`.+,\-*=%^?\/\"]{0,}$/).test(control.value);

  if(hasNumber){
    return null
  } else {
    return {validHasNumber: true}
  }
}


///username validators
export function ValidateSpecialChar(control: AbstractControl) {

  const hasSpecialChar = (/[^A-Za-z0-9-!$\/=_{|}~.@]/).test(control.value);

  if(hasSpecialChar){
    return {inValidSpecialChar: true}
  } else {
    return null
  }
}
