import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from "@angular/core";
import {InvestecService} from "./investec-services";

export interface AuthResponse {
  Client?: {
    FirstName?: string,
    Surname?: string,
    Title?: string,
    EmailAddress?: string,
    MobileNumber?: string,
    IdNumber?: string,
    Types?: {
      PrivateClient?: boolean,
      Intermediary?: boolean,
      Corporate?: boolean,
      DefaultType?: string,
      Youth?: boolean
    }
  },
  ClientProfileFlags?:{
    UKExecutiveClientPlatform?:boolean
  }
  ProfileList?: Array<InvestecService>
  WealthAndInvestmentCI?: boolean,
  WealthAndInvestmentUK?: boolean,
  WealthAndInvestmentGU?: boolean,
  WealthAndInvestmentZA?: boolean,
  WealthAndInvestmentClick?: boolean,
  WealthAndInvestment?: boolean,
  CanTrade?: boolean,
  ShareSchemes?: boolean,
  ResetPinRequired?: boolean,
  PrivateBank?: boolean,
  PrivateBankCI?: boolean,
  PrivateBankUK?: boolean,
  PrivateBankAU?: boolean,
  PrivateBankZA?: boolean,
  PrivateBankMU?: boolean,
  PersonalBanking?: boolean,
  Pfm?: boolean,
  PfmTargetMarket?: boolean,
  WealthManagement?: boolean,
  CanMasquerade?: boolean,
  IsMasqueradedAsClient?: boolean,
  ShowSSOLoginProfilePref?: boolean,
  AssetManagementUTZA?: boolean,
  AssetManagementIMSZA?: boolean,
  CanApply?: boolean,
  Briefcase?: boolean,
  BriefcaseTargetMarket?: boolean,
  BusinessBankingZa?: boolean,
  UserType?: string,
  DeviceRegistered?: boolean,
  IntermediaryAppActivated?: boolean,
  PrivateClientAppActivated?: boolean,
  UkAppActivated?: boolean,
  YouthAppActivated?: boolean,
  MaxSession?: string,
  SecondFactorPreference?: Array<string>,
  MyLegacy?: boolean,
  MyLegacyTargetMarket?: boolean,
  QuickLinksDisabled?: boolean,
  LifeTargetMarket?: boolean,
  Life?: boolean,
  Chat?: boolean,
  Misc?: {Zeta?}
  LastLogin?: string,
  HIPI?: string,
  ProfileMaxSession?: string,
  PasswordResetJourneyAllowed?: boolean,
  HasQa?: boolean,
  AcceptedWebTCs?: boolean,
  DownloadedApp?: boolean,
  RedirectExternalUrl?: string,
  RedirectInternalUrl?: string,
  HasQuickPass?: boolean,
  SecurityDetailsRequired?: boolean
  Securities?: boolean
  IsNewUser?: boolean
  displayChecklist?: boolean
  ShowQuickPass?: boolean
  ShowWelcomeScreen?: boolean,
  IntermediaryBanking?: boolean,
  CorporateAndInvestmentBankingUK?: boolean,
  CibUkIntermediaryBanking?: boolean,
  HasCapabilities?: boolean
}

export class SafeAuthResponse {

  constructor(res :AuthResponse){
    return {
      Client: res.Client,
      WealthAndInvestmentCI: res.WealthAndInvestmentCI,
      WealthAndInvestmentUK: res.WealthAndInvestmentUK,
      WealthAndInvestmentGU: res.WealthAndInvestmentGU,
      WealthAndInvestmentZA: res.WealthAndInvestmentZA,
      WealthAndInvestmentClick: res.WealthAndInvestmentClick,
      WealthAndInvestment: res.WealthAndInvestment,
      CanTrade: res.CanTrade,
      ShareSchemes: res.ShareSchemes,
      ResetPinRequired: res.ResetPinRequired,
      PrivateBank: res.PrivateBank,
      PrivateBankCI: res.PrivateBankCI,
      PrivateBankUK: res.PrivateBankUK,
      PrivateBankZA: res.PrivateBankZA,
      PrivateBankMU: res.PrivateBankMU,
      PersonalBanking: res.PersonalBanking,
      Pfm: res.Pfm,
      PfmTargetMarket: res.PfmTargetMarket,
      WealthManagement: res.WealthManagement,
      CanMasquerade: res.CanMasquerade,
      IsMasqueradedAsClient: res.IsMasqueradedAsClient,
      ShowSSOLoginProfilePref: res.ShowSSOLoginProfilePref,
      AssetManagementUTZA: res.AssetManagementUTZA,
      AssetManagementIMSZA: res.AssetManagementIMSZA,
      CanApply: res.CanApply,
      Briefcase: res.Briefcase,
      BriefcaseTargetMarket: res.BriefcaseTargetMarket,
      BusinessBankingZa: res.BusinessBankingZa,
      UserType: res.UserType,
      DeviceRegistered: res.DeviceRegistered,
      IntermediaryAppActivated: res.IntermediaryAppActivated,
      PrivateClientAppActivated: res.PrivateClientAppActivated,
      UkAppActivated: res.UkAppActivated,
      YouthAppActivated: res.YouthAppActivated,
      MaxSession: res.MaxSession,
      SecondFactorPreference: res.SecondFactorPreference,
      MyLegacy: res.MyLegacy,
      MyLegacyTargetMarket: res.MyLegacyTargetMarket,
      QuickLinksDisabled: res.QuickLinksDisabled,
      LifeTargetMarket: res.LifeTargetMarket,
      Life: res.Life,
      Misc: res.Misc,
      Chat: res.Chat,
      LastLogin: res.LastLogin,
      HIPI: res.HIPI,
      ProfileMaxSession: res.ProfileMaxSession,
      PasswordResetJourneyAllowed: res.PasswordResetJourneyAllowed,
      CorporateAndInvestmentBankingUK: res.CorporateAndInvestmentBankingUK,
      CibUkIntermediaryBanking: res.CibUkIntermediaryBanking
    }
  }

}

export class SetAuthResponse {
  static readonly type = '[AuthResponse] Add';

  constructor(public payload: AuthResponse) {
  }
}

export class AuthResponseStateModel {
  authRes: AuthResponse;
}

@State<AuthResponseStateModel>({
  name: 'AuthResponse'
})

@Injectable()

export class AuthResponseState {

  @Selector()
  static getAuthResponse(state: AuthResponseStateModel) {
    return state.authRes;
  }

  @Selector()
  static GetAppStoreAuthResponse(state: AuthResponseStateModel) {
    const returnArray = [];

    Object.keys(state.authRes).forEach(value => {
      switch (value) {
        case 'PrivateBankZA':
        case 'PrivateBankUK':
        case 'PrivateBankMU':
        case 'WealthAndInvestmentZA':
        case 'WealthAndInvestmentUk':
        case 'BusinessBankingZa':
          returnArray.push(value)
          break
      }
    })
    return returnArray;
  }

  @Action(SetAuthResponse)
  add({getState, patchState}: StateContext<AuthResponseStateModel>, {payload}: SetAuthResponse) {
    patchState({
      authRes: payload
    });
  }
}
