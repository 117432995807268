<nav class="sidebar">
  <div class="sidebar-sticky">

    <ul class="nav flex-column">

      <a (click)="showCardsOverview()" class="mb-1 pt-1">
      <ui-icon (click)="trackClickForAnalytics('back to card overview')" class="item" [name]="'interface-arrow-left'"
               [size]="'24'"
               [color]="'white'" placement="right" ngbTooltip="Back to cards" container="body"></ui-icon>
      </a>
      <a href="https://developer.investec.com/programmable-banking/" target="_blank">
        <ui-icon (click)="trackClickForAnalytics('documentation')" class="item" [name]="'interface-indicator-help'"
                 [size]="'24'"
                 [color]="'white'" placement="right" ngbTooltip="View documentation" container="body"></ui-icon>
      </a>

      <ui-icon (click)="open(content, 'give feedback')" class="item" [name]="'help-and-support-feedback'" [size]="'24'"
               [color]="'white'" placement="right" ngbTooltip="Feedback and support" container="body"></ui-icon>

      <ui-icon (click)="open(resetCOde, 'revert code')" class="item" [name]="'interface-resubmit'" [size]="'24'"
               [color]="'white'" placement="right" ngbTooltip="Reset code to default" container="body"></ui-icon>

      <ui-icon (click)="openSourceControl(selectSource, 'open source code')" class="item" [name]="'documents-proof-of-payment'" [size]="'24'"
               [color]="'white'" placement="right" ngbTooltip="Select source code" container="body"></ui-icon>

    </ul>

    <ng-template #content let-modal>

      <form #f="ngForm">

        <div class="modal-header">
          <h5 class="modal-title" id="modal-basic-title">
            <ui-icon [name]="'help-and-support-feedback'" [size]="'48'" [color]="'grey'"></ui-icon>
            Feedback
          </h5>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <p>At Investec, we welcome and value your opinion. As part of our efforts towards ensuring distinctive
            client experience, we invite you to provide your feedback below.</p>

          <div *ngIf="!showFeedbackConfirmation" class="row mt-2">
            <div class="col">
              <div class="content-box">
                <div class="row">
                  <div class="col-3">
                    <p><strong>User:</strong></p>
                  </div>
                  <div class="col">
                    <p><strong>{{user}}</strong></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <p><strong>Page:</strong></p>
                  </div>
                  <div class="col">
                    <p><strong>{{pageName}}</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!showFeedbackConfirmation" class="row mt-2">
            <div class="col">
              <div class="content-feedback">
                <p>Overall, how has your experience with Investec Online been thus far?</p>
                <div class="row no-wrap-row">
                  <div class="col-auto mb-2">
                    <ui-icon *ngFor="let item of ratings; let i = index" (click)="selectRating(i)"
                             [ngClass]="(item.selected)? 'selected':''" class="icon {{item.color}}"
                             [name]="item.iconName" [size]="'48'" [animateStyle]="'animate-scale-hover'"
                             [animateCondition]="true"></ui-icon>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <label>Feedback subject</label>
                    <ui-select #feedbackSubject="ngModel"
                               name="feedbackSubject"
                               [name]="'feedbackSubject'"
                               [autoWidth]="true"
                               [showLabel]="false"
                               [required]="true"
                               [errorText]="'please select an option'"
                               [displayProperty]="'Type'"
                               [optionList]="feedbackSubjectOptions"
                               [(ngModel)]="feedbackInfo.Type"
                               [errorCondition]="!feedbackSubject.valid && feedbackSubject.touched">

                    </ui-select>

                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <label>Your feedback</label>
                    <ui-textarea #feedbackMessage="ngModel"
                                 name="feedbackMessage"
                                 [(ngModel)]="feedbackInfo.MessageBody"
                                 [autoWidth]="true"
                                 [errorText]="'Please add some feedback'"
                                 [showLabel]="false"
                                 [name]="'feedbackMessage'"
                                 [errorCondition]="!feedbackMessage.valid && feedbackMessage.touched"
                                 [required]="true">
                    </ui-textarea>
                  </div>
                </div>

                <!--                <div class="row mb-2">-->
                <!--                  <div class="col">-->
                <!--                    <button id="submit-feedback" [disabled]="!f.valid" class="btn btn-primary float-right ml-1"-->
                <!--                            (click)="sendFeedback(f)">Submit-->
                <!--                    </button>-->
                <!--                    <button id="cancel-feedback" class="btn btn-outline-primary float-right" (click)="closeFeedbackPopup()">-->
                <!--                      Cancel-->
                <!--                    </button>-->
                <!--                  </div>-->
                <!--                </div>-->

              </div>
            </div>
          </div>

          <div *ngIf="showFeedbackConfirmation" class="row mt-3">
            <div class="col">

              <div class="mb-2 height-30">
                <ui-bullet-list-content-loader
                  *ngIf="!showFeedbackSuccess"></ui-bullet-list-content-loader>
                <p *ngIf="showFeedbackSuccess"><span
                  id="thank-you-for-feedback">Thank you for your feedback</span><strong> {{user}}</strong></p>
              </div>

            </div>
          </div>

          <!--        <form>-->
          <!--          <div class="form-group">-->
          <!--            <label for="dateOfBirth">Date of birth</label>-->
          <!--            <div class="input-group">-->
          <!--              <input id="dateOfBirth" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker-->
          <!--                     #dp="ngbDatepicker">-->
          <!--              <div class="input-group-append">-->
          <!--                <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"></button>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </form>-->
        </div>
        <div class="modal-footer">

          <button *ngIf="!showFeedbackSuccess" id="cancel-feedback" class="btn btn-outline-secondary float-right"
                  (click)="modal.dismiss('Cancel click')">
            Cancel
          </button>

          <button *ngIf="!showFeedbackSuccess" id="submit-feedback" [disabled]="!f.valid"
                  class="btn btn-primary float-right ml-1"
                  (click)="sendFeedback(f)">Submit
          </button>

          <button *ngIf="showFeedbackSuccess" id="close-feedback" class="btn btn-primary float-right"
                  (click)="modal.dismiss('Cancel click')">
            Close
          </button>

        </div>

      </form>
    </ng-template>

    <ng-template #resetCOde let-modal>


      <div class="modal-header">
        <h5 class="modal-title" id="modal-reset-code">
          <ui-icon [name]="'interface-resubmit'" [size]="'48'" [color]="'grey'"></ui-icon>
          Reset code to default
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <p>By clicking "Reset code", <code>main.js</code> will be reset to the default code. You will still need to
          deploy your code for the reset to take effect.</p>

      </div>
      <div class="modal-footer">

        <button id="cancel-reset-code" class="btn btn-outline-secondary float-right"
                (click)="modal.dismiss('Cancel click')">
          Cancel
        </button>

        <button id="reset-code" class="btn btn-primary float-right ml-1"
                (click)="resetCode(); modal.dismiss('Cancel click')">Reset code
        </button>

      </div>

    </ng-template>

    <ng-template #selectSource let-modal>


      <div class="modal-header">
        <h5 class="modal-title" id="modal-reset-code">
          <ui-icon [name]="'documents-proof-of-payment'" [size]="'48'" [color]="'grey'"></ui-icon>
          Select source code version
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="table-responsive" *ngIf="showSourceCodeVersions">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Description</th>
                <th>Created At</th>
                <th>Modified At</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="savedCode !== undefined && savedCode !== null" (click)="selectSourceCode(savedCode)">
                <td>Last saved</td>
                <td *ngIf="savedCode.createdAt !== null">
                  {{savedCode.createdAt | date:'yyyy-MM-dd hh:mm:ss'}}
                </td>
                <td *ngIf="savedCode.updatedAt !== null">
                  {{savedCode.updatedAt | date:'yyyy-MM-dd hh:mm:ss'}}
                </td>
              </tr>
              <tr *ngIf="publishedCode !== undefined && publishedCode !== null" (click)="selectSourceCode(publishedCode)">
                <td>Published to card</td>
                <td *ngIf="publishedCode.createdAt !== null">
                  {{publishedCode.createdAt | date:'yyyy-MM-dd hh:mm:ss'}}
                </td>
                <td *ngIf="publishedCode.publishedAt !== null">
                  {{publishedCode.publishedAt | date:'yyyy-MM-dd hh:mm:ss'}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="!showSourceCodeVersions">
          <p>Loading source code...</p>
        </div>

      </div>
      <div class="modal-footer">

        <button id="cancel-source-code" class="btn btn-outline-secondary float-right" (click)="modal.dismiss('Cancel click')">
          Cancel
        </button>

      </div>

    </ng-template>

  </div>
</nav>
