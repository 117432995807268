<div class="content">

<investec-online-error-toast></investec-online-error-toast>

<investec-online-second-factor></investec-online-second-factor>

<div id="appPlatformUserContainer">
  <router-outlet></router-outlet>
</div>

</div>

<investec-online-footer-nav [pageName]="currentPageName" (analyticsEvent)="trackAnalyticsEvent($event)"></investec-online-footer-nav>





