import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from "@angular/core";
import {invsy} from "@investec/invsy-sdk";

export interface SelectedProfile {
  ProfileId?: string,
  ProfileName?: string,
  Default?: boolean,
  IsFicaCompliant?: boolean,
  VasEnabled?: boolean,
  VasKillSwitch?: boolean,
  RtcEnabled?: boolean,
  Accounts?: number,
  RewardsEnabled?: boolean,
  RewardsCanTransfer?: boolean,
  InternationalPaymentsInboundUrl?: string,
  NoticeAccountInboundUrl?: string,
  BenAuthActive?: boolean,
  BenAuthPending?: boolean,
  HasNoticeAccounts?: boolean,
  CanMakePayment?: boolean,
  IsProfileOwner?: boolean
}

export class SetSelectedProfile {
  static readonly type = '[SelectedProfile] Add';

  constructor(public payload: SelectedProfile) {
  }
}

export class SelectedProfileStateModel {
  profile: SelectedProfile;
}

function storeSafeValue(data: SelectedProfile){
  return {
    ProfileId: data.ProfileId
  }
}

@State<SelectedProfileStateModel>({
  name: 'selectedProfile'
})

@Injectable()

export class SelectedProfileState {

  @Selector()
  static getDefaultProfileId(state: SelectedProfileStateModel) {

    return state.profile.ProfileId
  }

  @Selector()
  static getSelectedProfile(state: SelectedProfileStateModel) {
    return state.profile;
  }

  @Action(SetSelectedProfile)
  add({getState, patchState}: StateContext<SelectedProfileStateModel>, {payload}: SetSelectedProfile) {
    invsy.platform.updateContext({selectedProfile:{profile:payload}})
    patchState({
      profile: payload
    });
  }
}
