import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {Store} from "@ngxs/store";
import {
  AddAvailableUserSettings,
  AddFeatures, AddMenuConfig,
  AddUserSettings,
  SetAuthResponse,
  UpdatePortfolio
} from "@investec-online/platform-state-management";
import {invsy} from "@investec/invsy-sdk";

@Injectable({
  providedIn: 'root'
})
export class WpaasGuard implements CanActivate {
  constructor(private _store:Store) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(resolve => {
      invsy.data.getAllUserContext().then((res) => {
        if(res.result.AuthResponse.authRes){
          if(res.result.AuthResponse.authRes) this._store.dispatch(new SetAuthResponse(res.result.AuthResponse.authRes))
          if(res.result.userSettings.settings) this._store.dispatch(new AddUserSettings(res.result.userSettings.settings))
          if(res.result.availableUserSettings.availableSettings) this._store.dispatch(new AddAvailableUserSettings(res.result.availableUserSettings.availableSettings))
          if(res.result.userFeatures.features) this._store.dispatch(new AddFeatures(res.result.userFeatures.features))
          if(res.result.Menu.menuConfig) this._store.dispatch(new AddMenuConfig(res.result.Menu.menuConfig))
          if(res.result.Portfolio.data) this._store.dispatch(new UpdatePortfolio(res.result.Portfolio.data))
          resolve(true)
        } else {
          resolve(false)
        }
      })
    })

  }
}
