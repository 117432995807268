import {Component, TemplateRef} from '@angular/core';
import {ToastService} from "@investec-online/platform-services";

@Component({
  selector: 'investec-online-platform-toast',
  templateUrl: './platform-toast.component.html',
  styleUrls: ['./platform-toast.component.scss'],
  host: {'[class.ngb-toasts]': 'true'}
})
export class PlatformToastComponent {

  constructor(public toastService: ToastService) {
  }

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

}
