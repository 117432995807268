<form #f="ngForm" class="pl-4 pr-4" (change)="updateMock()">

  <div *ngIf="!executeFunctionsCodeInProgress">
    <div class="form-group row">
      <label for="amountInCents" class="col-form-label col-md-6">
        Cents amount
      </label>
      <div class="col-md-6">
        <input #amountInCents="ngModel"
               id="amountInCents"
               type="text"
               name="amountInCents"
               class="form-control"
               placeholder="e.g. 500"
               required="true"
               [disabled]="false"
               [ngClass]="{'is-valid': amountInCents.valid && amountInCents.dirty, 'is-invalid': amountInCents.invalid && amountInCents.touched}"
               [(ngModel)]="transactionInfo.Amount">
        <!--Error Msg-->
        <div *ngIf="amountInCents.errors && (amountInCents.dirty || amountInCents.touched)">
          <p *ngIf="amountInCents.errors.required" class="error-text medium">This field is required</p>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="currency" class="col-form-label col-md-6">
        Currency
      </label>
      <div class="col-md-6">
        <ui-currency-selector [id]="'currency'" [label]="''" [selectedOption]="transactionInfo.Currency"
                              [fullWidth]="true" (returnEvt)="changeCurrency($event)"></ui-currency-selector>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-6 col-form-label">
        Merchant code<br>
        <small><a href="javascript:void(0)" (click)="openReferenceInIde('merchants')">List of merchants and codes</a></small>
      </label>
      <div class="col-md-6">
        <input #merchantCategoryCode="ngModel"
               id="merchantCategoryCode"
               type="text"
               name="merchantCategoryCode"
               class="form-control"
               placeholder="4172"
               required="true"
               [disabled]="false"
               [ngClass]="{'is-valid': merchantCategoryCode.valid && merchantCategoryCode.dirty, 'is-invalid': merchantCategoryCode.invalid && merchantCategoryCode.touched || !merchantCodeIsValid}"
               [(ngModel)]="transactionInfo.MerchantCat.code" (change)="merchantCodeValidator(transactionInfo.MerchantCat.code)">
        <!--Error Msg-->
        <div *ngIf="merchantCategoryCode.errors && (merchantCategoryCode.dirty || merchantCategoryCode.touched)">
          <p *ngIf="merchantCategoryCode.errors.required" class="error-text medium">This field is required</p>
        </div>
        <p *ngIf="!merchantCodeIsValid" class="error-text medium">Please use a valid merchant code</p>
      </div>
    </div>

    <div class="form-group row">
      <label for="MerchantName" class="col-form-label col-md-6">
        Merchant name
      </label>
      <div class="col-md-6">
        <input #MerchantName="ngModel"
               id="MerchantName"
               type="text"
               name="MerchantName"
               class="form-control"
               placeholder="e.g. Example shop name"
               required="true"
               [disabled]="false"
               [ngClass]="{'is-valid': MerchantName.valid && MerchantName.dirty, 'is-invalid': MerchantName.invalid && MerchantName.touched}"
               [(ngModel)]="transactionInfo.MerchantName">
        <!--Error Msg-->
        <div *ngIf="MerchantName.errors && (MerchantName.dirty || MerchantName.touched)">
          <p *ngIf="MerchantName.errors.required" class="error-text medium">This field is required</p>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="MerchantCity" class="col-form-label col-md-6">
        Merchant city
      </label>
      <div class="col-md-6">
        <input #MerchantCity="ngModel"
               id="MerchantCity"
               type="text"
               name="MerchantCity"
               class="form-control"
               placeholder="e.g. Cape Town"
               required="true"
               [disabled]="false"
               [ngClass]="{'is-valid': MerchantCity.valid && MerchantCity.dirty, 'is-invalid': MerchantCity.invalid && MerchantCity.touched}"
               [(ngModel)]="transactionInfo.MerchantCity">
        <!--Error Msg-->
        <div *ngIf="MerchantCity.errors && (MerchantCity.dirty || MerchantCity.touched)">
          <p *ngIf="MerchantCity.errors.required" class="error-text medium">This field is required</p>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-6 col-form-label">
        Country code<br>
        <small><a href="javascript:void(0)" (click)="openReferenceInIde('countryCodes')">List of countries and codes</a></small>
      </label>
      <div class="col-md-6">
        <input #countryCode="ngModel"
               id="countryCode"
               type="text"
               name="countryCode"
               class="form-control"
               placeholder="ZA"
               required="true"
               [disabled]="false"
               [ngClass]="{'is-valid': countryCode.valid && countryCode.dirty, 'is-invalid': countryCode.invalid && countryCode.touched}"
               [(ngModel)]="transactionInfo.countryCode.code">
        <!--Error Msg-->
        <div *ngIf="countryCode.errors && (countryCode.dirty || countryCode.touched)">
          <p *ngIf="countryCode.errors.required" class="error-text medium">This field is required</p>
        </div>
      </div>
    </div>


    <ui-button *ngIf="!executeFunctionsCodeInProgress" [btnClass]="'secondary'" [block]="true"
               (click)="executeFunctionsCode(f)">Simulate card transaction
    </ui-button>
    <br>
    <p>View all available transaction properties via <code>console.log(authorization)</code></p>

  </div>

  <ui-bullet-list-content-loader *ngIf="executeFunctionsCodeInProgress"></ui-bullet-list-content-loader>

</form>
