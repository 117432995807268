import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ISubscription} from "rxjs-compat/Subscription";
import {TimerObservable} from "rxjs-compat/observable/TimerObservable";
import {SecondFactorInterface} from "../../../models/second-factor.interface";
import {SecondFactorService} from "../../../service/second-factor.service";
import {takeWhile} from "rxjs/operators";
import {environment} from "@env-vars";

@Component({
  selector: 'investec-online-type-inapp',
  templateUrl: './type-inapp.component.html',
  styleUrls: ['./type-inapp.component.scss']
})

export class TypeInappComponent implements OnInit, OnDestroy {

  popUpTimer = 60;
  stopTimer: ISubscription;
  activeSubscription = true;
  inappCodeVersion = 1;
  secureInappEnabled = null;
  inappCode = ""
  appDetails = {
    name: 'unknown',
    displayName: 'Investec',
    version: null,
    deviceType: 'cellphone',
    allowedVersion: null
  }
  showLoginReskin = environment.onboardingReskin.showLoginReskin;

  @Input() secondFactorData: SecondFactorInterface;
  @Output() stopSecondFactor: EventEmitter<any> = new EventEmitter();


  constructor(private _secondFactor: SecondFactorService) {}

  ngOnInit() {
    this.inappCode = this.secondFactorData.RequestId;
    this.initPopUpTimer();
    this._secondFactor.getToggledStatePromise().then( (toggled) => {
      this.secureInappEnabled = toggled;
      this.validateCode();
    }).catch( () => {
      this.secureInappEnabled = false;
    });
  }

  ngOnDestroy(){
    this.activeSubscription = false;
  }

  initPopUpTimer() {
    this.pollApi();
    this.popUpTimer = this.secondFactorData.TimeToLive;
    const popUpTimer = TimerObservable.create(1000, 1000);
    this.stopTimer = popUpTimer.subscribe(t => {
      this.popUpTimer -= 1;
      if (this.popUpTimer <= 0) {
        this.stopTimer.unsubscribe();
      }
    });
  }

  cancelInApp() {
    this.stopSecondFactor.next(false)
  }

  getFormattedAppDetails(callingapplication: string, applicationVersion: string) {

    const callingapplicationArray = callingapplication.split('-');
    let appname = '';
    let deviceType = callingapplicationArray[callingapplicationArray.length-1];
    for (let i = 0; i < callingapplicationArray.length; i++) {
      if (['ios', 'android', 'huawei'].includes(callingapplicationArray[i])) {
        break;
      } else {
        appname += (i > 0 ? '-' : '') + callingapplicationArray[i];
      }
    }

    let displayName = "Investec";
    let allowedVersion = null;
    switch(appname) {
      case 'core':
        displayName = "Core";
        allowedVersion = '1.3.0.0'
        break;
      case 'businessbanking':
        displayName = "Business Banking";
        allowedVersion = '2.21.0.0';
        break
      case 'uk-privateclient':
        displayName = "Investec UK";
        allowedVersion = '1.0.63.0';
        break
      case 'privateclient':
        displayName = "Private Client";
        allowedVersion = '3.4.0.0';
        break
      case 'youth':
        displayName = "Youth";
        break
      case 'intermediaries-online':
        displayName = "Intermediaries";
        allowedVersion = '1.11.0.0';
        break
      default:
        displayName = "Investec"
    }

    return {
      name: appname,
      displayName: displayName,
      version: applicationVersion,
      allowedVersion: allowedVersion,
      deviceType: deviceType === 'phone' ? 'cellphone' : 'tablet'
    }


  }

  validateCode(){
    const _AppDetails = this.secondFactorData.AppDetails;
    this.appDetails = this.getFormattedAppDetails(_AppDetails[3], _AppDetails[4]);

    const { version, name, allowedVersion } = this.appDetails;

    const formatVersion = (appVersion: string) => Number(appVersion.split('.').join(''));
    if ( this.secureInappEnabled && this.appDetails && formatVersion(version) >= formatVersion(allowedVersion)  ) {
      this.inappCodeVersion = 2;
      this.inappCode = String(this.secondFactorData.RequestId).slice(-4);
    } else {
      this.inappCodeVersion = 1;
      this.inappCode = this.secondFactorData.RequestId;
    }
  }

  pollApi() {
    TimerObservable.create(1000, 2000).pipe(takeWhile(()=> this.activeSubscription)).subscribe(timer => {
      this._secondFactor.pollInApp(this.secondFactorData.APP).subscribe(res => {
        if (res.Method) {
          this.activeSubscription = false;
          this._secondFactor.currentSecondFactorData.next(res)
        } else if(res.Fallback === 'OTP'){
          this.activeSubscription = false;
          this.secondFactorData.Method = 'OTP';
          this._secondFactor.currentSecondFactorData.next(this.secondFactorData)
        }

        if (res['InAppResponse'] === 'Accept') {
          this._secondFactor.secondFactorStatus.next(true);
          this.stopSecondFactor.next(true)
        } else if(res['InAppResponse'] === 'Reject'){
          this.stopSecondFactor.next(false)
        }
      }, err => {

      })
    });

  }


}
