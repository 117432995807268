import { Injectable } from '@angular/core';
import {ReplaySubject} from "rxjs";
import {SecondFactorInterface, SecondFactorObj} from "../models/second-factor.interface";
import {HttpClient} from "@angular/common/http";
import { environment } from 'environments/environment';

@Injectable({
  providedIn: "root"
})
export class SecondFactorService {

  currentSecondFactorData = new ReplaySubject<SecondFactorInterface>(1);
  secondFactorStatus = new ReplaySubject<boolean>(1);
  invokeSecondFactorModal = new ReplaySubject<boolean>(1);
  secondFactorCancelled = new ReplaySubject<boolean>(1);
  secondFactorInProgress = false;
  secureInapp = null;

  constructor(private _http: HttpClient) {
  }

  submitOtp(payload){
    return this._http.post<{Message,Result,Status}>('/proxy/auth/otp',payload)
  }

  pollInApp(id){
    return this._http.get<SecondFactorInterface>('/proxy/sso/notification/push/validate/'+id)
  }

  pollUssd(id){
    return this._http.get<SecondFactorInterface>('/proxy/auth/ussd/'+ id)
  }

  resendOtp(){
    return this._http.get('/proxy/auth/sendotp/resend')
  }

  async getToggledStatePromise(){
    const toggle = <[string]>await this._http.post('/proxy/toggles', {CategoryName: environment.platform.platformAuthenticationCategroy}).toPromise().catch( (error) => {
      throw new Error(error);
    });
    if ( toggle && toggle.includes('ea213e92266426024ecf29926fffed1e8f1019c5be4ef7975f1e9f10c949d8c9') ) {
      this.secureInapp = true;
    } else {
      this.secureInapp = false;
    }
    return this.secureInapp;
  }

}
