import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from "@angular/core";
import {produce} from "immer";

export interface ProductResponseModel {
  pcName?: string,
  pcDescription?: string,
  requiredPc?: string,
  applyOnline?: boolean,
  applyLater?: boolean,
  "W&I"?: boolean,
  channels?: Array<string>,
  productCardId?: string,
  addRem?: string,
  VERIFICATION_MESSAGE?: string
}

export interface Shopfront {
  user?: {
    name?: string,
    surname?: string,
    email?: string,
    contactNumber?: string,
    type?: string
  },
  metaData?: {
    source?: string
    campaign?: string,
    primary?: string,
    secondary?: string,
    targetMarket?: string
  },
  product?: string,
  solutions?: Array<any>,
  solutionFinderResults?:SolutionFinderInterface,
  insightsEngineProducts?: Array<ProductResponseModel>
}

export interface SolutionFinderInterface {
  name?:string,
  earnings?:string,
  goals?:any,
  age?:string,
  employmentStatus?:string,
  salariedProfession?:string,
  selfEmployedProfession?:string,
  itRelatedDegree?:string,
  salaryEmployedProfessionOther?:string,
  selfEmployedProfessionOther?:boolean,
  employmentStatusOver30?:string,
  goalsOver30?:string,
  lumpsum?: string,
  accountType?: string,
  lifeIncomeProtect?: string,
  bankingWithInvestec?: string,
  propertyOrVehicle?: string,
  annualPersonalIncome?: string,
  annualGrossIncome?: string,
  annualGrossIncomeHigh?: string
}

export interface SearchResultInterface{
  selected: boolean,
  name: string,
  description:string,
  ourApproach:Array<string>,
  details:{
    description:string,
    properties:Array<string>,
    whyInvestec: {
      quote: string,
      person: string
    },
    pdfUrl:string
  }
}

export class SetShopfront {
  static readonly type = '[Shopfront] Set';

  constructor(public payload: Shopfront) {
  }
}

export class UpdateShopfront {
  static readonly type = '[Shopfront] Update';

  constructor(public payload: Shopfront) {
  }
}

export class UpdateShopfrontSolutions {
  static readonly type = '[Shopfront] Update solutions';

  constructor(public payload: Array<SearchResultInterface>) {
  }
}

export class RemoveShopfront {
  static readonly type = '[Product] Remove';

  constructor(public payload: Shopfront) {
  }
}

export class ShopfrontStateModel {
  data: Shopfront;
}

@State<ShopfrontStateModel>({
  name: 'Shopfront',
  defaults: {data: {
      user: {name: undefined, surname: undefined, contactNumber: undefined, email: undefined, type: undefined},
      metaData: {source: undefined, campaign: undefined, primary: undefined, secondary: undefined, targetMarket: undefined},
      product: undefined,
      solutions: [],
      solutionFinderResults:{},
      insightsEngineProducts: []
    }}
})

@Injectable()

export class ShopfrontState {

  @Selector()
  static getShopfront(state: ShopfrontStateModel) {
    return state.data;
  }

  @Action(UpdateShopfront)
  updateShopfront(ctx: StateContext<ShopfrontStateModel>, {payload}: UpdateShopfront) {
    const newState = produce(ctx.getState(), draft => {
      Object.keys(payload).forEach(value => {
        if (payload[value] instanceof Array) {
          draft.data[value] = [];
          payload[value].forEach((item,index) => {
            this.updateProperties(draft.data[value], payload[value], index)
          })
        } else if (payload[value] instanceof Object) {
          draft.data[value] = {};
          Object.keys(payload[value]).forEach(key => {
            this.updateProperties(draft.data[value], payload[value], key)
          })
        } else {
          draft.data[value] = payload[value]
        }
      })
    })

    ctx.setState(newState)

  }

  @Action(UpdateShopfrontSolutions)
  updateShopfrontSolutions(ctx: StateContext<ShopfrontStateModel>, {payload}: UpdateShopfrontSolutions) {
    const newState = produce(ctx.getState(), draft => {
      payload.forEach((item) => {
        let found = false;
        draft.data.solutions.forEach((value, index) => {
          if(item.name === value.name){
            if(!item.selected){
              draft.data.solutions.splice(index,1);
            }
            found = true;
          }
        })

        if(!found && item.selected){
          draft.data.solutions.push(item)
        }
      })
    })

    ctx.setState(newState)
  }

  // @Action(RemoveShopfront)
  // removeShopfront(ctx: StateContext<ShopfrontStateModel>, {payload}: RemoveShopfront) {
  //   const id = payload.fields.filter(z => z.name === 'ID')[0].value
  //   const newState = produce(ctx.getState(),draft => {
  //     draft.Shopfront.forEach((value,key) => {
  //       for (let i = 0; i < value.fields.length; i++) {
  //         const valueElement = value.fields[i];
  //         if(valueElement.name === 'ID' && valueElement.value === id){
  //           draft.Shopfront.splice(key,1);
  //           break;
  //         }
  //       }
  //     })
  //
  //   })
  //
  //   ctx.setState(newState)
  //
  // }

  @Action(SetShopfront)
  setShopfront(ctx: StateContext<ShopfrontStateModel>, {payload}: any) {
    const newState = produce(ctx.getState(), draft => {
      draft.data = {...this.emptyShopFrontObject()}

      Object.keys(payload).forEach(value => {
        this.mutateObjectProp(value, payload[value], draft)
      })
    })

    ctx.setState(newState)
  }

  updateProperties(draft, payload, value) {
    if (payload[value] instanceof Array) {
      draft[value] = [];
      payload[value].forEach((item,index) => {
        this.updateProperties(draft[value], payload[value], index)
      })
    } else if (payload[value] instanceof Object) {
      draft[value] = {};
      Object.keys(payload[value]).forEach(key => {
        this.updateProperties(draft[value], payload[value], key)
      })
    } else {
      draft[value] = '';
      draft[value] = payload[value]
    }
  }

  emptyShopFrontObject() {
    return <Shopfront>{
      user: {name: undefined, surname: undefined, contactNumber: undefined, email: undefined, type: undefined},
      metaData: {source: undefined, campaign: undefined, primary: undefined, secondary: undefined, targetMarket: undefined},
      product: undefined,
      solutions: [],
      solutionFinderResults: {},
      insightsEngineProducts: []
    }
  }

  mutateObjectProp(prop, value, obj) {
    if (obj instanceof Object) {
      Object.keys(obj).forEach(key => {
        if (key === prop) obj[key] = value
        this.mutateObjectProp(prop, value, obj[key])
      })
    }
  }

}
