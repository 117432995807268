import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule} from '@angular/router';
import {AppPreloadingStrategy} from "./app-preloading-strategy";
import {routes} from "./routing-environments/routes";
import {UserContextResolver} from "./user-context.resolver";
import {environment} from "@env-vars";

const routerConfig: ExtraOptions = environment.development ? {
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload',
  preloadingStrategy: AppPreloadingStrategy
} : {onSameUrlNavigation: 'reload', preloadingStrategy: AppPreloadingStrategy}

@NgModule({
  imports: [
    RouterModule.forRoot(routes , routerConfig),
  ],
  exports: [RouterModule],
  providers: [UserContextResolver],
  declarations: []
})
export class AppRoutingModule {
}
