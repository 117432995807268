import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment-mini';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CloseChatConformationComponent } from './close-chat-conformation/close-chat-conformation.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { LogoutService } from '@investec-online/platform-services';


// import { PerfectScrollbarConfigInterface,
//   PerfectScrollbarComponent, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';

@Component({
  selector: 'investec-online-live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.scss']
})
export class LiveChatComponent implements OnInit {
  @Input() chatConnection: any;
  @Input() minimized: boolean;
  @Output() closedChat: EventEmitter<any> = new EventEmitter();
  @Output() minimizeClick: EventEmitter<any> = new EventEmitter();
  @Output() unreadMessage: EventEmitter<any> = new EventEmitter();
  @Output() chatStatus: EventEmitter<any> = new EventEmitter();

  // public config: PerfectScrollbarConfigInterface = {
  //   wheelSpeed: 0.3,
  //   wheelPropagation: true
  // };

  chatMessage = '';
  requestChatMsg = '';
  messagesList = [{
    message: '',
    chatTime: '',
    participant: '',
    className: ''
  }];
  apiErrorMessage = undefined;
  isTyping = false;
  agentNotAvailable = false;
  agentHasConnected = false;
  agentHasLeft = false;
  hasConnectedToAvaya = false;
  agentsInfoCall;
  preconfiguredMessages;

  constructor(private modalService: NgbModal, private _http: HttpClient, private _logoutService: LogoutService) {
  }

  ngOnInit() {
    window.sessionStorage.setItem('chatOpen', 'true');
    if (this.chatConnection.connectionState === 'Disconnected') {
      //Make agents call and wait for response
      this.getAgentsInfo().subscribe((response: any) => {
        this.apiErrorMessage = response.body.metrics.find(el => el.queueName === environment.platform.liveChatSkillset).message;
        this.agentNotAvailable = !!this.apiErrorMessage;

        //If there are agents available, start the chat
        if (!this.agentNotAvailable) {
          this.chatConnection.start().then((res) => {
            this.receiveChatHistory();
            this.receiveMessage();
          });
        } else {
          this.hasConnectedToAvaya = true;
        }
      })
    }

    this._logoutService.mustLogOut.subscribe(res => {
      this.chatStatus.next(false);
      window.sessionStorage.setItem('chatOpen', 'false');
      if (this.chatConnection.connectionState !== 'Disconnected') {
        this.chatConnection.invoke('SendMessage', JSON.stringify({
          'CloseConnection': true
        })).then(() => {
          this.chatConnection.stop();
        });
      }
    })
  }

  receiveChatHistory() {
    this.chatConnection.on('ReceiveChatHistory', (messageList) => {
      this.hasConnectedToAvaya = true;
      this.agentHasConnected = true;
      this.agentNotAvailable = false;
      this.chatStatus.next(true);

      messageList.forEach(message => {
        this.messagesList.push({
          message: this.convertEmojis(message.body),
          chatTime: moment(message.time).format('HH:mm'),
          participant: message.sender === 'Client' ? 'Me' : message.sender,
          className: `speech-bubble-live-chat speech-bubble-${message.sender === 'Client' ? 'client' : 'agent'}`
        });
      });
      this.autoScroll();
    });
  }

  receiveMessage() {
    this.chatConnection.on('ReceiveMessage', (message) => {
      if (message.type === 'notification') {
        switch (message.body.method) {
          case 'newMessage':
            if (this.minimized) {
              this.unreadMessage.next(null);
            }
            const time = moment().format('HH:mm');
            const className = 'speech-bubble-live-chat speech-bubble-agent';
            this.messagesList.push({
              message: this.convertEmojis(message.body.message),
              chatTime: time,
              participant: message.body.displayName,
              className: className
            });
            this.isTyping = false;
            this.autoScroll();
            break;

          case 'requestChat':
            this.hasConnectedToAvaya = true;
            this.preconfiguredMessages = Object.assign([], message.body.webOnHoldComfortGroups[0].messages)
            this.requestChatMsg = this.preconfiguredMessages.shift().message;

            const pleaseHoldMessageTimer = setInterval(() => {
              this.requestChatMsg = this.preconfiguredMessages.shift().message
              if (this.preconfiguredMessages.length === 0) {
                clearInterval(pleaseHoldMessageTimer);
              }
            }, message.body.webOnHoldComfortGroups[0].delay * 1000)
            break;

          case 'newParticipant':
            this.agentHasConnected = true;
            this.agentNotAvailable = false;
            this.chatStatus.next(true);
            break;

          case 'participantLeave':
            this.agentHasLeft = true;
            this.agentHasConnected = false;
            this.isTyping = false;

            this.chatStatus.next(false);
            window.sessionStorage.setItem('chatOpen', 'false');
            this.chatConnection.invoke('SendMessage', JSON.stringify({
              'CloseConnection': true
            })).then(() => {
              this.chatConnection.stop();
            });

            this.chatMessage = '';

            this.autoScroll();
            break;

          case 'isTyping':
            this.isTyping = true;
            this.autoScroll();
            break;
          default:
            break;
        }
      }
    });
  }

  minimizeLiveChat() {
    this.minimizeClick.next(null);
  }

  sendMessage() {
    if (this.chatMessage.trim() === '') {
      return;
    }
    if (this.chatConnection.connectionState === 'Connected') {
      this.chatMessage = this.convertEmojis(this.chatMessage);
      const message = JSON.stringify({
        'Message': this.chatMessage
      });
      const className = 'speech-bubble-live-chat speech-bubble-client';
      const time = moment(new Date()).format('HH:mm');
      this.messagesList.push({message: this.chatMessage, chatTime: time, participant: 'Me', className: className});
      this.chatConnection.invoke('SendMessage', message);
      this.chatMessage = '';
      this.autoScroll();
      this.isUserTyping();
    }
  }

  closeChat() {
    this.chatMessage = '';
    window.sessionStorage.setItem('chatOpen', 'false');
    this.messagesList = [];
    if (this.chatConnection.connectionState !== 'Disconnected') {
      this.chatConnection.invoke('SendMessage', JSON.stringify({
        'CloseConnection': true
      })).then(() => {
        this.chatConnection.stop();
        this.chatStatus.next(false);
        this.closedChat.next(null);
      });
    } else {
      this.chatStatus.next(false);
      this.closedChat.next(null);
    }
  }

  open() {
    const modalRef = this.modalService.open(CloseChatConformationComponent);
    modalRef.result
      .then(response => {
        if (response === 'Disconnect') {
          this.closeChat();
        }
      });
  }

  autoScroll() {
    setTimeout(() => {
      const elem = document.getElementsByClassName('chat-body')[0];
      elem.scrollTop = elem.scrollHeight - elem.clientHeight;
    }, 0);
  }

  isUserTyping() {
    if (this.chatMessage.length === 1) {
      const isTypingMessage = JSON.stringify({
        'IsTyping': true
      });
      this.chatConnection.invoke('SendMessage', isTypingMessage);
    } else if (this.chatMessage.length === 0) {
      const isTypingMessage = JSON.stringify({
        'IsTyping': false
      });
      this.chatConnection.invoke('SendMessage', isTypingMessage);
    }
  }

  getAgentsInfo() {
    return this._http.post(`/proxy/livechat/avayachat/agents`, {'skillset': environment.platform.liveChatSkillset}, {withCredentials: true})
  }

  convertEmojis(message) {
    // return message.replace('/:rofl/g', '🤣').replace('/:-\)/g', '🙂').replace('/:-D/g', '😃').replace('/(y)/g', '👍').replace('/(n)/g', '👎').replace('/:-(/g', '🙁');
    const emojiDictionary = {
      ":rofl": "🤣",
      ":-)": "🙂",
      ":-D": "😃",
      "(y)": "👍",
      "(n)": "👎",
      ":-(": "🙁"
    }
    let emojifiedMessage = message;
    for (const [key, value] of Object.entries(emojiDictionary)) {
      emojifiedMessage = this.replaceAll(emojifiedMessage, key, value)
    }

    return emojifiedMessage
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
}
