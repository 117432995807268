import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {produce} from "immer"
import { environment } from 'environments/environment';

export interface CallsignConfigInterface {
  essx?: string;
  esto?: string;
  ggow?: string;
  mosc?: string;
  mwel?: string;
  mwelsub?: string;
  mwelseq?: number;
  sanf?: string;
  time?: number;
  upfr?: boolean;
}

export interface CallsignConfigPayload {
  mwel?: string;
  mwelsub?: string;
  mwelseq?: number;
  sanf?: string;
  mosc?: string;
  upfr?: boolean;
  ggow?: string;
}

export class AddCallsignConfig {
  static readonly type = '[Callsign] AddCallsignConfig';

  constructor(public payload: CallsignConfigInterface) {}
}

export class UpdateCallsignConfig {
  static readonly type = '[Callsign] Update CallsignConfig';

  constructor(public payload: CallsignConfigPayload) {}
}

export class CallsignConfigStateModel {
  callsignConfig: CallsignConfigInterface;
}

@State<CallsignConfigStateModel>({
  name: 'Callsign',
})
@Injectable()
export class CallsignConfigState {
  callSignEnabled = environment.platform.callsign;

  createUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
      const random = (Math.random() * 16) | 0; // Nachkommastellen abschneiden
      const value = char === 'x' ? random : (random % 4) + 8; // Bei x Random 0-15 (0-F), bei y Random 0-3 + 8 = 8-11 (8-b) gemäss RFC 4122
      return value.toString(16); // Hexadezimales Zeichen zurückgeben
    });
  };

  @Selector()
  static getCallsignConfig(state: CallsignConfigStateModel) {
    return state.callsignConfig;
  }

  @Action(AddCallsignConfig)
  AddCallsignConfig({ getState, patchState }: StateContext<CallsignConfigStateModel>, { payload }: AddCallsignConfig) {
    patchState({
      callsignConfig: payload,
    });
  }

  @Action(UpdateCallsignConfig)
  UpdateCallsignConfig(ctx: StateContext<CallsignConfigStateModel>, { payload }: UpdateCallsignConfig) {
    if (this.callSignEnabled) {
      const newState = produce(ctx.getState(), (draft) => {
        if (draft === undefined || draft.callsignConfig === undefined) {
          return {
            callsignConfig: {
              essx: environment.platform.callsignConfig.essx,
              esto: environment.platform.callsignConfig.esto,
              mosc: payload.mosc,
              ggow: this.createUUID(),
              mwel: payload.mwel,
              mwelsub: payload.mwelsub,
              mwelseq: payload.mwelseq,
              sanf: payload.sanf,
              upfr: payload.upfr,
              time: Date.now(),
              ewps: true,
              loco: true,
              emxc: false,
              reed: false,
            },
          };
        } else {
          Object.keys(payload).forEach((value) => {
            if (draft.callsignConfig[value] !== payload[value]) {
              draft.callsignConfig[value] = payload[value];
              if (value === 'mwel') {
                //generate new transaction ID everytime the journey type changes
                //txn-journeyType-subPageType-subPageNumericalId-dateString
                draft.callsignConfig.ggow = `txn-${payload.mwel}-${payload.mwelsub}-${payload.mwelseq}-${Date.now()}`;
              }
            }
          });
          draft.callsignConfig.time = Date.now();
        }
      });

      sessionStorage.setItem('cx', JSON.stringify(newState.callsignConfig));
      ctx.setState(newState);
    }
  }

  stopWebSDK() {
    sessionStorage.removeItem('cx');
  }
}
