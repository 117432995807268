import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from "@angular/core";

export interface Currency {
  Code: string
  Country: string
  CountryCode: string
  CountryLongCode: string
  Description: string
  InternalIdenitifier: number
  Symbol: string
}

export interface Currencies extends Array<Currency> {
}

export class AddCurrencies {
  static readonly type = '[Currencies] Add';

  constructor(public payload: Currencies) {
  }
}

export class CurrenciesStateModel {
  currencies: Currencies;
}

@State<CurrenciesStateModel>({
  name: 'currencies'
})

@Injectable()

export class CurrenciesState {

  @Selector()
  static getCurrencies(state: CurrenciesStateModel) {
    return state.currencies;
  }

  @Action(AddCurrencies)
  add({getState, patchState}: StateContext<CurrenciesStateModel>, {payload}: AddCurrencies) {
    patchState({
      currencies: payload
    });
  }
}
