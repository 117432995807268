import { Routes } from '@angular/router';
import { UserContextResolver } from '../user-context.resolver';
import { WpaasGuard } from '@investec-online/platform-components';
import {environment} from "@env-vars";
export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../../login/login.module').then((m) => m.LoginModule),
    resolve: { context: UserContextResolver },
    data: { preload: false, delay: true, isAuthenticated: false },
  },
  {
    path: 'types',
    loadChildren: () =>
      import('../../core-modules/user-type-selector/user-type-selector.module').then((m) => m.UserTypeSelectorModule),
    canActivate: [WpaasGuard],
    data: {preload: false, delay: false}
  }, {
    path: 'home',
    loadChildren: () =>
      import('../../core-modules/platform-home/platform-home.module').then((m) => m.PlatformHomeModule),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'setup',
    loadChildren: () => environment.onboardingReskin.showOnboardingReskin
      ? import('../../core-modules/onboarding-reskin/onboarding-reskin.module').then(m => m.OnboardingReskinModule)
      : import('../../core-modules/onboarding/onboarding.module').then(m => m.OnboardingModule),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import('../../core-modules/session-elevation/session-elevation.module').then((m) => m.SessionElevationModule),
    data: { preload: false, delay: true },
  },
  {
    path: 'transaction-notification',
    loadChildren: () =>
      import('../../core-modules/transaction-notifications/transaction-notifications.module').then(
        (m) => m.TransactionNotificationsModule
      ),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'website-settings',
    loadChildren: () =>
      import('../../core-modules/website-settings/website-settings.module').then((m) => m.WebsiteSettingsModule),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'update-login-details',
    loadChildren: () =>
      import('../../core-modules/update-login-details/update-login-details.module').then(
        (m) => m.UpdateLoginDetailsModule
      ),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'security-questions',
    loadChildren: () =>
      import('../../core-modules/security-questions/security-questions.module').then((m) => m.SecurityQuestionsModule),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'investec-apps',
    loadChildren: () =>
      import('../../core-modules/investec-apps/investec-apps.module').then((m) => m.InvestecAppsModule),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'my-details',
    loadChildren: () => import('../../core-modules/my-details/my-details.module').then((m) => m.MyDetailsModule),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'logout',
    loadChildren: () => import('../../core-modules/logout/logout.module').then((m) => m.LogoutModule),
    resolve: { context: UserContextResolver },
    data: { preload: false, delay: true, isAuthenticated: false },
  },
  {
    path: 'oauth-consent',
    loadChildren: () =>
      import('../../core-modules/oauth-consent/oauth-consent.module').then((m) => m.OAuthConsentModule),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'more',
    loadChildren: () =>
      import('../../core-modules/platform-more/platform-more.module').then((m) => m.PlatformMoreModule),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'tools',
    loadChildren: () =>
      import('../../core-modules/platform-tools/platform-tools.module').then((m) => m.PlatformToolsModule),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'lifestyle',
    loadChildren: () =>
      import('../../core-modules/platform-lifestyle/platform-lifestyle.module').then((m) => m.PlatformLifestyleModule),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('../../core-modules/platform-contact-us/platform-contact-us.module').then(
        (m) => m.PlatformContactUsModule
      ),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'profile-settings',
    loadChildren: () =>
      import('../../core-modules/platform-profile-setting/platform-profile-setting.module').then(
        (m) => m.PlatformProfileSettingModule
      ),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'about-settings',
    loadChildren: () =>
      import('../../core-modules/pfm-settings/about-the-service/about.module').then((m) => m.AboutModule),
    canActivate: [WpaasGuard],
    data: {preload: false, delay: true, isAuthenticated: true}
  },
  {
    path: 'wealth-signup',
    loadChildren: () =>
      import('../../core-modules/wealth-signup/wealth-signup.module').then((m) => m.WealthSignupModule),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
  {
    path: 'profile-information',
    loadChildren: () =>
      import('../../core-modules/profile-information/profile-information.module').then(
        (m) => m.ProfileInformationModule
      ),
    canActivate: [WpaasGuard],
    data: { preload: false, delay: true, isAuthenticated: true },
  },
];
