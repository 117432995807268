import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ProgrammableCardService} from "../../../../services/programmable-card.service";
import {TransactionInterface} from "../../models/transaction.interface";
import {MerchantClass, MerchantInterface} from "../../models/merchant.class";
import {CountryCodeInterface, CountryCodes} from "../../models/country-codes";
import {ToastService} from "../../../../services/toast.service";
import {SimulatorService} from "../../../../services/simulator.service";
import {DataLayerService} from "@investec-online/platform-services";

export class ConsoleClass {
  logs: Array<any>;
  before: { time, output };
  after: { time, output };

  constructor() {
    return {
      logs: [],
      before: {
        time: 0,
        output: {}
      },
      after: {
        time: 0,
        output: {}
      }
    }
  }
}

@Component({
  selector: 'investec-online-prog-card-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss']
})

export class ProgCardSimulatorComponent implements OnInit {
  @Output() valueChange = new EventEmitter();

  public model: any;
  executeFunctionsCodeInProgress;
  transactionInfo;

  merchantOptions: Array<MerchantInterface>;
  countryCodes: Array<CountryCodeInterface>;

  output = [];
  console = new ConsoleClass();
  code: string;
  private merchantCodeIsValid = true;

  constructor(
    private simulatorService: SimulatorService,
    private _rootCard: ProgrammableCardService,
    public toastService: ToastService,
    private _analytics: DataLayerService) {
    this.merchantOptions = new MerchantClass().populateMerchantList();
    this.countryCodes = new CountryCodes().populateCountryCodeList();
    this.transactionInfo = this.simulatorService.fetch();
  }

  ngOnInit() {
    this.changeCurrency({
      Code: "ZAR",
      Country: "South Africa"
    });

    this._rootCard.rootCode.subscribe(res => {
      this.code = res;
    });
  }

  executeFunctionsCode(form) {

    this._analytics.trackData(
      'simulation transaction',
      'CTA|clicked',
      'programmable banking',
      'click',
      'loyalty',
      'flow',
      'engagement',
      'programmable banking',
      '',
      'cta'
    )

    if (form.invalid || !this.merchantCodeIsValid) {
      this.toastService.show(
        'Please complete all the input fields',
        {classname: 'bg-danger text-white'}
      );
      return;
    }
    this.executeFunctionsCodeInProgress = true;

    const beforeTransaction: TransactionInterface = {
      accountNumber: this._rootCard.selectedAccountNumber,
      type: "before_transaction",
      sandbox: true,
      simulationcode: this.code,
      authorization: {
        accountNumber: this._rootCard.selectedAccountNumber,
        dateTime: new Date(),
        centsAmount: this.transactionInfo.Amount,
        currencyCode: this.transactionInfo.activeCurrency.Code.toLowerCase(),
        type: "card",
        reference: "simulation",
        card: {
          display: this._rootCard.selectedRootCard.CardNumber,
          id: this._rootCard.selectedRootCard.CardKeyHash
        },
        merchant: {
          categoryCode: this.transactionInfo.MerchantCat.code,
          name: this.transactionInfo.MerchantName,
          city: this.transactionInfo.MerchantCity,
          countryCode: this.transactionInfo.countryCode.code
        }
      }
    };

    const afterTransaction: TransactionInterface = {
      accountNumber: this._rootCard.selectedAccountNumber,
      type: "after_transaction",
      sandbox: true,
      simulationcode: this.code,
      transaction: {
        accountNumber: this._rootCard.selectedAccountNumber,
        dateTime: new Date(),
        centsAmount: this.transactionInfo.Amount,
        currencyCode: this.transactionInfo.activeCurrency.Code.toLowerCase(),
        type: "card",
        reference: "simulation",
        card: {
          display: this._rootCard.selectedRootCard.CardNumber,
          id: this._rootCard.selectedRootCard.CardKeyHash
        },
        merchant: {
          categoryCode: this.transactionInfo.MerchantCat.code,
          name: this.transactionInfo.MerchantName,
          city: this.transactionInfo.MerchantCity,
          countryCode: this.transactionInfo.countryCode.code
        }
      }
    };

    this.executeCode(beforeTransaction).then(beforeTransactionRes => {

      const simulationResults = {
        beforeTransaction: beforeTransactionRes,
        afterTransaction: {}
      };

      this.executeFunctionsCodeInProgress = false;
      this.console.before.time = beforeTransactionRes.time;
      this.console.before.output = beforeTransactionRes.output;

      const vm = this;
      if (beforeTransactionRes.output['logs'] !== null) {
        beforeTransactionRes.output['logs'] = this.formatConsoleLogs(beforeTransactionRes.output['logs'])
      }

      if (!beforeTransactionRes.output['error']) {
        this.executeCode(afterTransaction).then(afterTransactionRes => {
          simulationResults.afterTransaction = afterTransactionRes;
          this._rootCard.simulation.next(JSON.stringify(simulationResults, null, 4));

          this.console.after.time = afterTransactionRes.time;
          this.console.after.output = afterTransactionRes.output;

          if (afterTransactionRes.output['logs'] !== null) {
            afterTransactionRes.output['logs'] = this.formatConsoleLogs(afterTransactionRes.output['logs'])
          }
        })
      }
    });

  }

  formatConsoleLogs(logs) {
    logs.forEach((value, index) => {
      try {
        logs[index] = JSON.parse(value.content);
      } catch (e) {
        // not valid json, continue
      }
    });
    return logs;
  }

  executeCode(code) {
    return new Promise<{ time, output }>((resolve, reject) => {
      this._rootCard.executeCodeSnippet(code).subscribe(res => {
        if (res['error']) {
          this.toastService.show(
            'Please verify all input values are valid',
            {classname: 'bg-danger text-white'}
          );
          this.executeFunctionsCodeInProgress = false;
        } else {
          this.output.push(res);

          const date1: string = res['createdAt'];
          const date2: string = res['completedAt'];

          const diffInMs: number = Date.parse(date2) - Date.parse(date1);

          resolve({time: diffInMs, output: res})
        }
      }, err => {
        console.log(err)
      });
    });

  }

  changeCurrency($event: any) {
    this.transactionInfo.activeCurrency = $event
  }

  openReferenceInIde(item) {
    this.valueChange.emit(item);
  }

  updateMock() {
    this.simulatorService.update(this.transactionInfo)
  }

  merchantCodeValidator(control) {
    const findCOde = this.merchantOptions.filter(t => t.code === control);
    if (!findCOde.length) {
      this.merchantCodeIsValid = false;
    } else {
      this.merchantCodeIsValid = true;
    }
  }
}
