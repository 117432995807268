import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from "@angular/core";
import {produce} from "immer";

export interface Manifest {
  id?: string,
  type?: string,
  lastUpdated?: string,
  name?: {
    short: string
    full: string
  },
  thumbnailURL?: string,
  feedbackList?: Array<Feedback>,
  ratingsList?: Array<Rating>,
  developer?: {
    name: string,
    author_id: string,
    websiteUrl: string,
    privacyUrl: string,
    termsOfUseUrl: string
  }
  description?: {
    short: string
  },
  termsConditions?: boolean,
  permissions?: Array<KeyValueObject>,
  purpose?: Array<KeyValueObject>,
  clientTypes?: Array<KeyValueObject>,
  tags?: Array<KeyValueObject>,
  developmentDomain?: string,
  domain?: string,
  versions?: Array<ManifestVersions>
}

export interface Feedback{
  fromEmail: string,
  rating: number,
  date: string,
  feedbackMessage: string
}

export interface Rating{
  rating: number,
  date: string,
  fromEmail: string,
}

export interface ManifestVersions {
  versionNumber: string,
  status: string,
  enabled: boolean,
  production: boolean,
  uploadedAssets: boolean
}

export interface KeyValueObject {
  key: string,
  value: string
}

export interface ManifestData {
  selectedManifest?: Manifest,
  launchVersion?: string
  userId?: string
  isAppActive?: boolean
}

export interface MarketPlaceApiCache {
  manifestData: ManifestData,
  apiResults: {},
  deeplinkApps: Array<{id:string,user:string}>,
}

export class UpdateMarketPlaceManifestData {
  static readonly type = '[MarketPlaceApiCache] update manifest data';

  constructor(public payload: ManifestData) {
  }
}

export class UpdateMarketPlaceApiCache {
  static readonly type = '[MarketPlaceApiCache] update api-results ';

  constructor(public payload: { apiName, dataName, data }) {
  }
}

export class AddMarketPlaceDeeplinkApps {
  static readonly type = '[MarketPlaceDeeplinkApps] add deeplink App ';

  constructor(public payload: Manifest) {
  }
}

export class RemoveMarketPlaceDeeplinkApps {
  static readonly type = '[MarketPlaceDeeplinkApps] remove deeplink Apps ';

  constructor(public payload: Manifest) {
  }
}

export class MarketPlaceApiCacheStateModel {
  MarketPlaceApiCache: MarketPlaceApiCache;
}

@State<MarketPlaceApiCacheStateModel>({
  name: 'MarketPlaceApiCache',
  defaults: {
    MarketPlaceApiCache: {
      apiResults: {},
      manifestData: {
        userId:undefined,
        launchVersion:undefined,
        selectedManifest:undefined,
        isAppActive: false
      },
      deeplinkApps:[]
    }
  }
})

@Injectable()

export class MarketPlaceApiCacheState {

  @Selector()
  static getMarketPlaceApiCache(state: MarketPlaceApiCacheStateModel) {
    return state.MarketPlaceApiCache;
  }

  @Selector()
  static getMarketPlaceManifestData(state: MarketPlaceApiCacheStateModel) {
    return state.MarketPlaceApiCache.manifestData;
  }

  @Selector()
  static getMarketPlaceDeeplinkApps(state: MarketPlaceApiCacheStateModel) {
    return state.MarketPlaceApiCache.deeplinkApps;
  }

  @Action(UpdateMarketPlaceApiCache)
  updateMarketPlaceApiCache(ctx: StateContext<MarketPlaceApiCacheStateModel>, {payload}: UpdateMarketPlaceApiCache) {
    const newState = produce(ctx.getState(), draft => {
      if (draft.MarketPlaceApiCache.apiResults[payload.apiName] === undefined) {
        draft.MarketPlaceApiCache.apiResults[payload.apiName] = {}
        draft.MarketPlaceApiCache.apiResults[payload.apiName][payload.dataName] = payload.data
      } else {
        draft.MarketPlaceApiCache.apiResults[payload.apiName][payload.dataName] = payload.data
      }
    })

    ctx.setState(newState)
  }

  @Action(UpdateMarketPlaceManifestData)
  updateMarketPlaceManifestData(ctx: StateContext<MarketPlaceApiCacheStateModel>, {payload}: UpdateMarketPlaceManifestData) {
    const newState = produce(ctx.getState(), draft => {
      if (payload.selectedManifest !== undefined) draft.MarketPlaceApiCache.manifestData.selectedManifest = payload.selectedManifest;
      if (payload.launchVersion !== undefined) draft.MarketPlaceApiCache.manifestData.launchVersion = payload.launchVersion;
      if (payload.userId !== undefined) draft.MarketPlaceApiCache.manifestData.userId = payload.userId;
      if (payload.isAppActive !== undefined) draft.MarketPlaceApiCache.manifestData.isAppActive = payload.isAppActive;
    })

    ctx.setState(newState)
  }

  @Action(AddMarketPlaceDeeplinkApps)
  addMarketPlaceDeeplinkApps(ctx: StateContext<MarketPlaceApiCacheStateModel>, {payload}: AddMarketPlaceDeeplinkApps) {
    const newState = produce(ctx.getState(), draft => {
      if(draft.MarketPlaceApiCache.deeplinkApps === undefined){
        draft.MarketPlaceApiCache.deeplinkApps = [];
      }
      draft.MarketPlaceApiCache.deeplinkApps.push({id: payload.id , user: payload.type === 'personal'? payload.developer.author_id : '000000'})
    })

    ctx.setState(newState)
  }

  @Action(RemoveMarketPlaceDeeplinkApps)
  removeMarketPlaceDeeplinkApps(ctx: StateContext<MarketPlaceApiCacheStateModel>, {payload}: RemoveMarketPlaceDeeplinkApps) {
    const newState = produce(ctx.getState(), draft => {
      draft.MarketPlaceApiCache.deeplinkApps = draft.MarketPlaceApiCache.deeplinkApps.filter(x => x.id !== payload.id);
    })
    ctx.setState(newState)
  }

}
