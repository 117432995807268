import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router"
import { DataLayerService } from "@investec-online/platform-services"

@Injectable()
export class DataLayerAnalyticsRouteResolver implements Resolve<boolean> {
  constructor(private dataLayerService: DataLayerService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const pageLoadEvent: PageLoadMeta = {
    //   type: route.data.componentName,
    //   tag: '',
    //   channel: 'SF',
    //   variant: '1',
    // }
    window.location.pathname = route.data.componentName;
    this.dataLayerService.digitalData(window, window.document, {}, new Date());
   return true
  }
}