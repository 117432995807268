import {Injectable, TemplateRef} from '@angular/core';
import {ServicesModule} from "./services.module";

@Injectable({
  providedIn: ServicesModule
})
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({textOrTpl, ...options});
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
