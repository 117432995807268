import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from "@angular/common/http";
import {InterceptorService} from "./interceptor.service";
import {PlatformServicesModule} from "@investec-online/platform-services";
import {XsrfInterceptorService} from "./xsrf-interceptor.service";

@NgModule({
  imports: [CommonModule,
    PlatformServicesModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrfToken',
      headerName: 'csrfToken'
    })],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptorService, multi: true},
  ]
})
export class GlobalInterceptorModule {}
