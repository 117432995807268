import {getActionTypeFromInstance} from '@ngxs/store';
import {tap} from 'rxjs/operators';

/*
* This plugin will
* 1. Store the state in localstorage, after every action
* 2. After page is refresed, read from localstorage data and write that into state
* */
export function persistPlugin(state, action, next) {

  // After every refresh first action fired will be @@INIT
  if (getActionTypeFromInstance(action) === '@@INIT') {

    // reading from local storage and writing into state, when app is refreshed
    const storedStateStr = localStorage.getItem('LOCALSTORAGE_APP_STATE');
    const storedState = JSON.parse(storedStateStr);
    state = {...state, ...storedState};
    return next(state, action);
  }

  return next(state, action).pipe(tap(result => {
    // following code will trigger after reducer
    // platform specific data to persist in local storage
    const platformState = {
      AuthResponse: {},
      SelectedCampaign: {},
      currencies: {},
      selectedProfile: {},
      userSettings: {},
      availableUserSettings: {},
      selectedAccount: {},
      selectedAccountSPS: {},
      UserType: {},
      ClientDetails: {},
      userFeatures: {},
      InvestecServices: {},
      businessBankingProfiles: {},
      PrivateClientAccounts: {},
      WealthAndInvestment: {},
      AuthorisationSetup: {},
      Shopfront: {},
      Menu: {},
      Portfolio: {},
      MarketPlaceApiCache:{MarketPlaceApiCache: {
          apiResults: {},
          manifestData: {
            userId:undefined,
            launchVersion:undefined,
            selectedManifest:undefined
          }
        }}
    };

    // filter out platform states
    Object.keys(result).forEach((value) => {
      if(platformState[value])
        platformState[value] = result[value];
    });

    // add shared state to local storage
    localStorage.setItem('LOCALSTORAGE_APP_STATE', JSON.stringify(platformState));
  }));
}
