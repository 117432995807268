import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from "@angular/core";

export interface InvestecService {
  KeyName: string
  ProfileName: string
  IsActive: boolean
  IsDefault: boolean
  ActiveUrl: string
  ApiPortfolioUrl: string
  NotActiveUrl: string
  NoRightsUrl: string
  MinSessionType: string
  LoginPrefEnabled: string
  ExcludeFromProfileList: boolean
  ExludeFromProfileListIntermediary: boolean
  ExternalRedirect: boolean
  InternalRedirect: boolean
  IsBusinessProfile: boolean
  IsIntermediaryProfile: boolean
}

export interface InvestecServices extends Array<InvestecService> {
}

export class AddInvestecServices {
  static readonly type = '[InvestecServices] Add';

  constructor(public payload: InvestecServices) {
  }
}

export class InvestecServicesStateModel {
  services: InvestecServices;
}

@State<InvestecServicesStateModel>({
  name: 'InvestecServices'
})

@Injectable()

export class InvestecServicesState {

  @Selector()
  static getInvestecServices(state: InvestecServicesStateModel) {
    return state.services;
  }

  @Action(AddInvestecServices)
  add({getState, patchState}: StateContext<InvestecServicesStateModel>, {payload}: AddInvestecServices) {
    patchState({
      services: payload
    });
  }
}
