import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'investec-online-close-chat-conformation',
  templateUrl: './close-chat-conformation.component.html',
  styleUrls: ['./close-chat-conformation.component.scss']
})
export class CloseChatConformationComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
