<div *ngIf="showLoginReskin">
  <div class="container d-flex align-items-center justify-content-center">
    <div class="card text-center">
      <p class="display-3 mb-4 mt-2">One time password</p>
      <p style="text-align: left">We have sent a one time pin to your number {{secondFactorData.Destination}}. Requesting a 'Resend' of your one time pin
         password will override the original one sent to you.</p>
      <div class="row mt-2">
        <div class="col-12">
          <ui-text-field
            #otpInput="ngModel"
            name="otp"
            [name]="'otp'"
            [label]="'Enter OTP'"
            [type]="'password'"
            [showLabel]="true"
            [required]="true"
            [showPasswordIcon]="true"
            [autofocus] = "true"
            [(ngModel)]="otpValue">
            ></ui-text-field>
        </div>
      </div>

      <div class="mt-1 flex align-items-center" *ngIf="otpHasError">
        <ui-icon [name]="'interface-close-circle'" [color]="'error'"></ui-icon>
        <p class="text-danger medium mb-0" style="text-align: left">You have entered your one-time password incorrectly.</p>
      </div>
      <div class="mt-1 flex align-items-center" *ngIf="otpInput.errors && (otpInput.dirty || otpInput.touched)">
        <ui-icon [name]="'interface-indicator-warning'" [color]="'error'"></ui-icon>
        <p *ngIf="otpInput.errors.required" class="text-danger medium mb-0" style="text-align: left">Please enter your OTP</p>
      </div>

      <div class="row justify-content-start mt-3">
        <div class="col-auto">
          <p (click)="resendOtp()" style="cursor: pointer; text-decoration: underline;">Resend OTP</p>
        </div>
      </div>

      <div class="row justify-content-end mt-3">
        <div class="col-auto">
          <ui-button [btnClass]="'secondary'" (click)="cancelOtp()">Cancel</ui-button>
        </div>
        <div class="col-auto">
          <ui-button [btnClass]="'primary'" (click)="submitOtp()">Submit</ui-button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!showLoginReskin">
  <div class="modal-body">
    <div class="row justify-content-center">
      <div class="col text-center">
        <img src="assets/images/icon-password.png" alt="password">
        <h6 class="font-weight-bold">One-time password sent</h6>

        <ui-alerts *ngIf="otpHasError" [alertName]="'danger'" [size]="false" [message]="'You have entered your one-time password incorrectly.'"></ui-alerts>

        <p>We have sent a one-time password to this mobile number</p>

        <form #f="ngForm">

          <div class="destination mb-1">
            <h5 class="m-0">{{secondFactorData.Destination}}</h5>
          </div>

          <p class="font-weight-bold">One-time password sent</p>

          <div class="form-group row">
            <div class="col-md-12">
              <input #otpInput="ngModel"
                     id="otpInput"
                     inputFocusAndSelect
                     (keydown.enter)="submitOtp()"
                     type="password"
                     name="otpInput"
                     class="form-control"
                     placeholder="Enter OTP"
                     [required]="true"
                     [disabled]="false"
                     [ngClass]="{'is-valid': otpInput.valid && otpInput.dirty, 'is-invalid': otpInput.invalid && otpInput.touched}"
                     [(ngModel)]="otpValue">
              <div *ngIf="otpInput.errors && (otpInput.dirty || otpInput.touched)">
                <p *ngIf="otpInput.errors.required" class="error-text medium">Please enter your OTP</p>
              </div>
            </div>
          </div>
        </form>

        <p class="mb-2">Requesting a 'Resend' of your one-time password will override the original one sent to you</p>

        <ui-button class="pr-1" [btnClass]="'secondary'" (click)="cancelOtp()">Cancel</ui-button>
        <ui-button class="pr-1" [btnClass]="'secondary'" (click)="resendOtp()">Resend</ui-button>
        <ui-button [btnClass]="'primary'" (click)="submitOtp()" [loading]="isLoading">Submit</ui-button>

      </div>
    </div>
  </div>
</div>

