import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {SecondFactorInterface} from "../../models/second-factor.interface";
import {SecondFactorService} from "../../service/second-factor.service";

@Component({
  selector: 'investec-online-txn2fa-modal',
  templateUrl: './second-factor-modal.component.html',
  styleUrls: ['./second-factor-modal.component.scss']
})
export class SecondFactorModalComponent implements OnInit {

  secondFactorData: SecondFactorInterface;
  type: string;

  constructor(private activeModal: NgbActiveModal, private _secondFactor: SecondFactorService) {
    this._secondFactor.currentSecondFactorData.subscribe(res => this.secondFactorData = res)
  }

  ngOnInit() {}

  closeModal(evt){
    this._secondFactor.secondFactorInProgress = false;
    this.activeModal.close(!evt);
    this._secondFactor.secondFactorCancelled.next(true);
  }

}
