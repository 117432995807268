import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

class WiukciAgencySelectorModel {
  public static equals(o1: WiukciIfaAgency, o2: WiukciIfaAgency | undefined): boolean {
    return !!o1 && !!o2 && o1.Code === o2.Code && o1.Description === o2.Description; // todo review comparison logic
  }

  public static getOriginalAgenciesListMock() {
    return {
      Data: [
        {
          Code: '7501',
          Description: 'Ali Nortier - Investec SA Asset Swap & Securities',
          ClientCount: 13,
          AccountCount: 13,
          CountDescription: '13 Clients',
          Balance: 39486891.8739169,
          StructureType: 'Agent',
          AccountType: null,
          LinkedMain: null,
          IfaStructure: [
            {
              Code: 'INCSC1771',
              Description: 'Adriaan Jacobus Basson Esq',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 219085.4149869,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'INCSC1771',
              IfaStructure: null,
              ValuationCurrency: 'USD',
              ValuationValue: 305613.87,
              PreferredCurrency: 'GBP',
              PreferredValue: 219085.4149869,
              BalanceBreakDown: null,
            },
            {
              Code: 'MOUSE0002',
              Description: 'Church Street Trustees Limited',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 2806396.6901355,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'MOUSE0002',
              IfaStructure: null,
              ValuationCurrency: 'USD',
              ValuationValue: 3914791.65,
              PreferredCurrency: 'GBP',
              PreferredValue: 2806396.6901355,
              BalanceBreakDown: null,
            },
            {
              Code: 'INCSC0740',
              Description: 'Imperium Trust Company Limited',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 9746997.5596609,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'INCSC0740',
              IfaStructure: null,
              ValuationCurrency: 'USD',
              ValuationValue: 13596604.07,
              PreferredCurrency: 'GBP',
              PreferredValue: 9746997.5596609,
              BalanceBreakDown: null,
            },
            {
              Code: 'INVBK0073',
              Description: 'Investec Securities Asset Swap A/C',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 492157.9870823,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'INVBK0073',
              IfaStructure: null,
              ValuationCurrency: 'USD',
              ValuationValue: 686537.29,
              PreferredCurrency: 'GBP',
              PreferredValue: 492157.9870823,
              BalanceBreakDown: null,
            },
            {
              Code: 'INCSC0358',
              Description: 'Investec Securities Asset Swap Account',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 975081.5057291,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'INCSC0358',
              IfaStructure: null,
              ValuationCurrency: 'USD',
              ValuationValue: 1360192.93,
              PreferredCurrency: 'GBP',
              PreferredValue: 975081.5057291,
              BalanceBreakDown: null,
            },
            {
              Code: 'INCSC2255',
              Description: 'ISL (Asset Swap) Acting as Nominee for',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 4360.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'INCSC2255',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 4360.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 4360.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'INCSC1135',
              Description: 'ISL (Asset Swaps) Acting as Nominee for',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 102709.5240024,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'INCSC1135',
              IfaStructure: null,
              ValuationCurrency: 'AUD',
              ValuationValue: 194573.52,
              PreferredCurrency: 'GBP',
              PreferredValue: 102709.5240024,
              BalanceBreakDown: null,
            },
            {
              Code: 'INCSC1029',
              Description: 'ISL(Asset Swaps)Acting as Nominee for Account P2 Trust',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 22508.1072769,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'INCSC1029',
              IfaStructure: null,
              ValuationCurrency: 'ZAR',
              ValuationValue: 457388.89,
              PreferredCurrency: 'GBP',
              PreferredValue: 22508.1072769,
              BalanceBreakDown: null,
            },
            {
              Code: 'INCSC2423',
              Description: 'Mr Ian George Manton Carthew-Gabriel & Mrs Lucinda Wendy Carthew-Gabriel',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 974077.7371864,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'INCSC2423',
              IfaStructure: null,
              ValuationCurrency: 'USD',
              ValuationValue: 1358792.72,
              PreferredCurrency: 'GBP',
              PreferredValue: 974077.7371864,
              BalanceBreakDown: null,
            },
            {
              Code: 'LENIS0001',
              Description: 'Mr Remo Lenisa',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 335255.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'LENIS0001',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 335255.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 335255.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'INCSC2451',
              Description: 'Mr Roger William Warren',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 497873.3478565,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'INCSC2451',
              IfaStructure: null,
              ValuationCurrency: 'USD',
              ValuationValue: 694509.95,
              PreferredCurrency: 'GBP',
              PreferredValue: 497873.3478565,
              BalanceBreakDown: null,
            },
            {
              Code: 'SOVER0038',
              Description: 'Sovereign Trust Limited',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 314830.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'SOVER0038',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 314830.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 314830.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'ZIMMO0001',
              Description: 'Zimmorn Services Inc',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 22995559.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'ZIMMO0001',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 22995559.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 22995559.0,
              BalanceBreakDown: null,
            },
          ],
          ValuationCurrency: null,
          ValuationValue: 0.0,
          PreferredCurrency: 'GBP',
          PreferredValue: 39486891.8739169,
          BalanceBreakDown: [
            { Currency: 'USD', TotalValue: 21917042.48 },
            {
              Currency: 'GBP',
              TotalValue: 23650004.0,
            },
            { Currency: 'AUD', TotalValue: 194573.52 },
            { Currency: 'ZAR', TotalValue: 457388.89 },
          ],
        },
        {
          Code: '5065',
          Description: 'D A Calder Ltd',
          ClientCount: 42,
          AccountCount: 65,
          CountDescription: '42 Clients',
          Balance: 15254407.0,
          StructureType: 'Agent',
          AccountType: null,
          LinkedMain: null,
          IfaStructure: [
            {
              Code: 'ATSIP0065',
              Description: '@SIPP (Pension Trustees) Limited',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 577126.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'ATSIP0065',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 577126.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 577126.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'SIPPC0099',
              Description: 'AJ Bell Investcentre',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 387317.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'SIPPC0099',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 387317.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 387317.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE0051',
              Description: 'D A CALDER',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 159389.0,
              StructureType: 'Client',
              AccountType: 'IHT',
              LinkedMain: 'CALDE0051',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 159389.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 159389.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'WALKE0453',
              Description: 'D A Calder (Insurance & Mortgage Broker)',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'WALKE0453',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'WALKE0558',
              Description: 'D A Calder (Insurance & Mortgage Broker)',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 2427.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'WALKE0558',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 2427.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 2427.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PROFF0036',
              Description: 'D A Calder Insurance & Mortgage Broker',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'PROFF0036',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE0050',
              Description: 'D A Calder Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'CALDE0050',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE0057',
              Description: 'D A Calder Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 113033.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'CALDE0057',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 113033.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 113033.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'MUNIC0023',
              Description: 'D A Calder Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'MUNIC0023',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PROFF0035',
              Description: 'D A Calder(Insurance & Morgage Broker)',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'PROFF0035',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE0052',
              Description: 'DA Calder Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'CALDE0052',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE0053',
              Description: 'DA Calder Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'CALDE0053',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE0060',
              Description: 'DA Calder Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 131523.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'CALDE0060',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 131523.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 131523.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'WILSO0672',
              Description: 'George Alexander Wilson Esq',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'WILSO0672',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'WILSO5004',
              Description: 'George Alexander Wilson Esq',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 13249.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'WILSO0672',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 13249.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 13249.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'IWITL0456',
              Description: 'Investec Wealth & Investment Trustees Limited',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 279797.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'IWITL0456',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 279797.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 279797.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'IWITL0582',
              Description: 'Investec Wealth & Investment Trustees Limited',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 189573.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'IWITL0582',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 189573.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 189573.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'IWITL0797',
              Description: 'Investec Wealth & Investment Trustees Limited',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 284090.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'IWITL0797',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 284090.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 284090.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'RENS 0380',
              Description: 'Investec Wealth & Investment Trustees Limited',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 553554.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'RENS 0380',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 553554.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 553554.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'RENS 0402',
              Description: 'Investec Wealth & Investment Trustees Limited',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 1462066.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'RENS 0402',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 1462066.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 1462066.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'GARTS5002',
              Description: 'James Powell Gartshore Esq',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 445416.0,
              StructureType: 'Client',
              AccountType: 'IHT2',
              LinkedMain: 'GARTS0010',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 445416.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 445416.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PROFF5006',
              Description: 'Miss Elizabeth Mitchell Allan',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 77714.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'PROFF0035',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 77714.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 77714.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'MACDO0134',
              Description: 'Miss Kathleen MacDonald',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'MACDO0134',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'MACDO5043',
              Description: 'Miss Kathleen MacDonald',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 87832.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'MACDO0134',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 87832.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 87832.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'MACLE0163',
              Description: 'Mr Alasdair Coinneach MacLean',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'MACLE0163',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'MACLE5063',
              Description: 'Mr Alasdair Coinneach MacLean',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 72075.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'MACLE0163',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 72075.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 72075.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE0058',
              Description: 'Mr Alasdair Coinneach MacLean & Mrs Kathleen MacDonald',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 219235.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'CALDE0058',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 219235.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 219235.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE5026',
              Description: 'Mr Allan Wilson',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 150288.0,
              StructureType: 'Client',
              AccountType: 'IHT3',
              LinkedMain: 'CALDE0065',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 150288.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 150288.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE0064',
              Description: 'Mr Allan Wilson & Mrs Irene Wilson',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 50928.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'CALDE0064',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 50928.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 50928.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE0065',
              Description: 'Mr Allan Wilson and Mrs Irene Wilson',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 44020.0,
              StructureType: 'Client',
              AccountType: 'IHT1',
              LinkedMain: 'CALDE0065',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 44020.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 44020.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PROFF5007',
              Description: 'Mr Anthony Charles Ogden',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 60802.0,
              StructureType: 'Client',
              AccountType: 'ISA1',
              LinkedMain: 'PROFF0036',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 60802.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 60802.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'BRYAN0086',
              Description: 'Mr Arthur W Bryan & Mrs Catherine Mackie Bryan',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 192442.0,
              StructureType: 'Client',
              AccountType: 'IHT',
              LinkedMain: 'BRYAN0086',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 192442.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 192442.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE5034',
              Description: 'Mr Gordon Barrie',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 53942.0,
              StructureType: 'Client',
              AccountType: 'ISA2',
              LinkedMain: 'CALDE0057',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 53942.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 53942.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'GARTS0010',
              Description: 'Mr James Powell Gartshore',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 369247.0,
              StructureType: 'Client',
              AccountType: 'IHT',
              LinkedMain: 'GARTS0010',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 369247.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 369247.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE5023',
              Description: 'Mr John Boyd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 119809.0,
              StructureType: 'Client',
              AccountType: 'ISA1',
              LinkedMain: 'CALDE0059',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 119809.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 119809.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE0059',
              Description: 'Mr John McCormick Boyd & Mrs Jane Boyd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 219249.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'CALDE0059',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 219249.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 219249.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE0072',
              Description: 'Mr Peter Kropholler',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 81439.0,
              StructureType: 'Client',
              AccountType: 'IHT',
              LinkedMain: 'CALDE0072',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 81439.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 81439.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE5030',
              Description: 'Mr Peter Kropholler',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 24471.0,
              StructureType: 'Client',
              AccountType: 'IHT2',
              LinkedMain: 'CALDE0072',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 24471.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 24471.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'FAITH0006',
              Description: 'Mr Thomas Faith',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 247587.0,
              StructureType: 'Client',
              AccountType: 'IHT',
              LinkedMain: 'FAITH0006',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 247587.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 247587.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'REID 0183',
              Description: 'Mr Thomas Reid',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 118065.0,
              StructureType: 'Client',
              AccountType: 'IHT',
              LinkedMain: 'REID 0183',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 118065.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 118065.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'WALKE5120',
              Description: 'Mr Thomas Stewart Walker',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 234722.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'WALKE0453',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 234722.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 234722.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE5033',
              Description: 'Mrs Aileen Collie Barrie',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 43801.0,
              StructureType: 'Client',
              AccountType: 'ISA1',
              LinkedMain: 'CALDE0057',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 43801.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 43801.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE5020',
              Description: 'Mrs Barbara Elizabeth Kropholler',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 28748.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'CALDE0053',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 28748.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 28748.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PROFF5008',
              Description: 'Mrs Barbara Ogden',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 88882.0,
              StructureType: 'Client',
              AccountType: 'ISA2',
              LinkedMain: 'PROFF0036',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 88882.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 88882.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE5021',
              Description: 'Mrs Deborah Ann Ward',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 47076.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'CALDE0052',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 47076.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 47076.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'WALKE5191',
              Description: 'Mrs Elizabeth Walker',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 129286.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'WALKE0558',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 129286.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 129286.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE5027',
              Description: 'Mrs Irene Wilson',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 80612.0,
              StructureType: 'Client',
              AccountType: 'IHT2',
              LinkedMain: 'CALDE0065',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 80612.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 80612.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE5024',
              Description: 'Mrs Jane Boyd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 117828.0,
              StructureType: 'Client',
              AccountType: 'ISA2',
              LinkedMain: 'CALDE0059',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 117828.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 117828.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE0068',
              Description: 'Mrs Joan Agnes Sim',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 207728.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'CALDE0068',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 207728.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 207728.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE5028',
              Description: 'Mrs Joan Agnes Sim',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 70787.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'CALDE0068',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 70787.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 70787.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE0074',
              Description: 'Mrs Linda Murdoch McMeeking',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'CALDE0074',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE5032',
              Description: 'Mrs Linda Murdoch McMeeking',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 22373.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'CALDE0074',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 22373.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 22373.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE5019',
              Description: 'Mrs Marion Webster',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 284975.0,
              StructureType: 'Client',
              AccountType: 'ISA1',
              LinkedMain: 'CALDE0050',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 284975.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 284975.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'CALDE5018',
              Description: 'Mrs Marion Webster',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'IHT2',
              LinkedMain: 'CALDE0051',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'MUNIC5001',
              Description: 'Ms Maria Del Carmen Munich-Matabosch',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 54629.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'MUNIC0023',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 54629.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 54629.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'AXA  0931',
              Description: 'Phoenix Wealth Trustee Services Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 1774186.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'AXA  0931',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 1774186.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 1774186.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'ROWAN0068',
              Description: 'Rowanmoor Personal Pensions Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 128024.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'ROWAN0068',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 128024.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 128024.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'SCOTT0449',
              Description: 'Scottish Widows',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 604732.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'SCOTT0449',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 604732.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 604732.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'STAND1222',
              Description: 'Standard Life',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 1701189.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'STAND1222',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 1701189.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 1701189.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'STAND1290',
              Description: 'Standard Life',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 679582.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'STAND1290',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 679582.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 679582.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'STAND1225',
              Description: 'Standard Life International Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 655677.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'STAND1225',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 655677.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 655677.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'UTMOS0039',
              Description: 'Utmost Limited',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 490024.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'UTMOS0039',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 490024.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 490024.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'UTMDL0010',
              Description: 'Utmost PanEurope dac',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 400752.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'UTMDL0010',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 400752.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 400752.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'UTMOS0042',
              Description: 'Utmost PanEurope dac',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 422428.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'UTMOS0042',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 422428.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 422428.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'UTMOS0093',
              Description: 'Utmost Wealth Solutions Limited',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 198661.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'UTMOS0093',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 198661.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 198661.0,
              BalanceBreakDown: null,
            },
          ],
          ValuationCurrency: null,
          ValuationValue: 0.0,
          PreferredCurrency: 'GBP',
          PreferredValue: 15254407.0,
          BalanceBreakDown: [{ Currency: 'GBP', TotalValue: 15254407.0 }],
        },
        {
          Code: '7684',
          Description: 'Future Asset Management LLP Re Tim Mogford',
          ClientCount: 1,
          AccountCount: 2,
          CountDescription: '1 Client',
          Balance: 141524.0,
          StructureType: 'Agent',
          AccountType: null,
          LinkedMain: null,
          IfaStructure: [
            {
              Code: 'MCGRE0043',
              Description: 'Mrs Veronica Mary McGregor',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'MCGRE0043',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'MCGRE5021',
              Description: 'Mrs Veronica Mary McGregor',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 141524.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'MCGRE0043',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 141524.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 141524.0,
              BalanceBreakDown: null,
            },
          ],
          ValuationCurrency: null,
          ValuationValue: 0.0,
          PreferredCurrency: 'GBP',
          PreferredValue: 141524.0,
          BalanceBreakDown: [{ Currency: 'GBP', TotalValue: 141524.0 }],
        },
        {
          Code: '7206',
          Description: 'Vision Independent Financial Planning Ltd Re One Vision Wealth Management Ltd Re Paul Duval',
          ClientCount: 27,
          AccountCount: 39,
          CountDescription: '27 Clients',
          Balance: 14244281.63,
          StructureType: 'Agent',
          AccountType: null,
          LinkedMain: null,
          IfaStructure: [
            {
              Code: 'PRAKA0004',
              Description: 'Jai Prakash Way FIP',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 381980.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'PRAKA0004',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 381980.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 381980.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'JAMES1246',
              Description: 'James Hay Pension Trustees Limited',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 422656.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'JAMES1246',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 422656.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 422656.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'JAMES1271',
              Description: 'James Hay Pension Trustees Limited',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 802619.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'JAMES1271',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 802619.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 802619.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'JAMES1027',
              Description: 'James Hay Pension Trustees Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 582482.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'JAMES1027',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 582482.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 582482.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'JAMES1056',
              Description: 'James Hay Pension Trustees Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 353008.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'JAMES1056',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 353008.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 353008.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'JAMES1077',
              Description: 'James Hay Pension Trustees Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 846710.63,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'JAMES1077',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 846710.63,
              PreferredCurrency: 'GBP',
              PreferredValue: 846710.63,
              BalanceBreakDown: null,
            },
            {
              Code: 'JAMES1078',
              Description: 'James Hay Pension Trustees Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 326256.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'JAMES1078',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 326256.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 326256.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'JAMES1244',
              Description: 'James Hay Pension Trustees Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 1755454.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'JAMES1244',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 1755454.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 1755454.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'JAMES1261',
              Description: 'James Hay Pension Trustees Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 142281.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'JAMES1261',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 142281.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 142281.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'JHPT 0029',
              Description: 'James Hay Pension Trustees Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 140134.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'JHPT 0029',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 140134.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 140134.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'JHPT 0034',
              Description: 'James Hay Pension Trustees Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 380262.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'JHPT 0034',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 380262.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 380262.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PANCH0009',
              Description: 'James Hay Pension Trustees Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 698784.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'PANCH0009',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 698784.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 698784.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PRAKA0001',
              Description: 'James Hay Pension Trustees Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 1557388.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'PRAKA0001',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 1557388.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 1557388.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PRAKA0002',
              Description: 'James Hay Pension Trustees Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 1578090.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'PRAKA0002',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 1578090.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 1578090.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'ROBER0863',
              Description: 'James Hay Pension Trustees Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 1077517.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'ROBER0863',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 1077517.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 1077517.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'SHARM0050',
              Description: 'James Hay Pension Trustees Ltd',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 506476.0,
              StructureType: 'Client',
              AccountType: 'SIPP',
              LinkedMain: 'SHARM0050',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 506476.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 506476.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PANCH0012',
              Description: 'Miss Devki Panchmatia',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'PANCH0012',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PANCH5011',
              Description: 'Miss Devki Panchmatia',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 16023.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'PANCH0012',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 16023.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 16023.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PANCH0011',
              Description: 'Miss Kalyani Panchmatia',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'PANCH0011',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PANCH6001',
              Description: 'Miss Kalyani Panchmatia',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 16023.0,
              StructureType: 'Client',
              AccountType: 'JISA',
              LinkedMain: 'PANCH0011',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 16023.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 16023.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PRAKA5001',
              Description: 'Mr Jai Prakash',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 333880.0,
              StructureType: 'Client',
              AccountType: 'ISA1',
              LinkedMain: 'PRAKA0003',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 333880.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 333880.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PRAKA0003',
              Description: 'Mr Jai Prakash & Mrs Sushma Prakash',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 5.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'PRAKA0003',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 5.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 5.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'EDWAR0564',
              Description: 'Mr Kenneth Edwards',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'EDWAR0564',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'EDWAR5150',
              Description: 'Mr Kenneth Edwards',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 191250.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'EDWAR0564',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 191250.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 191250.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'BRADL5040',
              Description: 'Mr Matthew Bradley',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 132227.0,
              StructureType: 'Client',
              AccountType: 'ISA1',
              LinkedMain: 'BRADL0162',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 132227.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 132227.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'BRADL0162',
              Description: 'Mr Matthew Bradley & Mrs Melissa Bradley',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 40156.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'BRADL0162',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 40156.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 40156.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'SHARM0053',
              Description: 'Mr Nikhil Sharma',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'SHARM0053',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'SHARM5025',
              Description: 'Mr Nikhil Sharma',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 143664.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'SHARM0053',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 143664.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 143664.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'SHARM0052',
              Description: 'Mr Nipun Sharma',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'SHARM0052',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'SHARM5024',
              Description: 'Mr Nipun Sharma',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 145391.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'SHARM0052',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 145391.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 145391.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'DUCK 0001',
              Description: 'Mr Philip Duck',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 63105.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'DUCK 0001',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 63105.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 63105.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'DUCK 5001',
              Description: 'Mr Philip Duck',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 242232.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'DUCK 0001',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 242232.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 242232.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PANCH0010',
              Description: 'Mr Shitul Ajitkumar Panchmatia',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 0.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'PANCH0010',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 0.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 0.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PANCH5008',
              Description: 'Mr Shitul Ajitkumar Panchmatia',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 79064.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'PANCH0010',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 79064.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 79064.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'DUCK 0002',
              Description: 'Mrs Amanda Duck',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 271048.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'DUCK 0002',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 271048.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 271048.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'DUCK 5002',
              Description: 'Mrs Amanda Duck',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 190343.0,
              StructureType: 'Client',
              AccountType: 'ISA',
              LinkedMain: 'DUCK 0002',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 190343.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 190343.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'BRADL5041',
              Description: 'Mrs Melissa Bradley',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 129956.0,
              StructureType: 'Client',
              AccountType: 'ISA2',
              LinkedMain: 'BRADL0162',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 129956.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 129956.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PRAKA5002',
              Description: 'Mrs Sushma Prakash',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 315837.0,
              StructureType: 'Client',
              AccountType: 'ISA2',
              LinkedMain: 'PRAKA0003',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 315837.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 315837.0,
              BalanceBreakDown: null,
            },
            {
              Code: 'PRAKA0005',
              Description: 'Sushma Prakash Way FIP',
              ClientCount: 1,
              AccountCount: 1,
              CountDescription: null,
              Balance: 381980.0,
              StructureType: 'Client',
              AccountType: 'Main',
              LinkedMain: 'PRAKA0005',
              IfaStructure: null,
              ValuationCurrency: 'GBP',
              ValuationValue: 381980.0,
              PreferredCurrency: 'GBP',
              PreferredValue: 381980.0,
              BalanceBreakDown: null,
            },
          ],
          ValuationCurrency: null,
          ValuationValue: 0.0,
          PreferredCurrency: 'GBP',
          PreferredValue: 14244281.63,
          BalanceBreakDown: [{ Currency: 'GBP', TotalValue: 14244281.63 }],
        },
      ],
      Totals: [
        { Currency: 'USD', TotalValue: 21917042.48 },
        {
          Currency: 'GBP',
          TotalValue: 53290216.63,
        },
        { Currency: 'AUD', TotalValue: 194573.52 },
        { Currency: 'ZAR', TotalValue: 457388.89 },
      ],
      FirstClient: 'INCSC1771',
    };
  }

  public static getParsedAgenciesListMock(): Array<WiukciIfaAgency> {
    return [
      {
        Code: '7501',
        Description: 'Ixf Qybvspk - Uikgqfsr BN Rigga Huvx & Jjpqwokopc',
        ClientCount: 13,
        AccountCount: 13,
        CountDescription: '13 Clients',
        Balance: 36464352.72221955,
        StructureType: 'Agent',
        AccountType: null,
        LinkedMain: null,
        IfaStructure: [
          {
            Code: 'INCSC1771',
            Description: 'Absdljt Juecppq Ijooou Whh',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 235437.674925,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'INCSC1771',
            IfaStructure: null,
            ValuationCurrency: 'USD',
            ValuationValue: 323625.67,
            PreferredCurrency: 'GBP',
            PreferredValue: 235437.674925,
            BalanceBreakDown: null,
          },
          {
            Code: 'MOUSE0002',
            Description: 'Gutrzq Dczfnv Zkyzxmen Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 2142079.219725,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'MOUSE0002',
            IfaStructure: null,
            ValuationCurrency: 'USD',
            ValuationValue: 2944438.79,
            PreferredCurrency: 'GBP',
            PreferredValue: 2142079.219725,
            BalanceBreakDown: null,
          },
          {
            Code: 'INCSC0740',
            Description: 'Mpfjfvkz Rysso Qkuufci Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 9191693.907,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'INCSC0740',
            IfaStructure: null,
            ValuationCurrency: 'USD',
            ValuationValue: 12634630.8,
            PreferredCurrency: 'GBP',
            PreferredValue: 9191693.907,
            BalanceBreakDown: null,
          },
          {
            Code: 'INCSC2423',
            Description: 'Mr Jad Ywvztx Tmcvrc Fhkmsjk-Lorxhto & Mrs Xaeqfwb Zzlok Fhkmsjk-Lorxhto',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 933089.892225,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'INCSC2423',
            IfaStructure: null,
            ValuationCurrency: 'USD',
            ValuationValue: 1282597.79,
            PreferredCurrency: 'GBP',
            PreferredValue: 933089.892225,
            BalanceBreakDown: null,
          },
          {
            Code: 'INCSC2451',
            Description: 'Mr Lziox Wdgzmaf Naldbe',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 475866.14535,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'INCSC2451',
            IfaStructure: null,
            ValuationCurrency: 'USD',
            ValuationValue: 654111.54,
            PreferredCurrency: 'GBP',
            PreferredValue: 475866.14535,
            BalanceBreakDown: null,
          },
          {
            Code: 'LENIS0001',
            Description: 'Mr Vgxk Oshovl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 315471,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'LENIS0001',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 315471,
            PreferredCurrency: 'GBP',
            PreferredValue: 315471,
            BalanceBreakDown: null,
          },
          {
            Code: 'ZIMMO0001',
            Description: 'Trsedhu Elqbtpae Der',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 21287337,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ZIMMO0001',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 21287337,
            PreferredCurrency: 'GBP',
            PreferredValue: 21287337,
            BalanceBreakDown: null,
          },
          {
            Code: 'SOVER0038',
            Description: 'Tzbwxriyy Rysso Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 300643,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'SOVER0038',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 300643,
            PreferredCurrency: 'GBP',
            PreferredValue: 300643,
            BalanceBreakDown: null,
          },
          {
            Code: 'INVBK0073',
            Description: 'Uikgqfsr Jjpqwokopc Rigga Huvx B/A',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 511588.17045,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'INVBK0073',
            IfaStructure: null,
            ValuationCurrency: 'USD',
            ValuationValue: 703213.98,
            PreferredCurrency: 'GBP',
            PreferredValue: 511588.17045,
            BalanceBreakDown: null,
          },
          {
            Code: 'INCSC0358',
            Description: 'Uikgqfsr Jjpqwokopc Rigga Huvx Phjseiw',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 933570.82065,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'INCSC0358',
            IfaStructure: null,
            ValuationCurrency: 'USD',
            ValuationValue: 1283258.86,
            PreferredCurrency: 'GBP',
            PreferredValue: 933570.82065,
            BalanceBreakDown: null,
          },
          {
            Code: 'INCSC1135',
            Description: 'ZEP (Rigga Gdabg) Mxrqsr ty Vbweexx mls',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 109212.7818695,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'INCSC1135',
            IfaStructure: null,
            ValuationCurrency: 'AUD',
            ValuationValue: 196655.77,
            PreferredCurrency: 'GBP',
            PreferredValue: 109212.7818695,
            BalanceBreakDown: null,
          },
          {
            Code: 'INCSC2255',
            Description: 'ZEP (Rigga Huvx) Mxrqsr ty Vbweexx mls',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 4087,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'INCSC2255',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 4087,
            PreferredCurrency: 'GBP',
            PreferredValue: 4087,
            BalanceBreakDown: null,
          },
          {
            Code: 'INCSC1029',
            Description: 'ZEP(Rigga Gdabg)Mxrqsr ty Vbweexx mls Phjseiw O2 Rysso',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 24276.11002505,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'INCSC1029',
            IfaStructure: null,
            ValuationCurrency: 'ZAR',
            ValuationValue: 486934.31,
            PreferredCurrency: 'GBP',
            PreferredValue: 24276.11002505,
            BalanceBreakDown: null,
          },
        ],
        ValuationCurrency: null,
        ValuationValue: 0,
        PreferredCurrency: 'GBP',
        PreferredValue: 36464352.72221955,
        BalanceBreakDown: [
          { Currency: 'USD', TotalValue: 19825877.43 },
          {
            Currency: 'GBP',
            TotalValue: 21907538,
          },
          { Currency: 'AUD', TotalValue: 196655.77 },
          { Currency: 'ZAR', TotalValue: 486934.31 },
        ],
      },
      {
        Code: '9503',
        Description: 'Uou Y/O S Gwqfxq/A Xhfp',
        ClientCount: 0,
        AccountCount: 0,
        CountDescription: '0 Clients',
        Balance: 0,
        StructureType: 'Agent',
        AccountType: null,
        LinkedMain: null,
        IfaStructure: [],
        ValuationCurrency: null,
        ValuationValue: 0,
        PreferredCurrency: 'GBP',
        PreferredValue: 0,
        BalanceBreakDown: null,
      },
      {
        Code: '5065',
        Description: 'D B Qgzjhj Ihd',
        ClientCount: 42,
        AccountCount: 65,
        CountDescription: '42 Clients',
        Balance: 14638572,
        StructureType: 'Agent',
        AccountType: null,
        LinkedMain: null,
        IfaStructure: [
          {
            Code: 'ATSIP0065',
            Description: '@HNAU (Gsrzafh Zkyzxmen) Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 557362,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'ATSIP0065',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 557362,
            PreferredCurrency: 'GBP',
            PreferredValue: 557362,
            BalanceBreakDown: null,
          },
          {
            Code: 'GARTS5002',
            Description: 'Cvkty Illmvn Iofqqqsrq Whh',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 417564,
            StructureType: 'Client',
            AccountType: 'IHT2',
            LinkedMain: 'GARTS0010',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 417564,
            PreferredCurrency: 'GBP',
            PreferredValue: 417564,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE0051',
            Description: 'D B QGZJHJ',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 149038,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'CALDE0051',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 149038,
            PreferredCurrency: 'GBP',
            PreferredValue: 149038,
            BalanceBreakDown: null,
          },
          {
            Code: 'WALKE0453',
            Description: 'D B Qgzjhj (Eggudbkbb & Cnkoocma Gpbuzc)',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'WALKE0453',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'WALKE0558',
            Description: 'D B Qgzjhj (Eggudbkbb & Cnkoocma Gpbuzc)',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 2427,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'WALKE0558',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 2427,
            PreferredCurrency: 'GBP',
            PreferredValue: 2427,
            BalanceBreakDown: null,
          },
          {
            Code: 'PROFF0036',
            Description: 'D B Qgzjhj Eggudbkbb & Cnkoocma Gpbuzc',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'PROFF0036',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE0050',
            Description: 'D B Qgzjhj Ihd',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'CALDE0050',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE0057',
            Description: 'D B Qgzjhj Ihd',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 109166,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'CALDE0057',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 109166,
            PreferredCurrency: 'GBP',
            PreferredValue: 109166,
            BalanceBreakDown: null,
          },
          {
            Code: 'MUNIC0023',
            Description: 'D B Qgzjhj Ihd',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'MUNIC0023',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'PROFF0035',
            Description: 'D B Qgzjhj(Eggudbkbb & Owjblog Gpbuzc)',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'PROFF0035',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ROWAN0068',
            Description: 'Imopvfbav Bnqxiump Nuqmexap Ihd',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 122376,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'ROWAN0068',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 122376,
            PreferredCurrency: 'GBP',
            PreferredValue: 122376,
            BalanceBreakDown: null,
          },
          {
            Code: 'SCOTT0449',
            Description: 'Kcxizsnf Rphsqh',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 586207,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'SCOTT0449',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 586207,
            PreferredCurrency: 'GBP',
            PreferredValue: 586207,
            BalanceBreakDown: null,
          },
          {
            Code: 'AXA 0931',
            Description: 'Lqjwbxr Rmlcub Xnunjjo Elqbtpae Ihd',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 1693548,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'AXA 0931',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 1693548,
            PreferredCurrency: 'GBP',
            PreferredValue: 1693548,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE0052',
            Description: 'LR Qgzjhj Ihd',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'CALDE0052',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE0053',
            Description: 'LR Qgzjhj Ihd',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'CALDE0053',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE0060',
            Description: 'LR Qgzjhj Ihd',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 128238,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'CALDE0060',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 128238,
            PreferredCurrency: 'GBP',
            PreferredValue: 128238,
            BalanceBreakDown: null,
          },
          {
            Code: 'MACDO0134',
            Description: 'Miss Ucsiasti EdvModlhx',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'MACDO0134',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'MACDO5043',
            Description: 'Miss Ucsiasti EdvModlhx',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 84030,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'MACDO0134',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 84030,
            PreferredCurrency: 'GBP',
            PreferredValue: 84030,
            BalanceBreakDown: null,
          },
          {
            Code: 'PROFF5006',
            Description: 'Miss Ushcbhdjy Eiqigihj Ngpyq',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 75899,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'PROFF0035',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 75899,
            PreferredCurrency: 'GBP',
            PreferredValue: 75899,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE5034',
            Description: 'Mr Bhlfjt Cdndvx',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 52628,
            StructureType: 'Client',
            AccountType: 'ISA2',
            LinkedMain: 'CALDE0057',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 52628,
            PreferredCurrency: 'GBP',
            PreferredValue: 52628,
            BalanceBreakDown: null,
          },
          {
            Code: 'MACLE0163',
            Description: 'Mr Blzazpnh Axsvvghsf RrlMdkx',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'MACLE0163',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'MACLE5063',
            Description: 'Mr Blzazpnh Axsvvghsf RrlMdkx',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 71668,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'MACLE0163',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 71668,
            PreferredCurrency: 'GBP',
            PreferredValue: 71668,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE0058',
            Description: 'Mr Blzazpnh Axsvvghsf RrlMdkx & Mrs Ucsiasti EdvModlhx',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 211875,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'CALDE0058',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 211875,
            PreferredCurrency: 'GBP',
            PreferredValue: 211875,
            BalanceBreakDown: null,
          },
          {
            Code: 'GARTS0010',
            Description: 'Mr Cvkty Illmvn Iofqqqsrq',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 344037,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'GARTS0010',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 344037,
            PreferredCurrency: 'GBP',
            PreferredValue: 344037,
            BalanceBreakDown: null,
          },
          {
            Code: 'BRYAN0086',
            Description: 'Mr Gopejh F Mzbbq & Mrs Whvjzxxnp Dazixw Mzbbq',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 178597,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'BRYAN0086',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 178597,
            PreferredCurrency: 'GBP',
            PreferredValue: 178597,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE5026',
            Description: 'Mr Ngpyq Uxqsdn',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 133244,
            StructureType: 'Client',
            AccountType: 'IHT3',
            LinkedMain: 'CALDE0065',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 133244,
            PreferredCurrency: 'GBP',
            PreferredValue: 133244,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE0064',
            Description: 'Mr Ngpyq Uxqsdn & Mrs Zxire Uxqsdn',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 49470,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'CALDE0064',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 49470,
            PreferredCurrency: 'GBP',
            PreferredValue: 49470,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE0065',
            Description: 'Mr Ngpyq Uxqsdn yno Mrs Zxire Uxqsdn',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 41057,
            StructureType: 'Client',
            AccountType: 'IHT1',
            LinkedMain: 'CALDE0065',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 41057,
            PreferredCurrency: 'GBP',
            PreferredValue: 41057,
            BalanceBreakDown: null,
          },
          {
            Code: 'PROFF5007',
            Description: 'Mr Qlgtmhu Qaffeno Kxkwu',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 59293,
            StructureType: 'Client',
            AccountType: 'ISA1',
            LinkedMain: 'PROFF0036',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 59293,
            PreferredCurrency: 'GBP',
            PreferredValue: 59293,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE5023',
            Description: 'Mr Vqyu Ejeg',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 119162,
            StructureType: 'Client',
            AccountType: 'ISA1',
            LinkedMain: 'CALDE0059',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 119162,
            PreferredCurrency: 'GBP',
            PreferredValue: 119162,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE0059',
            Description: 'Mr Vqyu TKRZBYQMC Ejeg & Mrs Kxyd Ejeg',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 224721,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'CALDE0059',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 224721,
            PreferredCurrency: 'GBP',
            PreferredValue: 224721,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE0072',
            Description: 'Mr Ykuiy Owpmddenfk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 74681,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'CALDE0072',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 74681,
            PreferredCurrency: 'GBP',
            PreferredValue: 74681,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE5030',
            Description: 'Mr Ykuiy Owpmddenfk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 23732,
            StructureType: 'Client',
            AccountType: 'IHT2',
            LinkedMain: 'CALDE0072',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 23732,
            PreferredCurrency: 'GBP',
            PreferredValue: 23732,
            BalanceBreakDown: null,
          },
          {
            Code: 'FAITH0006',
            Description: 'Mr Yvvzoy Fooau',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 227985,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'FAITH0006',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 227985,
            PreferredCurrency: 'GBP',
            PreferredValue: 227985,
            BalanceBreakDown: null,
          },
          {
            Code: 'REID 0183',
            Description: 'Mr Yvvzoy Idmw',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 110500,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'REID 0183',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 110500,
            PreferredCurrency: 'GBP',
            PreferredValue: 110500,
            BalanceBreakDown: null,
          },
          {
            Code: 'WALKE5120',
            Description: 'Mr Yvvzoy Uqerybf Wfzyua',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 229448,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'WALKE0453',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 229448,
            PreferredCurrency: 'GBP',
            PreferredValue: 229448,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE5019',
            Description: 'Mrs Bypltk Kqoywal',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 276261,
            StructureType: 'Client',
            AccountType: 'ISA1',
            LinkedMain: 'CALDE0050',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 276261,
            PreferredCurrency: 'GBP',
            PreferredValue: 276261,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE5018',
            Description: 'Mrs Bypltk Kqoywal',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'IHT2',
            LinkedMain: 'CALDE0051',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE0074',
            Description: 'Mrs Cqnnf Ovdpfso SRWZAEQAG',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'CALDE0074',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE5032',
            Description: 'Mrs Cqnnf Ovdpfso SRWZAEQAG',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 21534,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'CALDE0074',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 21534,
            PreferredCurrency: 'GBP',
            PreferredValue: 21534,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE5033',
            Description: 'Mrs Izhgqa Qgggiz Cdndvx',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 42399,
            StructureType: 'Client',
            AccountType: 'ISA1',
            LinkedMain: 'CALDE0057',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 42399,
            PreferredCurrency: 'GBP',
            PreferredValue: 42399,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE5024',
            Description: 'Mrs Kxyd Ejeg',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 115398,
            StructureType: 'Client',
            AccountType: 'ISA2',
            LinkedMain: 'CALDE0059',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 115398,
            PreferredCurrency: 'GBP',
            PreferredValue: 115398,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE0068',
            Description: 'Mrs Ncci Rslyy Por',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 202825,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'CALDE0068',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 202825,
            PreferredCurrency: 'GBP',
            PreferredValue: 202825,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE5028',
            Description: 'Mrs Ncci Rslyy Por',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 69175,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'CALDE0068',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 69175,
            PreferredCurrency: 'GBP',
            PreferredValue: 69175,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE5021',
            Description: 'Mrs Tgjhnsy Tza Znkq',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 45461,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'CALDE0052',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 45461,
            PreferredCurrency: 'GBP',
            PreferredValue: 45461,
            BalanceBreakDown: null,
          },
          {
            Code: 'WALKE5191',
            Description: 'Mrs Ushcbhdjy Wfzyua',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 121889,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'WALKE0558',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 121889,
            PreferredCurrency: 'GBP',
            PreferredValue: 121889,
            BalanceBreakDown: null,
          },
          {
            Code: 'PROFF5008',
            Description: 'Mrs Wicsetu Kxkwu',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 84632,
            StructureType: 'Client',
            AccountType: 'ISA2',
            LinkedMain: 'PROFF0036',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 84632,
            PreferredCurrency: 'GBP',
            PreferredValue: 84632,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE5020',
            Description: 'Mrs Wicsetu Ushcbhdjy Owpmddenfk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 28069,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'CALDE0053',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 28069,
            PreferredCurrency: 'GBP',
            PreferredValue: 28069,
            BalanceBreakDown: null,
          },
          {
            Code: 'CALDE5027',
            Description: 'Mrs Zxire Uxqsdn',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 74135,
            StructureType: 'Client',
            AccountType: 'IHT2',
            LinkedMain: 'CALDE0065',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 74135,
            PreferredCurrency: 'GBP',
            PreferredValue: 74135,
            BalanceBreakDown: null,
          },
          {
            Code: 'MUNIC5001',
            Description: 'Ms Uysgg Mcq Djcysm Yhdljo-Gleapvvas',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 53710,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'MUNIC0023',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 53710,
            PreferredCurrency: 'GBP',
            PreferredValue: 53710,
            BalanceBreakDown: null,
          },
          {
            Code: 'IWITL0456',
            Description: 'Uikgqfsr Rmlcub & Hrwmyofgrj Zkyzxmen Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 267642,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'IWITL0456',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 267642,
            PreferredCurrency: 'GBP',
            PreferredValue: 267642,
            BalanceBreakDown: null,
          },
          {
            Code: 'IWITL0582',
            Description: 'Uikgqfsr Rmlcub & Hrwmyofgrj Zkyzxmen Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 182785,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'IWITL0582',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 182785,
            PreferredCurrency: 'GBP',
            PreferredValue: 182785,
            BalanceBreakDown: null,
          },
          {
            Code: 'IWITL0797',
            Description: 'Uikgqfsr Rmlcub & Hrwmyofgrj Zkyzxmen Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 276052,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'IWITL0797',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 276052,
            PreferredCurrency: 'GBP',
            PreferredValue: 276052,
            BalanceBreakDown: null,
          },
          {
            Code: 'RENS 0380',
            Description: 'Uikgqfsr Rmlcub & Hrwmyofgrj Zkyzxmen Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 511040,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'RENS 0380',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 511040,
            PreferredCurrency: 'GBP',
            PreferredValue: 511040,
            BalanceBreakDown: null,
          },
          {
            Code: 'RENS 0402',
            Description: 'Uikgqfsr Rmlcub & Hrwmyofgrj Zkyzxmen Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 1365343,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'RENS 0402',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 1365343,
            PreferredCurrency: 'GBP',
            PreferredValue: 1365343,
            BalanceBreakDown: null,
          },
          {
            Code: 'STAND1222',
            Description: 'Vppudaoy Lsit',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 1642596,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'STAND1222',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 1642596,
            PreferredCurrency: 'GBP',
            PreferredValue: 1642596,
            BalanceBreakDown: null,
          },
          {
            Code: 'STAND1290',
            Description: 'Vppudaoy Lsit',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 681042,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'STAND1290',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 681042,
            PreferredCurrency: 'GBP',
            PreferredValue: 681042,
            BalanceBreakDown: null,
          },
          {
            Code: 'STAND1225',
            Description: 'Vppudaoy Lsit Yipfecnbtzxrj Ihd',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 638384,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'STAND1225',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 638384,
            PreferredCurrency: 'GBP',
            PreferredValue: 638384,
            BalanceBreakDown: null,
          },
          {
            Code: 'UTMOS0039',
            Description: 'Xstfdx Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 471417,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'UTMOS0039',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 471417,
            PreferredCurrency: 'GBP',
            PreferredValue: 471417,
            BalanceBreakDown: null,
          },
          {
            Code: 'UTMOS0093',
            Description: 'Xstfdx Rmlcub Ngtcendhl Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 194221,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'UTMOS0093',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 194221,
            PreferredCurrency: 'GBP',
            PreferredValue: 194221,
            BalanceBreakDown: null,
          },
          {
            Code: 'UTMDL0010',
            Description: 'Xstfdx WqhWchnpb mms',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 389124,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'UTMDL0010',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 389124,
            PreferredCurrency: 'GBP',
            PreferredValue: 389124,
            BalanceBreakDown: null,
          },
          {
            Code: 'UTMOS0042',
            Description: 'Xstfdx WqhWchnpb mms',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 415880,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'UTMOS0042',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 415880,
            PreferredCurrency: 'GBP',
            PreferredValue: 415880,
            BalanceBreakDown: null,
          },
          {
            Code: 'SIPPC0099',
            Description: 'YC Krqe Ytzfwrfgmqvt',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 376628,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'SIPPC0099',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 376628,
            PreferredCurrency: 'GBP',
            PreferredValue: 376628,
            BalanceBreakDown: null,
          },
          {
            Code: 'WILSO0672',
            Description: 'Ywvztx Piqqbbpaf Uxqsdn Whh',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'WILSO0672',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'WILSO5004',
            Description: 'Ywvztx Piqqbbpaf Uxqsdn Whh',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 12977,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'WILSO0672',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 12977,
            PreferredCurrency: 'GBP',
            PreferredValue: 12977,
            BalanceBreakDown: null,
          },
        ],
        ValuationCurrency: null,
        ValuationValue: 0,
        PreferredCurrency: 'GBP',
        PreferredValue: 14638572,
        BalanceBreakDown: [{ Currency: 'GBP', TotalValue: 14638572 }],
      },
      {
        Code: '1410',
        Description: 'Hiqmxcf Zjicqfwia Elqbtpae Gnyykxl',
        ClientCount: 52,
        AccountCount: 81,
        CountDescription: '52 Clients',
        Balance: 19041835,
        StructureType: 'Agent',
        AccountType: null,
        LinkedMain: null,
        IfaStructure: [
          {
            Code: 'ALLOT0080',
            Description: 'Cidctf Cfbdh Vqyu Xenpsiku',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0080',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT6005',
            Description: 'Cidctf Cfbdh Vqyu Xenpsiku',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 27138,
            StructureType: 'Client',
            AccountType: 'JISA',
            LinkedMain: 'ALLOT0080',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 27138,
            PreferredCurrency: 'GBP',
            PreferredValue: 27138,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0083',
            Description: 'Cidctf Igqjq Byjuxf Qviffpc',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0083',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT6006',
            Description: 'Cidctf Igqjq Byjuxf Qviffpc',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 20291,
            StructureType: 'Client',
            AccountType: 'JISA',
            LinkedMain: 'ALLOT0083',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 20291,
            PreferredCurrency: 'GBP',
            PreferredValue: 20291,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0102',
            Description: 'Cidctf Tdpt Piqqbbpaf Qviffpc',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0102',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT6008',
            Description: 'Cidctf Tdpt Piqqbbpaf Qviffpc',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 4334,
            StructureType: 'Client',
            AccountType: 'JISA',
            LinkedMain: 'ALLOT0102',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 4334,
            PreferredCurrency: 'GBP',
            PreferredValue: 4334,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0084',
            Description: 'Cidctf Ztrimb Tgnubk Xenpsiku',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0084',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT6007',
            Description: 'Cidctf Ztrimb Tgnubk Xenpsiku',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 20249,
            StructureType: 'Client',
            AccountType: 'JISA',
            LinkedMain: 'ALLOT0084',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 20249,
            PreferredCurrency: 'GBP',
            PreferredValue: 20249,
            BalanceBreakDown: null,
          },
          {
            Code: 'SCOTT0586',
            Description: 'DV687 Lsit Eggudbkbb Qkuufci Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 295736,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'SCOTT0586',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 295736,
            PreferredCurrency: 'GBP',
            PreferredValue: 295736,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0012',
            Description: 'Hiqmxcf',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0012',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0025',
            Description: 'Hiqmxcf',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 360700,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0025',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 360700,
            PreferredCurrency: 'GBP',
            PreferredValue: 360700,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0028',
            Description: 'Hiqmxcf',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 154231,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0028',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 154231,
            PreferredCurrency: 'GBP',
            PreferredValue: 154231,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0032',
            Description: 'Hiqmxcf Poxjvsqrc Deftwltfomp',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 32426,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0032',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 32426,
            PreferredCurrency: 'GBP',
            PreferredValue: 32426,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0033',
            Description: 'Hiqmxcf Poxjvsqrc Deftwltfomp',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 669477,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0033',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 669477,
            PreferredCurrency: 'GBP',
            PreferredValue: 669477,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0034',
            Description: 'Hiqmxcf Poxjvsqrc Deftwltfomp',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 234061,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'ALLOT0034',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 234061,
            PreferredCurrency: 'GBP',
            PreferredValue: 234061,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0048',
            Description: 'Hiqmxcf Zjicqfwia Elqbtpae',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 416872,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0048',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 416872,
            PreferredCurrency: 'GBP',
            PreferredValue: 416872,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0071',
            Description: 'Hiqmxcf Zjicqfwia Elqbtpae',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0071',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0072',
            Description: 'Hiqmxcf Zjicqfwia Elqbtpae',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 644,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0072',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 644,
            PreferredCurrency: 'GBP',
            PreferredValue: 644,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0036',
            Description: 'Hiqmxcf Zjicqfwia Elqbtpae Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 167095,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'ALLOT0036',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 167095,
            PreferredCurrency: 'GBP',
            PreferredValue: 167095,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0056',
            Description: 'Hiqmxcf Zjicqfwia Elqbtpae Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0056',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0057',
            Description: 'Hiqmxcf Zjicqfwia Elqbtpae Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0057',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0058',
            Description: 'Hiqmxcf Zjicqfwia Elqbtpae Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0058',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0060',
            Description: 'Hiqmxcf Zjicqfwia Elqbtpae Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 149982,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'ALLOT0060',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 149982,
            PreferredCurrency: 'GBP',
            PreferredValue: 149982,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0076',
            Description: 'Hiqmxcf Zjicqfwia Elqbtpae Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 233833,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'ALLOT0076',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 233833,
            PreferredCurrency: 'GBP',
            PreferredValue: 233833,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0078',
            Description: 'Hiqmxcf Zjicqfwia Elqbtpae Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 187983,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'ALLOT0078',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 187983,
            PreferredCurrency: 'GBP',
            PreferredValue: 187983,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0059',
            Description: 'Hiqmxcf Zjicqfwia Elqbtpae Ihd',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0059',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0067',
            Description: 'Hiqmxcf Zjicqfwia Elqbtpae Ihd',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 226281,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'ALLOT0067',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 226281,
            PreferredCurrency: 'GBP',
            PreferredValue: 226281,
            BalanceBreakDown: null,
          },
          {
            Code: 'SCOTT0678',
            Description: 'Kcxizsnf Rphsqh Gnyykxl pf Mr Uxtrjx Axzq (Jfjfqg Jn 49195159)',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 127606,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'SCOTT0678',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 127606,
            PreferredCurrency: 'GBP',
            PreferredValue: 127606,
            BalanceBreakDown: null,
          },
          {
            Code: 'SCOTT0679',
            Description: 'Kcxizsnf Rphsqh Gnyykxl pf Mr Uxtrjx Axzq (Jfjfqg Jn 58109690)',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 609993,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'SCOTT0679',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 609993,
            PreferredCurrency: 'GBP',
            PreferredValue: 609993,
            BalanceBreakDown: null,
          },
          {
            Code: 'SCOTT0756',
            Description: 'Kcxizsnf Rphsqh Gnyykxl Pf Mr Yavwwd Bmmteqdit (Jfjfqg Jn 60682030)',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 519503,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'SCOTT0756',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 519503,
            PreferredCurrency: 'GBP',
            PreferredValue: 519503,
            BalanceBreakDown: null,
          },
          {
            Code: 'SCOTT0760',
            Description: 'Kcxizsnf Rphsqh Gnyykxl pf Mr Yavwwd Bmmteqdit (Jfjfqg Jn 72586622)',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'SCOTT0760',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0049',
            Description: 'Lhtyva Poxjvsqrc Deftwltfomp',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 169358,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'ALLOT0049',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 169358,
            PreferredCurrency: 'GBP',
            PreferredValue: 169358,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0073',
            Description: 'Lhtyva Zjicqfwia Elqbtpae',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 242601,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0073',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 242601,
            PreferredCurrency: 'GBP',
            PreferredValue: 242601,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0095',
            Description: 'Miss Avuhe Kxyd Iusazjwet',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 568422,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0095',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 568422,
            PreferredCurrency: 'GBP',
            PreferredValue: 568422,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5030',
            Description: 'Miss Avuhe Kxyd Iusazjwet',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 284289,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0095',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 284289,
            PreferredCurrency: 'GBP',
            PreferredValue: 284289,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0079',
            Description: 'Miss Lqeniy Jaystj Xenpsiku',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0079',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT6004',
            Description: 'Miss Lqeniy Jaystj Xenpsiku',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 28459,
            StructureType: 'Client',
            AccountType: 'JISA',
            LinkedMain: 'ALLOT0079',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 28459,
            PreferredCurrency: 'GBP',
            PreferredValue: 28459,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5010',
            Description: 'Miss Szlry Jaystj Olrmg',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 245931,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0048',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 245931,
            PreferredCurrency: 'GBP',
            PreferredValue: 245931,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5011',
            Description: 'Mr Cnavxiv Lruubj Hcwg',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 87982,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0057',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 87982,
            PreferredCurrency: 'GBP',
            PreferredValue: 87982,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5023',
            Description: 'Mr Cxtfcf Xvjdfdr',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 362890,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0073',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 362890,
            PreferredCurrency: 'GBP',
            PreferredValue: 362890,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0081',
            Description: 'Mr Lxafj Gopejh Ugda & Mrs Swwjci Rygl Ugda',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 113283,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'ALLOT0081',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 113283,
            PreferredCurrency: 'GBP',
            PreferredValue: 113283,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5036',
            Description: 'Mr Lziox Wdgzmaf Nmeiabb Deceased',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0103',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0098',
            Description: 'Mr Nmotwdu Ccz',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 967633,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0098',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 967633,
            PreferredCurrency: 'GBP',
            PreferredValue: 967633,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5032',
            Description: 'Mr Nmotwdu Ccz',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 103558,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0098',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 103558,
            PreferredCurrency: 'GBP',
            PreferredValue: 103558,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0097',
            Description: 'Mr Rmlup Aizfxbr',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'ALLOT0097',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5031',
            Description: 'Mr Rmlup Aizfxbr',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 12215,
            StructureType: 'Client',
            AccountType: 'IHT2',
            LinkedMain: 'ALLOT0097',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 12215,
            PreferredCurrency: 'GBP',
            PreferredValue: 12215,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0087',
            Description: 'Mr Rmlup Pzfhkh Fobmk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 1129226,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'ALLOT0087',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 1129226,
            PreferredCurrency: 'GBP',
            PreferredValue: 1129226,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0090',
            Description: 'Mr Uxtrjx Axzq',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 495,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0090',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 495,
            PreferredCurrency: 'GBP',
            PreferredValue: 495,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5017',
            Description: 'Mr Uxtrjx Axzq',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 221452,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0090',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 221452,
            PreferredCurrency: 'GBP',
            PreferredValue: 221452,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5012',
            Description: 'Mr Xidlrf Wdgzmaf Pucudwe',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 13653,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0058',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 13653,
            PreferredCurrency: 'GBP',
            PreferredValue: 13653,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5021',
            Description: 'Mr Yavwwd Bmmteqdit',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 35279,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0071',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 35279,
            PreferredCurrency: 'GBP',
            PreferredValue: 35279,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0086',
            Description: 'Mr Yavwwd Wmiv & Mrs Rygl Wmiv',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 101979,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'ALLOT0086',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 101979,
            PreferredCurrency: 'GBP',
            PreferredValue: 101979,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5009',
            Description: 'Mr Yoorogvocun Rltemqfri Wupdr',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 19750,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0032',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 19750,
            PreferredCurrency: 'GBP',
            PreferredValue: 19750,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5001',
            Description: 'Mrs Awgzkp Jaystj Qviffpc',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 503069,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0025',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 503069,
            PreferredCurrency: 'GBP',
            PreferredValue: 503069,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5014',
            Description: 'Mrs Dtmwoz Btirb Pucudwe',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 57013,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0059',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 57013,
            PreferredCurrency: 'GBP',
            PreferredValue: 57013,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0096',
            Description: 'Mrs Grza Gcvnso Ccz',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 1274616,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0096',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 1274616,
            PreferredCurrency: 'GBP',
            PreferredValue: 1274616,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5029',
            Description: 'Mrs Grza Gcvnso Ccz',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 237158,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0096',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 237158,
            PreferredCurrency: 'GBP',
            PreferredValue: 237158,
            BalanceBreakDown: null,
          },
          {
            Code: 'PUGH 5009',
            Description: 'Mrs Hhdiiauh Vyeg',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 141455,
            StructureType: 'Client',
            AccountType: 'ISA2',
            LinkedMain: 'ALLOT0012',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 141455,
            PreferredCurrency: 'GBP',
            PreferredValue: 141455,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5004',
            Description: 'Mrs Kmfj Ybvrvrfb Olrmg',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 302168,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0028',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 302168,
            PreferredCurrency: 'GBP',
            PreferredValue: 302168,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0100',
            Description: 'Mrs Kxyd Nmeiabb',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 27886,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0100',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 27886,
            PreferredCurrency: 'GBP',
            PreferredValue: 27886,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5034',
            Description: 'Mrs Kxyd Nmeiabb',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 215844,
            StructureType: 'Client',
            AccountType: 'ISA1',
            LinkedMain: 'ALLOT0100',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 215844,
            PreferredCurrency: 'GBP',
            PreferredValue: 215844,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5026',
            Description: 'Mrs Pprfo Agoevfc Slbkzkt',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 163389,
            StructureType: 'Client',
            AccountType: 'IHT2',
            LinkedMain: 'ALLOT0067',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 163389,
            PreferredCurrency: 'GBP',
            PreferredValue: 163389,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5013',
            Description: 'Mrs Suyje Zpk Hcwg',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 203411,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0056',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 203411,
            PreferredCurrency: 'GBP',
            PreferredValue: 203411,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5022',
            Description: 'Mrs Tgqrbqo Bmmteqdit',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 31449,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0072',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 31449,
            PreferredCurrency: 'GBP',
            PreferredValue: 31449,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5020',
            Description: 'Mrs Tjwx Ushcbhdjy Jizilk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 360856,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0093',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 360856,
            PreferredCurrency: 'GBP',
            PreferredValue: 360856,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0093',
            Description: 'Mrs Tjwx Xhqsiaol Jizilk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0093',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'HOLME0144',
            Description: 'Mrs Ushcbhdjy Tjwx Lnqfpa Tzvefw',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 647700,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'HOLME0144',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 647700,
            PreferredCurrency: 'GBP',
            PreferredValue: 647700,
            BalanceBreakDown: null,
          },
          {
            Code: 'HOLME0217',
            Description: 'Mrs Ushcbhdjy Tjwx Lnqfpa Tzvefw',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'HOLME0217',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'HOLME5037',
            Description: 'Mrs Ushcbhdjy Tjwx Lnqfpa Tzvefw',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 274446,
            StructureType: 'Client',
            AccountType: 'IHT2',
            LinkedMain: 'HOLME0217',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 274446,
            PreferredCurrency: 'GBP',
            PreferredValue: 274446,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0101',
            Description: 'Mrs Uysgg Ottcnmf Qlkcoh',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 202570,
            StructureType: 'Client',
            AccountType: 'IHT',
            LinkedMain: 'ALLOT0101',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 202570,
            PreferredCurrency: 'GBP',
            PreferredValue: 202570,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT5008',
            Description: 'Ms Ihlvwqcvg Exbx Jizilk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 310042,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ALLOT0033',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 310042,
            PreferredCurrency: 'GBP',
            PreferredValue: 310042,
            BalanceBreakDown: null,
          },
          {
            Code: 'ALLOT0103',
            Description: 'Mudhqhhtz if Mr Lziox Wdgzmaf Nmeiabb Deceased',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ALLOT0103',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'PUGH 5008',
            Description: 'Wvblb Wdgzmaf Vyeg Whh',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 169597,
            StructureType: 'Client',
            AccountType: 'ISA1',
            LinkedMain: 'ALLOT0012',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 169597,
            PreferredCurrency: 'GBP',
            PreferredValue: 169597,
            BalanceBreakDown: null,
          },
          {
            Code: 'AXA 0647',
            Description: 'Xstfdx Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 413799,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'AXA 0647',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 413799,
            PreferredCurrency: 'GBP',
            PreferredValue: 413799,
            BalanceBreakDown: null,
          },
          {
            Code: 'AXA 0667',
            Description: 'Xstfdx Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 164481,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'AXA 0667',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 164481,
            PreferredCurrency: 'GBP',
            PreferredValue: 164481,
            BalanceBreakDown: null,
          },
          {
            Code: 'AJBEL0882',
            Description: 'YC Krqe Ytzfwrfgmqvt',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 1778121,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'AJBEL0882',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 1778121,
            PreferredCurrency: 'GBP',
            PreferredValue: 1778121,
            BalanceBreakDown: null,
          },
          {
            Code: 'AJBEL0943',
            Description: 'YC Krqe Ytzfwrfgmqvt',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'AJBEL0943',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'AJBEL0983',
            Description: 'YC Krqe Ytzfwrfgmqvt',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'AJBEL0983',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'SIPPC0075',
            Description: 'YC Krqe Ytzfwrfgmqvt',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 1373855,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'SIPPC0075',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 1373855,
            PreferredCurrency: 'GBP',
            PreferredValue: 1373855,
            BalanceBreakDown: null,
          },
          {
            Code: 'SIPPC0147',
            Description: 'YC Krqe Ytzfwrfgmqvt',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 520453,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'SIPPC0147',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 520453,
            PreferredCurrency: 'GBP',
            PreferredValue: 520453,
            BalanceBreakDown: null,
          },
          {
            Code: 'SIPPC0148',
            Description: 'YC Krqe Ytzfwrfgmqvt',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 501567,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'SIPPC0148',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 501567,
            PreferredCurrency: 'GBP',
            PreferredValue: 501567,
            BalanceBreakDown: null,
          },
        ],
        ValuationCurrency: null,
        ValuationValue: 0,
        PreferredCurrency: 'GBP',
        PreferredValue: 19041835,
        BalanceBreakDown: [{ Currency: 'GBP', TotalValue: 19041835 }],
      },
      {
        Code: '1893',
        Description: 'Nusytufa Rigga Prdqkswvij Ihd Pf NP & Zjicqfwia',
        ClientCount: 0,
        AccountCount: 0,
        CountDescription: '0 Clients',
        Balance: 0,
        StructureType: 'Agent',
        AccountType: null,
        LinkedMain: null,
        IfaStructure: [],
        ValuationCurrency: null,
        ValuationValue: 0,
        PreferredCurrency: 'GBP',
        PreferredValue: 0,
        BalanceBreakDown: null,
      },
      {
        Code: '6120',
        Description: 'Repleirp Cvkty QCS Pf Gmjp Tmckqae',
        ClientCount: 42,
        AccountCount: 79,
        CountDescription: '42 Clients',
        Balance: 16144339,
        StructureType: 'Agent',
        AccountType: null,
        LinkedMain: null,
        IfaStructure: [
          {
            Code: 'SUMME0059',
            Description: 'Asljyyh Jtogidp Qwutdeg Whh',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'SUMME0059',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'SUMME5011',
            Description: 'Asljyyh Jtogidp Qwutdeg Whh',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 70109,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'SUMME0059',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 70109,
            PreferredCurrency: 'GBP',
            PreferredValue: 70109,
            BalanceBreakDown: null,
          },
          {
            Code: 'HARRI1031',
            Description:
              'Fyk Wdgzmaf Iprgjwp Lsiqabq & Frewwz Mxvck Zufpmuic ty Ohxtdpat mls Wdgzmaf Kneo Mxvck Zufpmuic',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 990088,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'HARRI1031',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 990088,
            PreferredCurrency: 'GBP',
            PreferredValue: 990088,
            BalanceBreakDown: null,
          },
          {
            Code: 'PONTI0001',
            Description: 'Fyk Wdgzmaf Iprgjwp Lsiqabq Whh',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'PONTI0001',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'PONTI5001',
            Description: 'Fyk Wdgzmaf Iprgjwp Lsiqabq Whh',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 38336,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'PONTI0001',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 38336,
            PreferredCurrency: 'GBP',
            PreferredValue: 38336,
            BalanceBreakDown: null,
          },
          {
            Code: 'JEVON0004',
            Description: 'Khsxxmwuyy Yipfecnbtzxrj',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 492904,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'JEVON0004',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 492904,
            PreferredCurrency: 'GBP',
            PreferredValue: 492904,
            BalanceBreakDown: null,
          },
          {
            Code: 'ILLSL0001',
            Description: 'Miss Hbtjzrxc Tjwx Rexdgma',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ILLSL0001',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ILLSL5001',
            Description: 'Miss Hbtjzrxc Tjwx Rexdgma',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 216855,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ILLSL0001',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 216855,
            PreferredCurrency: 'GBP',
            PreferredValue: 216855,
            BalanceBreakDown: null,
          },
          {
            Code: 'GWILT0003',
            Description: 'Miss Pdjfkn Vdfstuk Rsepk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'GWILT0003',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'GWILT5003',
            Description: 'Miss Pdjfkn Vdfstuk Rsepk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 8106,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'GWILT0003',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 8106,
            PreferredCurrency: 'GBP',
            PreferredValue: 8106,
            BalanceBreakDown: null,
          },
          {
            Code: 'WILKI0298',
            Description: 'Mr Cpoisyn S Bmmteqdit',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'WILKI0298',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'WILKI5063',
            Description: 'Mr Cpoisyn Yvvzoy Bmmteqdit',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 126556,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'WILKI0298',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 126556,
            PreferredCurrency: 'GBP',
            PreferredValue: 126556,
            BalanceBreakDown: null,
          },
          {
            Code: 'CLIBB0002',
            Description: 'Mr Drjiqb Asljyyh & Mrs Mdrc Yllayzi',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'CLIBB0002',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'CLIBB5001',
            Description: 'Mr Drjiqb Asljyyh Yllayzi',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 283496,
            StructureType: 'Client',
            AccountType: 'ISA1',
            LinkedMain: 'CLIBB0002',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 283496,
            PreferredCurrency: 'GBP',
            PreferredValue: 283496,
            BalanceBreakDown: null,
          },
          {
            Code: 'HULSE5003',
            Description: 'Mr Eizudbk Sypyo',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 85373,
            StructureType: 'Client',
            AccountType: 'ISA2',
            LinkedMain: 'HULSE0006',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 85373,
            PreferredCurrency: 'GBP',
            PreferredValue: 85373,
            BalanceBreakDown: null,
          },
          {
            Code: 'HULSE0006',
            Description: 'Mr Eizudbk Sypyo & Mrs Ybvrvrfb Mlzuy MgBjhht Sypyo',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'HULSE0006',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'JEVON0003',
            Description: 'Mr Fyk Wdgzmaf Iprgjwp Lsiqabq Dxvtqm dm Mrs Lcnpbxba Kvwsm',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 883206,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'JEVON0003',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 883206,
            PreferredCurrency: 'GBP',
            PreferredValue: 883206,
            BalanceBreakDown: null,
          },
          {
            Code: 'VALLA0013',
            Description: 'Mr Fyk Wdgzmaf Iprgjwp Lsiqabq ty Dxvtqm mls Mr Tbkr Uqirms Xrkxcbbg',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 396307,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'VALLA0013',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 396307,
            PreferredCurrency: 'GBP',
            PreferredValue: 396307,
            BalanceBreakDown: null,
          },
          {
            Code: 'OGORM5003',
            Description: 'Mr Kneo Vqyu APwlnqn',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 65436,
            StructureType: 'Client',
            AccountType: 'ISA2',
            LinkedMain: 'OGORM0002',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 65436,
            PreferredCurrency: 'GBP',
            PreferredValue: 65436,
            BalanceBreakDown: null,
          },
          {
            Code: 'VALLA5003',
            Description: 'Mr Tbkr Uqirms Xrkxcbbg',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 46651,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'VALLA0013',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 46651,
            PreferredCurrency: 'GBP',
            PreferredValue: 46651,
            BalanceBreakDown: null,
          },
          {
            Code: 'CLIBB0004',
            Description: 'Mr Uqirms Yllayzi',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 7159,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'CLIBB0004',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 7159,
            PreferredCurrency: 'GBP',
            PreferredValue: 7159,
            BalanceBreakDown: null,
          },
          {
            Code: 'CLIBB5003',
            Description: 'Mr Uqirms Yllayzi',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 76728,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'CLIBB0004',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 76728,
            PreferredCurrency: 'GBP',
            PreferredValue: 76728,
            BalanceBreakDown: null,
          },
          {
            Code: 'COLE 5024',
            Description: 'Mr Vqyu Yzosxc Sfym',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 81875,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'COLE 0105',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 81875,
            PreferredCurrency: 'GBP',
            PreferredValue: 81875,
            BalanceBreakDown: null,
          },
          {
            Code: 'DICKI0160',
            Description: 'Mr Vragu Dvsptgjs Lpnstmakk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'DICKI0160',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'DICKI5029',
            Description: 'Mr Vragu Dvsptgjs Lpnstmakk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 132542,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'DICKI0160',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 132542,
            PreferredCurrency: 'GBP',
            PreferredValue: 132542,
            BalanceBreakDown: null,
          },
          {
            Code: 'HARRI5336',
            Description: 'Mr Wdgzmaf Kneo Mxvck Zufpmuic',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 42853,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'HARRI1031',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 42853,
            PreferredCurrency: 'GBP',
            PreferredValue: 42853,
            BalanceBreakDown: null,
          },
          {
            Code: 'DEAN 0068',
            Description: 'Mrs Awgzkp Tza Fciu',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 39969,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'DEAN 0068',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 39969,
            PreferredCurrency: 'GBP',
            PreferredValue: 39969,
            BalanceBreakDown: null,
          },
          {
            Code: 'DEAN 5022',
            Description: 'Mrs Awgzkp Tza Fciu',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 217879,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'DEAN 0068',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 217879,
            PreferredCurrency: 'GBP',
            PreferredValue: 217879,
            BalanceBreakDown: null,
          },
          {
            Code: 'PONTI0002',
            Description: 'Mrs Cahvwp Ushcbhdjy Lsiqabq',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'PONTI0002',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'PONTI5002',
            Description: 'Mrs Cahvwp Ushcbhdjy Lsiqabq',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 84720,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'PONTI0002',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 84720,
            PreferredCurrency: 'GBP',
            PreferredValue: 84720,
            BalanceBreakDown: null,
          },
          {
            Code: 'SUMME0058',
            Description: 'Mrs Dowmskzy Kxyd Qwutdeg',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'SUMME0058',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'SUMME5010',
            Description: 'Mrs Dowmskzy Kxyd Qwutdeg',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 174359,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'SUMME0058',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 174359,
            PreferredCurrency: 'GBP',
            PreferredValue: 174359,
            BalanceBreakDown: null,
          },
          {
            Code: 'HOLDE0091',
            Description: 'Mrs Enpia Tarqma Gfijiq',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 62763,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'HOLDE0091',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 62763,
            PreferredCurrency: 'GBP',
            PreferredValue: 62763,
            BalanceBreakDown: null,
          },
          {
            Code: 'HOLDE5032',
            Description: 'Mrs Enpia Tarqma Gfijiq',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 162384,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'HOLDE0091',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 162384,
            PreferredCurrency: 'GBP',
            PreferredValue: 162384,
            BalanceBreakDown: null,
          },
          {
            Code: 'EVISO0006',
            Description: 'Mrs Exbx Qzwkgvnth Hlialo',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 1392080,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'EVISO0006',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 1392080,
            PreferredCurrency: 'GBP',
            PreferredValue: 1392080,
            BalanceBreakDown: null,
          },
          {
            Code: 'EVISO5003',
            Description: 'Mrs Exbx Qzwkgvnth Hlialo',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 213731,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'EVISO0006',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 213731,
            PreferredCurrency: 'GBP',
            PreferredValue: 213731,
            BalanceBreakDown: null,
          },
          {
            Code: 'OGORM0002',
            Description: 'Mrs Jgldrie Hcs & Kneo Vqyu APwlnqn',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'OGORM0002',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'OGORM5002',
            Description: 'Mrs Jgldrie Hcs APwlnqn',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 56825,
            StructureType: 'Client',
            AccountType: 'ISA1',
            LinkedMain: 'OGORM0002',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 56825,
            PreferredCurrency: 'GBP',
            PreferredValue: 56825,
            BalanceBreakDown: null,
          },
          {
            Code: 'DICKI0159',
            Description: 'Mrs Jkxiyh Cndhzdia Lpnstmakk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'DICKI0159',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'DICKI5028',
            Description: 'Mrs Jkxiyh Cndhzdia Lpnstmakk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 126589,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'DICKI0159',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 126589,
            PreferredCurrency: 'GBP',
            PreferredValue: 126589,
            BalanceBreakDown: null,
          },
          {
            Code: 'WILKI0299',
            Description: 'Mrs Kcbq Bmmteqdit',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'WILKI0299',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'WILKI5064',
            Description: 'Mrs Kcbq Bmmteqdit',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 95796,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'WILKI0299',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 95796,
            PreferredCurrency: 'GBP',
            PreferredValue: 95796,
            BalanceBreakDown: null,
          },
          {
            Code: 'GWILT0002',
            Description: 'Mrs Kobz Rsepk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 576091,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'GWILT0002',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 576091,
            PreferredCurrency: 'GBP',
            PreferredValue: 576091,
            BalanceBreakDown: null,
          },
          {
            Code: 'GWILT5002',
            Description: 'Mrs Kobz Rsepk',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 479052,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'GWILT0002',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 479052,
            PreferredCurrency: 'GBP',
            PreferredValue: 479052,
            BalanceBreakDown: null,
          },
          {
            Code: 'LEE 0457',
            Description: 'Mrs Kxyd Elzzzobib Tsznpp Bfq',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 340687,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'LEE 0457',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 340687,
            PreferredCurrency: 'GBP',
            PreferredValue: 340687,
            BalanceBreakDown: null,
          },
          {
            Code: 'LEE 5099',
            Description: 'Mrs Kxyd Elzzzobib Tsznpp Bfq',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 147896,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'LEE 0457',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 147896,
            PreferredCurrency: 'GBP',
            PreferredValue: 147896,
            BalanceBreakDown: null,
          },
          {
            Code: 'JEVON5001',
            Description: 'Mrs Lcnpbxba Kvwsm',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 212772,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'JEVON0003',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 212772,
            PreferredCurrency: 'GBP',
            PreferredValue: 212772,
            BalanceBreakDown: null,
          },
          {
            Code: 'CLIBB5002',
            Description: 'Mrs Mdrc Yllayzi',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 225160,
            StructureType: 'Client',
            AccountType: 'ISA2',
            LinkedMain: 'CLIBB0002',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 225160,
            PreferredCurrency: 'GBP',
            PreferredValue: 225160,
            BalanceBreakDown: null,
          },
          {
            Code: 'BYRNE0029',
            Description: 'Mrs Mkpbh Cqnnf Wjeic',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 100,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'BYRNE0029',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 100,
            PreferredCurrency: 'GBP',
            PreferredValue: 100,
            BalanceBreakDown: null,
          },
          {
            Code: 'BYRNE5008',
            Description: 'Mrs Mkpbh Cqnnf Wjeic',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 207,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'BYRNE0029',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 207,
            PreferredCurrency: 'GBP',
            PreferredValue: 207,
            BalanceBreakDown: null,
          },
          {
            Code: 'MORGA0459',
            Description: 'Mrs Msxfrrlv Rygl Qqnuux-Eovcn',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 157725,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'MORGA0459',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 157725,
            PreferredCurrency: 'GBP',
            PreferredValue: 157725,
            BalanceBreakDown: null,
          },
          {
            Code: 'MORGA5057',
            Description: 'Mrs Msxfrrlv Rygl Qqnuux-Eovcn',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 418427,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'MORGA0459',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 418427,
            PreferredCurrency: 'GBP',
            PreferredValue: 418427,
            BalanceBreakDown: null,
          },
          {
            Code: 'MORRI0560',
            Description: 'Mrs Qjukv Tjwx Jticwv',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'MORRI0560',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'MORRI5092',
            Description: 'Mrs Qjukv Tjwx Jticwv',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 142208,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'MORRI0560',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 142208,
            PreferredCurrency: 'GBP',
            PreferredValue: 142208,
            BalanceBreakDown: null,
          },
          {
            Code: 'HOPKI0110',
            Description: 'Mrs Rpufncl Msxfrrlv Epqtmfy',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 48006,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'HOPKI0110',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 48006,
            PreferredCurrency: 'GBP',
            PreferredValue: 48006,
            BalanceBreakDown: null,
          },
          {
            Code: 'HOPKI5029',
            Description: 'Mrs Rpufncl Msxfrrlv Epqtmfy',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 694779,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'HOPKI0110',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 694779,
            PreferredCurrency: 'GBP',
            PreferredValue: 694779,
            BalanceBreakDown: null,
          },
          {
            Code: 'DAVIE0759',
            Description: 'Mrs Rpufncl Nxouap',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'DAVIE0759',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'DAVIE5126',
            Description: 'Mrs Rpufncl Nxouap',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 149677,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'DAVIE0759',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 149677,
            PreferredCurrency: 'GBP',
            PreferredValue: 149677,
            BalanceBreakDown: null,
          },
          {
            Code: 'COLE 0106',
            Description: 'Mrs Vhqfjzc Sfym',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'COLE 0106',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'COLE 5025',
            Description: 'Mrs Vhqfjzc Sfym',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 95724,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'COLE 0106',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 95724,
            PreferredCurrency: 'GBP',
            PreferredValue: 95724,
            BalanceBreakDown: null,
          },
          {
            Code: 'HULSE5002',
            Description: 'Mrs Ybvrvrfb Mlzuy MgBjhht Sypyo',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 202249,
            StructureType: 'Client',
            AccountType: 'ISA1',
            LinkedMain: 'HULSE0006',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 202249,
            PreferredCurrency: 'GBP',
            PreferredValue: 202249,
            BalanceBreakDown: null,
          },
          {
            Code: 'CARR 1066',
            Description: 'Mrs Zhmwv Msxfrrlv Ndgl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'CARR 1066',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'CARR 5040',
            Description: 'Mrs Zhmwv Msxfrrlv Ndgl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 3269,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'CARR 1066',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 3269,
            PreferredCurrency: 'GBP',
            PreferredValue: 3269,
            BalanceBreakDown: null,
          },
          {
            Code: 'ROWLE0034',
            Description: 'Mrs Zzlok Mkpbh Hztjov',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'ROWLE0034',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'ROWLE5011',
            Description: 'Mrs Zzlok Mkpbh Hztjov',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 155137,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'ROWLE0034',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 155137,
            PreferredCurrency: 'GBP',
            PreferredValue: 155137,
            BalanceBreakDown: null,
          },
          {
            Code: 'MOTT 0009',
            Description: 'Ms Avuhe Lnkenjh Sqwn',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'MOTT 0009',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'MOTT 5005',
            Description: 'Ms Avuhe Lnkenjh Sqwn',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 258795,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'MOTT 0009',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 258795,
            PreferredCurrency: 'GBP',
            PreferredValue: 258795,
            BalanceBreakDown: null,
          },
          {
            Code: 'HOPKI0109',
            Description: 'Rmlup Tmcktm Epqtmfy Whh',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 130646,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'HOPKI0109',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 130646,
            PreferredCurrency: 'GBP',
            PreferredValue: 130646,
            BalanceBreakDown: null,
          },
          {
            Code: 'HOPKI5030',
            Description: 'Rmlup Tmcktm Epqtmfy Whh',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 685618,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'HOPKI0109',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 685618,
            PreferredCurrency: 'GBP',
            PreferredValue: 685618,
            BalanceBreakDown: null,
          },
          {
            Code: 'RENS 0970',
            Description: 'Uikgqfsr Rmlcub & Hrwmyofgrj Zkyzxmen Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 505890,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'RENS 0970',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 505890,
            PreferredCurrency: 'GBP',
            PreferredValue: 505890,
            BalanceBreakDown: null,
          },
          {
            Code: 'RENS 1066',
            Description: 'Uikgqfsr Rmlcub & Hrwmyofgrj Zkyzxmen Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 322896,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'RENS 1066',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 322896,
            PreferredCurrency: 'GBP',
            PreferredValue: 322896,
            BalanceBreakDown: null,
          },
          {
            Code: 'RENS 1075',
            Description: 'Uikgqfsr Rmlcub & Hrwmyofgrj Zkyzxmen Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 189244,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'RENS 1075',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 189244,
            PreferredCurrency: 'GBP',
            PreferredValue: 189244,
            BalanceBreakDown: null,
          },
          {
            Code: 'RENS 1099',
            Description: 'Uikgqfsr Rmlcub & Hrwmyofgrj Zkyzxmen Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 553898,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'RENS 1099',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 553898,
            PreferredCurrency: 'GBP',
            PreferredValue: 553898,
            BalanceBreakDown: null,
          },
          {
            Code: 'RENS 1104',
            Description: 'Uikgqfsr Rmlcub & Hrwmyofgrj Zkyzxmen Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 254820,
            StructureType: 'Client',
            AccountType: 'SIPP',
            LinkedMain: 'RENS 1104',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 254820,
            PreferredCurrency: 'GBP',
            PreferredValue: 254820,
            BalanceBreakDown: null,
          },
          {
            Code: 'OLDMU0078',
            Description: 'Uqdcbsh Yipfecnbtzxrj Rzzv if Zfd Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 2112458,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'OLDMU0078',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 2112458,
            PreferredCurrency: 'GBP',
            PreferredValue: 2112458,
            BalanceBreakDown: null,
          },
          {
            Code: 'COLE 0105',
            Description: 'Vqyu Yzosxc Sfym Whh',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'COLE 0105',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'FEDER0005',
            Description: 'Wivheruwq Vsmh Jigtfvy Euidy',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 0,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'FEDER0005',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 0,
            PreferredCurrency: 'GBP',
            PreferredValue: 0,
            BalanceBreakDown: null,
          },
          {
            Code: 'FEDER5001',
            Description: 'Wivheruwq Vsmh Jigtfvy Euidy',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 247927,
            StructureType: 'Client',
            AccountType: 'ISA',
            LinkedMain: 'FEDER0005',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 247927,
            PreferredCurrency: 'GBP',
            PreferredValue: 247927,
            BalanceBreakDown: null,
          },
          {
            Code: 'AXA 0462',
            Description: 'Xstfdx Gnyykxl',
            ClientCount: 1,
            AccountCount: 1,
            CountDescription: null,
            Balance: 161306,
            StructureType: 'Client',
            AccountType: 'Main',
            LinkedMain: 'AXA 0462',
            IfaStructure: null,
            ValuationCurrency: 'GBP',
            ValuationValue: 161306,
            PreferredCurrency: 'GBP',
            PreferredValue: 161306,
            BalanceBreakDown: null,
          },
        ],
        ValuationCurrency: null,
        ValuationValue: 0,
        PreferredCurrency: 'GBP',
        PreferredValue: 16144339,
        BalanceBreakDown: [
          {
            Currency: 'GBP',
            TotalValue: 16144339,
          },
        ],
      },
    ];
  }
}
export class WiukciAccountSelectorModel {
  public static equals(o1: SelectedAccountWIUK, o2: SelectedAccountWIUK): boolean {
    return (
      !!o1 && !!o2 && o1.HashedProductAccountNumber === o2.HashedProductAccountNumber && o1.SubAccount === o2.SubAccount
    );
  }

  public static getOriginalAccountsListMock() {
    return {
      MainLinkedAccounts: [
        {
          AccountLocation: 'UK',
          Accounts: [
            {
              AccountLocation: 'UK',
              Balance: {
                AccountName: null,
                AccountNumber: 'ACKE 0002',
                AccountType: 'Main',
                PortfolioTotal: 1982439.23,
                BasePortfolioTotal: 1982439.23,
                AvailableCash: -2906.61,
                BaseAvailableCash: -2906.61,
                TradingCash: 0.0,
                BaseTradingCash: 0.0,
                PortfolioCurrency: 'GBP',
                ReferenceCurrency: 'GBP',
                Type: null,
                EstimatedIncome: 36703.62,
                BaseEstimatedIncome: 36703.62,
                EstimatedYield: 1.85143733258345578643538042,
                TotalValue: 1979532.62,
                BaseTotalValue: 1979532.62,
                AccruedInterest: 0.0,
                BaseAccruedInterest: 0.0,
                Obj: null,
                Risk: null,
                Service: null,
                HoldingsAsAtDate: null,
                GrandPortfolioTotal: null,
                GrandAvailableCash: null,
                GrandTotalValue: null,
                GrandEstimatedIncome: null,
                GrandEstimatedYield: null,
                SubAccountName: null,
              },
              ProductName: 'Main',
              ProductAccountNumber: 'ACKE 0002',
              HashedProductAccountNumber: '143C40D31F9C8D71133655C46B2ADF86D9DC517AD3A176AEC3CDB7E1A3122322',
              PrimaryAccountNumber: 'ACKE 0002',
              Country: 'GBR',
              Currency: 'GBP',
              ManagerName: 'Marcus Vertue',
              ManagerMobile: '',
              ManagerEmail: 'online@investecwin.co.uk',
              Risk: 'Medium/High',
              Ojb: 'Balanced',
              Service: 'Discretionary',
              Name: 'Paul Raphael Acke Esq',
              SubAccountName: '',
            },
            {
              AccountLocation: 'UK',
              Balance: {
                AccountName: null,
                AccountNumber: 'ACKE 5001',
                AccountType: 'ISA',
                PortfolioTotal: 1093082.23,
                BasePortfolioTotal: 1093082.23,
                AvailableCash: 3708.95,
                BaseAvailableCash: 3708.95,
                TradingCash: 0.0,
                BaseTradingCash: 0.0,
                PortfolioCurrency: 'GBP',
                ReferenceCurrency: 'GBP',
                Type: null,
                EstimatedIncome: 30632.28,
                BaseEstimatedIncome: 30632.28,
                EstimatedYield: 2.80237654215639385154033654,
                TotalValue: 1096791.18,
                BaseTotalValue: 1096791.18,
                AccruedInterest: 0.0,
                BaseAccruedInterest: 0.0,
                Obj: null,
                Risk: null,
                Service: null,
                HoldingsAsAtDate: null,
                GrandPortfolioTotal: null,
                GrandAvailableCash: null,
                GrandTotalValue: null,
                GrandEstimatedIncome: null,
                GrandEstimatedYield: null,
                SubAccountName: null,
              },
              ProductName: 'ISA',
              ProductAccountNumber: 'ACKE 5001',
              HashedProductAccountNumber: 'F15F21DD84D8D8C2F12D8FB66406C1219EA0CBAC57F51FC070B611941675D043',
              PrimaryAccountNumber: 'ACKE 5001',
              Country: 'GBR',
              Currency: 'GBP',
              ManagerName: 'Marcus Vertue',
              ManagerMobile: '',
              ManagerEmail: 'online@investecwin.co.uk',
              Risk: 'Medium/High',
              Ojb: 'Balanced',
              Service: 'Discretionary',
              Name: 'Paul Raphael Esq',
              SubAccountName: '',
            },
          ],
          ProductName: 'Paul Raphael Acke Esq',
          ProductAccountNumber: 'ACKE 0002',
          HashedProductAccountNumber: '143C40D31F9C8D71133655C46B2ADF86D9DC517AD3A176AEC3CDB7E1A3122322',
          ManagerName: 'Marcus Vertue',
          ManagerMobile: '',
          ManagerEmail: 'online@investecwin.co.uk',
          Balance: {
            AccountName: null,
            AccountNumber: 'ACKE 0002',
            AccountType: 'Main',
            PortfolioTotal: 3075521.46,
            BasePortfolioTotal: 3075521.46,
            AvailableCash: 802.34,
            BaseAvailableCash: 802.34,
            TradingCash: 0.0,
            BaseTradingCash: 0.0,
            PortfolioCurrency: 'GBP',
            ReferenceCurrency: 'GBP',
            Type: null,
            EstimatedIncome: 67335.9,
            BaseEstimatedIncome: 67335.9,
            EstimatedYield: 2.18941408394529622303464597,
            TotalValue: 3076323.8,
            BaseTotalValue: 3076323.8,
            AccruedInterest: 0.0,
            BaseAccruedInterest: 0.0,
            Obj: null,
            Risk: null,
            Service: null,
            HoldingsAsAtDate: null,
            GrandPortfolioTotal: 3075521.46,
            GrandAvailableCash: 802.34,
            GrandTotalValue: 3076323.8,
            GrandEstimatedIncome: 67335.9,
            GrandEstimatedYield: 2.18941408394529622303464597,
            SubAccountName: null,
          },
          SubAccountName: '',
        },
        {
          AccountLocation: 'UK',
          Accounts: [
            {
              AccountLocation: 'UK',
              Balance: {
                AccountName: null,
                AccountNumber: 'FIA  0001',
                AccountType: 'Main',
                PortfolioTotal: 133005047.03,
                BasePortfolioTotal: 149695638.44,
                AvailableCash: 504947.49,
                BaseAvailableCash: 568312.55334677,
                TradingCash: 0.0,
                BaseTradingCash: 0.0,
                PortfolioCurrency: 'EUR',
                ReferenceCurrency: 'GBP',
                Type: null,
                EstimatedIncome: 2507328.18,
                BaseEstimatedIncome: 2821968.83,
                EstimatedYield: 1.88513764289203561915080203,
                TotalValue: 133509994.52,
                BaseTotalValue: 150263950.99334677,
                AccruedInterest: 25413.86,
                BaseAccruedInterest: 28603.0,
                Obj: null,
                Risk: null,
                Service: null,
                HoldingsAsAtDate: null,
                GrandPortfolioTotal: null,
                GrandAvailableCash: null,
                GrandTotalValue: null,
                GrandEstimatedIncome: null,
                GrandEstimatedYield: null,
                SubAccountName: null,
              },
              ProductName: 'Main',
              ProductAccountNumber: 'FIA  0001',
              HashedProductAccountNumber: '9F7CA812041EFBD974357D416644E906A39399D08769DA2CD9BEE8D588F81ECF',
              PrimaryAccountNumber: 'FIA  0001',
              Country: 'GBR',
              Currency: 'EUR',
              ManagerName: 'Tom Foster',
              ManagerMobile: '',
              ManagerEmail: 'online@investecwin.co.uk',
              Risk: 'Medium',
              Ojb: 'Balanced',
              Service: 'Discretionary',
              Name: 'FIA Foundation',
              SubAccountName: 'Charity Ref:1088670',
            },
          ],
          ProductName: 'FIA Foundation',
          ProductAccountNumber: 'FIA  0001',
          HashedProductAccountNumber: '9F7CA812041EFBD974357D416644E906A39399D08769DA2CD9BEE8D588F81ECF',
          ManagerName: 'Tom Foster',
          ManagerMobile: '',
          ManagerEmail: 'online@investecwin.co.uk',
          Balance: {
            AccountName: null,
            AccountNumber: 'FIA  0001',
            AccountType: 'Main',
            PortfolioTotal: 133005047.03,
            BasePortfolioTotal: 149695638.44,
            AvailableCash: 504947.49,
            BaseAvailableCash: 568312.55334677,
            TradingCash: 0.0,
            BaseTradingCash: 0.0,
            PortfolioCurrency: 'EUR',
            ReferenceCurrency: 'GBP',
            Type: null,
            EstimatedIncome: 2507328.18,
            BaseEstimatedIncome: 2821968.83,
            EstimatedYield: 1.88513764289203561915080203,
            TotalValue: 133509994.52,
            BaseTotalValue: 150263950.99334677,
            AccruedInterest: 25413.86,
            BaseAccruedInterest: 28603.0,
            Obj: null,
            Risk: null,
            Service: null,
            HoldingsAsAtDate: null,
            GrandPortfolioTotal: 133033650.03,
            GrandAvailableCash: 504947.49,
            GrandTotalValue: 133535408.38,
            GrandEstimatedIncome: 2507328.18,
            GrandEstimatedYield: 1.88513764289203561915080203,
            SubAccountName: null,
          },
          SubAccountName: 'Charity Ref:1088670',
        },
        {
          AccountLocation: 'UK',
          Accounts: [
            {
              AccountLocation: 'UK',
              Balance: {
                AccountName: null,
                AccountNumber: 'ARWAS0002',
                AccountType: 'Main',
                PortfolioTotal: 1814350.83,
                BasePortfolioTotal: 1814350.83,
                AvailableCash: 40523.04,
                BaseAvailableCash: 40523.04393816,
                TradingCash: 0.0,
                BaseTradingCash: 0.0,
                PortfolioCurrency: 'GBP',
                ReferenceCurrency: 'GBP',
                Type: null,
                EstimatedIncome: 18113.53,
                BaseEstimatedIncome: 18113.53,
                EstimatedYield: 0.99834771205743048052068298,
                TotalValue: 1854873.87,
                BaseTotalValue: 1854873.87393816,
                AccruedInterest: 24.0,
                BaseAccruedInterest: 24.0,
                Obj: null,
                Risk: null,
                Service: null,
                HoldingsAsAtDate: null,
                GrandPortfolioTotal: null,
                GrandAvailableCash: null,
                GrandTotalValue: null,
                GrandEstimatedIncome: null,
                GrandEstimatedYield: null,
                SubAccountName: null,
              },
              ProductName: 'Main',
              ProductAccountNumber: 'ARWAS0002',
              HashedProductAccountNumber: '85C97C53331122C829E57148B202127500AFE476123C867025DDE3D567266C89',
              PrimaryAccountNumber: 'ARWAS0002',
              Country: 'GBR',
              Currency: 'GBP',
              ManagerName: 'Nick Russell',
              ManagerMobile: '',
              ManagerEmail: 'online@investecwin.co.uk',
              Risk: 'Medium',
              Ojb: 'Growth',
              Service: 'Discretionary',
              Name: 'Mrs Rosalind Louise Arwas',
              SubAccountName: '',
            },
          ],
          ProductName: 'Mrs Rosalind Louise Arwas',
          ProductAccountNumber: 'ARWAS0002',
          HashedProductAccountNumber: '85C97C53331122C829E57148B202127500AFE476123C867025DDE3D567266C89',
          ManagerName: 'Nick Russell',
          ManagerMobile: '',
          ManagerEmail: 'online@investecwin.co.uk',
          Balance: {
            AccountName: null,
            AccountNumber: 'ARWAS0002',
            AccountType: 'Main',
            PortfolioTotal: 1814350.83,
            BasePortfolioTotal: 1814350.83,
            AvailableCash: 40523.04,
            BaseAvailableCash: 40523.04393816,
            TradingCash: 0.0,
            BaseTradingCash: 0.0,
            PortfolioCurrency: 'GBP',
            ReferenceCurrency: 'GBP',
            Type: null,
            EstimatedIncome: 18113.53,
            BaseEstimatedIncome: 18113.53,
            EstimatedYield: 0.99834771205743048052068298,
            TotalValue: 1854873.87,
            BaseTotalValue: 1854873.87393816,
            AccruedInterest: 24.0,
            BaseAccruedInterest: 24.0,
            Obj: null,
            Risk: null,
            Service: null,
            HoldingsAsAtDate: null,
            GrandPortfolioTotal: 1814374.83,
            GrandAvailableCash: 40523.04,
            GrandTotalValue: 1854897.87,
            GrandEstimatedIncome: 18113.53,
            GrandEstimatedYield: 0.99834771205743048052068298,
            SubAccountName: null,
          },
          SubAccountName: '',
        },
        {
          AccountLocation: 'UK',
          Accounts: [
            {
              AccountLocation: 'UK',
              Balance: {
                AccountName: null,
                AccountNumber: 'JAMES1077',
                AccountType: 'SIPP',
                PortfolioTotal: 774889.78,
                BasePortfolioTotal: 774889.78,
                AvailableCash: 20749.36,
                BaseAvailableCash: 20749.36,
                TradingCash: 0.0,
                BaseTradingCash: 0.0,
                PortfolioCurrency: 'GBP',
                ReferenceCurrency: 'GBP',
                Type: null,
                EstimatedIncome: 8379.89,
                BaseEstimatedIncome: 8379.89,
                EstimatedYield: 1.08142992929910625482762207,
                TotalValue: 795639.14,
                BaseTotalValue: 795639.14,
                AccruedInterest: 13.0,
                BaseAccruedInterest: 13.0,
                Obj: null,
                Risk: null,
                Service: null,
                HoldingsAsAtDate: null,
                GrandPortfolioTotal: null,
                GrandAvailableCash: null,
                GrandTotalValue: null,
                GrandEstimatedIncome: null,
                GrandEstimatedYield: null,
                SubAccountName: null,
              },
              ProductName: 'SIPP',
              ProductAccountNumber: 'JAMES1077',
              HashedProductAccountNumber: '31977EB772430A05B7748C05FEEA8687B8BCBDB19B54621812BAECF85D9BCB69',
              PrimaryAccountNumber: 'JAMES1077',
              Country: 'GBR',
              Currency: 'GBP',
              ManagerName: 'Nick Russell',
              ManagerMobile: '',
              ManagerEmail: 'online@investecwin.co.uk',
              Risk: 'Medium',
              Ojb: 'Balanced',
              Service: 'Discretionary',
              Name: 'James Hay Pension Trustees Ltd',
              SubAccountName: 'A/C Mr Graham John Davis Re 110682',
            },
          ],
          ProductName: 'James Hay Pension Trustees Ltd',
          ProductAccountNumber: 'JAMES1077',
          HashedProductAccountNumber: '31977EB772430A05B7748C05FEEA8687B8BCBDB19B54621812BAECF85D9BCB69',
          ManagerName: 'Nick Russell',
          ManagerMobile: '',
          ManagerEmail: 'online@investecwin.co.uk',
          Balance: {
            AccountName: null,
            AccountNumber: 'JAMES1077',
            AccountType: 'SIPP',
            PortfolioTotal: 774889.78,
            BasePortfolioTotal: 774889.78,
            AvailableCash: 20749.36,
            BaseAvailableCash: 20749.36,
            TradingCash: 0.0,
            BaseTradingCash: 0.0,
            PortfolioCurrency: 'GBP',
            ReferenceCurrency: 'GBP',
            Type: null,
            EstimatedIncome: 8379.89,
            BaseEstimatedIncome: 8379.89,
            EstimatedYield: 1.08142992929910625482762207,
            TotalValue: 795639.14,
            BaseTotalValue: 795639.14,
            AccruedInterest: 13.0,
            BaseAccruedInterest: 13.0,
            Obj: null,
            Risk: null,
            Service: null,
            HoldingsAsAtDate: null,
            GrandPortfolioTotal: 774902.78,
            GrandAvailableCash: 20749.36,
            GrandTotalValue: 795652.14,
            GrandEstimatedIncome: 8379.89,
            GrandEstimatedYield: 1.08142992929910625482762207,
            SubAccountName: null,
          },
          SubAccountName: 'A/C Mr Graham John Davis Re 110682',
        },
      ],
      MinDateHolding: '1998/01/30',
      MinDateTransactions: '1984/01/30',
      FromDateTransactions: '2020/07/25',
    };
  }

  public static getParsedAccountListMock(): Array<SelectedAccountWIUK> {
    return [
      {
        Region: 'UK',
        AccountName: 'Paul Raphael Acke Esq',
        AccountType: 'All',
        AccountNumber: 'ACKE 0002',
        HashedProductAccountNumber: '143C40D31F9C8D71133655C46B2ADF86D9DC517AD3A176AEC3CDB7E1A3122322',
        Risk: 'Medium/High',
        Service: 'Discretionary',
        Objective: 'Balanced',
        ReferenceCurrency: 'GBP',
        PortfolioCurrency: 'GBP',
        TotalValue: 3073058.55,
        BaseTotalValue: 3073058.55,
        PortfolioTotal: 3072256.21,
        BasePortfolioTotal: 3072256.21,
        AvailableCash: 802.34,
        BaseAvailableCash: 802.34,
        SubAccountName: '',
        SubAccount: false,
        BaseAccruedInterest: 23,
        BaseEstimatedIncome: 56,
        GrandEstimatedYield: 890,
        ChildAccountName: 'Paul Raphael Acke',
        EstimatedYield: 2.14,
        GroupAccountKey: 'ACKE 0002',
        GrandAvailableCash: 1,
        GrandEstimatedIncome: 12,
        GrandPortfolioTotal: 676,
        GrandTotalValue: 78,
        CanAddFunds: true,
        IsAimIht: false,
        IsAimIhtIsa: false,
        IsCharity: false,
        IsCorp: false,
        IsHeroNominee: false,
        IsIsaOrJisa: false,
        IsSipp: false,
        IsTrust: false,
        IsTrustOrCorp: false,
        PaymentAccountType: '',
        PaymentInitializationAllowed: false,
        ManagerEmail: '',
        ManagerName: '',
        IsIsa: false,
        IsJisa: false,
        ClientsubmittedConsentDecision: true,
        HasAcceptedConsent: true,
        ShowClientConsentTabApiFlag: true,
        ClientConsentAccountNumber: [ 'FIA 0001' ]
      },
      {
        Region: 'UK',
        AccountName: 'Paul Raphael Acke Esq',
        AccountType: 'Main',
        AccountNumber: 'ACKE 0002',
        HashedProductAccountNumber: '143C40D31F9C8D71133655C46B2ADF86D9DC517AD3A176AEC3CDB7E1A3122322',
        Risk: 'Medium/High',
        Service: 'Discretionary',
        Objective: 'Balanced',
        ReferenceCurrency: 'GBP',
        PortfolioCurrency: 'GBP',
        TotalValue: 1978390.27,
        BaseTotalValue: 1978390.27,
        PortfolioTotal: 1981296.88,
        BasePortfolioTotal: 1981296.88,
        AvailableCash: -2906.61,
        BaseAvailableCash: -2906.61,
        SubAccountName: '',
        SubAccount: true,
        BaseAccruedInterest: 23,
        BaseEstimatedIncome: 56,
        GrandEstimatedYield: 890,
        ChildAccountName: 'Paul Raphael Acke',
        EstimatedYield: 2.14,
        GroupAccountKey: 'ACKE 0002',
        GrandAvailableCash: 1,
        GrandEstimatedIncome: 12,
        GrandPortfolioTotal: 676,
        GrandTotalValue: 78,
        CanAddFunds: true,
        IsAimIht: false,
        IsAimIhtIsa: false,
        IsCharity: false,
        IsCorp: false,
        IsHeroNominee: false,
        IsIsaOrJisa: false,
        IsSipp: false,
        IsTrust: false,
        IsTrustOrCorp: false,
        PaymentAccountType: '',
        PaymentInitializationAllowed: false,
        ManagerEmail: '',
        ManagerName: '',
        IsIsa: false,
        IsJisa: false,
        ClientsubmittedConsentDecision: true,
        HasAcceptedConsent: true,
        ShowClientConsentTabApiFlag: true,
        ClientConsentAccountNumber: [ 'FIA 0001' ]
      },
      {
        Region: 'UK',
        AccountName: 'Paul Raphael Acke Esq',
        AccountType: 'ISA',
        AccountNumber: 'ACKE 5001',
        HashedProductAccountNumber: 'F15F21DD84D8D8C2F12D8FB66406C1219EA0CBAC57F51FC070B611941675D043',
        Risk: 'Medium/High',
        Service: 'Discretionary',
        Objective: 'Balanced',
        ReferenceCurrency: 'GBP',
        PortfolioCurrency: 'GBP',
        TotalValue: 1094668.28,
        BaseTotalValue: 1094668.28,
        PortfolioTotal: 1090959.33,
        BasePortfolioTotal: 1090959.33,
        AvailableCash: 3708.95,
        BaseAvailableCash: 3708.95,
        SubAccountName: '',
        SubAccount: true,
        BaseAccruedInterest: 23,
        BaseEstimatedIncome: 56,
        GrandEstimatedYield: 890,
        ChildAccountName: 'Paul Raphael Acke',
        EstimatedYield: 2.14,
        GroupAccountKey: 'ACKE 0002',
        GrandAvailableCash: 1,
        GrandEstimatedIncome: 12,
        GrandPortfolioTotal: 676,
        GrandTotalValue: 78,
        CanAddFunds: true,
        IsAimIht: false,
        IsAimIhtIsa: false,
        IsCharity: false,
        IsCorp: false,
        IsHeroNominee: false,
        IsIsaOrJisa: false,
        IsSipp: false,
        IsTrust: false,
        IsTrustOrCorp: false,
        PaymentAccountType: '',
        PaymentInitializationAllowed: false,
        ManagerEmail: '',
        ManagerName: '',
        IsIsa: false,
        IsJisa: false,
        ClientsubmittedConsentDecision: true,
        HasAcceptedConsent: true,
        ShowClientConsentTabApiFlag: true,
        ClientConsentAccountNumber: [ 'FIA 0001' ]
      },
      {
        Region: 'UK',
        AccountName: 'FIA Foundation',
        AccountType: 'All',
        AccountNumber: 'FIA 0001',
        HashedProductAccountNumber: '9F7CA812041EFBD974357D416644E906A39399D08769DA2CD9BEE8D588F81ECF',
        Risk: 'Medium',
        Service: 'Discretionary',
        Objective: 'Balanced',
        ReferenceCurrency: 'GBP',
        PortfolioCurrency: 'EUR',
        TotalValue: 133466650.51,
        BaseTotalValue: 150215167.79334676,
        PortfolioTotal: 132961703.02,
        BasePortfolioTotal: 149646855.24,
        AvailableCash: 504947.49,
        BaseAvailableCash: 568312.55334677,
        SubAccountName: 'Charity Ref:1088670',
        SubAccount: false,
        BaseAccruedInterest: 23,
        BaseEstimatedIncome: 56,
        GrandEstimatedYield: 890,
        ChildAccountName: 'Paul Raphael Acke',
        EstimatedYield: 2.14,
        GroupAccountKey: 'FIA 0001',
        GrandAvailableCash: 1,
        GrandEstimatedIncome: 12,
        GrandPortfolioTotal: 676,
        GrandTotalValue: 78,
        CanAddFunds: true,
        IsAimIht: false,
        IsAimIhtIsa: false,
        IsCharity: false,
        IsCorp: false,
        IsHeroNominee: false,
        IsIsaOrJisa: false,
        IsSipp: false,
        IsTrust: false,
        IsTrustOrCorp: false,
        PaymentAccountType: '',
        PaymentInitializationAllowed: false,
        ManagerEmail: '',
        ManagerName: '',
        IsIsa: false,
        IsJisa: false,
        ClientsubmittedConsentDecision: true,
        HasAcceptedConsent: true,
        ShowClientConsentTabApiFlag: true,
        ClientConsentAccountNumber: [ 'FIA 0001' ]
      },
      {
        Region: 'UK',
        AccountName: 'FIA Foundation',
        AccountType: 'Main',
        AccountNumber: 'FIA 0001',
        HashedProductAccountNumber: '9F7CA812041EFBD974357D416644E906A39399D08769DA2CD9BEE8D588F81ECF',
        Risk: 'Medium',
        Service: 'Discretionary',
        Objective: 'Balanced',
        ReferenceCurrency: 'GBP',
        PortfolioCurrency: 'EUR',
        TotalValue: 133466650.51,
        BaseTotalValue: 150215167.79334676,
        PortfolioTotal: 132961703.02,
        BasePortfolioTotal: 149646855.24,
        AvailableCash: 504947.49,
        BaseAvailableCash: 568312.55334677,
        SubAccountName: 'Charity Ref:1088670',
        SubAccount: true,
        BaseAccruedInterest: 23,
        BaseEstimatedIncome: 56,
        GrandEstimatedYield: 890,
        ChildAccountName: 'Paul Raphael Acke',
        EstimatedYield: 2.14,
        GroupAccountKey: 'FIA 0001',
        GrandAvailableCash: 1,
        GrandEstimatedIncome: 12,
        GrandPortfolioTotal: 676,
        GrandTotalValue: 78,
        CanAddFunds: true,
        IsAimIht: false,
        IsAimIhtIsa: false,
        IsCharity: false,
        IsCorp: false,
        IsHeroNominee: false,
        IsIsaOrJisa: false,
        IsSipp: false,
        IsTrust: false,
        IsTrustOrCorp: false,
        PaymentAccountType: '',
        PaymentInitializationAllowed: false,
        ManagerEmail: '',
        ManagerName: '',
        IsIsa: false,
        IsJisa: false,
        ClientsubmittedConsentDecision: true,
        HasAcceptedConsent: true,
        ShowClientConsentTabApiFlag: true,
        ClientConsentAccountNumber: [ 'FIA 0001' ]
      },
      {
        Region: 'UK',
        AccountName: 'Mrs Rosalind Louise Arwas',
        AccountType: 'All',
        AccountNumber: 'ARWAS0002',
        HashedProductAccountNumber: '85C97C53331122C829E57148B202127500AFE476123C867025DDE3D567266C89',
        Risk: 'Medium',
        Service: 'Discretionary',
        Objective: 'Growth',
        ReferenceCurrency: 'GBP',
        PortfolioCurrency: 'GBP',
        TotalValue: 1855199.69,
        BaseTotalValue: 1855199.69393816,
        PortfolioTotal: 1814676.65,
        BasePortfolioTotal: 1814676.65,
        AvailableCash: 40523.04,
        BaseAvailableCash: 40523.04393816,
        SubAccountName: '',
        SubAccount: false,
        BaseAccruedInterest: 23,
        BaseEstimatedIncome: 56,
        GrandEstimatedYield: 890,
        ChildAccountName: 'Paul Raphael Acke',
        EstimatedYield: 2.14,
        GroupAccountKey: 'ARWAS0002',
        GrandAvailableCash: 1,
        GrandEstimatedIncome: 12,
        GrandPortfolioTotal: 676,
        GrandTotalValue: 78,
        CanAddFunds: true,
        IsAimIht: false,
        IsAimIhtIsa: false,
        IsCharity: false,
        IsCorp: false,
        IsHeroNominee: false,
        IsIsaOrJisa: false,
        IsSipp: false,
        IsTrust: false,
        IsTrustOrCorp: false,
        PaymentAccountType: '',
        PaymentInitializationAllowed: false,
        ManagerEmail: '',
        ManagerName: '',
        IsIsa: false,
        IsJisa: false,
        ClientsubmittedConsentDecision: true,
        HasAcceptedConsent: true,
        ShowClientConsentTabApiFlag: true,
        ClientConsentAccountNumber: [ 'ARWAS0002' ]
      },
      {
        Region: 'UK',
        AccountName: 'Mrs Rosalind Louise Arwas',
        AccountType: 'Main',
        AccountNumber: 'ARWAS0002',
        HashedProductAccountNumber: '85C97C53331122C829E57148B202127500AFE476123C867025DDE3D567266C89',
        Risk: 'Medium',
        Service: 'Discretionary',
        Objective: 'Growth',
        ReferenceCurrency: 'GBP',
        PortfolioCurrency: 'GBP',
        TotalValue: 1855199.69,
        BaseTotalValue: 1855199.69393816,
        PortfolioTotal: 1814676.65,
        BasePortfolioTotal: 1814676.65,
        AvailableCash: 40523.04,
        BaseAvailableCash: 40523.04393816,
        SubAccountName: '',
        SubAccount: true,
        BaseAccruedInterest: 23,
        BaseEstimatedIncome: 56,
        GrandEstimatedYield: 890,
        ChildAccountName: 'Paul Raphael Acke',
        EstimatedYield: 2.14,
        GroupAccountKey: 'ARWAS0002',
        GrandAvailableCash: 1,
        GrandEstimatedIncome: 12,
        GrandPortfolioTotal: 676,
        GrandTotalValue: 78,
        CanAddFunds: true,
        IsAimIht: false,
        IsAimIhtIsa: false,
        IsCharity: false,
        IsCorp: false,
        IsHeroNominee: false,
        IsIsaOrJisa: false,
        IsSipp: false,
        IsTrust: false,
        IsTrustOrCorp: false,
        PaymentAccountType: '',
        PaymentInitializationAllowed: false,
        ManagerEmail: '',
        ManagerName: '',
        IsIsa: false,
        IsJisa: false,
        ClientsubmittedConsentDecision: true,
        HasAcceptedConsent: true,
        ShowClientConsentTabApiFlag: true,
        ClientConsentAccountNumber: [ 'ARWAS0002' ]
      },
      {
        Region: 'UK',
        AccountName: 'James Hay Pension Trustees Ltd',
        AccountType: 'All',
        AccountNumber: 'JAMES1077',
        HashedProductAccountNumber: '31977EB772430A05B7748C05FEEA8687B8BCBDB19B54621812BAECF85D9BCB69',
        Risk: 'Medium',
        Service: 'Discretionary',
        Objective: 'Balanced',
        ReferenceCurrency: 'GBP',
        PortfolioCurrency: 'GBP',
        TotalValue: 795773.53,
        BaseTotalValue: 795773.53,
        PortfolioTotal: 775024.17,
        BasePortfolioTotal: 775024.17,
        AvailableCash: 20749.36,
        BaseAvailableCash: 20749.36,
        SubAccountName: 'A/C Mr Graham John Davis Re 110682',
        SubAccount: false,
        BaseAccruedInterest: 23,
        BaseEstimatedIncome: 56,
        GrandEstimatedYield: 890,
        ChildAccountName: 'Paul Raphael Acke',
        EstimatedYield: 2.14,
        GroupAccountKey: 'JAMES1077',
        GrandAvailableCash: 1,
        GrandEstimatedIncome: 12,
        GrandPortfolioTotal: 676,
        GrandTotalValue: 78,
        CanAddFunds: true,
        IsAimIht: false,
        IsAimIhtIsa: false,
        IsCharity: false,
        IsCorp: false,
        IsHeroNominee: false,
        IsIsaOrJisa: false,
        IsSipp: false,
        IsTrust: false,
        IsTrustOrCorp: false,
        PaymentAccountType: '',
        PaymentInitializationAllowed: false,
        ManagerEmail: '',
        ManagerName: '',
        IsIsa: false,
        IsJisa: false,
        ClientsubmittedConsentDecision: true,
        HasAcceptedConsent: true,
        ShowClientConsentTabApiFlag: true,
        ClientConsentAccountNumber: [ 'JAMES1077' ]
      },
      {
        Region: 'UK',
        AccountName: 'James Hay Pension Trustees Ltd',
        AccountType: 'SIPP',
        AccountNumber: 'JAMES1077',
        HashedProductAccountNumber: '31977EB772430A05B7748C05FEEA8687B8BCBDB19B54621812BAECF85D9BCB69',
        Risk: 'Medium',
        Service: 'Discretionary',
        Objective: 'Balanced',
        ReferenceCurrency: 'GBP',
        PortfolioCurrency: 'GBP',
        TotalValue: 795773.53,
        BaseTotalValue: 795773.53,
        PortfolioTotal: 775024.17,
        BasePortfolioTotal: 775024.17,
        AvailableCash: 20749.36,
        BaseAvailableCash: 20749.36,
        SubAccountName: 'A/C Mr Graham John Davis Re 110682',
        SubAccount: true,
        BaseAccruedInterest: 23,
        BaseEstimatedIncome: 56,
        GrandEstimatedYield: 890,
        ChildAccountName: 'Paul Raphael Acke',
        EstimatedYield: 2.14,
        GroupAccountKey: 'JAMES1077',
        GrandAvailableCash: 1,
        GrandEstimatedIncome: 12,
        GrandPortfolioTotal: 676,
        GrandTotalValue: 78,
        CanAddFunds: true,
        IsAimIht: false,
        IsAimIhtIsa: false,
        IsCharity: false,
        IsCorp: false,
        IsHeroNominee: false,
        IsIsaOrJisa: false,
        IsSipp: false,
        IsTrust: false,
        IsTrustOrCorp: false,
        PaymentAccountType: '',
        PaymentInitializationAllowed: false,
        ManagerEmail: '',
        ManagerName: '',
        IsIsa: false,
        IsJisa: false,
        ClientsubmittedConsentDecision: true,
        HasAcceptedConsent: true,
        ShowClientConsentTabApiFlag: true,
        ClientConsentAccountNumber: [ 'JAMES1077' ]
      },
    ];
  }
}

interface IfaBalanceBreakDown {
  Currency: string;
  TotalValue: number;
}

interface WiukciIfaAgency {
  Code: string;
  Description: string;
  ClientCount: number;
  AccountCount: number;
  CountDescription: string | null;
  Balance: number;
  StructureType: string;
  AccountType: string | null;
  LinkedMain: string | null;
  IfaStructure: Array<WiukciIfaAgency> | null;
  ValuationCurrency?: string | null;
  ValuationValue?: number;
  PreferredCurrency?: string;
  PreferredValue?: number;
  BalanceBreakDown?: Array<IfaBalanceBreakDown> | null;
}

interface CountryDiallingCode {
  code: string | undefined;
  name: string | undefined;
  displayName: string | undefined;
  popular: boolean | undefined;
}

interface GenerateOtpResponseModel {
  token: string | undefined;
  transactionToken: string | undefined;
}
export interface SelectedAccountWIUK {
  Region: string;
  AccountName: string; // parent account ProductName
  AccountType: string; // child ProductName or 'All' for parent account
  AccountNumber: string;
  GroupAccountKey: string | undefined; //Main account number to group Isa and main accounts together
  HashedProductAccountNumber: string; // parent and main account have the same ID
  Risk: string;
  Service: string;
  Objective: string;
  ReferenceCurrency: string; // selected currency
  PortfolioCurrency: string; // base account currency
  TotalValue: number; // in selected currency
  BaseTotalValue: number; // in base account currency
  PortfolioTotal: number; // in selected currency
  BasePortfolioTotal: number; // in base account currency
  AvailableCash: number; // in selected currency
  BaseAvailableCash: number; // in base account currency
  SubAccountName: string;
  SubAccount: boolean; // added parameter indicating child account
  BaseAccruedInterest: number;
  BaseEstimatedIncome: number;
  GrandEstimatedYield: number;
  EstimatedYield: number;
  ChildAccountName: string;
  CapitalTotalDisplay?: string;
  AccruedInterestTotalDisplay?: string;
  GrandTotalDisplay?: string;
  GrandPortfolioTotal: number;
  GrandAvailableCash: number;
  GrandTotalValue: number;
  GrandEstimatedIncome: number;
  CanAddFunds: boolean;
  IsAimIht: boolean;
  IsAimIhtIsa: boolean;
  IsHeroNominee: boolean;
  IsIsaOrJisa: boolean;
  IsIsa: boolean;
  IsJisa: boolean;
  IsSipp: boolean;
  IsTrustOrCorp: boolean;
  IsTrust: boolean;
  IsCorp: boolean;
  IsCharity: boolean;
  PaymentInitializationAllowed: boolean;
  HasAcceptedConsent: boolean;
  ShowClientConsentTabApiFlag: boolean;
  ClientsubmittedConsentDecision: boolean;
  ClientConsentAccountNumber: string[];
  PaymentAccountType: string;
  ManagerEmail: string;
  ManagerName: string;
}

export interface AccountType {
  key: string | undefined;
  value: string | undefined;
}

export interface FeatureFlags {
  FeatureBankDetailsDesktop: boolean;
  FeatureBankDetailsMobile: boolean;
  FeatureFraudMessagesV2: boolean;
  FeatureInitiatePaymentDesktop: boolean;
  FeatureInitiatePaymentMobile: boolean;
  FeatureIsUserEnabledMFADesktop: boolean;
  FeatureIsUserEnabledMFAMobile: boolean;
  FeaturePaymentCurrenciesDesktop: boolean;
  FeaturePaymentCurrenciesMobile: boolean;
  FeaturePaymentDetailsDesktop: boolean;
  FeaturePaymentDetailsMobile: boolean;
  FeatureProductOptOut: boolean;
  FeatureSendEmailUsingMimeCastApi: boolean;
  FeatureWealthUpSell: boolean;
  FeatureTopFundHoldingsDesktop: boolean; 
  FeatureTopPortfolioHoldingsDesktop: boolean; 
  FeatureIfaAnnualDoc: boolean;
  FeatureGetDocuments: boolean;
  FeatureWealthDatePicker: boolean;
  WealthFeatureClientConsent: boolean;

} 

//action
export class WIUKSetSelectedAccount {
  static readonly type = '[SelectedAccountWIUK] Set selected account';

  constructor(public payload: SelectedAccountWIUK | undefined) {}
}

//action
export class WIUKAccountsLoadedFromAPI {
  static readonly type = '[SelectedAccountWIUK] Accounts Loaded From API';

  constructor(public payload: Array<any>) {}
}

//action
export class WIUKCountryDiallingCodesLoadedFromAPI {
  static readonly type = '[SelectedAccountWIUK] Country Dialling Codes Loaded From API';

  constructor(public payload: Array<any>) {}
}

//action
export class WIUKUpdateAccountLoadedFlag {
  static readonly type = '[SelectedAccountWIUK] Update Account Loaded Flag';

  constructor(public payload: boolean) {}
}

//action
export class WIUKSetIfaAgencyInStore {
  static readonly type = '[SelectedAccountWIUK] Set selected IFA Agency';

  constructor(public payload: WiukciIfaAgency | undefined) {}
}

//action
export class WIUKIfaAgenciesLoadedFromAPI {
  static readonly type = '[SelectedAccountWIUK] IFA Agencies Loaded From API';

  constructor(public payload: Array<any>) {}
}

//action
export class WIUKUpdateIfaAgenciesLoadedFlag {
  static readonly type = '[SelectedAccountWIUK] Update IFA Agency Loaded Flag';

  constructor(public payload: boolean) {}
}

//action
export class WIUKGenerateOtpRequestedFromAPI {
  static readonly type = '[SelectedAccountWIUK] Generate Otp Requested From API';

  constructor(public payload: GenerateOtpResponseModel) {}
}

//action
export class WIUKOtpValidatedFromAPI {
  static readonly type = '[SelectedAccountWIUK] Otp Validated From API ';

  constructor(public payload: boolean) {}
}

//action
export class WIUKIsUserEnabledMfaFlagFromAPI {
  static readonly type = '[SelectedAccountWIUK] WIUK Is User Enabled Mfa Flag From API';

  constructor(public payload: boolean) {}
}

export class WIUKClientConsentResponseFromApi {
  static readonly type = '[SelectedAccountWIUK] WIUK Client Consent Response From API';

  constructor(public payload: boolean) {}
}

//action
export class WIUKWiukciAzureFeatureFlagsFromAPI {
  static readonly type = '[SelectedAccountWIUK] WIUK Wiukci Azure Feature Flags From API';

  constructor(public payload: boolean) {}
}

export class WIUKSelectedAccountStateModel {
  account: SelectedAccountWIUK | undefined;
  accounts: Array<SelectedAccountWIUK> | undefined;
  accountLoaded: boolean = false;
  countryDiallingCodes: Array<CountryDiallingCode> | undefined;
  countryDiallingCodesLoaded: boolean = false;
  otpTransactionToken: string | undefined | null;
  otpToken: string | undefined | null;
  otpValidated: boolean = false;
  otpIsInvalid: boolean = false;
  otpIsLocked: boolean = false;
  show2faFlag: boolean = false;
  wiukciFeatureFlags: FeatureFlags;
  otpValidatedResponseLoadedFromApi: boolean = false;
  submitClientConsentResponse: boolean | undefined;
  //showClientConsentTabApiFlag: boolean | undefined;
  ifaAgency: WiukciIfaAgency | undefined;
  ifaAgencies: Array<WiukciIfaAgency> | undefined;
  ifaAgencyLoaded: boolean = false;
  ifaTotals: any; // todo not any
  vulnerableClient: boolean = false;

  // parsing API response into flat structure
  // used in both UK and CI
  public static parseSelectedAccount(
    account: any | undefined,
    subAccount: boolean,
    parentProductName?: string,
    groupAccountKey?: string
  ): SelectedAccountWIUK | undefined {
    if (!account) {
      return undefined;
    }

    const parsedAcc: SelectedAccountWIUK = {
      Region: account.AccountLocation,
      AccountName: subAccount ? parentProductName : account.ProductName,
      AccountType: subAccount ? account.ProductName : 'All',
      AccountNumber: account.ProductAccountNumber,
      HashedProductAccountNumber: account.HashedProductAccountNumber,
      Risk: account.Risk ? account.Risk : 'N/A',
      Service: account.Service ? account.Service : 'N/A',
      Objective: account.Ojb ? account.Ojb : 'N/A',
      ReferenceCurrency: account.Balance.ReferenceCurrency,
      PortfolioCurrency: account.Balance.PortfolioCurrency,
      TotalValue: account.Balance.TotalValue,
      BaseTotalValue: account.Balance.BaseTotalValue,
      PortfolioTotal: account.Balance.PortfolioTotal,
      BasePortfolioTotal: account.Balance.BasePortfolioTotal,
      AvailableCash: account.Balance.AvailableCash,
      BaseAvailableCash: account.Balance.BaseAvailableCash,
      SubAccountName: account.SubAccountName,
      SubAccount: subAccount,
      BaseAccruedInterest: account.Balance.BaseAccruedInterest,
      BaseEstimatedIncome: account.Balance.BaseEstimatedIncome,
      GrandEstimatedYield: account.Balance.GrandEstimatedYield,
      EstimatedYield: account.Balance.EstimatedYield,
      ChildAccountName: account.Name,
      GroupAccountKey: groupAccountKey,
      GrandAvailableCash: account.Balance.GrandAvailableCash,
      GrandEstimatedIncome: account.Balance.GrandEstimatedIncome,
      GrandPortfolioTotal: account.Balance.GrandPortfolioTotal,
      GrandTotalValue: account.Balance.GrandTotalValue,
      CanAddFunds: account.CanAddFunds,
      IsAimIht: account.IsAimIht,
      IsAimIhtIsa: account.IsAimIhtIsa,
      IsHeroNominee: account.IsHeroNominee,
      IsIsaOrJisa: account.IsIsaOrJisa,
      IsIsa: account.IsIsa,
      IsJisa: account.IsJisa,
      IsSipp: account.IsSipp,
      IsTrustOrCorp: account.IsTrustOrCorpOrCharity,
      IsCharity: account.IsCharity,
      PaymentInitializationAllowed: account.PaymentInitializationAllowed,
      IsCorp: account.IsCorp,
      IsTrust: account.IsTrust,
      PaymentAccountType: account.PaymentAccountType,
      ManagerEmail: account.ManagerEmail,
      ManagerName: account.ManagerName,
      ClientsubmittedConsentDecision: account.ClientsubmittedConsentDecision,
      HasAcceptedConsent: account.HasAcceptedConsent,
      ShowClientConsentTabApiFlag: account.ShowClientConsentTabApiFlag,
      ClientConsentAccountNumber: account.ClientConsentAccountNumber
    };
    // for parent account, assign missing attributes from the first child
    if (!subAccount) {
      parsedAcc.Risk = account.Accounts[0]?.Risk ? account.Accounts[0].Risk : 'N/A';
      parsedAcc.Service = account.Accounts[0]?.Service ? account.Accounts[0].Service : 'N/A';
      parsedAcc.Objective = account.Accounts[0]?.Ojb ? account.Accounts[0].Ojb : 'N/A';
    }
    return parsedAcc;
  }

  // loop to parse full array of accounts
  // used in both UK and CI
  public static wiukciParseAccounts(apiResponse: any): Array<SelectedAccountWIUK | undefined> {
    const temp: Array<SelectedAccountWIUK | undefined> = [];
    if (apiResponse && apiResponse.MainLinkedAccounts) {
      apiResponse.MainLinkedAccounts.forEach((account: any) => {
        // parse parent account
        temp.push(
          WIUKSelectedAccountStateModel.parseSelectedAccount(account, false, undefined, account.ProductAccountNumber)
        );
        // parse all sub-accounts
        account.Accounts.forEach((subAccount: any) => {
          temp.push(
            WIUKSelectedAccountStateModel.parseSelectedAccount(
              subAccount,
              true,
              account.ProductName,
              account.ProductAccountNumber
            )
          );
        });
      });
    }
    return temp;
  }

  // used in both UK and CI
  public static wiukciUpdateSelectedAccountFromAccountsList(
    accounts: Array<any>,
    selectedAccount: SelectedAccountWIUK
  ) {
    const accountInList: Array<SelectedAccountWIUK> = accounts.filter((x: SelectedAccountWIUK) => {
      return WiukciAccountSelectorModel.equals(x, selectedAccount);
    });
    return accountInList.length === 1 ? accountInList[0] : undefined;
  }

  // used in both UK and CI
  public static wiukciUpdateSelectedAgencyFromAgenciesList(
    agencies: Array<any> | undefined,
    selectedAgency: WiukciIfaAgency | undefined
  ) {
    const agencyInList: Array<WiukciIfaAgency> | undefined = agencies?.filter((x: WiukciIfaAgency) => {
      return WiukciAgencySelectorModel.equals(x, selectedAgency);
    });
    return agencyInList?.length === 1 ? agencyInList[0] : undefined;
  }

  // used in both UK and CI
  public static getFilteredAgencies(agencies: Array<WiukciIfaAgency>) {
    let filteredAgencies: Array<WiukciIfaAgency> = [];

    const agenciesMainStructureType = agencies[0]?.StructureType;
    let agenciesMainStructureTypeLevel = 3;

    switch (agenciesMainStructureType) {
      case 'Agent': {
        agenciesMainStructureTypeLevel = 0;
        filteredAgencies = agencies;
        break;
      }
      case 'Firm': {
        agenciesMainStructureTypeLevel = 1;
        agencies.forEach((agent) => {
          agent.IfaStructure?.forEach((agent1) => {
            filteredAgencies.push(agent1);
          });
        });
        break;
      }
      case 'Network': {
        agenciesMainStructureTypeLevel = 2;
        agencies.forEach((agent) => {
          agent.IfaStructure?.forEach((agent1) => {
            agent1.IfaStructure?.forEach((agent2) => {
              filteredAgencies.push(agent2);
            });
          });
        });
        break;
      }
      default: {
        // wrong structure, shouldn't happen
        agenciesMainStructureTypeLevel = 3;
        break;
      }
    }

    if (agenciesMainStructureTypeLevel !== 3) {
      return filteredAgencies;
    }
    return;
  }
}

export function getWIUKSelectedAccountInit(): WIUKSelectedAccountStateModel {
  return {
    account: undefined,
    accounts: undefined,
    accountLoaded: false,
    ifaAgency: undefined,
    ifaAgencies: undefined,
    ifaAgencyLoaded: false,
    ifaTotals: null,
    vulnerableClient: false,
    countryDiallingCodes: undefined,
    countryDiallingCodesLoaded: false,
    otpToken: undefined,
    otpTransactionToken: undefined,
    otpValidated: false,
    show2faFlag: false,
    wiukciFeatureFlags: undefined,
    otpValidatedResponseLoadedFromApi: false,
    otpIsInvalid: false,
    otpIsLocked: false,
    submitClientConsentResponse: undefined,
  };
}

@State<WIUKSelectedAccountStateModel>({
  name: 'selectedAccountWIUK',
  defaults: getWIUKSelectedAccountInit(),
})
@Injectable()
export class WIUKSelectedAccountState {
  @Selector()
  static getSelectedAccount(state: WIUKSelectedAccountStateModel) {
    return state.account;
  }

  @Action(WIUKAccountsLoadedFromAPI)
  AccountsLoadedFromAPI(ctx: StateContext<WIUKSelectedAccountStateModel>, { payload }: any) {
    const parsedAccounts: Array<any> | undefined = WIUKSelectedAccountStateModel.wiukciParseAccounts(payload);
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      accounts: parsedAccounts,
      vulnerableClient: payload?.Vulnerable,
      accountLoaded: true,
    });
    if (state.account !== undefined) {
      // get latest balance from returned account call
      // match selected accountId with Account Id from accounts call
      // fixes incorrect balance after a payment is made
      const newSelectedAccount = WIUKSelectedAccountStateModel.wiukciUpdateSelectedAccountFromAccountsList(
        parsedAccounts,
        state.account
      );
      ctx.dispatch([new WIUKSetSelectedAccount(newSelectedAccount), new WIUKUpdateAccountLoadedFlag(true)]);
    }
  }

  @Action(WIUKCountryDiallingCodesLoadedFromAPI)
  CountryDiallingCodesLoadedFromAPI(ctx: StateContext<WIUKSelectedAccountStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      countryDiallingCodes: payload,
      countryDiallingCodesLoaded: true,
    });
  }

  @Action(WIUKSetSelectedAccount)
  SetAccountInStore(ctx: StateContext<WIUKSelectedAccountStateModel>, { payload }: WIUKSetSelectedAccount) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      account: payload,
    });
  }

  @Action(WIUKUpdateAccountLoadedFlag)
  UpdateAccountLoadedFlag(ctx: StateContext<WIUKSelectedAccountStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      accountLoaded: payload,
    });
  }

  @Action(WIUKIfaAgenciesLoadedFromAPI)
  IfaAgenciesLoadedFromAPI(ctx: StateContext<WIUKSelectedAccountStateModel>, { payload }: any) {
    const parsedAgencies = WIUKSelectedAccountStateModel.getFilteredAgencies(payload.Data);
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      ifaAgencies: parsedAgencies,
      ifaTotals: payload.Totals,
      ifaAgencyLoaded: true,
    });
    if (state.ifaAgency !== null) {
      const newSelectedAgency = WIUKSelectedAccountStateModel.wiukciUpdateSelectedAgencyFromAgenciesList(
        parsedAgencies,
        state.ifaAgency
      );
      ctx.dispatch([new WIUKSetIfaAgencyInStore(newSelectedAgency), new WIUKUpdateIfaAgenciesLoadedFlag(true)]);
    }
  }

  @Action(WIUKSetIfaAgencyInStore)
  SetIfaAgencyInStore(ctx: StateContext<WIUKSelectedAccountStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      ifaAgency: payload,
    });
  }

  @Action(WIUKUpdateIfaAgenciesLoadedFlag)
  UpdateAIfaAgenciesLoadedFlag(ctx: StateContext<WIUKSelectedAccountStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      ifaAgencyLoaded: payload,
    });
  }

  @Action(WIUKGenerateOtpRequestedFromAPI)
  GenerateOtpRequestedFromAPI(ctx: StateContext<WIUKSelectedAccountStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      otpTransactionToken: payload.transactionToken,
      otpToken: payload.token,
    });
  }

  @Action(WIUKOtpValidatedFromAPI)
  OtpValidatedFromAPI(ctx: StateContext<WIUKSelectedAccountStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      otpValidated: payload.success,
      otpIsInvalid: payload.data?.isInvalid,
      otpIsLocked: payload.data?.isLocked,
      otpValidatedResponseLoadedFromApi: true,
    });
  }

  @Action(WIUKIsUserEnabledMfaFlagFromAPI)
  IsUserEnabledMfaFlagFromAPI(ctx: StateContext<WIUKSelectedAccountStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      show2faFlag: payload,
    });
  }

  @Action(WIUKClientConsentResponseFromApi)
  ClientConsentResponseFromApi(ctx: StateContext<WIUKSelectedAccountStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      submitClientConsentResponse: payload,
    });
  }

  @Action(WIUKWiukciAzureFeatureFlagsFromAPI)
  WiukciAzureFeatureFlagsFromAPI(ctx: StateContext<WIUKSelectedAccountStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      wiukciFeatureFlags: payload,
    });
  }
}
