import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {SecondFactorInterface} from "../../../models/second-factor.interface";
import {SecondFactorService} from "../../../service/second-factor.service";
import {environment} from "@env-vars";

@Component({
  selector: 'investec-online-type-otp',
  templateUrl: './type-otp.component.html',
  styleUrls: ['./type-otp.component.scss']
})
export class TypeOtpComponent implements OnInit {

  otpValue = '';
  otpHasError = false;
  isLoading = false;
  showLoginReskin = environment.onboardingReskin.showLoginReskin;

  @Input() secondFactorData: SecondFactorInterface;
  @Output() stopSecondFactor: EventEmitter<any> = new EventEmitter();


  constructor(private _secondFactorService: SecondFactorService) {}

  ngOnInit() {}

  submitOtp(){
    this.isLoading = true;
    this._secondFactorService.submitOtp({OTP: this.otpValue}).subscribe(res => {
      if(res.Result){
        this._secondFactorService.secondFactorStatus.next(true);
        this.stopSecondFactor.next(true);
      } else {
        this.otpValue = '';
        this.otpHasError = true;
      }
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      console.log(err)
    })
  }

  cancelOtp(){
    this.stopSecondFactor.next(false);
  }

  resendOtp() {
    this._secondFactorService.resendOtp().subscribe(res => {
      if(!res){
        this.stopSecondFactor.next(false);
      }
    }, err => {
      this.stopSecondFactor.next(false);
    });
  }
}
