import {AfterViewChecked, AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {DataLayerService, UserContextService} from "@investec-online/platform-services";
import {Store} from "@ngxs/store";
import {PlatformInteractionState, SetPlatformInteraction} from "@investec-online/platform-state-management";
import {invsy} from "@investec/invsy-sdk";

@Component({
  selector: 'investec-online-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'app-platform-client';
  currentPageName: string;
  initiateSession = false;

  constructor(private _route: Router,
              private _titleService: Title,
              private _userContext: UserContextService,
              private _store: Store,
              private dataLayerService: DataLayerService) {

    this._userContext.setTitleToCurrentPageContext().then(res => this.currentPageName = res);
    this._store.select(PlatformInteractionState.getPlatformInteraction).subscribe(res => {
      if(res !== undefined){
        this.initiateSession = res.showLogOut
      }
    })
    this._userContext.setUrlOnRouteChange();
    this._userContext.setDynatraceUserId()
  }

  ngOnInit() {
    invsy.platform.init()
    invsy.platform.initSession();
  }


  trackAnalyticsEvent(event: { eventName, eventAction, itemName, eventType, subcategory3, attributes, itemID }) {
    this.dataLayerService.trackData(event.eventName, event.eventAction, event.itemName, event.eventType, 'loyalty', 'flow',
      'user', event.subcategory3, event.attributes, event.itemID)
  }
}
