import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ISubscription} from "rxjs-compat/Subscription";
import {SecondFactorInterface} from "../../../models/second-factor.interface";
import {SecondFactorService} from "../../../service/second-factor.service";
import {TimerObservable} from "rxjs-compat/observable/TimerObservable";
import {takeWhile} from "rxjs/operators";
import {SharedHttpService} from "../../../../../..//platform-services/src";

@Component({
  selector: 'investec-online-type-ussd',
  templateUrl: './type-ussd.component.html',
  styleUrls: ['./type-ussd.component.scss']
})
export class TypeUssdComponent implements OnInit, OnDestroy {


  popUpTimer = 60;
  stopTimer: ISubscription;
  activeSubscription = true;
  canRetry = false;
  isLoading = false;

  @Input() secondFactorData: SecondFactorInterface;
  @Output() stopSecondFactor: EventEmitter<any> = new EventEmitter();


  constructor(private _secondFactor: SecondFactorService, private _sharedHttp: SharedHttpService) {}

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    if(event.key === 'Enter'){
      if(this.canRetry)
        this.retryUssd();
    }
  }

  ngOnInit() {
    this.initPopUpTimer();
  }

  ngOnDestroy(){
    this.activeSubscription = false;
  }

  initPopUpTimer() {
    this.isLoading = false;
    this.pollApi();
    this.popUpTimer = this.secondFactorData.TimeToLive;
    const popUpTimer = TimerObservable.create(1000, 1000);
    this.stopTimer = popUpTimer.subscribe(t => {
      this.popUpTimer -= 1;
      if (this.popUpTimer <= 0) {
        this.stopTimer.unsubscribe();
      }
    });
  }

  cancelUssd() {
    this.stopSecondFactor.next(false)
  }

  pollApi() {
    this.activeSubscription = true;
    TimerObservable.create(1000, 3000).pipe(takeWhile(()=> this.activeSubscription)).subscribe(timer => {
      this._secondFactor.pollUssd(this.secondFactorData.USSD).subscribe(res => {
        if (res.Method) {
          this.activeSubscription = false;
          this._secondFactor.currentSecondFactorData.next(res)
        } else if(res.Fallback === 'OTP'){
          this.activeSubscription = false;
          this.secondFactorData.Method = 'OTP';
          this._secondFactor.currentSecondFactorData.next(this.secondFactorData)
        }


        if (res['UssdResponse'] === 'Accept') {
          this._secondFactor.secondFactorStatus.next(true);
          this.stopSecondFactor.next(true)
        } else if(res['UssdResponse'] === 'Reject'){
          this.stopSecondFactor.next(false)
        } else if(res['Retry'] === true){
          this.activeSubscription = false;
          this.canRetry = true;
          this.popUpTimer = 1;
        }
      }, err => {

      })
    });

  }

  retryUssd(){
    this.stopTimer.unsubscribe();
    this.activeSubscription = false;
    this.canRetry = false;
    this.isLoading = true;

    this._sharedHttp.getCurrentProfileAccess().subscribe(res => {
      this.secondFactorData = {
        TimeToLive: res.TimeToLive,
        Method: res.OTPAction === 'SMS' ? 'OTP' : res.OTPAction,
        Destination: res.Destination,
        RequestId: res.RequestId ? res.RequestId : '',
        APP: res.SessionToken,
        USSD: res.SessionToken
      };

      this.initPopUpTimer();
    });
  }

}
