import { State, Action, StateContext, Selector } from '@ngxs/store';
import {patch, updateItem} from "@ngxs/store/operators";
import {Injectable} from "@angular/core";

export interface UserSetting {
  KeyId: number,
  RootKeyId: number,
  KeyName: string,
  Value: string,
  Value2: any,
  DisplayName: string,
  Sequence: number
}

export interface UserSettings extends Array<UserSetting>{}

export class AddUserSettings {
  static readonly type = '[UserSettings] Add';

  constructor(public payload: UserSettings) {}
}

export class UpdateUserSetting {
  static readonly  type = '[UserSetting] Update'

  constructor(public payload: {KeyId,KeyName,Value}) {}
}

export class UserSettingsStateModel {
  settings: UserSettings;
}

@State<UserSettingsStateModel>({
  name: 'userSettings'
})

@Injectable()

export class UserSettingsState {

  @Selector()
  static getUserSettings(state: UserSettingsStateModel) {
    return state.settings;
  }

  @Selector()
  static getDefaultCurrency(state: UserSettingsStateModel) {

    const curr = state.settings.filter(value => {
      return (value['KeyName'] === 'Currency')? value : false
    });

    return curr[0].Value
  }

  @Selector()
  static getProfileLoadingPreference(state: UserSettingsStateModel) {

    const preference = state.settings.filter(value => {
      return (value['KeyName'] === 'ProfileLoadingPreference')? value : false
    });

    return (JSON.parse(preference[0].Value))
  }

  @Selector()
  static getPushNotificationEnabled(state: UserSettingsStateModel) {

    const pushNotification = state.settings.filter(value => {
      return (value['KeyName'] === 'PushNotificationEnabled')? value : false
    });

    return (JSON.parse(pushNotification[0].Value))
  }

  @Action(AddUserSettings)
  add({getState, patchState }: StateContext<UserSettingsStateModel>, { payload }: AddUserSettings) {
    patchState({
      settings: payload
    });
  }

  @Action(UpdateUserSetting)
  updateUserSetting(ctx: StateContext<UserSettingsStateModel>, { payload }: UpdateUserSetting) {
    ctx.setState(
      patch({
        settings: updateItem<UserSetting>(item => item.KeyName === payload.KeyName, patch(payload))
      })
    );
  }
}
